
/** compressImage
 * @param {Blob} imgToCompress
 * @param {number} resizingFactor
 * @param {number} quality
       * @param {number} compressedImage
       * @param {number} compressedImageBlob
 */
async function compressImage(
    imgToCompress,
    resizingFactor,
    quality,
    // compressedImage,
) {
  let compressedImageBlob;
  // Descomentar linea siguiente y dentro de Promise para
  // visualizar y debuggear imagen comprimida
  // const compressedImage = document.createElement('img');
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const originalWidth = imgToCompress.width;
  const originalHeight = imgToCompress.height;
  const originalType = imgToCompress.src.substring(imgToCompress
      .src.indexOf(':image/') + 1, imgToCompress.src.indexOf(';base64'));

  const canvasWidth = originalWidth * resizingFactor;
  const canvasHeight = originalHeight * resizingFactor;

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  context.drawImage(
      imgToCompress,
      0,
      0,
      originalWidth * resizingFactor,
      originalHeight * resizingFactor,
  );

  // reducing the quality of the image
  return new Promise(function(resolve, reject) {
    canvas.toBlob(
        async (blob) => {
          if (blob) {
            compressedImageBlob = blob;
            // compressedImage.src =
            //   URL.createObjectURL(compressedImageBlob);
            resolve(compressedImageBlob);
          }
        },
        originalType,
        quality,
    );
  });
}

/**
 * fileToDataUri
 * @param {*} field
 * @return {Promise}
 */
function fileToDataUri(field) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      resolve(reader.result);
    });
    reader.readAsDataURL(field);
  });
}

/**
  * bytesToSize
  * @param {*} bytes
  * @return {string}
  */
function bytesToSize(bytes) {
  // const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  if (bytes === 0) {
    return 0;
  }

  // const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  // return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  return Number((bytes / 1024 / 1024).toFixed(3));
}

/**
  * blobToFile
  * @param {Blob} blob
  * @param {String} name
  * @return {File}
  */
function blobToFile(blob, name) {
  return new File([blob], name, {
    type: blob.type,
  });
}

/**
  * prepareImageToUpload
  * @param {File} file
  * @param {Number} resizingFactor
  * @param {Number} quality
  * @return {File}
 */
async function prepareImageToUpload(file, resizingFactor = 0.8, quality = 0.8) {
  return new Promise((resolve, reject) => {
    // originalImage.src = await fileToDataUri(file);
    fileToDataUri(file)
        .then((TempFileUrl)=>{
          // storing the original image
          const originalImage = document.createElement('img');
          originalImage.src = TempFileUrl;
          // compressing the uplodaded image
          originalImage.addEventListener('load', async () => {
            await compressImage(originalImage, resizingFactor, quality)
                .then((compressedImageBlob) => {
                  resolve(blobToFile(compressedImageBlob, file.name));
                });
          });
        });
  });
}

export {
  blobToFile,
  bytesToSize,
  compressImage,
  fileToDataUri,
  prepareImageToUpload,
};
