import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import {createProvider} from './vue-apollo';
import {fechaEsVe, formatDate} from './utils/validations';
import VueGtag from 'vue-gtag';
import VueOnlinePlugin from 'vue-navigator-online';
import Clipboard from 'v-clipboard';

import 'vue-select/dist/vue-select.css'; // Estilos del vue-select
import vSelect from 'vue-select';

import 'bootstrap-css-only/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbvue/lib/css/mdb.min.css';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import './fa-icons';

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('v-select', vSelect);

Vue.config.productionTip = false;

Vue.use(VueOnlinePlugin);
if (process.env.NODE_ENV === 'production') {
  Vue.use(VueGtag, {
    config: {id: 'G-RQ849VEKYF'},
    appName: 'AMII médico',
    pageTrackerScreenviewEnabled: true,
  }, router);
}

Vue.use(Clipboard);

Vue.filter('fecha_es_ve', fechaEsVe);
Vue.filter('format_date', formatDate);

(async function() {
  new Vue({
    router,
    apolloProvider: await createProvider(),
    render: (h) => h(App),
  }).$mount('#app');
})();
