<template>
  <div>
    <mdb-modal
      centered
      elegant
      size="lg"
      :show="showOtherState.stateId == 'otro en pais'
        || showOtherCity.cityId == 'otro en estado'
        || showOtherInstitute.medical_instituteID == 'otro en ciudad'
        || showOtherEducationalInst.educationalInstituteId == 'otro en ciudad'"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          Agregar
          <span
            v-if="showOtherState.stateId == 'otro en pais'"
            class="fs-inherit"
          >
            estado al país
          </span>
          <span
            v-else-if="showOtherCity.cityId == 'otro en estado'"
            class="fs-inherit"
          >
            ciudad al estado
          </span>
          <span
            v-else
            class="fs-inherit"
          >
            instituto a la ciudad
          </span>
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModalNewAddress"
          class="close"
          @click="closeForm"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <form @submit.prevent="createGeneral">
          <div class="row justify-content-center">
            <div class="col-12">
              <mdb-input
                v-model="name"
                class="my-1"
                label="Nombre"
                outline
              />
            </div>
            <div
              v-if="showOtherInstitute.medical_instituteID == 'otro en ciudad'
                || showOtherEducationalInst.educationalInstituteId
                  == 'otro en ciudad'"
              class="col-12"
            >
              <mdb-input
                v-model="address.line"
                class="my-1"
                label="Edificio/Casa"
                outline
              />
            </div>
            <div
              v-if="showOtherInstitute.medical_instituteID == 'otro en ciudad'
                || showOtherEducationalInst.educationalInstituteId
                  == 'otro en ciudad'"
              class="col-12"
            >
              <mdb-input
                v-model="address.street"
                class="my-1"
                label="Calle/Avenida"
                outline
              />
            </div>
            <div
              v-if="showOtherInstitute.medical_instituteID == 'otro en ciudad'
                || showOtherEducationalInst.educationalInstituteId
                  == 'otro en ciudad'"
              class="col-12"
            >
              <mdb-input
                v-model="address.zip"
                class="my-1"
                label="Código postal"
                outline
              />
            </div>
          </div>
          <div class="row justify-content-between my-2 mx-sm-1 mx-md-2">
            <mdb-btn
              ref="btnCerrar"
              type="button"
              class="px-3 flat-bordered"
              flat
              dark-waves
              icon="times"
              @click="closeForm"
            >
              Cerrar
            </mdb-btn>
            <BtnLoader
              btn-type="submit"
              btn-classes="px-3"
              :btn-disabled="btnDisabled"
              btn-title="Agregar"
            />
          </div>
        </form>
      </mdb-modal-body>
    </mdb-modal>
    <AlertaMsj :alerta-msj="alertaMsj" />
  </div>
</template>
<script>
import {mdbBtn, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody,
  mdbInput} from 'mdbvue';
import BtnLoader from '@/components/customButtons/BtnLoader.vue';
import AlertaMsj from './AlertaMsj.vue';
import gql from 'graphql-tag';
import statesGql from '@/graphql/states.gql';
import citiesGql from '@/graphql/cities.gql';
import medicalInstitutesInCityGql from '@/graphql/medicalInstitutesInCity.gql';
import stateCreateGql from '@/graphql/stateCreate.gql';
import cityCreateGql from '@/graphql/cityCreate.gql';
import medicalinstituteCreateGql from '@/graphql/medicalinstituteCreate.gql';
import educationalinstituteCreateGql
  from '@/graphql/educationalinstituteCreate.gql';
import {errorMessages} from '@/utils/validations.js';

export default {
  name: 'NuevasUbicaciones',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbInput,
    AlertaMsj,
    BtnLoader,
  },
  props: {
    showOtherState: {
      type: Object,
      default: function() {
        return {};
      },
    },
    showOtherCity: {
      type: Object,
      default: function() {
        return {};
      },
    },
    showOtherInstitute: {
      type: Object,
      default: function() {
        return {};
      },
    },
    showOtherEducationalInst: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      name: '',
      address: {
        line: '',
        street: '',
        zip: '',
      },
      alertaMsj: {
        content: '',
      },
      btnDisabled: false,
      offlineId: null,
    };
  },
  methods: {
    createGeneral() {
      if (this.showOtherState.stateId == 'otro en pais') {
        if (!this.showOtherState.countryId) {
          return this.alertaMsj = {
            content: 'Seleccione un país antes de crear un nuevo Estado',
            type: 'warning',
          };
        }
        if (!this.name) {
          return this.alertaMsj = {
            content: 'Llena el nombre del Estado',
            type: 'warning',
          };
        }
        this.offlineId = this.showOtherState.countryId;
        this.btnDisabled = true;
        this.$apollo.mutate({
          mutation: gql`${stateCreateGql}`,
          variables: {
            state: {
              name: this.name,
            },
            countryId: this.offlineId,
          },
          update: (store, {data: {stateCreate}}) => {
            const data = store.readQuery({
              query: statesGql,
              variables: {
                id: this.offlineId,
              },
            });
            data.states.push(stateCreate);
            store.writeQuery({
              query: statesGql,
              variables: {
                id: this.offlineId,
              },
              data,
            });
            this.$emit('newState', stateCreate);
            this.name = '';
            this.btnDisabled = false;
            this.alertaMsj = {
              content: 'Agregado nuevo Estado al país',
              type: 'success',
            };
          },
          optimisticResponse: {
            __typename: 'Mutation',
            stateCreate: {
              __typename: 'State',
              id: -1,
              name: this.name,
            },
          },
        }).catch((error) => {
          this.btnDisabled = false;
          return this.alertaMsj = errorMessages(
              error,
              'Hubo un error al crear nuevo Estado',
              'danger',
          );
        });
      }
      if (this.showOtherCity.cityId == 'otro en estado') {
        if (!this.showOtherCity.stateId
          || this.showOtherCity.stateId == 'otro en pais') {
          return this.alertaMsj = {
            content: 'Seleccione un estado antes de crear una nueva Ciudad',
            type: 'warning',
          };
        }
        if (!this.name) {
          return this.alertaMsj = {
            content: 'Llena el nombre de la Ciudad',
            type: 'warning',
          };
        }
        this.offlineId = this.showOtherCity.stateId;
        this.btnDisabled = true;
        this.$apollo.mutate({
          mutation: gql`${cityCreateGql}`,
          variables: {
            city: {
              name: this.name,
            },
            stateId: this.showOtherCity.stateId,
          },
          update: (store, {data: {cityCreate}}) => {
            const data = store.readQuery({
              query: citiesGql,
              variables: {
                id: this.offlineId,
              },
            });
            data.cities.push(cityCreate);
            store.writeQuery({
              query: citiesGql,
              variables: {
                id: this.offlineId,
              },
              data,
            });
            this.$emit('newCity', cityCreate);
            this.name = '';
            this.btnDisabled = false;
            this.alertaMsj = {
              content: 'Agregada nueva ciudad al Estado',
              type: 'success',
            };
          },
          optimisticResponse: {
            __typename: 'Mutation',
            cityCreate: {
              __typename: 'City',
              id: -1,
              name: this.name,
            },
          },
        }).catch((error) => {
          this.btnDisabled = false;
          this.alertaMsj = {
            content: 'Hubo un error al crear nueva Ciudad',
            type: 'danger',
          };
        });
      }
      if (this.showOtherInstitute.medical_instituteID == 'otro en ciudad') {
        if (!this.showOtherInstitute.cityId
          || this.showOtherInstitute.cityId == 'otro en estado') {
          return this.alertaMsj = {
            content: 'Seleccione una ciudad antes de crear un nuevo instituto',
            type: 'warning',
          };
        }
        if (!this.name) {
          return this.alertaMsj = {
            content: 'Llena el nombre del instituto',
            type: 'warning',
          };
        }
        if (!this.address.line || !this.address.street) {
          return this.alertaMsj = {
            content: 'Llena los datos de la dirección del Instituto',
            type: 'warning',
          };
        }
        this.offlineId = this.showOtherInstitute.cityId;
        this.btnDisabled = true;
        this.$apollo.mutate({
          mutation: gql`${medicalinstituteCreateGql}`,
          variables: {
            cityId: this.showOtherInstitute.cityId,
            address: this.address,
            medicalInstitute: {
              name: this.name,
            },
          },
          context: {
            serializationKey: 'Lugares',
          },
          update: (store, {data: {medicalinstituteCreate}}) => {
            const data = store.readQuery({
              query: medicalInstitutesInCityGql,
              variables: {
                cityId: this.offlineId,
              },
            });
            data.medicalInstitutesInCity.push(medicalinstituteCreate);
            store.writeQuery({
              query: medicalInstitutesInCityGql,
              variables: {
                cityId: this.offlineId,
              },
              data,
            });
            this.$emit('newInstitute', medicalinstituteCreate);
            this.name = '';
            this.address = {};
            this.btnDisabled = false;
            this.alertaMsj = {
              content: 'Agregado nuevo instituto a la ciudad',
              type: 'success',
            };
          },
          optimisticResponse: {
            __typename: 'Mutation',
            medicalinstituteCreate: {
              __typename: 'Medical_Institute',
              // No cambiar el valor, el -1 se valida en LugaresDeTrabajo.vue
              id: -1, // Leer arriba antes de cambiar.
              name: this.name,
            },
          },
        }).catch((error) => {
          this.btnDisabled = false;
          this.alertaMsj = {
            content: 'Hubo un error al crear nuevo instituto',
            type: 'danger',
          };
        });
      }
      if (this.showOtherEducationalInst.educationalInstituteId
          == 'otro en ciudad') {
        if (!this.showOtherEducationalInst.cityId
          || this.showOtherEducationalInst.cityId == 'otro en estado') {
          return this.alertaMsj = {
            content: 'Seleccione una ciudad antes de '
              +'crear un nuevo instituto de educación',
            type: 'warning',
          };
        }
        if (!this.name) {
          return this.alertaMsj = {
            content: 'Llena el nombre del instituto de educación',
            type: 'warning',
          };
        }
        if (!this.address.line || !this.address.street) {
          return this.alertaMsj = {
            content: 'Llena los datos de la dirección del Instituto',
            type: 'warning',
          };
        }
        this.btnDisabled = true;
        this.$apollo.mutate({
          mutation: gql`${educationalinstituteCreateGql}`,
          variables: {
            cityId: this.showOtherEducationalInst.cityId,
            address: this.address,
            educationalInstitute: {
              name: this.name,
            },
          },
        }).then((res) => {
          const institute = res.data.educationalinstituteCreate;
          this.$emit('newEducationalInst', institute);
          this.name = '';
          this.address = {};
          this.btnDisabled = false;
        }).catch((error) => {
          this.btnDisabled = false;
          this.alertaMsj = {
            content: 'Hubo un error al crear nuevo Instituto de Educación',
            type: 'danger',
          };
        });
      }
    },
    closeForm() {
      this.name = '';
      this.address = {};
      this.$emit('closeForm');
    },
  },
  apollo: {},
};
</script>
<style lang="scss">

</style>
