<template>
  <!--
    Modal para ver gráfico en
    pantalla completa
  -->
  <mdb-modal
    class=""
    size="fluid"
    :show="showChartFull"
    @close="$emit('close', false)"
  >
    <mdb-modal-body class="delete-document">
      <p
        class="my-2 text-center"
      >
        <span class="font-weight-bold">
          <!-- {{ options.options.plugins.title.text }} -->
        </span>
      </p>
      <Chart
        :cref="'full'"
        :options="options"
      />
      <div class="d-flex justify-content-center">
        <mdb-btn
          ref="btnCancelar"
          class="flat-bordered order-2 order-sm-1"
          flat
          icon="times"
          dark-waves
          @click="$emit('close', false)"
        >
          Cerrar
        </mdb-btn>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>
<script>
import {mdbBtn, mdbModal, mdbModalBody} from 'mdbvue';
import Chart from '@/components/charts/ChartView.vue';

export default {
  name: 'ModalChartFullSize',
  components: {
    Chart,
    mdbBtn,
    mdbModal,
    mdbModalBody,
  },
  props: {
    showChartFull: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
  apollo: {},
};
</script>
<style lang="scss" scoped>

</style>
