/**
 * Ckeditor instance
 * @param {Object} doc
 */
export function print(doc) {
  const snippetCSSElement = [...document.querySelectorAll('link')]
      .find((linkElement) => linkElement.href.endsWith('snippet.css'));

  const iframeElement = document.querySelector('#print-data-container');

  iframeElement.srcdoc = '<html>'
          +'<head>'
          +`<title>${document.title}</title>`
          + `<link rel='stylesheet' href="
          ${snippetCSSElement.href}" type='text/css'>`
          +`<style>`
          +`.bg-edit::before {content: ' '; opacity: ${doc.bgImgOpacity};`
          +` position: absolute; display: block; left: 0; top: 0;`
          +` width: 100%; height: 100%;`
          +` background-position: ${doc.bgImgPosition};`
          +` background-repeat: ${doc.bgImgRepeat};`
          +` background-size: ${doc.bgImgSize};`
          +` background-image: url('${doc.bgImgUrl}');  z-index: -1;}`
          +`</style>`
          +'</head>'
          +`<body class="ck-content bg-edit" style="`
          +`background: ${doc.bgColor};`
          +` print-color-adjust: exact;">`
          +`<div class="editor-content">`
          +doc.body
          +'</div>'
          + (doc.bgImgUrl
            ? `<img src='${doc.bgImgUrl}' style="height:0px">` : '')
          +'<script>'
          +'window.addEventListener( \'load\', '
          +'() => { window.print(); } );'
           +'</script>'
           +'</body>'
           +'</html>';
}
