import {InMemoryCache} from 'apollo-cache-inmemory';
// Comentado por falta de gestión de la versión de cache
// https://github.com/apollographql/apollo-cache-persist/blob/HEAD/docs/faq.md
// import {persistCache, LocalStorageWrapper} from 'apollo3-cache-persist';

const cache= new InMemoryCache({
  cacheRedirects: {
    Query: {
      Patient: (_, {id}, {getCacheKey}) =>
        getCacheKey({__typename: 'Patient', id}),
      Appointment: (_, {id}, {getCacheKey}) =>
        getCacheKey({__typename: 'Appointment', id}),
    },
  },
});

cache.writeData({
  data: {
    estatusNotificaciones: {
      __typename: 'EstatusNotification',
      permiso: 'default',
      esCompatible: !('serviceWorker' in navigator)
      ? false : !('PushManager' in window)
      ? false : true,
      existeSuscripcion: false,
    },
  },
});

// persistCache({
//   cache,
//   storage: new LocalStorageWrapper(window.localStorage),
//   maxSize: false,
// });

export {cache};
