/* eslint-disable eol-last */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import VueApollo/* , {ApolloProvider} */ from 'vue-apollo';
import {createApolloClient, restartWebsockets}
  from 'vue-cli-plugin-apollo/graphql-client';
import {errorMessages} from '@/utils/validations.js';
import {ApolloLink, fromPromise} from 'apollo-link';
import {RetryLink} from 'apollo-link-retry';
import {persistQueue} from 'apollo-link-queue-persist';
import QueueLink from '@/libs/apollo-link-queue.js';
import SerializingLink from 'apollo-link-serialize';
import {onError} from 'apollo-link-error';

import {getPersonId} from '@/utils/authdecode.js';
import renovarTokenGql from '@/graphql/renovarToken.gql';
import {cache} from '@/state/initCache.js';
import {typeDefs, resolvers} from '@/state/schema.js';

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';
const ERROR_TOKEN = {
  tokenExpired: 'Your token is expired',
  token_expired: 'token_expired',
  autenticacionRequerida: 'autenticacion_requerida',
};

// Http endpoint
const httpEndpoint = process.env.NODE_ENV === 'production'
  ? process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql'
  : 'http://localhost:4000/graphql';
// Files URL root
export const filesRoot =
  process.env.VUE_APP_FILES_ROOT
  || httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'));

Vue.prototype.$filesRoot = filesRoot;


const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    return !!error && navigator.onLine && count <= 7;
  },
});
const queueLink = new QueueLink();
const serializingLink = new SerializingLink();

// Optionally only queue mutations
QueueLink.setFilter(['query']);

// window.offlineLink = queueLink;
window.addEventListener('offline', () => queueLink.close());
window.addEventListener('online', () => queueLink.open());

const getNewToken = async () => {
  const personId = getPersonId();
  const {data} = await apolloClient.mutate({
    mutation: renovarTokenGql,
    variables: {personId},
  });
  return data?.renovarToken?.token;
};
const errorLink = onError(
    ({graphQLErrors, /*  networkError,  */operation, forward}) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          switch (err.message) {
            case ERROR_TOKEN.tokenExpired:
            case ERROR_TOKEN.token_expired:
            case ERROR_TOKEN.autenticacionRequerida:
              return fromPromise(
                  getNewToken().catch((error) => {
                    // clean token of LS and redirect to login
                    onLogout(apolloClient);
                    // localStorage.removeItem(AUTH_TOKEN);
                    console.log(error);
                    return;
                  }),
              )
                  .filter((value) => Boolean(value))
                  .flatMap((accessToken) => {
                    localStorage.setItem(AUTH_TOKEN, accessToken);
                    // modify authorization header with refreshToken
                    operation.setContext({
                      headers: {
                        authorization: `Bearer ${accessToken}`,
                      },
                    });

                    // retry the request, returning the new observable
                    return forward(operation);
                  });
          }
        }
      }
    },
);

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  inMemoryCacheOptions: {
    freezeResults: false,
  },
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  // websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: ApolloLink.from([
    // loggerLink,
    errorLink,
    queueLink,
    serializingLink,
    retryLink,
    // httpLink,
  ]),

  // Override default cache
  cache,

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
  typeDefs,
  resolvers,
};
// Create apollo client
const {apolloClient, wsClient} = createApolloClient({
  ...defaultOptions,
});
// Call this in the Vue app file
/**
 * Crear proveedor de Apollo
 * @param {Object} options Options
 * @return {ApolloProvider} Apollo
 */
export async function createProvider(options = {}) {
  apolloClient.wsClient = wsClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      let mensaje;
      if (error.message.indexOf('Your token is expired') !== -1) {
        // if (getRole() == 'Doctor') {
        // setTimeout(() => {
        //   this.$router.push('/pagos');
        // }, 20 * 1000);
        // mensaje = 'Parece que olvidaste pagar el servicio de AMII. '
        // +'Ve al menú de pagos para seguir disfrutando de las ventajas que '
        // +'AMII tiene para ti.';
        // }
      } else if (error.message
          .indexOf('GraphQL error: You are not authorized') !== -1) {
        mensaje = errorMessages(
            error,
            'Se venció tu sesión',
        ).content;
      } else {
        mensaje = errorMessages(
            error,
            'Error desconocido mientras se consultaban los datos '
            + '¿Tenemos conexión a internet?',
        ).content;
      }
      if (this.alertaMsj) {
        this.alertaMsj = {
          content: mensaje,
          type: 'warning',
        };
      } else {
        alert(mensaje);
      }
      if (process.env.NODE_ENV === 'production') {
        this.$gtag.event('exception', {
          description: error.message,
          fatal: false,
        });
      } else {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log('%cError', 'background: red; color: white; padding: 2px'
          +' 4px; border-radius: 3px; font-weight: bold;', error.message);
        }
      }
    },
  });

  // Persiste la cola queue en storage
  await persistQueue({
    queueLink,
    storage: window.localStorage,
    client: apolloClient,
  });

  return apolloProvider;
}


// Manually call this when user log in
/**
 * onLogin al hacer login
 * @param {apolloClient} apolloClient apollo Client
 * @param {String} token token de sesión
 */
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out

/**
 * onLogin al hacer login
 * @param {apolloClient} apolloClient apollo Client
 * @param {String} token token de sesión
 */
export function onLogout(apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
  window.removeEventListener('offline', () => queueLink.close());
  window.removeEventListener('online', () => queueLink.open());
}

/**
 * isAuthenticated valida si esta autenticado el usuario
 * @param {Function} to
 * @param {Function} from
 * @param {Function} next
 * @return {Boolean}
 */
export function isAuthenticated(to, from, next) {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem(AUTH_TOKEN) ? next() : next('/login');
  }
}

/**
 * isNotAuthenticated valida si no esta autenticado el usuario
 * @param {Function} to
 * @param {Function} from
 * @param {Function} next
 * @return {Boolean}
 */
export function isNotAuthenticated(to, from, next) {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem(AUTH_TOKEN) ? next('/') : next();
  }
}
