<template>
  <section>
    <div id="app">
      <Navbar />
      <router-view />
    </div>
    <AlertaSnackBar
      :alerta-snack-bar="{
        color: 'informativo',
        contenido: 'Nueva versión disponible',
      }"
      :btn-accion="{ icono: 'rotate', texto: 'Clic para actualizar'}"
      :mostrar-snack-bar="existeActualizacion"
      @btnAccion="actualizarApp"
      @cerrar="existeActualizacion=false"
    />
  </section>
</template>
<script>
import Navbar from '@/components/Navbar.vue';
import AlertaSnackBar from '@/components/AlertaSnackBar.vue';
export default {
  components: {
    Navbar,
    AlertaSnackBar,
  },
  data() {
    return {
      cargandoApp: false,
      detalles: null,
      estaActualizando: false,
      existeActualizacion: false,
    };
  },
  created() {
    this.cargandoApp = true;
    setTimeout(()=> this.cargandoApp = false, 250);
    document.addEventListener(
        'actualizacionServiceWorker',
        this.mostrarActualizacion,
        {once: true},
    );
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.estaActualizando) return;
        this.estaActualizando = true;
        window.location.reload();
      });
    }
  },
  methods: {
    mostrarActualizacion(e) {
      this.detalles = e.detail;
      this.existeActualizacion = true;
    },
    actualizarApp() {
      this.existeActualizacion = false;
      if (!this.detalles || !this.detalles.waiting) return;
      this.detalles.waiting.postMessage({type: 'SKIP_WAITING'});
    },
  },
};
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 56px;
}
</style>
