<template>
  <div
    :class="['cchart w-100', {'vh-mobile': cref != 'chart'}]"
  >
    <canvas
      :ref="cref"
    />
  </div>
</template>
<script>
import ChartJS from 'chart.js/auto';
let ctx = null;
export default {
  name: 'Chart',
  components: {
  },
  props: {
    options: {
      type: Object,
      require: true,
      default() {
        return {};
      },
    },
    cref: {
      type: String,
      require: false,
      default: 'chart',
    },
  },
  data() {
    return {
    };
  },
  watch: {
    options() {
      if (ctx) {
        ctx.destroy();
      }
      ctx = new ChartJS(this.$refs[this.cref], this.options);
    },
  },
  mounted() {
    if (this.options.data) {
      if (ctx) {
        ctx.destroy();
      }
      ctx = new ChartJS(this.$refs[this.cref], this.options);
    }
  },
  methods: {},
};
</script>

<style scoped>
  .cchart{
    height: auto;
    min-height: 230px;
    @media (max-width: 576px) {
      height: 60vh;
      position: relative;
    }
  }
  .vh-mobile{
    height: 60vh;
  }
</style>
