var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('mdb-modal',{attrs:{"centered":"","elegant":"","size":"lg","show":_vm.showOtherState.stateId == 'otro en pais'
      || _vm.showOtherCity.cityId == 'otro en estado'
      || _vm.showOtherInstitute.medical_instituteID == 'otro en ciudad'
      || _vm.showOtherEducationalInst.educationalInstituteId == 'otro en ciudad'}},[_c('mdb-modal-header',{attrs:{"close":false}},[_c('mdb-modal-title',[_vm._v(" Agregar "),(_vm.showOtherState.stateId == 'otro en pais')?_c('span',{staticClass:"fs-inherit"},[_vm._v(" estado al país ")]):(_vm.showOtherCity.cityId == 'otro en estado')?_c('span',{staticClass:"fs-inherit"},[_vm._v(" ciudad al estado ")]):_c('span',{staticClass:"fs-inherit"},[_vm._v(" instituto a la ciudad ")])]),_c('a',{ref:"mdbModalCerrarModalNewAddress",staticClass:"close",on:{"click":_vm.closeForm}},[_vm._v("×")])],1),_c('mdb-modal-body',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.createGeneral.apply(null, arguments)}}},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('mdb-input',{staticClass:"my-1",attrs:{"label":"Nombre","outline":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),(_vm.showOtherInstitute.medical_instituteID == 'otro en ciudad'
              || _vm.showOtherEducationalInst.educationalInstituteId
                == 'otro en ciudad')?_c('div',{staticClass:"col-12"},[_c('mdb-input',{staticClass:"my-1",attrs:{"label":"Edificio/Casa","outline":""},model:{value:(_vm.address.line),callback:function ($$v) {_vm.$set(_vm.address, "line", $$v)},expression:"address.line"}})],1):_vm._e(),(_vm.showOtherInstitute.medical_instituteID == 'otro en ciudad'
              || _vm.showOtherEducationalInst.educationalInstituteId
                == 'otro en ciudad')?_c('div',{staticClass:"col-12"},[_c('mdb-input',{staticClass:"my-1",attrs:{"label":"Calle/Avenida","outline":""},model:{value:(_vm.address.street),callback:function ($$v) {_vm.$set(_vm.address, "street", $$v)},expression:"address.street"}})],1):_vm._e(),(_vm.showOtherInstitute.medical_instituteID == 'otro en ciudad'
              || _vm.showOtherEducationalInst.educationalInstituteId
                == 'otro en ciudad')?_c('div',{staticClass:"col-12"},[_c('mdb-input',{staticClass:"my-1",attrs:{"label":"Código postal","outline":""},model:{value:(_vm.address.zip),callback:function ($$v) {_vm.$set(_vm.address, "zip", $$v)},expression:"address.zip"}})],1):_vm._e()]),_c('div',{staticClass:"row justify-content-between my-2 mx-sm-1 mx-md-2"},[_c('mdb-btn',{ref:"btnCerrar",staticClass:"px-3 flat-bordered",attrs:{"type":"button","flat":"","dark-waves":"","icon":"times"},on:{"click":_vm.closeForm}},[_vm._v(" Cerrar ")]),_c('BtnLoader',{attrs:{"btn-type":"submit","btn-classes":"px-3","btn-disabled":_vm.btnDisabled,"btn-title":"Agregar"}})],1)])])],1),_c('AlertaMsj',{attrs:{"alerta-msj":_vm.alertaMsj}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }