
import {Essentials} from '@ckeditor/ckeditor5-essentials';
import {Link} from '@ckeditor/ckeditor5-link';
import {Paragraph} from '@ckeditor/ckeditor5-paragraph';
import {Alignment} from '@ckeditor/ckeditor5-alignment';
import {Autoformat} from '@ckeditor/ckeditor5-autoformat';
import {BlockQuote} from '@ckeditor/ckeditor5-block-quote';
import {Bold, Code, Italic, Strikethrough, Subscript, Superscript, Underline}
  from '@ckeditor/ckeditor5-basic-styles';
import {DocumentList} from '@ckeditor/ckeditor5-list';
import {Font} from '@ckeditor/ckeditor5-font';
import {Heading} from '@ckeditor/ckeditor5-heading';
import {Highlight} from '@ckeditor/ckeditor5-highlight';
import {HorizontalLine} from '@ckeditor/ckeditor5-horizontal-line';
import {
  Image, ImageCaption, ImageResize,
  ImageStyle, ImageToolbar, ImageUpload,
}
  from '@ckeditor/ckeditor5-image';
import {Indent, IndentBlock} from '@ckeditor/ckeditor5-indent';
import {Mention} from '@ckeditor/ckeditor5-mention';
import {PageBreak} from '@ckeditor/ckeditor5-page-break';
import {PasteFromOffice} from '@ckeditor/ckeditor5-paste-from-office';
import {RemoveFormat} from '@ckeditor/ckeditor5-remove-format';
import {SpecialCharacters, SpecialCharactersEssentials}
  from '@ckeditor/ckeditor5-special-characters';
import {
  Table, TableToolbar, TableUtils, TableProperties,
  TableCellProperties, TableColumnResize,
}
  from '@ckeditor/ckeditor5-table';
import {TextTransformation} from '@ckeditor/ckeditor5-typing';
import {GeneralHtmlSupport} from '@ckeditor/ckeditor5-html-support';
import {
  prepareImageToUpload,
} from '@/utils/imageCompress.js';
import {getPersonId} from '@/utils/authdecode.js';
/**
 * Personalización del mention para aceptar atributos data-
 * @param {editor} editor Instancia del editor
 */
function MentionCustomization(editor) {
  // The upcast converter will convert
  // <a class="mention" href="" data-user-id="">
  // elements to the model 'mention' attribute.
  editor.conversion.for('upcast').elementToAttribute({
    view: {
      name: 'span',
      key: 'data-mention',
      classes: ['mention', 'editor-var'],
      attributes: {
        'data-type': true,
        'data-id': true,
      },
    },
    model: {
      key: 'mention',
      value: (viewItem) => {
        // The mention feature expects that the mention attribute value
        // in the model is a plain object with a set of additional attributes.
        // In order to create a proper object, use the toMentionAttribute
        // helper method:
        const mentionAttribute = editor.plugins.get('Mention')
            .toMentionAttribute(viewItem, {
              // Add any other properties that you need.
              type: viewItem.getAttribute('data-type'),
              dataId: viewItem.getAttribute('data-id'),
            });

        return mentionAttribute;
      },
    },
    converterPriority: 'high',
  });

  // Downcast the model 'mention' text attribute to a view <span> element.
  editor.conversion.for('downcast').attributeToElement({
    model: 'mention',
    view: (modelAttributeValue, {writer}) => {
      if (!modelAttributeValue) {
        return;
      }
      return writer.createAttributeElement('span', {
        'class': 'mention editor-var',
        'data-type': modelAttributeValue.type,
        'data-id': modelAttributeValue.dataId,
      }, {
        // Make mention attribute to be wrapped by other attribute elements.
        priority: 20,
        // Prevent merging mentions together.
        id: modelAttributeValue.uid,
      });
    },
    converterPriority: 'high',
  });
}
/**
 * MyUploadAdapter
 */
class MyUploadAdapter {
  /**
   * Constructor
   * @param {*} loader
   * @param {*} docId
   * @param {*} entity
   */
  constructor(loader, docId, entity) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.docId = docId;
    this.entity = entity;
  }

  // Starts the upload process.
  /**
   * Upload
   * @return {*}
   */
  upload() {
    return this.loader.file
        .then((file) => new Promise((resolve, reject) => {
          this._sendRequest(resolve, reject, file, this.docId, this.entity);
        }));
  }

  // Prepares the data and sends the request.
  /**
   * _sendRequest
   * @param {*} resolve
   * @param {*} reject
   * @param {file} file
   * @param {String} docId
   * @param {String} entity
   */
  async _sendRequest(resolve, reject, file, docId, entity) {
    if (!docId.length) {
      reject('Selecciona o guarda el '
      + 'nuevo documento antes de subir una imagen');
      return;
    }
    prepareImageToUpload(file)
        .then((fileResized)=>{
          // Prepare the form data.
          const formData = new FormData();

          formData.append('file', fileResized);
          formData.append('entityId', docId);
          formData.append('entity', entity);
          const endpoint = process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_MULTIMEDIA_SUBIR_S
          : process.env.VUE_APP_MULTIMEDIA_SUBIR;

          fetch(endpoint, {
            method: 'POST',
            headers: {'Authorization': 'Bearer '
              + localStorage.getItem('apollo-token')},
            body: formData,
          })
              .then((res) => res.json())
              .then((res)=>{
                const data = res.data;
                if (data.imageCreate[0] && data.imageCreate[0].id) {
                  const endpoint = process.env.NODE_ENV === 'production'
                    ? process.env.VUE_APP_MULTIMEDIA_HTTPS+getPersonId()
                    : process.env.VUE_APP_MULTIMEDIA_HTTP+getPersonId();
                  const url = data.imageCreate[0].url;

                  resolve({
                    default: endpoint+'/'+url,
                  });
                } else {
                  // this.alertaMsj = {
                  //   content: 'Intenta guardar la imagen de nuevo',
                  //   type: 'warning',
                  // };
                  reject('No se pudo subir la imagen. Intenta de nuevo');
                }
              }).catch((err)=>{
                reject('No se pudo subir la imagen. Intenta de nuevo');
                // this.alertaMsj = {
                //   content: 'No se guardó la imagen. Intenta de nuevo',
                //   type: 'warning',
                // };
              });
        });
  }
}

/**
 * MyCustomUploadAdapterPlugin
 * @param {*} editor
 */
function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    // Configure the URL to the upload script in your back-end here!
    const docId = editor.config.get('documentId');
    const entity = editor.config.get('entity');
    return new MyUploadAdapter(loader, docId, entity);
  };
}
/**
 * Dowcansting configuration
 * @param {*} editor
 */
function ImageBlockAndImageInlineCustomization(editor) {
  editor.conversion.for('upcast').attributeToAttribute({
    view: {name: 'div', key: 'class'},
    model: 'class',
  })
      .attributeToAttribute({
        view: {name: 'div', key: 'data-type'},
        model: 'data-type',
      })
      .attributeToAttribute({
        view: {name: 'div', key: 'data-id'},
        model: 'data-id',
      });

  editor.conversion.for('upcast').attributeToAttribute({
    model: 'dataId',
    view: 'data-id',
  });

  editor.conversion.for('upcast').attributeToAttribute({
    model: 'dataType',
    view: 'data-type',
  });

  editor.conversion.for('editingDowncast').add((dispatcher) => {
    dispatcher.on('attribute:class:imageInline',
        (evt, data, {writer, consumable, mapper}) => {
          if (!consumable.consume(data.item, evt.name)) {
            return;
          }
          const imageContainer = mapper.toViewElement(data.item);
          const imageElement = imageContainer.getChild(0);
          if (data.attributeNewValue !== null) {
            writer.setAttribute('class', data.attributeNewValue, imageElement);
          } else {
            writer.removeAttribute('class', imageElement);
          }
        });
    dispatcher.on('attribute:data-type:imageInline',
        (evt, data, {writer, consumable, mapper}) => {
          if (!consumable.consume(data.item, evt.name)) {
            return;
          }
          const imageContainer = mapper.toViewElement(data.item);
          const imageElement = imageContainer.getChild(0);
          if (data.attributeNewValue !== null) {
            writer.setAttribute('data-type',
                data.attributeNewValue, imageElement);
          } else {
            writer.removeAttribute('data-type', imageElement);
          }
        });
    dispatcher.on('attribute:data-id:imageInline',
        (evt, data, {writer, consumable, mapper}) => {
          if (!consumable.consume(data.item, evt.name)) {
            return;
          }
          const imageContainer = mapper.toViewElement(data.item);
          const imageElement = imageContainer.getChild(0);
          if (data.attributeNewValue !== null) {
            writer.setAttribute('data-id',
                data.attributeNewValue, imageElement);
          } else {
            writer.removeAttribute('data-id', imageElement);
          }
        });
  });
  editor.conversion.for('downcast').add(
      (dispatcher) => {
        dispatcher.on('attribute:class:imageBlock',
            (evt, data, conversionApi) => {
              if (!conversionApi.consumable.consume(data.item, evt.name)) {
                return;
              }
              const viewWriter = conversionApi.writer;
              const figure = conversionApi.mapper.toViewElement(data.item);
              const img = figure.getChild(0);

              if (data.attributeNewValue !== null) {
                viewWriter.setAttribute('class', data.attributeNewValue, img);
              } else {
                viewWriter.removeAttribute('class', img);
              }
            });
        dispatcher.on('attribute:data-type:imageBlock',
            (evt, data, conversionApi) => {
              if (!conversionApi.consumable.consume(data.item, evt.name)) {
                return;
              }
              const viewWriter = conversionApi.writer;
              const figure = conversionApi.mapper.toViewElement(data.item);
              const img = figure.getChild(0);

              if (data.attributeNewValue !== null) {
                viewWriter.setAttribute('data-type',
                    data.attributeNewValue, img);
              } else {
                viewWriter.removeAttribute('data-type', img);
              }
            });
        dispatcher.on('attribute:data-id:imageBlock',
            (evt, data, conversionApi) => {
              if (!conversionApi.consumable.consume(data.item, evt.name)) {
                return;
              }
              const viewWriter = conversionApi.writer;
              const figure = conversionApi.mapper.toViewElement(data.item);
              const img = figure.getChild(0);

              if (data.attributeNewValue !== null) {
                viewWriter.setAttribute('data-id', data.attributeNewValue, img);
              } else {
                viewWriter.removeAttribute('data-id', img);
              }
            });
      });
}
/**
 * insertStaticImage
 * @param {*} id
 * @param {*} editor
 */
function insertStaticImage(id, editor) {
  const imageUrl = '/img/vectores/AMII_img_temp.svg';

  editor.model.change((writer) => {
    const imageElement = writer.createElement('imageBlock',
        {
          'src': imageUrl,
          'alt': 'Imagen. Asegura haberla guardado o agregado.',
          'data-type': 'myType',
          'data-id': 'myId',
        });
    const type = 'Parameter';
    writer.setAttribute('class', 'editor-var', imageElement);
    writer.setAttribute('data-type', type, imageElement);
    writer.setAttribute('data-id', id, imageElement);
    editor.model.schema.extend('imageBlock',
        {
          allowAttributes: ['class', 'data-type', 'data-id'],
        });
    editor.model.schema.extend('imageInline', {
      allowAttributes: ['class', 'data-type', 'data-id'],
    });
    editor.model.insertContent(imageElement, editor.model.document.selection);
  });
}

/**
 * ckeditorConfigEdit
 * @param {*} getVariables
 * @return {Object}
 */
function ckeditorConfigEdit(getVariables) {
  return {
    plugins: [
      Essentials,
      Link,
      Paragraph,
      Alignment,
      Autoformat,
      BlockQuote,
      Bold,
      Italic,
      Underline,
      Strikethrough,
      Code,
      Subscript,
      Superscript,
      DocumentList,
      Font,
      Heading,
      Highlight,
      HorizontalLine,
      Image,
      ImageToolbar,
      ImageCaption,
      ImageStyle,
      ImageResize,
      Indent,
      IndentBlock,
      Mention,
      MentionCustomization,
      PageBreak,
      PasteFromOffice,
      RemoveFormat,
      SpecialCharacters,
      SpecialCharactersEssentials,
      Table,
      TableToolbar,
      TextTransformation,
      TableUtils,
      TableProperties,
      TableCellProperties,
      TableColumnResize,
      ImageUpload,
      GeneralHtmlSupport,
      ImageBlockAndImageInlineCustomization,
    ],
    language: 'es',
    toolbar: {
      items: [
        'link',
        'undo',
        'redo',
        'alignment',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'code',
        'subscript',
        'superscript',
        'bulletedList',
        'numberedList',
        'fontSize',
        'fontFamily',
        'fontColor',
        'fontBackgroundColor',
        'heading',
        'highlight',
        'horizontalLine',
        'insertImage',
        'outdent',
        'indent',
        'pageBreak',
        'removeFormat',
        'specialCharacters',
        'insertTable',
      ],
    },
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true,
        },
      ],
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells',
        'tableProperties', 'tableCellProperties', 'toggleTableCaption'],
    },
    image: {
      insert: {
        // This is the default configuration, you do not need to provide
        // this configuration key if the list content and order
        //  reflects your needs.
        integrations: ['upload', 'assetManager', 'url'],
        type: 'auto',
      },
      toolbar: [
        'toggleImageCaption',
        'imageTextAlternative',
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
      ],
    },
    extraPlugins: [
      MyCustomUploadAdapterPlugin,
    ],
    mention: {
      dropdownLimit: 5,
      feeds: [
        {
          marker: '@',
          feed: [],
          minimumCharacters: 1,
          // itemRenderer: customItemRenderer
        },
      ],
    },
    documentId: '',
    entity: '',
  };
}


export {
  ckeditorConfigEdit,
  insertStaticImage,
};
