<template>
  <div
    class="contenedor-panel"
    @click="$emit('cerrar')"
  >
    <div
      class="panel"
      @click.stop
    >
      <mdb-card>
        <mdb-card-body>
          <mdb-card-title
            class="d-flex align-items-center justify-content-between
              userSelect-none"
          >
            Notificaciones
            <span
              v-if="notificacionesL.length"
              class="text-muted"
            >
              {{ notificacionesL.length }}
            </span>
          </mdb-card-title>
          <!-- Configuración de la pestaña activa:
            active establece la tab activa para mostrar:
            0 - Historial
            1 - Configuración
          -->
          <mdb-tabs
            :active="estatusNotificaciones.existeSuscripcion ? 0 : 1"
            default
            :links="[
              { text: 'Historial' },
              { text: 'Configuración' }]"
          >
            <template :slot="'Historial'">
              <div
                v-if="notificacionesL.length"
              >
                <mdb-btn
                  block
                  flat
                  dark-waves
                  icon="envelope-open"
                  class="mx-0 px-1"
                  @click="$emit('notificaciones-borrar')"
                >
                  Marcar como leídas
                </mdb-btn>
                <mdb-list-group>
                  <mdb-list-group-item
                    v-for="n in notificacionesL"
                    :key="n"
                    class="px-2"
                  >
                    <div class="w-100">
                      <h6 class="mb-2 h6 font-weight-bold">
                        {{ n.title }}
                      </h6>
                      <p class="mb-2">
                        {{ n.message }}
                      </p>
                      <small class="d-block text-right text-muted">
                        Recibida el {{ n.time }}
                      </small>
                    </div>
                  </mdb-list-group-item>
                </mdb-list-group>
              </div>
              <div
                v-else
                class="text-center text-muted userSelect-none"
              >
                <font-awesome-icon
                  icon="bell-slash"
                  size="2x"
                  class="d-block mx-auto my-3"
                />
                No hay notificaciones por leer.
              </div>
            </template>
            <template :slot="'Configuración'">
              <blockquote
                v-if="alertas.ss || !estatusNotificaciones.esCompatible"
                class="blockquote bq-danger text-danger my-2"
              >
                <font-awesome-icon icon="circle-exclamation" />
                Tu dispositivo no es compatible con las notificaciones.
                Intenta desde un navegador actualizado, recomendamos Chrome.
              </blockquote>
              <mdb-card-text
                v-if="!estatusNotificaciones.existeSuscripcion
                  || estatusNotificaciones.permiso === 'denied'"
                class="mt-2"
              >
                Para una mejor experiencia sugerimos activar las notificaciones.
              </mdb-card-text>
              <div class="custom-control custom-switch my-1 py-2">
                <input
                  id="estado-de-notificaciones"
                  :checked="estatusNotificaciones.existeSuscripcion"
                  :disabled="$data.$apolloData.loading
                    || estatusNotificaciones.permiso === 'denied'"
                  type="checkbox"
                  class="custom-control-input"
                  @change="activarODesactivar"
                >
                <label
                  class="custom-control-label cursor-pointer userSelect-none"
                  for="estado-de-notificaciones"
                >
                  Notificaciones
                  {{ estatusNotificaciones.existeSuscripcion
                    ? 'activadas' : 'desactivadas' }}.
                </label>
                <small
                  v-if="estatusNotificaciones.permiso === 'denied'"
                  class="d-block text-muted ml-n36"
                >
                  <span
                    class="font-weight-bold fs-inherit"
                  >Bloqueadas por ti</span>.
                  Para más información ve al menú de ayuda.
                </small>
                <blockquote
                  v-if="alertas.push"
                  class="blockquote bq-warning text-warning ml-n36 my-2"
                >
                  <font-awesome-icon icon="triangle-exclamation" />
                  Tu dispositivo no es compatible para notificarte
                  de nuevas actividades (Notificaciones push).
                  Intenta usar un navegar actualizado, recomendamos Chrome.
                </blockquote>
              </div>
              <mdb-card-title
                v-if="estatusNotificaciones.permiso !== 'denied'"
                class="userSelect-none text-muted mt-2"
                tag="h6"
                sub
              >
                Ajustes en permisos de las notificaciones
              </mdb-card-title>
              <div class="row mx-0">
                <div
                  v-if="estatusNotificaciones.permiso==='default'"
                  class="col"
                >
                  <mdb-btn
                    color="primary"
                    icon="check"
                    class="mx-0 py-2 px-3"
                    :disabled="!estatusNotificaciones.esCompatible"
                    @click="pedirPermisoNotificacion"
                  >
                    Activar notificaciones
                  </mdb-btn>
                </div>
                <div
                  v-if="estatusNotificaciones.permiso==='granted'
                    && !estatusNotificaciones.existeSuscripcion"
                  class="col"
                >
                  <mdb-btn
                    flat
                    dark-waves
                    icon="mouse-pointer"
                    class="mx-0 py-2 px-3"
                    :disabled="!estatusNotificaciones.esCompatible"
                    @click="suscribirUsuario"
                  >
                    Aceptar que me notifiquen
                  </mdb-btn>
                </div>
                <small
                  v-if="!estatusNotificaciones.permiso==='granted'
                    && estatusNotificaciones.existeSuscripcion"
                  class="col-12 mt-2 px-0 text-muted"
                >
                  <span
                    class="font-weight-bold fs-inherit"
                  >No pudimos suscribirte automáticamente</span>.
                  Presiona el botón para reintentar.
                </small>
              </div>
            </template>
          </mdb-tabs>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</template>

<script>
import {mdbBtn, mdbCard, mdbCardBody, mdbCardTitle, mdbCardText,
  mdbTabs, mdbListGroup, mdbListGroupItem} from 'mdbvue';
import gql from 'graphql-tag';
import pushSubSaveGql from '../graphql/pushSubSave.gql';
import pushSubDeleteGql from '../graphql/pushSubDelete.gql';
import estatusNotificacionesGql from '../graphql/estatusNotificaciones.gql';
import estatusNotificacionesSuscripcionGql
  from '../graphql/estatusNotificacionesSuscripcion.gql';
import estatusNotificacionesPermisoGql
  from '../graphql/estatusNotificacionesPermiso.gql';
import estatusNotificacionesCompatibleGql
  from '../graphql/estatusNotificacionesCompatible.gql';
const serverKey = process.env.VUE_APP_VAPID_KEY;
export default {
  name: 'Notificaciones',
  components: {
    mdbBtn,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbTabs,
    mdbListGroup,
    mdbListGroupItem,
  },
  props: {
    notificacionesL: {
      type: Array,
      required: false,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      estatusNotificaciones: {},
      alertas: {
        ss: '',
        push: '',
      },
    };
  },
  methods: {
    comprobarCompatibilidad() {
      let esCompatible;
      if (!('serviceWorker' in navigator)) {
        // Service Worker isn't supported on this browser, disable or hide UI.
        esCompatible = false;
      } else {
        navigator.serviceWorker.addEventListener('message', (event) => {
          this.notificaciones.unshift(event.data);
        });
        // Saber estatus de permiso
        const permiso = Notification.permission;
        this.$apollo.mutate({
          mutation: estatusNotificacionesPermisoGql,
          variables: {
            permiso,
          },
        });
        if (!('PushManager' in window)) {
          // Push isn't supported on this browser, disable or hide UI.
          this.alertas.push = 'Tu dispositivo no es compatible con alertas '
            + 'desde nuestro sistema.';
          esCompatible = false;
        } else {
          esCompatible = true;
          // Verificar si existe suscripción
          navigator.serviceWorker.ready.then((reg) => {
            reg.pushManager.getSubscription().then((subscription) => {
              const existeSuscripcion = subscription && subscription.endpoint
                ? true : false;
              this.$apollo.mutate({
                mutation: estatusNotificacionesSuscripcionGql,
                variables: {
                  existe: existeSuscripcion,
                },
              });
            });
          });
        }
      }
      this.$apollo.mutate({
        mutation: estatusNotificacionesCompatibleGql,
        variables: {
          esCompatible,
        },
      });
    },
    activarODesactivar() {
      navigator.serviceWorker.ready.then(() => {
        if (this.existeSuscripcion) {
          this.desuscribirUsuario();
        } else {
          if (this.permiso === 'granted') {
            this.suscribirUsuario();
          } else {
            this.pedirPermisoNotificacion();
          }
        }
      });
    },
    pedirPermisoNotificacion() {
      return new Promise((resolve, reject) => {
        const permissionResult =
          Notification.requestPermission((result) => {
            resolve(result);
          });
        if (permissionResult) {
          permissionResult.then(resolve, reject);
        }
      }).then((permissionResult) => {
        if (permissionResult !== 'granted') {
          this.alertaMsj = {
            content: 'No aceptaste las notificaciones. Si cambias de '
            + 'opinión, debes desbloquearlas manualmente. Consulta '
            + 'con soporte para más información',
            type: 'danger',
          };
        } else {
          this.suscribirUsuario();
        }
        this.$apollo.mutate({
          mutation: estatusNotificacionesPermisoGql,
          variables: {
            permiso: permissionResult,
          },
        });
      });
    },
    suscribirUsuario() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((reg) => {
          reg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: serverKey,
          }).then((sub)=> {
            this.$apollo.mutate({
              mutation: gql`${pushSubSaveGql}`,
              variables: {
                device: {
                  pushId: JSON.stringify(sub),
                },
              },
            }).then((res) => {
              const msg = res.data.pushSubSave.msg;
              let existe;
              if (msg == 'success') {
                this.alertaMsj = {
                  content: 'Guardado',
                  type: 'success',
                };
                existe = true;
              } else {
                this.alertaMsj = {
                  content: 'No se pudo suscribir a las notificaciones',
                  type: 'danger',
                };
                existe = false;
              }
              this.$apollo.mutate({
                mutation: estatusNotificacionesSuscripcionGql,
                variables: {
                  existe,
                },
              });
            }).catch((error) => {
              this.alertaMsj = {
                content: 'Hubo un error. Intenta de nuevo',
                type: 'danger',
              };
            });
          }).catch((e) => {
            if (Notification.permission === 'denied') {
              this.alertaMsj = {
                content: 'No aceptaste las notificaciones. Si cambias de '
                + 'opinión, debes desbloquearlas manualmente. Consulta '
                + 'con soporte para más información',
                type: 'danger',
              };
            } else {
              this.alertaMsj = {
                content: 'Hubo un error. Intenta de nuevo',
                type: 'danger',
              };
            }
          });
        });
      }
    },
    desuscribirUsuario() {
      navigator.serviceWorker.ready.then((reg) => {
        reg.pushManager.getSubscription().then((subscription) => {
          subscription.unsubscribe().then((successful) => {
            // You've successfully unsubscribed
            // Se debe llamar a la api para borrar la suscripción
            this.alertaMsj = {
              content: 'No recibirás más notificaciones push',
              type: 'success',
            };
            this.$apollo.mutate({
              mutation: gql`${pushSubDeleteGql}`,
              variables: {
                device: {
                  pushId: JSON.stringify(subscription),
                },
              },
            }).then((res) => {
              const msg = res.data.pushSubDelete.msg;
              let existe;
              if (msg == 'success') {
                this.alertaMsj = {
                  content: 'Guardado',
                  type: 'success',
                };
                existe = false;
              } else {
                this.alertaMsj = {
                  content: 'No se pudo suscribir a las notificaciones',
                  type: 'danger',
                };
                existe = true;
              }
              this.$apollo.mutate({
                mutation: estatusNotificacionesSuscripcionGql,
                variables: {
                  existe,
                },
              });
            }).catch((error) => {
              this.alertaMsj = {
                content: 'Hubo un error. Intenta de nuevo',
                type: 'danger',
              };
            });
          }).catch((e) => {
            this.alertaMsj = {
              content: 'Hubo un error. Intenta de nuevo',
              type: 'danger',
            };
          });
        });
      });
    },
  },
  apollo: {
    estatusNotificaciones() {
      return {
        query: estatusNotificacionesGql,
      };
    },
  },
};
</script>
<style lang="scss">
.contenedor-panel {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.panel {
  position: fixed;
  right: 1rem;
  top: 3.5rem;
  z-index: 1031;

  .card {
    width: 16rem;
    @media (min-width: 360px) {
      width: 20rem;
    }
    @media (min-width: 576px) {
      width: 26rem;
    }
  }
  .card-body {
    max-height: calc(84vh);
    overflow-y: auto;
  }

  .ml-n36 {
    margin-left: -36px;
  }

  .list-group-item {
    border-left: none;
    border-right: none;
  }

  .blockquote {
    font-size: .8rem;
  }
}
</style>
