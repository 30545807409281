<template>
  <div>
    <div class="container pb-5">
      <div class="row align-items-center justify-content-between mt-3 mb-2">
        <div class="col-12 col-sm-6 col-lg-4 col-xl-3 flex-align-center">
          <p class="mb-0">
            Citas del día
          </p>
          <div class="col">
            <date-picker
              v-model="selFecha"
              :input-props="{ class: 'form-control date-outline' }"
              :locale="{ id: 'es', masks: { L: 'DD-MM-YYYY' } }"
              color="blue"
            />
          </div>
        </div>
        <AgregarPaciente
          :id-work-place="$route.params.lugar"
        />
      </div>
      <div
        v-if="$apolloData.queries.lugar.loading
          && !citasPendientes.length
          && !citasRealizadas.length"
        ref="cargando"
        class="text-center text-muted vertical-centered vc-75vh"
      >
        <font-awesome-icon
          :icon="['fas', 'circle-notch']"
          class="fa-spin fa-5x my-4"
        />
        <small class="d-block">
          CARGANDO
        </small>
      </div>
      <section
        v-else
      >
        <!-- Contadores -->
        <div class="contadores">
          <p>
            Pacientes vistos: {{ citasRealizadas.length }}
          </p>
          <p>
            Tiempo promedio de consulta: {{ duracionPromedio }} minutos
          </p>
          <p
            v-for="(ing, i) in ingresosDelDia"
            :key="i"
          >
            Acumulado en {{ ing.moneda }}: {{ ing.cantidad }}
          </p>
        </div>
        <!-- Lista de pacientes en espera -->
        <article
          v-if="citasPendientes.length"
          class="timeline-view"
        >
          <header
            class="col-12 col-md h5-responsive mt-3 text-center
              text-md-left"
          >
            {{ citasPendientes.length }}
            Paciente{{ citasPendientes.length > 1 ? 's' : ' ' }} en espera
          </header>
          <div
            class="timeline"
            :data-start-of-work="jornadas[0].start"
            :data-end-of-work="jornadas[jornadas.length -1].end"
          >
            <div class="patients-list with-columns py-2">
              <div
                v-for="(cita, indexCita) in citasPendientes"
                :key="indexCita"
                class="row align-items-center patient-card small-card"
                :data-initial-hour="formatHour(
                  cita.date.hour, cita.date.minute)"
              >
                <!-- Información personal -->
                <div class="col">
                  <p>
                    <span
                      class="cursor-pointer"
                      @click="$router.push(
                        {name:'Paciente',
                         params:{personId: cita.person.id,
                                 salaEsperaId: $route.params.lugar}}
                      )"
                    >
                      <small>
                        {{ cita.person.age }}
                      </small>
                      <font-awesome-icon
                        :icon="['fas', genderIcons[cita.person.gender]]"
                        size="lg"
                        :class="[
                          'mr-1', genderIconColors[cita.person.gender]
                        ]"
                      />
                      {{ cita.person.fname }} {{ cita.person.mname }}
                      {{ cita.person.lname }} {{ cita.person.llname }}
                    </span>
                    <mdb-btn
                      v-if="cita.person.tels && cita.person.tels.length"
                      flat
                      dark-waves
                      fab
                      icon="whatsapp"
                      class="success-text p-0"
                      tag="a"
                      target="_blank"
                      :href="'https://wa.me/'
                        +cita.person.tels[0].country+cita.person.tels[0].number"
                    />
                  </p>
                  <p
                    :title="cita.person.identificationType"
                  >
                    {{ cita.person.identification }}
                  </p>
                  <p
                    v-if="cita.reasons && cita.reasons.length"
                    class="text-muted font-italic"
                  >
                    {{ listarMotivos(cita.reasons) }}
                  </p>
                </div>
                <!-- Estatus de la cita -->
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="row align-items-center justify-content-betw">
                    <div class="col-auto">
                      <small class="text-muted font-weight-bold">
                        Estatus:
                      </small>
                    </div>
                    <div class="col pl-0">
                      <div
                        class="md-form md-outline outline-select my-2"
                      >
                        <select
                          id="schedule-select"
                          ref="selectTime"
                          v-model="cita.status"
                          class="custom-select"
                          @change.stop="appointmentUpdate(cita)"
                        >
                          <option
                            class="d-none"
                            disabled
                            value=""
                          >
                            Seleccione
                          </option>
                          <option
                            v-for="status in citaStatus"
                            :key="status.id"
                            :value="status.value"
                          >
                            {{ status.text }}
                          </option>
                        </select>
                      </div>
                      <small class="text-muted font-italic">
                        {{ cita.type }}
                      </small>
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 col-lg-3 d-flex d-lg-block text-center
                  justify-content-around"
                >
                  <mdb-btn
                    ref="clickCambiarCita"
                    flat
                    dark-waves
                    icon="calendar-day"
                    icon-class="fa-lg"
                    size="sm"
                    class="m-0 px-2"
                    @click.stop.native="showChangeDateForm = true;
                                        citaTemp = cita;"
                  >
                    Cambiar fecha
                  </mdb-btn>
                  <mdb-btn
                    v-if="isOnline"
                    color="primary-base"
                    icon="user-check"
                    icon-class="fa-lg"
                    size="sm"
                    class="m-0 px-2"
                    @click="$router.push({
                      name: 'Paciente',
                      params: {
                        personId: cita.person.id,
                        salaEsperaId: $route.params.lugar,
                      },
                    })"
                  >
                    Ver paciente
                  </mdb-btn>
                  <mdb-btn
                    v-else
                    color="primary-base"
                    icon="user-check"
                    icon-class="fa-lg"
                    size="sm"
                    class="m-0 px-2"
                    @click="empezarEncuentroOffline(cita)"
                  >
                    Iniciar consulta
                  </mdb-btn>
                </div>
              </div>
            </div>
          </div>
        </article>
        <div
          v-else
          ref="!citasPendientes"
          class="text-muted text-center my-4"
        >
          <font-awesome-icon
            :icon="['fas', 'clipboard-check']"
            size="3x"
            class="mb-3"
          />
          <p class="h1-responsive px-3">
            No hay pacientes en espera
          </p>
        </div>
        <!-- Lista de pacientes atendidos -->
        <div v-if="citasRealizadas.length">
          <header
            class="h6-responsive font-weight-bold mt-3 text-center text-md-left"
          >
            {{ citasRealizadas.length }}
            Paciente<span
              v-if="citasRealizadas.length > 1"
              class="fs-inherit"
            >s</span>
            atendido<span
              v-if="citasRealizadas.length > 1"
              class="fs-inherit"
            >s</span>
          </header>
          <div class="row justify-content-center justify-content-md-start">
            <div
              v-for="(cita, indexCita) in citasRealizadas"
              :key="indexCita"
              class="col-12 col-sm-8 col-md-6 col-lg-4 py-2"
              @click="$router.push({name:'Paciente', params:{
                personId: cita.person.id, salaEsperaId: $route.params.lugar}})"
            >
              <div class="patient-card pb-3">
                <p>
                  <font-awesome-icon
                    :icon="['fas', genderIcons[cita.person.gender]]"
                    size="lg"
                    :class="['mr-1', genderIconColors[cita.person.gender]]"
                  />
                  {{ cita.person.age }}
                  <small
                    v-if="cita.status === 'Fulfilled'"
                    class="info float-right text-uppercase"
                  >
                    Completada
                  </small>
                </p>
                <!-- Información personal -->
                {{ cita.person.fname }} {{ cita.person.mname }}
                {{ cita.person.lname }} {{ cita.person.llname }}
                <!-- Motivos -->
                <p class="text-muted font-italic mt-1">
                  {{ listarMotivos(cita.reasons) }}
                </p>
                <div class="buttons">
                  <mdb-btn
                    block
                    color="success-base"
                    icon="plus"
                    icon-class="fa-lg"
                    size="sm"
                    class="col-auto"
                    @click.stop.native="
                      showModalBillUpdate(cita.encounter.bill)"
                  >
                    Añadir pago
                    {{ cita.encounter.bill.symbol }}
                    {{ cita.encounter.bill.total }}
                  </mdb-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="text-muted text-center my-4"
        >
          <font-awesome-icon
            :icon="['fas', 'triangle-exclamation']"
            size="3x"
            class="mb-3"
          />
          <p class="h1-responsive px-3">
            No tienes pacientes atendidos
          </p>
        </div>
        <!-- Calendario de consultas -->
        <!-- <div
          ref="calendario"
          class="mb-5"
        >
          <header
            class="text-center text-md-left font-weight-bold mt-3 mb-1"
          >
            Calendario de consultas
          </header>
          <vue-cal
            hide-view-selector
            :time="false"
            default-view="month"
            small
            locale="es"
            :disable-views="['week','year','years','day']"
          />
        </div> -->
      </section>
    </div>

    <!-- Modal agregar pago -->
    <mdb-modal
      ref="showAddPayment"
      centered
      elegant
      scrollable
      :show="showAddPayment"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          Añadir pago
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModalAddPayment"
          class="close"
          @click="showAddPayment = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <header class="pb-2 font-weight-bold">
          Monto total de tu factura:
          <span class="font-weight-normal">
            {{ bill.symbol }} {{ bill.total }}
          </span>
        </header>
        <header class="pb-2 font-weight-bold">
          Monto abonado:
          <span class="font-weight-normal">
            {{ bill.symbol }}
            {{ bill.accumulatedAmount }}
          </span>
        </header>
        <header class="py-2 border-top font-weight-bold text-center">
          Nuevo pago
        </header>
        <header class="pb-2">
          Selecciona la moneda de pago
        </header>
        <div class="row justify-content-between">
          <div
            v-for="(moneda, i) in monedasFactura"
            :key="i"
            class="col-12 col-sm-6 our-radio2"
            title="Marcar moneda a pagar"
          >
            <label>
              <input
                ref="changeMoneda"
                v-model="payment.currency"
                type="radio"
                name="primaryRadio"
                :value="moneda.name"
                @change="payment.symbol = moneda.symbol"
              >
              <span />
              {{ moneda.name }}
            </label>
          </div>
        </div>
        <div class="row align-items-center">
          <header class="col-12 col-sm-auto pb-2">
            Monto a abonar
          </header>
          <div class="col-12 col-sm">
            <mdb-input
              v-model="amountToPay"
              type="number"
              class="my-1"
              size="sm"
              outline
            />
          </div>
        </div>
        <header class="py-2 font-weight-bold">
          Método de pago
        </header>
        <div class="row align-items-center">
          <div
            v-for="(metodo, i) in paymentMethods"
            :key="i"
            class="col-12 col-sm-6 our-radio2"
          >
            <label>
              <input
                ref="paymentMethods"
                v-model="payment.method"
                type="radio"
                name="radio-pago"
                :value="metodo"
                @change="payment.reference = ''"
              >
              <span />
              {{ metodo }}
            </label>
          </div>
          <div
            v-if="payment.method == 'Transferencia'"
            class="col-12 col-sm"
          >
            <mdb-input
              v-model="payment.reference"
              type="text"
              label="# Referencia"
              class="my-1"
              size="sm"
              outline
            />
          </div>
          <div
            v-if="payment.method == 'Plan de Pago'"
            class="col-12 col-sm"
          >
            <mdb-input
              type="text"
              label="# Cuotas"
              class="my-1"
              size="sm"
              outline
            />
          </div>
        </div>
        <div class="row align-items-center">
          <header class="col-12 col-sm-auto py-2 font-weight-bold">
            Estado del pago
          </header>
          <div
            class="col-12 col-sm md-form md-outline outline-select my-1"
          >
            <select
              v-model="bill.status"
              class="custom-select"
            >
              <option
                v-for="(status, i) in billStatus"
                :key="i"
                :value="status.value"
              >
                {{ status.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer-btn">
          <mdb-btn
            ref="cerrarBill"
            flat
            dark-waves
            icon="times"
            class="flat-bordered"
            @click="showAddPayment = false"
          >
            Cerrar
          </mdb-btn>
          <BtnLoader
            ref="billUpdate"
            :btn-disabled="btnDisabled"
            btn-title="Añadir"
            @click="billUpdate"
          />
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <!-- Modal para cambiar la cita -->
    <mdb-modal
      ref="showChangeDateForm"
      centered
      elegant
      scrollable
      :show="showChangeDateForm"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          Cambiar cita
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModalChangeDateForm"
          class="close"
          @click="showChangeDateForm = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <FormularioCitas
          ref="formularioCitasInCambiarCita"
          :schedule="lugar.schedule"
          @saveAppointmenDate="appointment.date=$event;
                               paraCita.date=$event"
          @saveSchedule="paraCita.schedule=$event"
          @saveTime="paraCita.time=$event"
          @saveConfirmDate="pacienteConfirmarFecha=$event"
        />
        <div class="modal-footer-btn">
          <mdb-btn
            ref="cerrarCambiarCita"
            flat
            dark-waves
            icon="times"
            class="flat-bordered"
            @click="showChangeDateForm = false"
          >
            Cerrar
          </mdb-btn>
          <BtnLoader
            ref="cambiarAppointmentMoveDate"
            :btn-disabled="btnDisabled"
            btn-icon="rotate"
            btn-title="Cambiar"
            @click="appointmentMoveDate"
          />
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <AlertaMsj :alerta-msj="alertaMsj" />
  </div>
</template>
<script>
import {mdbBtn, mdbInput, mdbModal, mdbModalHeader, mdbModalTitle,
  mdbModalBody} from 'mdbvue';
import gql from 'graphql-tag';
import {v4 as uuidv4} from 'uuid';
import BtnLoader from '@/components/customButtons/BtnLoader.vue';
import AlertaMsj from '@/components/AlertaMsj.vue';
import AgregarPaciente from '@/components/AgregarPaciente.vue';
import {genderIcons, genderIconColors}
  from '@/components/customFields/SelectGender.vue';
import FormularioCitas
  from '@/components/FormularioCitas.vue';
// import VueCal from 'vue-cal';
// import 'vue-cal/dist/vuecal.css';
// import 'vue-cal/dist/i18n/es.es.js';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import {getPersonId} from '@/utils/authdecode.js';
import {todayDate, calculateAge, appointmentSchema,
  debounce, validHourInRange} from '@/utils/validations.js';
import workPlaceGql from '@/graphql/workPlace.gql';
import appointmentMoveDateGql from '@/graphql/appointmentMoveDate.gql';
import appointmentUpdateGql from '@/graphql/appointmentUpdate.gql';
import billUpdateGql from '@/graphql/billUpdate.gql';
import currenciesAcceptedGql from '@/graphql/currenciesAccepted.gql';
import encounterCreateGql from '@/graphql/encounterCreate.gql';
// import patientGql from '@/graphql/patient.gql';

export default {
  name: 'SalaDeEspera',
  components: {
    BtnLoader,
    AlertaMsj,
    AgregarPaciente,
    mdbBtn,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    // VueCal,
    DatePicker,
    FormularioCitas,
  },
  data() {
    return {
      selFecha: new Date(),
      fecha: todayDate(),
      enableWeek: false,
      citaStatus: [
        {
          id: '3',
          value: 'Booked',
          text: 'Agendada',
        },
        {
          id: '4',
          value: 'Confirmed',
          text: 'Confirmada',
        },
        {
          id: '5',
          value: 'Arrived',
          text: 'Llegó',
        },
        {
          id: '0',
          value: 'NoShow',
          text: 'No llegó',
        },
        {
          id: '1',
          value: 'Cancelled',
          text: 'Cancelada',
        },
        {
          id: '2',
          value: 'Fulfilled',
          text: 'Finalizada',
        },
      ],
      lugar: {
        appointments: [],
        schedule: [],
      },
      // Render
      alertaMsj: {
        content: '',
      },
      paraCita: {
        paciente: {
          id: '',
        },
        servicioId: '',
        date: todayDate(),
      },
      appointment: {},
      appointmentReasons: [
        {
          name: '',
        },
      ],
      bill: {},
      amountToPay: '',
      billReact: {},
      showAddPayment: false, // Abre modal de pago
      showChangeDateForm: false, // Abre modal de cambiar cita
      pacienteConfirmarFecha: false, // Necesita confirmacion cambiar cita
      citaTemp: {},
      btnDisabled: false,
      genderIcons, genderIconColors, // Para mostrar icon y color de gender
      payment: {},
      paymentMethods: [
        'TDD',
        'TDC',
        'Efectivo',
        'Transferencia',
        'Plan de Pago',
      ],
      billStatus: [
        {
          name: 'Pendiente',
          value: 'Pending',
        },
        {
          name: 'En Aprobación',
          value: 'Checking',
        },
        {
          name: 'Aprobado',
          value: 'Approved',
        },
        {
          name: 'Rechazado',
          value: 'Rejected',
        },
      ],
      monedasFactura: [],
    };
  },
  computed: {
    ingresosDelDia() {
      const ingresos = {};
      const cantidades = [];
      if (this.lugar.appointments) {
        this.lugar.appointments.forEach((c) => {
          if (c.encounter && c.encounter.bill && c.encounter.bill.payments) {
            c.encounter.bill.payments.forEach((p) => {
              if (!ingresos[p.currency]) ingresos[p.currency] = 0;
              ingresos[p.currency] += p.amount;
            });
          }
        });
        for (const moneda in ingresos) {
          if (Object.prototype.hasOwnProperty.call(ingresos, moneda)) {
            cantidades.push({
              moneda,
              cantidad: ingresos[moneda],
            });
          }
        }
      }
      return cantidades;
    },
    duracionPromedio() {
      let duracion = 0;
      if (!this.citasRealizadas) return 0;
      this.citasRealizadas.forEach((c) => {
        if (c.encounter && c.encounter.duration) {
          duracion += c.encounter.duration;
        }
      });
      return this.citasRealizadas.length
        ? (duracion / this.citasRealizadas.length).toFixed(0)
        : 0;
    },
    citasPendientes() {
      const citas = this.lugar.appointments.filter((a)=>{
        const born = a.person.born;
        a.person.age = calculateAge(born);
        return a.status != 'Fulfilled';
      });
      return citas;
    },
    citasRealizadas() {
      const citas = this.lugar.appointments.filter((a)=>{
        const born = a.person.born;
        a.person.age = calculateAge(born);
        return a.status == 'Fulfilled' && a.encounter;
      });
      return citas;
    },
    jornadas() {
      let jornadas = [{
        start: 'Sin horario inicial establecido',
        end: 'Sin horario final establecido',
      }];
      if (this.lugar.schedule && this.lugar.schedule.length) {
        const horarios = JSON.parse(JSON.stringify(this.lugar.schedule));
        if (horarios.length === 1) {
          jornadas = horarios;
        } else {
          jornadas = horarios.sort(function(a, b) {
            if (a.start.split(':')[0] > b.start.split(':')[0]
              && a.start.split(':')[1] > b.start.split(':')[1]
            ) {
              return 1;
            }
            if (a.start.split(':')[0] < b.start.split(':')[0]
              && a.start.split(':')[1] < b.start.split(':')[1]
            ) {
              return -1;
            }
            return 0;
          });
        }
      }
      return jornadas;
    },
  },
  watch: {
    isOnline: function() {
      if (this.isOnline) {
        this.alertaMsj = {
          content: 'Estamos en línea nuevamente',
          type: 'success',
        };
      } else {
        this.alertaMsj = {
          content: 'Sin conexión a Internet, es importante estar en línea para '
          + 'la sincronización de los datos',
          type: 'warning',
        };
      }
    },
    selFecha: function(newF, oldF) {
      if (newF == null) return;
      this.getAppointments();
    },
  },
  created: function() {
    this.getAppointments = debounce(this.setDateDebounced, 1500);
  },
  methods: {
    appointmentMoveDate() {
      const appointment = {
        id: this.citaTemp.id,
        date: this.paraCita.date,
        status: this.pacienteConfirmarFecha ? 'Proposed'
          : this.paraCita.status ? this.paraCita.status : 'Booked',
      };
      if (!(appointmentSchema.isValidSync(appointment))) {
        return this.alertaMsj = {
          content: 'Revisa la fecha, horario y hora de la cita',
          type: 'warning',
        };
      }
      appointment.date = appointment.date + 'T' + this.paraCita.time;
      if (!this.paraCita.schedule) {
        return this.alertaMsj = {
          content: 'Seleccione el horario',
          type: 'warning',
        };
      }
      if (!this.paraCita.time) {
        return this.alertaMsj = {
          content: 'Indique la hora de su preferencia',
          type: 'warning',
        };
      }
      if (!this.paraCita.time
        || !validHourInRange(
            this.paraCita.time,
            this.paraCita.schedule.start,
            this.paraCita.schedule.end,
        )) {
        return this.alertaMsj = {
          content: 'Seleccione una hora dentro del rango del horario',
          type: 'warning',
        };
      }
      delete this.paraCita.schedule.__typename;
      delete this.paraCita.schedule.day.__typename;
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${appointmentMoveDateGql}`,
        variables: {
          appointment,
          schedule: this.paraCita.schedule,
        },
      }).then((res) => {
        switch (res.data.appointmentMoveDate.msg) {
          case 'success': {
            if (this.paraCita.date != this.fecha) {
              this.citasPendientes.splice(
                  this.citasPendientes.indexOf(this.citaTemp), 1);
            }
            this.paraCita.date = todayDate();
            delete this.paraCita.schedule;
            delete this.paraCita.time;
            this.showChangeDateForm = false;
            this.pacienteConfirmarFecha = false;
            this.alertaMsj = {
              content: 'Cita cambiada',
              type: 'success',
            };
            break;
          }
          default: {
            this.alertaMsj = {
              content: 'No se cambió la fecha.'
              + ' ¿Seguro que es diferente a la que ya tiene?',
              type: 'warning',
            };
            break;
          }
        }
        this.btnDisabled = false;
      }).catch((error) => {
        this.btnDisabled = false;
        return this.alertaMsj = {
          content: 'Hubo un error al cambiar la cita del paciente',
          type: 'danger',
        };
      });
    },
    appointmentUpdate(cita) {
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${appointmentUpdateGql}`,
        variables: {
          appointment: {
            id: cita.id,
            status: cita.status,
          },
        },
      }).then((res) => {
        switch (res.data.appointmentUpdate.msg) {
          case 'success': {
            this.alertaMsj = {
              content: 'Estatus cambiado',
              type: 'success',
            };
            break;
          }
          default: {
            this.alertaMsj = {
              content: 'No se cambió el estatus.',
              type: 'warning',
            };
            break;
          }
        }
        this.btnDisabled = false;
      }).catch((error) => {
        this.btnDisabled = false;
        return this.alertaMsj = {
          content: 'Hubo un error al cambiar el estatus',
          type: 'danger',
        };
      });
    },
    showModalBillUpdate(bill) {
      this.billReact = bill;
      const billTemp = JSON.parse(JSON.stringify(bill));
      this.bill = billTemp;
      let totalAmountPayments = 0;
      this.monedasFactura = [];
      if (this.bill.payments.length) {
        this.bill.payments.forEach((p) => {
          totalAmountPayments += p.amount;
        });
        this.bill.payments.forEach((p) => {
          if (!this.monedasFactura.some((e) => {
            return JSON.stringify({name: e.name, symbol: e.symbol})
              === JSON.stringify({
                name: p.currency,
                symbol: p.symbol,
              });
          })) {
            this.monedasFactura.push({
              name: p.currency,
              symbol: p.symbol,
            });
          }
        });
      } else {
        this.monedasFactura = this.monedas;
      }
      this.bill.status = 'Approved';
      this.bill.accumulatedAmount = totalAmountPayments;
      this.amountToPay = Number((Number(this.bill.total)-
        totalAmountPayments).toFixed(3));
      this.showAddPayment = true;
    },
    billUpdate() {
      const payments = JSON.parse(JSON.stringify(this.bill.payments));
      payments.forEach((p) => {
        p.date = p.date.formatted;
        delete p.__typename;
      });
      if (!this.payment.currency) {
        return this.alertaMsj = {
          content: 'Elige una moneda',
          type: 'warning',
        };
      }
      if (this.amountToPay > 0) {
        payments.push({
          amount: Number(this.amountToPay),
          currency: this.payment.currency,
          symbol: this.payment.symbol,
          method: this.payment.method,
          reference: this.payment.reference,
          date: new Date().toISOString(),
          status: this.bill.status,
        });
      } else {
        return this.alertaMsj = {
          content: 'El monto a abonar debe ser mayor a 0',
          type: 'warning',
        };
      }
      if (!this.payment.method) {
        return this.alertaMsj = {
          content: 'Elige un método de pago',
          type: 'warning',
        };
      }
      if (!this.bill.status) {
        return this.alertaMsj = {
          content: 'Elige un estado del pago',
          type: 'warning',
        };
      }
      const bill = JSON.parse(JSON.stringify(this.bill));
      delete bill.__typename;
      delete bill.items;
      delete bill.payments;
      delete bill.accumulatedAmount;
      const variablesApollo = {
        bill,
        payments,
      };
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${billUpdateGql}`,
        variables: variablesApollo,
      }).then((res) => {
        const msg = res.data.billUpdate.msg;
        switch (msg) {
          case 'success': {
            this.bill.payments.push({
              amount: Number(this.amountToPay),
              currency: this.payment.currency,
              symbol: this.payment.symbol,
              method: this.payment.method,
              reference: this.payment.reference,
              date: new Date().toISOString(),
              status: this.bill.status,
            });
            this.billReact.payments = this.bill.payments;
            this.billReact.status = this.bill.status;
            this.bill = {};
            this.payment = {};
            this.alertaMsj = {
              content: 'Factura actualizada',
              type: 'success',
            };
            this.showAddPayment = false;
            break;
          }
          default: {
            this.alertaMsj = {
              content: 'Hubo un error al enviar la factura',
              type: 'danger',
            };
          }
        }
        this.btnDisabled = false;
      }).catch((error) => {
        this.btnDisabled = false;
        this.alertaMsj = {
          content: 'Hubo un error al actualizar la factura',
          type: 'danger',
        };
      });
    },
    setDateDebounced() {
      this.fecha = this.selFecha.toISOString().split('T')[0];
    },
    listarMotivos(motivos) {
      const listaMotivos = motivos.map((motivo) => motivo.name);
      return listaMotivos.join(', ') + '.';
    },
    formatHour(hour, minute) {
      const HOUR = hour > 9 ? hour : '0' + hour;
      const MINUTE = minute > 9 ? minute : '0' + minute;

      return `${HOUR}:${MINUTE}`;
    },
    empezarEncuentroOffline(cita) {
      this.encounterCreate(cita);
      this.$router
          .push({
            name: 'PacienteDiagnostico',
            params: {
              salaEsperaId: this.$route.params.lugar,
              personId: cita.person.id,
              encuentroId: cita.id,
              aDT: cita.date.formatted,
              iden: cita.person.identification,
              idenType: cita.person.identificationType,
            },
          });
    },
    encounterCreate(cita) {
      const variablesApollo = {
        encounter: {
          date: new Date().toISOString(),
          status: 'InProgress',
        },
        appointmentDT: cita.date.formatted,
        identification: cita.person.identification,
        identificationType: cita.person.identificationType,
      };
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${encounterCreateGql}`,
        variables: variablesApollo,
        context: {serializationKey: 'PERSONS'},
        optimisticResponse: {
          __typename: 'Mutation',
          encounterCreate: {
            __typename: 'Encounter',
            id: uuidv4(),
          },
        },
      }).catch((error) => {
        this.btnDisabled = false;
        this.alertaMsj = {
          content: 'Error al crear el encuentro',
          type: 'danger',
        };
      });
    },
  },
  apollo: {
    lugar() {
      return {
        query: gql`${workPlaceGql}`,
        variables: () => {
          return {
            date: this.fecha,
            personId: getPersonId(),
            workPlaceId: this.$route.params.lugar,
          };
        },
        update: (data) => data.Doctor[0].works_at.length
          ? data.Doctor[0].works_at[0]
          : {appointments: []},
        result: ({data}) => {
          if (data && data.Doctor[0] && data.Doctor[0].works_at[0]
            && data.Doctor[0].works_at[0].servicesOffered
            && !data.Doctor[0].works_at[0].schedule.length
          ) {
            this.alertaMsj = {
              content: 'Para poder crear una cita por favor registra el horario'
                + ' de trabajo. Ve a menú, configuración, consultorios',
              type: 'warning',
            };
          }
        },
        fetchPolicy: 'cache-and-network',
      };
    },
    monedas() {
      return {
        query: gql`${currenciesAcceptedGql}`,
        update: (data) => data.currenciesAccepted,
      };
    },
  },
};
</script>
<style lang="scss">
  .contadores {
    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    p {
      margin-bottom: .25rem;
      @media screen and (min-width: 768px){
        padding-right: .75rem;
        width: 50%;
      }
      @media screen and (min-width: 1200px){
        width: 33.33%;
      }
    }
  }
  .overflowX-hidden {
    overflow-x: hidden;
  }
  .overflowY-hidden {
    overflow-y: hidden;
  }
  .overflowY-md-visible {
    @media screen and (min-width: 768px) {
      overflow-y: visible;
    }
  }

  .timeline-view {
    padding: 2rem 1rem 1rem 4rem !important;
    .timeline-head {
      display: flex;
    }
    .timeline::before {
      content: attr(data-start-of-work);
      margin-left: -3rem;
    }

    .timeline::after {
      content: attr(data-end-of-work);
      margin-left: -3rem;
    }
  }

  .patients-list {
    display: flex;
    gap: 1.5rem;

    &.with-rows {
      flex-flow: row wrap;
      justify-content: center;

      @media screen and (min-width: 768px) {
        justify-content: flex-start;
      }

      .patient-card {
        flex: 0 1 294px;

        @media screen and (min-width: 1200px) {
          flex: 0 1 330px;
        }
      }
    }
    &.with-columns {
      flex-direction: column;
      gap: 0.5rem;

      .patient-card {
        flex: 0 1 auto;
        width: 100%;

        &.small-card {
          padding: .5rem 1rem;
        }

        &::before {
          content: attr(data-initial-hour);
          left: -3rem;
          position: absolute;
        }
      }
    }

    .patient-card {
      position: relative;

      .buttons {
        bottom: .8rem;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        position: absolute;
        width: 90%;
      }
    }
  }
  .patient-card {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    padding: .5rem 1rem calc(.8rem + 40px + .8rem);

    p {
      font-size: .9rem;
      margin-bottom: 0;
    }

    .info {
      font-size: 90%;
      color: $muted-color;
      font-style: italic;
    }
  }
</style>
