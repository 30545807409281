<template>
  <div
    :class="[className, 'md-form', { 'md-outline outline-input': outline },
             'ul-data']"
  >
    <!-- v-model="value" -->
    <input
      :id="label + identificador + '-label'"
      ref="input"
      :value="value"
      :disabled="disabled"
      :required="required"
      class="form-control mb-0"
      :type="type"
      autocomplete="off"
      @input="isActiveList = true;$emit('input', $event.target.value)"
      @focus="isActiveList = true"
      @click="isActiveList = true"
      @blur="hideList"
    >
    <label
      :for="label + identificador + '-label'"
      :class="value && value.toString().length
        ? 'label-active' : 'label-inactive'"
    >
      {{ label }}
    </label>
    <ul
      v-if="list.length"
      :class="['datalist', {'active-list': isActiveList}]"
    >
      <slot :selected="selected">
        <!-- Si no se usa el li del slot default, se debe asignar el
        @mousedown.prevent al elemento li que se pase para asegurar
        que la opción quede seleccionada y luego se esconda y no
        al revés. -->
        <li
          v-for="(option, i) in list"
          :key="i"
          @click="selected(option)"
          @mousedown.prevent
        >
          {{ option.name }}
        </li>
      </slot>
      <slot
        name="new-option"
        :hideList="hideList"
        :selected="selected"
      />
    </ul>
    <div
      v-else
      :class="['datalist', {'active-list': isActiveList}]"
    >
      <div class="li text-muted cursor-notAllowed">
        No hay resultados
      </div>
      <slot
        name="new-option"
        :hideList="hideList"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'CustomDatalist',
  props: {
    className: { // Clase del div principal
      type: [String, Array],
      required: true,
    },
    identificador: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    outline: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // value: '',
      isActiveList: false,
    };
  },
  methods: {
    focusInput() {
      this.$refs.input.focus();
    },
    selected(option) {
      this.isActiveList = false;
      this.$emit('save', option);
    },
    hideList() {
      this.isActiveList = false;
    },
  },
};
</script>
<style lang="scss">
  .md-outline.outline-data .label-active {
    background: $white;
    font-weight: 500;
    padding: 0 5px;
  }
  .ul-data {
    cursor: pointer;

    // Estilos base de presentación
    input {
      background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
    }
    label {
      cursor: pointer !important;
      &.label-active {
        -webkit-transform: translate(9px, -15px) scale(0.8) !important;
        transform: translate(9px, -15px) scale(0.8) !important;
      }
    }
    .datalist {
      background-color: $white;
      border-radius: 0 0 4px 4px;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
      height: 0;
      list-style-type: none;
      opacity: 0;
      overflow-y: hidden;
      position: absolute;
      top: 38px;
      transition: z-index 0.1s ease-out, opacity 0.1s ease-in;
      width: 100%;
      z-index: -1;

      li,
      .li {
        padding: .6rem .5rem;
        transition: all .5s ease;

        &:hover {
          background-color: rgba(0, 0, 0, .1);
        }
      }
      li.cursor-notAllowed,
      .li.cursor-notAllowed {
        cursor: not-allowed;
        &:hover {
          background-color: transparent;
        }
      }
    }

    // Estilos base de comportamiento
    .datalist.active-list {
      height: auto;
      opacity: .9;
      z-index: 5;
    }
  }
  .dark-datalist {
    .datalist {
      background-color: $gris-muted;
    }
  }
</style>
