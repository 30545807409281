<template>
  <div>
    <mdb-btn
      v-if="antecedenteId"
      icon="pen"
      size="sm"
      dark-waves
      flat
      title="Editar antecedente"
      data-button-type="hover-default"
      @click="editHistory(antecedenteId)"
    >
      Editar
    </mdb-btn>
    <mdb-btn
      v-else
      :class="[buttonSecondary.length
        ? buttonSecondary : 'col-auto m-0 fixed-br-mobile']"
      :dark-waves="!!buttonSecondary.length"
      :flat="!!buttonSecondary.length"
      :icon="buttonSecondary
        ? 'plus-circle' : 'plus'"
      color="success-base"
      :size="buttonSize"
      rounded
      @click="newHistory"
    >
      Agregar antecedente
    </mdb-btn>
    <!-- Modal para agregar / modificar antecedente -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="showHistoryForm"
      @shown="$refs.antecedenteRef.focusInput()"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          {{ historyForm.title }}
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModalHistoryForm"
          class="close"
          @click="showHistoryForm = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <form @submit.prevent="historyMutate()">
          <div class="row justify-content-center">
            <div class="col-12">
              <CustomDatalist
                :id="'Antecedente'"
                ref="antecedenteRef"
                v-model="historyTemp.name"
                :list="historyItems"
                class-name="my-2"
                label="Nombre del antecedente"
                :outline="true"
                @save="
                  historyTemp.class = $event.class;
                  historyTemp.subclass = $event.subclass;
                  historyTemp.type = $event.type;
                  historyTemp.options = $event.options;
                  historyTemp.name = $event.name;
                "
                @input="
                  historyTemp.class = '';
                  buscarHistorias(historyTemp.name);
                "
              >
                <template slot-scope="scope">
                  <li
                    v-for="(hisI, ii) in historyItems"
                    :key="'hio' + ii"
                    @click="scope.selected(hisI)"
                    @mousedown.prevent
                  >
                    {{ hisI.name }}
                    <small> - </small>
                    <small> {{ histClasses[hisI.class] }}. </small>
                  </li>
                </template>
              </CustomDatalist>
            </div>
            <div class="col-12 col-md-6">
              <div class="md-form md-outline outline-select my-2">
                <select
                  id="class-select"
                  v-model="historyTemp.class"
                  class="custom-select"
                >
                  <option
                    class="d-none"
                    disabled
                    value=""
                  >
                    Seleccione
                  </option>
                  <option
                    v-for="(clase, valor) in histClasses"
                    :key="clase"
                    :value="valor"
                  >
                    {{ clase }}
                  </option>
                </select>
                <label
                  for="class-select"
                  :class="
                    historyTemp.class && historyTemp.class.length
                      ? 'label-active'
                      : 'label-inactive'
                  "
                >
                  Tipo
                </label>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <mdb-input
                v-model="historyTemp.subclass"
                class="my-2"
                label="Categoría (opcional)"
                type="text"
                outline
              />
            </div>
          </div>
          <p class="font-weight-bold text-center mt-2 mb-1">
            Tipo de entrada
          </p>
          <!-- Lista de parámetros -->
          <div class="row justify-content-between">
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="historyTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="boolean"
                  >
                  <span />
                  Verdadero / Falso
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="historyTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="date"
                  >
                  <span />
                  Fecha
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="historyTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="datetime"
                  >
                  <span />
                  Fecha y hora
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="historyTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="decimal"
                  >
                  <span />
                  Numérico decimal
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="historyTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="integer"
                  >
                  <span />
                  Numérico entero
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="historyTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="rangeDates"
                  >
                  <span />
                  Rango de fechas
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="historyTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="range"
                  >
                  <span />
                  Rango numérico
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="historyTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="simpleOption"
                  >
                  <span />
                  Selección simple
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="historyTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="multipleOption"
                  >
                  <span />
                  Selección múltiple
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="historyTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="text"
                  >
                  <span />
                  Texto
                </label>
              </div>
            </div>
          </div>
          <!-- Mostrar cuando el parámetro sea de tipo selección simple -->
          <div
            v-if="
              historyTemp.type == 'simpleOption' ||
                historyTemp.type == 'multipleOption'
            "
          >
            <p class="font-weight-bold text-center mt-2 mb-1">
              Opciones para la selección
            </p>
            <div class="row justify-content-center">
              <div class="col-12">
                <mdb-input
                  v-model="newOptionH"
                  class="my-2 icon-input-group"
                  placeholder="Añadir opción"
                  outline
                >
                  <mdb-btn
                    slot="append"
                    class="success-text"
                    dark-waves
                    flat
                    group
                    icon="plus-circle"
                    :icon-class="newOptionH ? 'animated pulse-bg infinite' : ''"
                    size="sm"
                    title="Añadir"
                    type="button"
                    :disabled="!newOptionH"
                    @click="addOptionH(newOptionH)"
                  />
                </mdb-input>
              </div>
              <div
                v-for="(option, i) in historyTemp.options"
                :key="i"
                class="col-12"
              >
                <mdb-input
                  class="my-2 icon-input-group"
                  :value="option"
                  outline
                >
                  <mdb-btn
                    slot="append"
                    class="danger-text"
                    dark-waves
                    flat
                    group
                    icon="minus-circle"
                    size="sm"
                    title="Eliminar"
                    type="button"
                    @click="optionHistoryDelete(i)"
                  />
                </mdb-input>
              </div>
            </div>
          </div>
          <!-- Mostrar cuando el parámetro sea de tipo Rango -->
          <div v-if="historyTemp.type == 'range'">
            <header class="mb-2 mt-3 font-weight-bold text-center">
              Establece el rango
            </header>
            <div class="row justify-content-between align-items-center">
              <div class="col-12 col-sm-6">
                <mdb-input
                  v-model="range.rangeLow"
                  type="number"
                  class="my-2"
                  label="Desde"
                  outline
                />
              </div>
              <div class="col-12 col-sm-6">
                <mdb-input
                  v-model="range.rangeHigh"
                  type="number"
                  class="my-2"
                  label="Hasta"
                  outline
                />
              </div>
            </div>
          </div>
          <!-- Botonces del modal -->
          <div class="modal-footer-btn">
            <mdb-btn
              flat
              dark-waves
              type="button"
              icon="times"
              class="flat-bordered"
              @click="showHistoryForm = false"
            >
              Cerrar
            </mdb-btn>
            <BtnLoader
              btn-type="submit"
              :btn-disabled="btnDisabled"
              :btn-icon="historyForm.btnIcon"
              :btn-title="historyForm.btnLabel"
            />
          </div>
        </form>
      </mdb-modal-body>
    </mdb-modal>
    <AlertaMsj :alerta-msj="alertaMsj" />
  </div>
</template>
<script>
import {
  mdbBtn,
  mdbInput,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
} from 'mdbvue';
import AlertaMsj from '@/components/AlertaMsj.vue';
import BtnLoader from '@/components/customButtons/BtnLoader.vue';
import CustomDatalist from '@/components/customFields/CustomDatalist.vue';
import {histClasses} from '@/components/Antecedentes.vue';
import {getPersonId} from '@/utils/authdecode.js';
import gql from 'graphql-tag';
import historyItemsByNameGql from '@/graphql/historyItemsByName.gql';
import historyItemCreateGql from '@/graphql/historyItemCreate.gql';
import historyItemUpdateGql from '@/graphql/historyItemUpdate.gql';
import encounterAppointmentGql from '@/graphql/encounterAppointment.gql';
import doctorServicesInWorkplaceGql from
  '@/graphql/doctorServicesInWorkplace.gql';

export default {
  name: 'AgregarAntecedente',
  components: {
    mdbBtn,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    CustomDatalist,
    BtnLoader,
    AlertaMsj,
  },
  props: {
    antecedenteId: {
      type: Object,
      default: (()=>{}),
    },
    buttonSecondary: {
      type: String,
      default: '',
    },
    buttonSize: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      historias: {},
      historyTemp: {
        class: '',
        options: [],
      },
      historyItems: [],
      // Render:
      showHistoryForm: false,
      historyForm: {
        title: '',
        btnLabel: '',
        btnIcon: 'plus',
      },
      alertaMsj: {
        content: '',
      },
      btnDisabled: false,
      newOptionH: '',
      range: {
        rangeLow: '',
        rangeHigh: '',
      },
      histClasses,
    };
  },
  methods: {
    editHistory(hist) {
      this.showHistoryForm = true;
      this.historySeleccionado = hist;
      this.historyTemp = JSON.parse(
          JSON.stringify(hist));
      if (this.historyTemp.type == 'range'
        && this.historyTemp.options.length >= 2) {
        this.range = {
          rangeLow: this.historyTemp.options[0],
          rangeHigh: this.historyTemp.options[1],
        };
      }
      this.historyForm = {
        title: 'Edición del antecedente',
        btnLabel: 'Actualizar',
        btnIcon: 'rotate',
      };
    },
    historyMutate() {
      (this.historyForm.btnLabel == 'Añadir')
        ? this.historyItemCreate()
        : this.historyItemUpdate();
    },
    historyItemCreate() {
      if (!this.historyTemp.name || !this.historyTemp.type
        || !this.historyTemp.class) {
        return this.alertaMsj = {
          content: 'Llena nombre y tipo para el antecedente',
          type: 'warning',
        };
      }
      if ((this.historyTemp.type == 'simpleOption'
        || this.historyTemp.type == 'multipleOption')
        && !this.historyTemp.options) {
        return this.alertaMsj = {
          content: 'Llena las opciones de la selección',
          icon: 'warning',
        };
      }
      if (this.historyTemp.type == 'range') {
        if (!this.range.rangeLow && !this.range.rangeHigh) {
          return this.alertaMsj = {
            content: 'Llena los campos de rango numérico',
            type: 'warning',
          };
        }
        if (Number(this.range.rangeLow) < Number(this.range.rangeHigh)) {
          this.historyTemp.options = [];
          this.historyTemp.options.push(this.range.rangeLow);
          this.historyTemp.options.push(this.range.rangeHigh);
        } else {
          return this.alertaMsj = {
            content: 'El rango inicial debe ser menor al rango final',
            type: 'warning',
          };
        }
      }
      const historyItem = {...this.historyTemp};
      const variablesApollo = {
        historyItem,
      };
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${historyItemCreateGql}`,
        variables: variablesApollo,
        update: (store, {data: {historyItemCreate}}) => {
          const element = {
            __typename: '_DoctorHistoryItems',
            order: null,
            History_Item: {
              __typename: 'History_Item',
              id: historyItemCreate.id,
              ...historyItem,
              options: this.historyTemp.options || [],
            },
          };
          if (this.$route.params.appointmentId && this.$route.params.personId) {
            const data = store.readQuery({
              query: gql`${encounterAppointmentGql}`,
              variables: {
                id: this.$route.params.appointmentId,
                doctorId: getPersonId(),
                patientId: this.$route.params.personId,
              },
            });
            data.Doctor[0].historyItems.push(element);
            store.writeQuery({
              query: gql`${encounterAppointmentGql}`,
              variables: {
                id: this.$route.params.appointmentId,
                doctorId: getPersonId(),
                patientId: this.$route.params.personId,
              },
              data,
            });
          }
          try {
            const data = store.readQuery({
              query: gql`${doctorServicesInWorkplaceGql}`,
              variables: {
                id: getPersonId(),
              },
            });
            data.Doctor[0].historyItems.push(element);
            store.writeQuery({
              query: gql`${doctorServicesInWorkplaceGql}`,
              variables: {
                id: getPersonId(),
              },
              data,
            });
          } catch (e) {
            //
          }
        },
      }).then((res) => {
        if (res.data.historyItemCreate.id) {
          this.alertaMsj = {
            content: 'Antecedente agregado',
            type: 'success',
          };
          this.historyTemp = {};
          this.showHistoryForm = false;
          this.$emit('created', historyItem);
        } else {
          this.alertaMsj = {
            content: 'Hubo un error al crear el antecedente',
            type: 'warning',
          };
        }
        this.btnDisabled = false;
      }).catch((error) => {
        this.btnDisabled = false;
        this.alertaMsj = {
          content: 'Hubo un error al crear el antecedente',
          type: 'danger',
        };
      });
    },
    historyItemUpdate() {
      if (!this.historyTemp.name || !this.historyTemp.type
        || !this.historyTemp.class) {
        return this.alertaMsj = {
          content: 'Llena nombre y tipo para el antecedente',
          type: 'warning',
        };
      }
      if ((this.historyTemp.type == 'simpleOption'
        || this.historyTemp.type == 'multipleOption')
        && !this.historyTemp.options) {
        return this.alertaMsj = {
          content: 'Llena las opciones de la selección',
          type: 'warning',
        };
      }
      if (this.historyTemp.type == 'range') {
        if (!this.range.rangeLow && !this.range.rangeHigh) {
          return this.alertaMsj = {
            content: 'Llena los campos de rango numérico',
            type: 'warning',
          };
        }
        if (Number(this.range.rangeLow) < Number(this.range.rangeHigh)) {
          this.historyTemp.options = [];
          this.historyTemp.options.push(this.range.rangeLow);
          this.historyTemp.options.push(this.range.rangeHigh);
        } else {
          return this.alertaMsj = {
            content: 'El rango inicial debe ser menor al rango final',
            type: 'warning',
          };
        }
      }
      const historyItem = {...this.historyTemp};
      const oldHItem = {...this.historySeleccionado};
      delete historyItem.__typename;
      delete oldHItem.__typename;
      const variablesApollo = {
        historyItem,
        oldHItem,
      };
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${historyItemUpdateGql}`,
        variables: variablesApollo,
        update: (store, {data: {historyItemUpdate}}) => {
          const data = store.readQuery({
            query: gql`${doctorServicesInWorkplaceGql}`,
            variables: {
              id: getPersonId(),
            },
          });
          const element = {
            __typename: '_DoctorHistoryItems',
            order: null,
            History_Item: {
              __typename: 'History_Item',
              id: historyItemUpdate.id,
              ...historyItem,
              options: this.historyTemp.options || [],
            },
          };
          data.Doctor[0].historyItems = data.Doctor[0].historyItems
              .map((hi) => {
                if (
                  hi.History_Item.name === oldHItem.name
                  && hi.History_Item.class === oldHItem.class
                ) {
                  hi = element;
                }
                return hi;
              });
          store.writeQuery({
            query: gql`${doctorServicesInWorkplaceGql}`,
            variables: {
              id: getPersonId(),
            },
            data,
          });
        },
      }).then((res) => {
        this.alertaMsj = {
          content: 'Antecedente actualizado',
          type: 'success',
        };
        this.btnDisabled = false;
        this.showHistoryForm = false;
      }).catch((error) => {
        this.btnDisabled = false;
        this.alertaMsj = {
          content: 'Hubo un error al actualizar el antecedente'+error,
          type: 'danger',
        };
      });
    },
    addOptionH(newOption) {
      if (!this.historyTemp.options) {
        this.historyTemp.options = [];
      }
      this.historyTemp.options.push(newOption);
      this.newOptionH = '';
    },
    optionHistoryDelete(i) {
      this.historyTemp.options.splice(i, 1);
    },
    newHistory() {
      this.showHistoryForm = true;
      this.historyTemp = {
        class: '',
        subclass: '',
        type: '',
        options: [],
      };
      this.range = {};
      this.historyForm = {
        title: 'Agregar antecedente',
        btnLabel: 'Añadir',
        btnIcon: 'plus',
      };
    },
    buscarHistorias(name) {
      if (name.length < 4) {
        return;
      }
      this.$apollo.query({
        query: gql`${historyItemsByNameGql}`,
        variables: {
          name,
        },
      }).then((res) => {
        this.historyItems = res.data.historyItemsByName;
      }).catch((err) => {});
    },
  },
};
</script>
