<template>
  <div
    :class="['md-form', className,
             {'class-valid': validateValue == 'valid'},
             {'class-invalid': validateValue == 'invalid'}]"
  >
    <select
      :id="`${nameSelect ? nameSelect + '-' : ''}bloodType-select`
        + `${identificador ? '-' + identificador : ''}`"
      ref="selectBloodT"
      :value="value"
      class="custom-select"
      @change="changed($event.target.value)"
    >
      <option
        v-for="(bType, i) in bloodTypes"
        :key="`${i}${nameSelect ? '-' + nameSelect : ''}`
          + `${identificador ? '-' + identificador : ''}`"
        :value="bType.value"
        :class="{ 'd-none': bType.name == 'Seleccione' }"
      >
        {{ bType.name }}
      </option>
    </select>
    <label
      :for="`${nameSelect ? nameSelect + '-' : ''}bloodType-select`
        + `${identificador ? '-' + identificador : ''}`"
      :class="[value && value.length ? 'label-active' : 'label-inactive']"
    >
      Tipo de sangre
    </label>
  </div>
</template>
<script>
export const SelectBloodType = {
  name: 'SelectBloodType',
  props: {
    // Clase del div principal
    className: {
      type: String,
      required: true,
    },
    identificador: {
      type: [String, Number],
      default: '',
    },
    nameSelect: {
      type: String,
      default: '',
    },
    validateValue: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      bloodType: {
        name: 'Seleccione',
        value: '',
      },
      bloodTypes: [
        {
          name: 'Seleccione',
          value: '',
        },
        {
          name: 'Desconocido',
          value: '',
        },
        {
          name: 'A+',
          value: 'Ap',
        },
        {
          name: 'A-',
          value: 'Am',
        },
        {
          name: 'B+',
          value: 'Bp',
        },
        {
          name: 'B-',
          value: 'Bm',
        },
        {
          name: 'AB+',
          value: 'ABp',
        },
        {
          name: 'AB-',
          value: 'ABm',
        },
        {
          name: 'O+',
          value: 'Op',
        },
        {
          name: 'O-',
          value: 'Om',
        },
      ],
    };
  },
  mounted() {
    this.changed(this.value);
  },
  methods: {
    focusInput() {
      this.$refs.selectBloodT.focus();
    },
    changed(value) {
      this.$emit('input', value);
      this.bloodType = this.bloodTypes.filter((i) =>
        i.value == value)[0];
    },
  },
};

const bloodTypes = {};
SelectBloodType.data().bloodTypes.forEach((e) => {
  bloodTypes[e.value] = e.name;
});
export {bloodTypes};
export default SelectBloodType;
</script>
<style lang="scss">

</style>
