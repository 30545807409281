/* eslint-disable */
import { ApolloLink } from '@apollo/client/link/core';
import { Observable, } from '@apollo/client/utilities';

/**
 * Queue link
 */
export default class QueueLink extends ApolloLink {
  /**
   * 
   */
  constructor() {
    super(...arguments);
    this.opQueue = [];
    this.isOpen = true;
  }
  getQueue() {
    return this.opQueue;
  }
  isType(query, type) {
    return query.definitions.filter((e) => {
        return e.operation === type;
    }).length > 0;
  }
  isFilteredOut(operation) {
    if (!QueueLink.filter || !QueueLink.filter.length)
    return false;
    return operation.query.definitions.filter((e) => {
      return QueueLink.filter.includes(e.operation);
    }).length > 0;
  }
  open() {
    this.isOpen = true;
    const opQueueCopy = [...this.opQueue];
    this.opQueue = [];
    opQueueCopy.forEach(({ operation, forward, observer }) => {
      const key = QueueLink.key(operation.operationName, 'dequeue');
      if (key in QueueLink.listeners) {
        QueueLink.listeners[key].forEach((listener) => {
            listener({ operation, forward, observer });
        });
      }
      const keyAny = QueueLink.key('any', 'dequeue');
      if (keyAny in QueueLink.listeners) {
        QueueLink.listeners[keyAny].forEach((listener) => {
            listener({ operation, forward, observer });
        });
      }
      forward(operation).subscribe(observer);
    });
  }
  static key(op, ev) {
    return `${op}${ev}`.toLocaleLowerCase();
  }
  close() {
    this.isOpen = false;
  }
  request(operation, forward) {
    if (this.isOpen) {
        return forward(operation);
    }
    if (operation.getContext().skipQueue) {
        return forward(operation);
    }
    if (this.isFilteredOut(operation)) {
        return forward(operation);
    }
    return new Observable((observer) => {
        const operationEntry = { operation, forward, observer };
        this.enqueue(operationEntry);
        return () => this.cancelOperation(operationEntry);
    });
  }
  cancelOperation(entry) {
    this.opQueue = this.opQueue.filter(e => e !== entry);
  }
  enqueue(entry) {
    this.opQueue.push(entry);
    const key = QueueLink.key(entry.operation.operationName, 'enqueue');
    if (key in QueueLink.listeners) {
        QueueLink.listeners[key].forEach((listener) => {
            listener(entry);
        });
    }
    const keyAny = QueueLink.key('any', 'enqueue');
    if (keyAny in QueueLink.listeners) {
        QueueLink.listeners[keyAny].forEach((listener) => {
            listener(entry);
        });
    }
  }
}
QueueLink.listeners = {};
QueueLink.filter = null;
QueueLink.addLinkQueueEventListener = (opName, event, listener) => {
  const key = QueueLink.key(opName, event);
  const newListener = {[key]: [
    ...(key in QueueLink.listeners ? QueueLink.listeners[key] : []),
    ...[listener],
  ]};
  QueueLink.listeners = Object.assign(Object.assign({}, QueueLink.listeners), newListener);
};
QueueLink.setFilter = (filter) => {
    QueueLink.filter = filter;
};
