<template>
  <transition name="alerta-transition">
    <div
      v-if="alertaMsj.content && alertaMsj.content.length"
      :class="['alert-message', alertaMsj.type]"
      role="alertdialog"
      aria-labelledby="alert-message"
      @click="cerrarAlerta"
    >
      <p>
        <font-awesome-icon
          :icon="iconoAlerta"
          size="lg"
          class="mr-1"
        />
        {{ alertaMsj.content }}
      </p>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'AlertaMsj',
  props: {
    alertaMsj: {
      type: Object,
      default: function() {
        return {
          content: '',
        };
      },
    },
    autoOcultar: {
      default: 'successOnly',
      validator: function(value) {
        // The value must match one of these values
        return [true, false, 'successOnly'].includes(value);
      },
    },
    tiempoAutoOcultar: {
      type: Number,
      default: 4500, // time in ms
    },
  },
  data() {
    return {
      controlTemporizador: '',
    };
  },
  computed: {
    /* Alertas disponibles: success, danger, informative y warning */
    iconoAlerta() {
      switch (this.alertaMsj.type) {
        case 'success':
          return 'circle-check';
        case 'danger':
          return 'circle-xmark';
        case 'informative':
          return 'circle-info';
        case 'warning':
        default:
          return 'circle-exclamation';
      }
    },
  },
  watch: {
    alertaMsj: function() {
      if (this.autoOcultar === true
        || (this.autoOcultar === 'successOnly'
            && this.alertaMsj.type === 'success')) {
        clearTimeout(this.controlTemporizador);
        this.controlTemporizador = setTimeout(
            () => this.cerrarAlerta(),
            this.tiempoAutoOcultar,
        );
      }
    },
  },
  methods: {
    cerrarAlerta() {
      if (this.autoOcultar === true
        || (this.autoOcultar === 'successOnly'
            && this.alertaMsj.type === 'success')) {
        clearTimeout(this.controlTemporizador);
      }
      this.alertaMsj.content = '';
    },
  },
};
</script>
<style lang="scss" scoped>
  .alert-message {
    border-radius: .75rem .125rem .75rem .75rem;
    color: $white;
    cursor: pointer;
    font-weight: 400;
    max-width: 90%;
    padding: 1rem;
    position: fixed;
    right: 1rem;
    top: 5rem;
    z-index: 1051;

    &.warning {background-color: $warning-color;}
    &.danger {background-color: $danger-color;}
    &.success {background-color: $success-color;}
    &.informative {background-color: $primary-color;}

    p {
      margin-bottom: 0;
      user-select: none;
    }
  }

  // Estilos de transición
  .alerta-transition-enter-active {animation-name: fadeInRight;}
  .alerta-transition-leave-active {animation-name: fadeOutRight;}
  .alerta-transition-enter-active,
  .alerta-transition-leave-active {
    animation-duration: .5s;
  }
</style>
