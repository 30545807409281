<template>
  <div>
    <!-- Fecha de la cita -->
    <header class="font-weight-bold mt-1">
      Fecha de la cita
    </header>
    <div
      :class="[{'class-valid': validateValue.date == 'valid'},
               {'class-invalid': validateValue.date == 'invalid'}]"
    >
      <vue-cal
        hide-view-selector
        :time="false"
        active-view="month"
        small
        locale="es"
        :min-date="minDate"
        :max-date="maxDate"
        :hide-weekdays="enableWeek ? [] : hiddenDays"
        :disable-views="['week','year','years','day']"
        @cell-focus="paraCita.schedule=''; setDate($event)"
      />
    </div>
    <div
      v-if="enableAllWeek"
      class="custom-control custom-switch mt-1 mb-2"
    >
      <input
        id="enable-all-week"
        v-model="enableWeek"
        type="checkbox"
        class="custom-control-input"
        @change="$emit('enableAllWeek', enableWeek)"
      >
      <label
        for="enable-all-week"
        class="custom-control-label"
      >
        Habilitar toda la semana
      </label>
    </div>
    <p class="text-center font-weight-bold">
      Fecha seleccionada: {{ paraCita.date }}
    </p>
    <header class="font-weight-bold mt-1">
      Horario del día
    </header>
    <div
      :class="['md-form md-outline outline-select my-2',
               {'class-valid': validateValue.schedule == 'valid'},
               {'class-invalid': validateValue.schedule == 'invalid'}]"
    >
      <select
        id="schedule-select"
        ref="selectTime"
        v-model="paraCita.schedule"
        class="custom-select"
        @change="$emit('saveSchedule', paraCita.schedule);
                 paraCita.time = paraCita.schedule.start;
                 $emit('saveTime', paraCita.time)"
      >
        <option
          class="d-none"
          disabled
          value=""
        >
          Seleccione
        </option>
        <option
          v-for="sche in schedules"
          :key="sche.id"
          :value="sche"
        >
          {{ accentInWeekDay(sche.day.name) }} de
          {{ sche.start }} a {{ sche.end }}
        </option>
      </select>
    </div>
    <!-- Hora de la cita -->
    <div
      :class="['md-form md-outline my-2',
               {'class-valid': validateValue.time == 'valid'},
               {'class-invalid': validateValue.time == 'invalid'},
               {'class-warning-border': warnOvertime}]"
    >
      <input
        ref="startTime"
        v-model="paraCita.time"
        type="time"
        name="startTime"
        step="60"
        class="form-control"
        @input="$emit('saveTime', paraCita.time)"
      >
      <label class="active">
        Hora
      </label>
      <small
        v-if="warnOvertime"
        id="passwordHelpBlock"
        class="form-text warning-text mt-n1 pl-1"
      >
        <font-awesome-icon icon="triangle-exclamation" />
        La hora excede el horario establecido
      </small>
    </div>
    <!-- Servicios a usar -->
    <header
      v-if="createAppointment"
      class="font-weight-bold mt-1"
    >
      Grupo de parámetros a usar
    </header>
    <div
      v-if="createAppointment"
      :class="['md-form md-outline outline-select my-1',
               {'class-valid': validateValue.servicioId == 'valid'},
               {'class-invalid':validateValue.servicioId == 'invalid'}]"
    >
      <select
        id="service-select"
        ref="selectServices"
        v-model="paraCita.servicioId"
        class="custom-select"
        @change="$emit('saveServiceId', paraCita.servicioId)"
      >
        <option
          class="d-none"
          disabled
          value=""
        >
          Seleccione
        </option>
        <option
          v-for="servicio in servicesOffered"
          :key="servicio.id"
          :value="servicio.id"
        >
          {{ servicio.name }}
        </option>
      </select>
    </div>
    <!-- Motivos -->
    <mdb-btn
      v-if="createAppointment"
      ref="mdbBtnAddReason"
      flat
      dark-waves
      icon="plus-circle"
      icon-class="success-text mr-1"
      title="Agregar motivos"
      class="fs-inherit tt-inherit flex-align-center mx-0 mb-0 p-2"
      type="button"
      @click="$emit('addReason')"
    >
      Motivos
    </mdb-btn>
    <div v-if="createAppointment">
      <div
        v-for="(motivo, i) in appointmentReasons"
        :key="i"
        class="row align-items-center"
      >
        <div :class="['col', {'pr-0': appointmentReasons.length > 1}]">
          <CustomDatalist
            :id="i+'Motivo'"
            ref="customDatalist"
            v-model="motivo.name"
            :list="motivosFrecuentes"
            :class-name="['my-2',
                          {'class-invalid':
                            validateValue.appointmentReason == 'invalid'}]"
            label="Motivo"
            :identificador="i"
            :outline="true"
            @input="setMotivo(motivo.name)"
            @save="motivo.name=$event.name;"
          />
        </div>
        <div
          v-if="appointmentReasons.length > 1"
          class="col-auto"
        >
          <mdb-btn
            ref="mdbBtnRemove"
            class="m-0 p-2"
            dark-waves
            flat
            icon="trash"
            icon-class="fa-lg"
            size="md"
            @click="$emit('removeReason', i)"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!createAppointment"
      class="our-checkbox2"
    >
      <label>
        <input
          ref="inputSaveConfirm"
          v-model="pacienteConfirmarFecha"
          type="checkbox"
          name="confirmation-check"
          @change="$emit('saveConfirmDate', pacienteConfirmarFecha)"
        >
        <span />
        Necesita confirmación del paciente
      </label>
    </div>
  </div>
</template>
<script>
import {mdbBtn} from 'mdbvue';
import CustomDatalist from '@/components/customFields/CustomDatalist.vue';
import {validHourAtEnd, accentInWeekDay} from '@/utils/validations.js';
// import AlertaMsj from '@/components/AlertaMsj.vue';
// import Gql from '@/graphql/.gql';
import gql from 'graphql-tag';
import reasonsByNameGql from '@/graphql/reasonsByName.gql';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/i18n/es.js';

export default {
  name: 'FormularioCitas',
  components: {
    CustomDatalist,
    mdbBtn,
    VueCal,
  },
  props: {
    createAppointment: {
      type: Boolean,
      default: false,
    },
    enableAllWeek: {
      type: Boolean,
      default: false,
    },
    servicesOffered: {
      type: Array,
      default: function() {
        return [];
      },
    },
    appointmentReasons: {
      type: Array,
      default: function() {
        return [
          {
            name: '',
          },
        ];
      },
    },
    schedule: {
      type: Array,
      default: function() {
        return [];
      },
    },
    validateValue: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      accentInWeekDay,
      enableWeek: false,
      paraCita: {
        servicioId: '',
        date: '',
        schedule: '',
        time: '',
      },
      pacienteConfirmarFecha: false,
      motivosFrecuentes: [],
      motivoABuscar: '',
    };
  },
  computed: {
    warnOvertime() {
      if (this.paraCita.schedule && this.paraCita.time) {
        return !validHourAtEnd(this.paraCita.time, this.paraCita.schedule.end);
      } else return false;
    },
    hiddenDays() {
      const days = {
        Lunes: 1,
        Martes: 2,
        Miercoles: 3,
        Jueves: 4,
        Viernes: 5,
        Sabado: 6,
        Domingo: 7,
      };
      if (!this.schedule) return [];
      this.schedule.forEach((s)=> {
        delete days[s.day.name];
      });
      const hidden = [];
      for (const prop in days) {
        if (Object.prototype.hasOwnProperty.call(days, prop)) {
          hidden.push(days[prop]);
        }
      }
      return hidden;
    },
    minDate() {
      const now = new Date();
      return now;
    },
    maxDate() {
      const now = new Date();
      const date = new Date(now);
      date.setDate(now.getDate() + 90);
      return date;
    },
    schedules() {
      if (!this.schedule && !this.enableWeek) return [];
      const d = new Date(this.paraCita.date);
      const z = d.getTimezoneOffset();
      const v = d.valueOf();
      const r = z * 1000*60 + v;
      const da = new Date(r);
      const k = da.getDay();
      const days = [
        'Domingo',
        'Lunes',
        'Martes',
        'Miercoles',
        'Jueves',
        'Viernes',
        'Sabado',
      ];
      let schedule = this.schedule.filter((l) => l.day.name == days[k]);
      if (!schedule.length && this.enableWeek) {
        schedule = [{
          start: this.paraCita.time,
          end: this.paraCita.time,
          day: {name: days[k]},
        }];
      }
      return schedule;
    },
  },
  watch: {
    schedules(newS, oldS) {
      if (newS.length == 1) this.autoSelectSchedule(newS[0]);
    },
  },
  methods: {
    autoSelectSchedule(schedule) {
      this.paraCita.schedule = schedule;
      this.paraCita.time = schedule.start;
      this.$emit('saveSchedule', this.paraCita.schedule);
      this.$emit('saveTime', this.paraCita.time);
    },
    setDate(date) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      this.paraCita.date = [year, month, day].join('-');
      this.$emit('saveAppointmenDate', this.paraCita.date);
    },
    setMotivo(motivo) {
      this.motivoABuscar = motivo;
    },
  },
  apollo: {
    motivosFrecuentes() {
      return {
        query: gql`${reasonsByNameGql}`,
        variables() {
          return {
            name: this.motivoABuscar,
          };
        },
        update: (data) => data.reasonsByName,
        skip: () => this.motivoABuscar.length > 1 ? false : true,
        debounce: 1000,
      };
    },
  },
};
</script>
<style lang="scss">

</style>
