<template>
  <div
    :class="['md-form', className,
             {'class-valid': validateValue == 'valid'},
             {'class-invalid': validateValue == 'invalid'}]"
  >
    <select
      :id="`${nameSelect ? nameSelect + '-' : ''}kinship-select`
        + `${identificador ? '-' + identificador : ''}`"
      ref="selectKinship"
      :value="value"
      class="custom-select"
      @change="changed($event.target.value)"
    >
      <option
        v-for="(kType, i) in kinshipTypes"
        :key="`${i}${nameSelect ? '-' + nameSelect : ''}`
          + `${identificador ? '-' + identificador : ''}`"
        :value="kType.value"
        :class="{ 'd-none': kType.value == '' }"
      >
        {{ kType.name }}
      </option>
    </select>
    <label
      :for="`${nameSelect ? nameSelect + '-' : ''}kinship-select`
        + `${identificador ? '-' + identificador : ''}`"
      :class="[value && value.length ? 'label-active' : 'label-inactive']"
    >
      Parentesco
    </label>
  </div>
</template>
<script>
export const SelectKinship = {
  name: 'SelectKinship',
  props: {
    // Clase del div principal
    className: {
      type: String,
      required: true,
    },
    identificador: {
      type: [String, Number],
      default: '',
    },
    nameSelect: {
      type: String,
      default: '',
    },
    validateValue: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      kinshipType: {
        name: 'Seleccione',
        value: '',
      },
      kinshipTypes: [
        {
          name: 'Seleccione',
          value: '',
        },
        {
          name: 'Madre',
          value: 'Mother',
        },
        {
          name: 'Padre',
          value: 'Father',
        },
        {
          name: 'Hija',
          value: 'Daughter',
        },
        {
          name: 'Hijo',
          value: 'Son',
        },
        {
          name: 'Hermana',
          value: 'Sister',
        },
        {
          name: 'Hermano',
          value: 'Brother',
        },
        {
          name: 'Abuelo',
          value: 'Grandfather',
        },
        {
          name: 'Abuela',
          value: 'Grandmother',
        },
        {
          name: 'Tío',
          value: 'Uncle',
        },
        {
          name: 'Tía',
          value: 'Aunt',
        },
        {
          name: 'Otro',
          value: 'Other',
        },
      ],
    };
  },
  mounted() {
    this.changed(this.value);
  },
  methods: {
    focusInput() {
      this.$refs.selectKinship.focus();
    },
    changed(value) {
      this.$emit('input', value);
      this.kinshipType = this.kinshipTypes.filter((i) =>
        i.value == value)[0];
    },
  },
};

const kinshipTypes = {};
SelectKinship.data().kinshipTypes.forEach((e) => {
  kinshipTypes[e.value] = e.name;
});
export {kinshipTypes};
export default SelectKinship;
</script>
<style lang="scss">

</style>
