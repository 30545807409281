<template>
  <transition name="alerta-snackbar-transition">
    <div
      v-if="mostrarSnackBar"
      :class="['alerta-snackbar', tipoAlerta.clase]"
      role="alertdialog"
    >
      <p class="col px-0 text-break">
        <font-awesome-icon
          :icon="tipoAlerta.icono"
          size="lg"
          class="mr-1"
        />
        {{ alertaSnackBar.contenido }}
      </p>
      <mdb-btn
        v-if="btnAccion.texto"
        flat
        dark-waves
        :icon="btnAccion.icono ? btnAccion.icono : 'check'"
        :class="['col-auto my-md-0 ml-md-4 px-2', tipoAlerta.botonTexto]"
        @click="$emit('btnAccion')"
      >
        {{ btnAccion.texto }}
      </mdb-btn>
      <mdb-btn
        flat
        dark-waves
        :class="['col-auto my-md-0 ml-md-4 px-2', tipoAlerta.botonTexto]"
        @click="$emit('cerrar')"
      >
        X
      </mdb-btn>
    </div>
  </transition>
</template>

<script>
import {mdbBtn} from 'mdbvue';
export default {
  name: 'AlertaSnackBar',
  components: {mdbBtn},
  props: {
    alertaSnackBar: {
      type: Object,
      default: function() {
        return {
          color: '',
          contenido: '',
        };
      },
    },
    btnAccion: {
      type: Object,
      default: function() {
        return {
          icono: '',
          texto: '',
        };
      },
    },
    mostrarSnackBar: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    tipoAlerta() {
      switch (this.alertaSnackBar.color) {
        case 'correcto':
          return {
            botonTexto: 'texto-terciario',
            clase: 'exitosa',
            icono: 'circle-check',
          };
        case 'error':
          return {
            botonTexto: 'texto-claro',
            clase: 'error',
            icono: 'circle-xmark',
          };
        case 'informativo':
          return {
            botonTexto: 'texto-primario',
            clase: 'informativa',
            icono: 'circle-info',
          };
        default:
          return {
            botonTexto: 'texto-claro',
            clase: 'advertencia',
            icono: 'circle-exclamation',
          };
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.alerta-snackbar {
  align-items: center;
  border-radius: .75rem;
  color: $white;
  display: flex;
  flex-flow: column wrap;
  font-weight: 400;
  justify-content: center;
  max-width: 90%;
  padding: 1rem;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 1051;

  @media screen and (min-width: 768px){
    flex-direction: row;
  }

  &.advertencia {background-color: $warning-color;}
  &.error {background-color: $danger-color;}
  &.exitosa {background-color: $success-color;}
  &.informativa {background-color: $primary-color;}

  p {
    margin-bottom: 0;
    user-select: none;
  }
}

// Estilos de transición
.alerta-snackbar-transition-enter-active {animation-name: fadeInUp;}
.alerta-snackbar-transition-leave-active {animation-name: fadeOutDown;}
.alerta-snackbar-transition-enter-active,
.alerta-snackbar-transition-leave-active {
  animation-duration: .5s;
}
</style>
