import { render, staticRenderFns } from "./SalaDeEspera.vue?vue&type=template&id=498b6342"
import script from "./SalaDeEspera.vue?vue&type=script&lang=js"
export * from "./SalaDeEspera.vue?vue&type=script&lang=js"
import style0 from "./SalaDeEspera.vue?vue&type=style&index=0&id=498b6342&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports