<template>
  <div class="paciente-diagnostico">
    <link
      rel="stylesheet"
      href="https://ckeditor.com/docs/ckeditor5/latest/snippets/features/page-break/snippet.css"
      type="text/css"
    >
    <div class="container registro-diagnostico">
      <div class="top-bar">
        <div class="top-bar-actions border-bottom border-light">
          <div class="row justify-content-around justify-content-md-between">
            <mdb-btn
              class="py-2 px-3 btn-back"
              dark-waves
              flat
              icon="chevron-left"
              type="button"
              @click="showGoBackToPatient = true"
            >
              <small class="d-none d-sm-inline">
                Atrás
              </small>
            </mdb-btn>
            <mdb-btn
              class="py-2 px-3 btn-back"
              dark-waves
              flat
              icon="eye"
              type="button"
              @click="showCardConfiguration = !showCardConfiguration"
            >
              <small class="d-none d-sm-inline">
                Visibilidad
              </small>
            </mdb-btn>
            <mdb-btn
              class="py-2 px-3 btn-save"
              dark-waves
              flat
              icon="save"
              type="button"
              title="Guardar sin finalizar"
              @click="encounterUpdate(
                {status: 'InProgress', dontUseRouter: true})"
            >
              <small class="d-none d-sm-inline">
                Guardar
              </small>
            </mdb-btn>
            <mdb-btn
              class="py-2 px-3 btn-recover"
              dark-waves
              flat
              icon="sync"
              :icon-class="$apolloData.queries.cita.loading ? 'fa-spin' : ''"
              type="button"
              title="Recargar datos de la consulta"
              @click="skipEncounterAppointment = false;
                      $apollo.queries.cita.refresh()"
            >
              <small class="d-none d-sm-inline">
                Recargar
              </small>
            </mdb-btn>
          </div>
          <p class="text-center mb-0">
            {{ paciente.persona.fname }} {{ paciente.persona.mname }}
            {{ paciente.persona.lname }} {{ paciente.persona.llname }},
            {{ paciente.persona.age }}
            <small class="d-block mb-0 text-center">
              {{ identificationTypes[paciente.persona.identificationType] }}
              {{ paciente.persona.identification }}
            </small>
          </p>
          <!--
          <div class="row justify-content-center justify-content-md-end">
            <p>
              Consulta: dd/mm/aaaa
            </p>
          </div> -->
          <mdb-alert
            v-if="$apolloData.queries.cita.loading"
            color="warning"
          >
            <font-awesome-icon
              icon="rotate"
              class="fa-spin"
            />
            Sincronizando tu información
          </mdb-alert>
        </div>
        <Transition name="fadeIn">
          <div
            v-if="showCardConfiguration"
            class="d-flex flex-wrap top-bar-options
            border-bottom border-light rounded-bottom
            top-bar-menu"
          >
            <div
              v-for="(card, c) in cardVis"
              :key="c"
              class="col-12 col-md-6 col-lg-4 my-1"
            >
              <div class="d-flex">
                <span class="flex-fill p-1">
                  {{ card.name }}
                </span>
                <div class="our-radio2 px-2">
                  <label
                    class="fs-09 w-100"
                    title="Visible"
                  >
                    <input
                      v-model="card.vis"
                      value="2"
                      :name="card.name"
                      type="radio"
                      class="d-none"
                      @change="setCardVis(c, 2)"
                    >
                    <font-awesome-icon
                      :class="[card.vis == 2 ? 'text-success': 'text-muted']"
                      icon="eye"
                    />
                  </label>
                </div>
                <div class="our-radio2 px-2">
                  <label
                    class="fs-09 w-100"
                    title="Minimizada"
                  >
                    <input
                      v-model="card.vis"
                      value="1"
                      :name="card.name"
                      type="radio"
                      class="d-none"
                      @change="setCardVis(c, 1)"
                    >
                    <font-awesome-icon
                      :class="[card.vis == 1 ? 'text-success': 'text-muted']"
                      icon="chevron-down"
                    />
                  </label>
                </div>
                <div class="our-radio2 px-2">
                  <label
                    class="fs-09 w-100"
                    title="Oculto"
                  >
                    <input
                      v-model="card.vis"
                      :value="0"
                      :name="card.name"
                      type="radio"
                      class="d-none"
                      @change="setCardVis(c, 0)"
                    >
                    <font-awesome-icon
                      :class="[!card.vis ? 'text-success': 'text-muted']"
                      icon="eye-slash"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
      <!-- Lista de tarjetas -->
      <div class="card-columns cc-1 cc-md-2 cc-lg-3">
        <!-- Lista de motivos -->
        <TarjetasDelDiagnostico
          :show-card="cita.encuentro.reasons
            && !!cita.encuentro.reasons.length
            && !!cardVis.reasons.vis"
          :show-body="cardVis.reasons.vis == 2"
        >
          <template #title>
            {{ cardVis.reasons.name }}
          </template>
          <template #buttons>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.reasons.vis == 2 ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.reasons.vis == 2
                  ? cardVis.reasons.vis = 1
                  : cardVis.reasons.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.reasons.vis = 0"
            />
          </template>
          <template #body>
            <ul class="card-text angle-list reasons">
              <li
                v-for="(motivo, iM) in cita.encuentro.reasons"
                :key="iM"
              >
                {{ motivo.name }}
              </li>
            </ul>
          </template>
        </TarjetasDelDiagnostico>
        <!-- Tarjeta Enfermedad actual -->
        <TarjetasDelDiagnostico
          :show-card="!!cardVis.actualDisease.vis"
          :show-body="cardVis.actualDisease.vis == 2"
          :card-class="'rgba-yellow-slight'"
        >
          <template #title>
            {{ cardVis.actualDisease.name }}
          </template>
          <template #buttons>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.actualDisease.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.actualDisease.vis == 2
                  ? cardVis.actualDisease.vis = 1
                  : cardVis.actualDisease.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.actualDisease.vis = 0"
            />
          </template>
          <template #body>
            <mdb-input
              v-model="encounter.actualDisease"
              type="textarea"
              class="my-1"
              :rows="5"
              outline
              @blur="capitalizeActualDisease()"
            />
          </template>
        </TarjetasDelDiagnostico>
        <!-- Tarjeta de Antecedentes -->
        <TarjetasDelDiagnostico
          :show-card="!!cardVis.historyItems.vis"
          :show-body="cardVis.historyItems.vis == 2"
        >
          <template #title>
            {{ cardVis.historyItems.name }}
          </template>
          <template #buttons>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.historyItems.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.historyItems.vis == 2
                  ? cardVis.historyItems.vis = 1
                  : cardVis.historyItems.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.historyItems.vis = 0"
            />
          </template>
          <!-- Antecedentes -->
          <template
            v-if="Object.entries(historias).length"
            #body
          >
            <div
              v-for="(hists, k) in historias"
              :key="'hi'+k"
            >
              <span class="font-weight-bold">{{ histClasses[k] }}</span>
              <ul
                class="list-unstyled"
              >
                <li
                  v-for="(parametro, i) in hists"
                  :key="'hi.'+i"
                  class="d-flex flex-wrap align-items-center"
                >
                  <template v-if="parametro.type == 'integer'">
                    <div class="col-12 pl-sm-0 pr-sm-2">
                      <mdb-input
                        v-model="parametro.value"
                        type="number"
                        class="my-1"
                        :label="parametro.name"
                        outline
                      />
                    </div>
                  </template>
                  <template v-else-if="parametro.type == 'decimal'">
                    <div class="col-12 pl-sm-0 pr-sm-2">
                      <mdb-input
                        v-model="parametro.value"
                        type="number"
                        class="my-1"
                        :label="parametro.name"
                        outline
                      />
                    </div>
                  </template>
                  <template v-else-if="parametro.type == 'date'">
                    <div class="col-12 pl-sm-0 pr-sm-2">
                      <mdb-input
                        v-model="parametro.value"
                        type="date"
                        class="my-1 outline-date"
                        :label="parametro.name"
                        outline
                      />
                    </div>
                  </template>
                  <template v-else-if="parametro.type == 'datetime'">
                    <div class="col-12 pl-sm-0 pr-sm-2">
                      <mdb-input
                        v-model="parametro.value"
                        type="time"
                        :step="60"
                        class="my-1"
                        :label="parametro.name"
                        outline
                      />
                    </div>
                  </template>
                  <template v-else-if="parametro.type == 'boolean'">
                    <header class="col-12 col-sm-6">
                      {{ parametro.name }}
                    </header>
                    <div class="col-12 col-sm-6 pl-sm-0 pr-sm-2">
                      <div class="our-checkbox2 px-2">
                        <label class="fs-09 w-100">
                          <input
                            v-model="parametro.value"
                            type="checkbox"
                          >
                          <span />
                          Verdadero
                        </label>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="parametro.type == 'simpleOption'">
                    <header class="col-12 col-sm-6">
                      {{ parametro.name }}
                    </header>
                    <div class="col-12 col-sm-6 pl-sm-0 pr-sm-2">
                      <div
                        v-for="opt in parametro.options"
                        :key="opt"
                        class="our-radio2 px-2"
                      >
                        <label class="fs-09 w-100">
                          <input
                            v-model="parametro.value"
                            type="radio"
                            :value="opt"
                          >
                          <span />
                          {{ opt }}
                        </label>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="parametro.type == 'multipleOption'">
                    <header class="col-12 col-sm-6">
                      {{ parametro.name }}
                    </header>
                    <div class="col-12 col-sm-6 pl-sm-0 pr-sm-2">
                      <div
                        v-for="opt in parametro.options"
                        :key="opt"
                        class="our-checkbox2 px-2"
                      >
                        <label class="fs-09 w-100">
                          <input
                            v-model="parametro.value"
                            type="checkbox"
                            :value="opt"
                          >
                          <span />
                          {{ opt }}
                        </label>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="parametro.type == 'range'">
                    <header class="col-12 col-sm-6">
                      {{ parametro.name }}
                    </header>
                    <div class="col-12 col-sm-6 pl-sm-0 pr-sm-2">
                      <mdb-input
                        v-model="parametro.value1"
                        type="number"
                        class="my-1"
                        outline
                        @blur="parametro.value = addParamValueDuo(parametro)"
                      />
                      <mdb-input
                        v-model="parametro.value2"
                        type="number"
                        class="my-1"
                        outline
                        @blur="parametro.value = addParamValueDuo(parametro)"
                      />
                    </div>
                  </template>
                  <template v-else-if="parametro.type == 'rangeDates'">
                    <header class="col-12 col-sm-6">
                      {{ parametro.name }}
                    </header>
                    <div class="col-12 col-sm-6 pl-sm-0 pr-sm-2">
                      <mdb-input
                        v-model="parametro.value1"
                        type="date"
                        class="my-1 outline-date"
                        outline
                        @blur="parametro.value = addParamValueDuo(parametro)"
                      />
                      <mdb-input
                        v-model="parametro.value2"
                        type="date"
                        class="my-1 outline-date"
                        outline
                        @blur="parametro.value = addParamValueDuo(parametro)"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <div class="col-12 pl-sm-0 pr-sm-2">
                      <mdb-input
                        v-model="parametro.value"
                        class="my-1"
                        :label="parametro.name"
                        outline
                      />
                    </div>
                  </template>
                </li>
              </ul>
            </div>
          </template>
          <template
            v-else
            #body
          >
            <div class="text-center userSelect-none">
              <p class="card-text mb-1">
                Configura los antecedentes que usas con frecuencia en:
                <span class="d-block fs-inherit">
                  Configuración /
                  <font-awesome-icon
                    :icon="['fas', 'folder-open']"
                    size="sm"
                    class="mx-1"
                  />
                  Antecedentes
                </span>
              </p>
            </div>
          </template>
          <template
            #bottom-buttons
          >
            <AgregarAntecedente
              :button-secondary="'success-text px-1 py-2 m-0'"
              @created="updateHistItemList($event)"
            />
          </template>
        </TarjetasDelDiagnostico>
        <!-- Tarjeta de Vacunas -->
        <TarjetasDelDiagnostico
          :show-card="!!cardVis.immunizations.vis"
          :show-body="cardVis.immunizations.vis == 2"
        >
          <template #title>
            {{ cardVis.immunizations.name }}
          </template>
          <template #buttons>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.immunizations.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.immunizations.vis == 2
                  ? cardVis.immunizations.vis = 1
                  : cardVis.immunizations.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.immunizations.vis = 0"
            />
          </template>
          <template #body>
            <div
              v-if="vaccines && vaccines.length"
              class="row align-items-center mb-2"
            >
              <div class="col">
                <div class="md-form md-outline outline-select my-2">
                  <select
                    id="newCondTemp-select"
                    v-model="vaccineToUse"
                    class="custom-select"
                  >
                    <option
                      class="d-none"
                      disabled
                      :value="{}"
                    >
                      Seleccione
                    </option>
                    <option
                      v-for="ct in vaccines"
                      :key="ct.id"
                      :value="ct"
                    >
                      {{ ct.name }}
                    </option>
                  </select>
                  <label
                    for="newCondTemp-select"
                    :class="vaccineToUse
                      ? 'label-active' : 'label-inactive'"
                  >
                    Vacuna
                  </label>
                </div>
              </div>
              <mdb-btn
                :class="['col-auto m-0 mr-2 p-2',
                         {'animated pulse-bg infinite': vaccineToUse.id}
                ]"
                dark-waves
                flat
                icon="plus-circle"
                icon-class="success-text fa-lg fs-15-mobileOnly"
                size="md"
                @click="immunizations
                  .push({
                    vaccine:{
                      id: vaccineToUse.id,
                      name: vaccineToUse.name,
                      doses: vaccineToUse.doses,
                    },
                    vaccinations: [{
                      dose: {
                        type: vaccineToUse.doses[0].type,
                        order: vaccineToUse.doses[0].order,
                      },
                      fromDate: {formatted: sumToDate(
                        vaccineToUse.fromAge
                          ? paciente.persona.born.formatted
                          : null,
                        vaccineToUse.fromAge,
                        vaccineToUse.fromAgeUnit,
                      )},
                      toDate: {formatted: sumToDate(
                        vaccineToUse.toAge
                          ? paciente.persona.born.formatted
                          : null,
                        vaccineToUse.toAge,
                        vaccineToUse.toAgeUnit,
                      )},
                      // appliedAt: {formatted: ''},
                    }],
                    status: 'assigned',
                    shared: false,
                  }); vaccineToUse = {}"
              />
            </div>
            <p
              v-else
              class="card-text text-center userSelect-none"
            >
              No tienes vacunas registradas,
              ve a:
              <span class="d-block fs-inherit">
                Configuración /
                <font-awesome-icon
                  :icon="['fas', 'syringe']"
                  size="sm"
                  class="mx-1"
                />
                Vacunas
              </span>
              y agrega las vacunas que deseas utilizar.
            </p>
            <div v-if="immunizations && immunizations.length">
              <div
                v-for="(immunn, i) in immunizations"
                :key="'C'+i"
                class="px-2"
              >
                <hr
                  v-if="i > 0"
                  class="mt-1"
                >
                <div class="d-flex justify-content-between align-items-center">
                  <p class="m-0">
                    {{ immunn.vaccine.name }}
                    <mdb-badge
                      pill
                      :color="immunn.status == 'done'
                        ? 'bg-success-base'
                        : immunn.status == 'assigned'
                          ? 'bg-primary' : immunn.status == 'inProgress'
                            ? 'bg-warning' : 'bg-danger-base'"
                    >
                      {{ immunn.status == 'done' ? 'Terminado' :
                        immunn.status == 'assigned' ? 'Asignado' :
                        immunn.status == 'inProgress'
                          ? 'En curso' : 'Perdido' }}
                    </mdb-badge>
                  </p>
                  <mdb-btn
                    flat
                    dark-waves
                    size="sm"
                    class="m-0 px-2 icon-vial-circle-xmark"
                    @click="lostVaccine(immunn)"
                  >
                    Pasar a perdido
                  </mdb-btn>
                </div>
                <div class="d-flex justify-content-between">
                  <p class="m-0 line-height-minus">
                    <span
                      v-for="doses in immunn.vaccine.doses"
                      :key="doses.id"
                      class="d-block text-muted text-capitalize"
                    >
                      <small>
                        {{ doses.type }}:
                      </small>
                      <small>
                        {{ doses.startPeriod }}
                        {{ TemporalUnits[doses.startPeriodUnit] }}
                        <template v-if="doses.endPeriod">
                          - {{ doses.endPeriod }}
                          {{ TemporalUnits[doses.endPeriodUnit] }}
                        </template>
                      </small>
                    </span>
                  </p>
                  <mdb-btn
                    class="m-0 p-2"
                    dark-waves
                    flat
                    icon="trash"
                    icon-class="fa-lg"
                    size="md"
                    title="Eliminar la vacuna"
                    @click="immunizations.splice(i, 1)"
                  />
                </div>
                <hr class="w-25 mt-2 mb-1 mx-auto">
                <div
                  v-for="vac in immunn.vaccinations"
                  :key="vac.id"
                >
                  <p class="d-flex">
                    <span>
                      <span class="d-block text-capitalize">
                        {{ vac.dose.type }}
                      </span>
                      <small
                        v-if="vac.fromDate && vac.fromDate.formatted"
                        class="d-block text-muted"
                      >
                        {{ vac.fromDate.formatted.split('T')[0] | format_date }}
                        <template v-if="vac.toDate && vac.toDate.formatted">
                          al {{ vac.toDate.formatted.split('T')[0]
                            | format_date }}
                        </template>
                      </small>
                      <template v-if="vac.appliedAt">
                        <small v-if="vac.appliedAt.formatted">
                          Aplicado el
                          {{ vac.appliedAt.formatted.split('T')[0]
                            | format_date }}
                        </small>
                        <div>
                          <mdb-input
                            v-model="vac.appliedAt.formatted"
                            type="date"
                            label="Aplicado el"
                            class="my-1 outline-date"
                            outline
                          />
                        </div>
                      </template>
                    </span>
                    <mdb-btn
                      v-if="!vac.appliedAt
                        || (vac.appliedAt && !vac.appliedAt.formatted)"
                      flat
                      dark-waves
                      size="sm"
                      class="m-0 px-2 float-right"
                      icon="shield-virus"
                      @click="vac.appliedAt={
                                formatted: (new Date())
                                  .toISOString().split('T')[0]};
                              agregarDosis(immunn, vac)"
                    >
                      Registrar {{ vac.dose.type }}
                    </mdb-btn>
                  </p>
                </div>
              </div>
            </div>
            <p
              v-else
              class="card-text text-center userSelect-none pt-3"
            >
              El paciente no tiene vacunas registradas
            </p>
          </template>
        </TarjetasDelDiagnostico>
        <!-- Tarjeta de Alergias -->
        <TarjetasDelDiagnostico
          :show-card="!!cardVis.allergies.vis"
          :show-body="cardVis.allergies.vis == 2"
        >
          <template #title>
            {{ cardVis.allergies.name }}
          </template>
          <template #buttons>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.allergies.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.allergies.vis == 2
                  ? cardVis.allergies.vis = 1
                  : cardVis.allergies.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.allergies.vis = 0"
            />
          </template>
          <template #body>
            <div
              v-if="allergies && allergies.length"
              class="row align-items-center"
            >
              <div
                v-for="(alergia, i) in allergies"
                :key="i"
                class="col-12"
              >
                <mdb-input
                  v-model="alergia.allergenic.name"
                  class="my-2 icon-input-group"
                  placeholder="Alergia"
                  outline
                >
                  <mdb-btn
                    slot="append"
                    dark-waves
                    flat
                    group
                    icon="trash"
                    icon-class="fa-lg"
                    size="md"
                    @click="allergies.splice(i, 1)"
                  />
                </mdb-input>
              </div>
            </div>
            <p
              v-else
              class="card-text text-center userSelect-none"
            >
              El paciente no tiene alergias registradas
            </p>
          </template>
          <template #bottom-buttons>
            <mdb-btn
              class="success-text px-1 py-2 m-0"
              dark-waves
              flat
              icon="plus-circle"
              title="Agregar prueba"
              type="button"
              @click="allergies.push({allergenic:{name: ''}})"
            >
              Agregar
            </mdb-btn>
          </template>
        </TarjetasDelDiagnostico>
        <!-- Tarjeta de Condiciones especiales -->
        <TarjetasDelDiagnostico
          :show-card="!!cardVis.conditionsEspecials.vis"
          :show-body="cardVis.conditionsEspecials.vis == 2"
        >
          <template #title>
            {{ cardVis.conditionsEspecials.name }}
          </template>
          <template #buttons>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.conditionsEspecials.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.conditionsEspecials.vis == 2
                  ? cardVis.conditionsEspecials.vis = 1
                  : cardVis.conditionsEspecials.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.conditionsEspecials.vis = 0"
            />
          </template>
          <template #body>
            <div v-if="conditionsEspecials && conditionsEspecials.length">
              <div
                v-for="(condicionEs, i) in conditionsEspecials"
                :key="i"
                class="row align-items-center"
              >
                <div class="col-12">
                  <mdb-input
                    v-model="condicionEs.name"
                    class="my-2 icon-input-group"
                    placeholder="Condición especial"
                    outline
                  >
                    <mdb-btn
                      slot="append"
                      dark-waves
                      flat
                      group
                      icon="trash"
                      icon-class="fa-lg"
                      size="md"
                      @click="conditionsEspecials.splice(i, 1)"
                    />
                  </mdb-input>
                </div>
              </div>
            </div>
            <p
              v-else
              class="card-text text-center userSelect-none"
            >
              El paciente no tiene condiciones especiales registradas
            </p>
          </template>
          <template #bottom-buttons>
            <mdb-btn
              class="success-text px-1 py-2 m-0"
              dark-waves
              flat
              icon="plus-circle"
              type="button"
              @click="conditionsEspecials.push({name: '', type: 'Especial'})"
            >
              Agregar
            </mdb-btn>
          </template>
        </TarjetasDelDiagnostico>
        <!-- Tarjeta de Condiciones temporales -->
        <TarjetasDelDiagnostico
          :show-card="!!cardVis.conditionsTemporals.vis"
          :show-body="cardVis.conditionsTemporals.vis == 2"
        >
          <template #title>
            {{ cardVis.conditionsTemporals.name }}
          </template>
          <template #buttons>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.conditionsTemporals.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.conditionsTemporals.vis == 2
                  ? cardVis.conditionsTemporals.vis = 1
                  : cardVis.conditionsTemporals.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.conditionsTemporals.vis = 0"
            />
          </template>
          <template #body>
            <div class="row align-items-center mb-2">
              <div class="col">
                <div class="md-form md-outline outline-select my-2">
                  <select
                    id="newCondTemp-select"
                    v-model="conditionTempToUse"
                    class="custom-select"
                  >
                    <option
                      class="d-none"
                      disabled
                      :value="{}"
                    >
                      Seleccione
                    </option>
                    <option
                      v-for="ct in conditionTemplates"
                      :key="ct.id"
                      :value="ct"
                    >
                      {{ ct.name }}
                    </option>
                  </select>
                  <label
                    for="newCondTemp-select"
                    :class="conditionTempToUse
                      ? 'label-active' : 'label-inactive'"
                  >
                    Plantilla
                  </label>
                </div>
              </div>
              <mdb-btn
                :class="['col-auto m-0 mr-2 p-2',
                         {'animated pulse-bg infinite': conditionTempToUse.id}
                ]"
                dark-waves
                flat
                icon="plus-circle"
                icon-class="success-text fa-lg fs-15-mobileOnly"
                size="md"
                @click="conditionsTemporals
                  .push({name: conditionTempToUse.name
                           || '',
                         end: '',start: '',
                         type: 'Temporal',
                         operation: conditionTempToUse.operation || null,
                         temporalUnit: conditionTempToUse.temporalUnit || null,
                         quantity: conditionTempToUse.quantity || null,
                         templateId: conditionTempToUse.id || null,
                  }); conditionTempToUse = {}"
              />
            </div>
            <div v-if="conditionsTemporals && conditionsTemporals.length">
              <div
                v-for="(condicionTemp, i) in conditionsTemporals"
                :key="'C'+i"
                class="row align-items-center mb-2"
              >
                <div class="col pr-0">
                  <hr
                    v-if="i > 0"
                    class="mt-1"
                  >
                  <div class="row">
                    <div class="col-12">
                      <mdb-input
                        v-model="condicionTemp.name"
                        class="my-1"
                        label="Nombre"
                        placeholder="Condición temporal"
                        outline
                      />
                    </div>
                    <div class="col-12 col-sm-6 col-md-12">
                      <mdb-input
                        v-model="condicionTemp.start"
                        type="date"
                        label="Desde"
                        class="my-1 outline-date"
                        outline
                        @input="condicionTemp.end =
                          calcConditionTemporal(condicionTemp)"
                      />
                    </div>
                    <div
                      v-if="!condicionTemp.operation"
                      class="col-12 col-sm-6 col-md-12"
                    >
                      <mdb-input
                        v-model="condicionTemp.end"
                        type="date"
                        label="Hasta"
                        class="my-1 outline-date"
                        outline
                      />
                    </div>
                    <div
                      v-else
                      class="col-12 col-sm-6 col-md-12"
                    >
                      <mdb-alert
                        v-if="condicionTemp.end || false"
                        color="primary"
                        class="mb-0 p-1"
                      >
                        <template v-if="condicionTemp.operation === 'com'">
                          <h6 class="alert-heading mb-0">
                            Tiempo estimado
                          </h6>
                          <span class="font-weight-bold">
                            {{ condicionTemp.end }}
                          </span>
                        </template>
                        <template v-else>
                          <h6 class="alert-heading">
                            Fecha estimada
                          </h6>
                          <span class="font-weight-bold">
                            {{ condicionTemp.end | format_date }}
                          </span>
                        </template>
                      </mdb-alert>
                    </div>
                  </div>
                </div>
                <div class="col-auto px-2">
                  <mdb-btn
                    class="m-0 p-2"
                    dark-waves
                    flat
                    icon="trash"
                    icon-class="fa-lg"
                    size="md"
                    @click="conditionsTemporals.splice(i, 1)"
                  />
                </div>
              </div>
            </div>
            <p
              v-else
              class="card-text text-center userSelect-none pt-3"
            >
              El paciente no tiene condiciones temporales registradas
            </p>
          </template>
          <template #bottom-buttons>
            <mdb-btn
              class="success-text px-1 py-2 m-0"
              dark-waves
              flat
              icon="plus-circle"
              type="button"
              @click="conditionsTemporals
                .push({name: conditionTempToUse.name
                         || '',
                       end: '',start: '',
                       type: 'Temporal',
                       operation: conditionTempToUse.operation || null,
                       temporalUnit: conditionTempToUse.temporalUnit || null,
                       quantity: conditionTempToUse.quantity || null,
                       templateId: conditionTempToUse.id || null,
                }); conditionTempToUse = {}"
            >
              Agregar
            </mdb-btn>
          </template>
        </TarjetasDelDiagnostico>
        <!-- Formulario para servicio a usar -->
        <TarjetasDelDiagnostico
          :show-card="!!cardVis.service.vis"
          :show-body="cardVis.service.vis == 2"
        >
          <template #title>
            {{ cardVis.service.name }}
          </template>
          <template #buttons>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.service.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.service.vis == 2
                  ? cardVis.service.vis = 1
                  : cardVis.service.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.service.vis = 0"
            />
          </template>
          <template
            v-if="cita.doctor.services
              && cita.doctor.services.length"
            #body
          >
            <div class="row align-items-center mb-2">
              <div class="col">
                <div class="md-form md-outline outline-select my-1">
                  <select
                    id="newTest-select"
                    v-model="newServiceToUse"
                    class="custom-select"
                  >
                    <option
                      class="d-none"
                      disabled
                      :value="{}"
                    >
                      Seleccione
                    </option>
                    <option
                      v-for="servicio in cita.doctor.services"
                      :key="servicio.id"
                      :value="servicio"
                    >
                      {{ servicio.name }}
                    </option>
                  </select>
                  <label
                    for="newTest-select"
                    :class="newServiceToUse ? 'label-active' : 'label-inactive'"
                  >
                    Grupo de parámetros
                  </label>
                </div>
              </div>
              <mdb-btn
                :class="['col-auto success-text m-0 p-2',
                         {'animated pulse-bg infinite': newServiceToUse.id}]"
                dark-waves
                flat
                icon="plus-circle"
                icon-class="fs-15-mobileOnly"
                size="sm"
                title="Añadir grupo"
                :disabled="!newServiceToUse.id"
                @click="addDiagnostic"
              />
            </div>
            <transition-group
              name="custom-classes-transition"
              enter-active-class="animated fadeIn"
              leave-active-class="animated fadeOut"
              tag="ul"
              class="card-text list-unstyled mb-0 mt-2"
            >
              <li
                v-for="(servicio, iSer) in services"
                :key="servicio.id"
                class="row align-items-center"
              >
                <header class="col">
                  {{ servicio.name }}
                </header>
                <mdb-btn
                  class="col-auto danger-text m-0 mr-2 p-1"
                  dark-waves
                  flat
                  icon="minus-circle"
                  size="sm"
                  title="Eliminar estudio"
                  type="button"
                  @click="removeDiagnostic(iSer)"
                />
              </li>
            </transition-group>
          </template>
          <template
            v-else
            #body
            class="text-center userSelect-none"
          >
            <p class="card-text mb-1">
              Configura parámetros que mides con frecuencia en:
              <span class="d-block fs-inherit">
                Configuración /
                <font-awesome-icon
                  :icon="['fas', 'stethoscope']"
                  size="sm"
                  class="mx-1"
                />
                Parámetros
              </span>
            </p>
          </template>
        </TarjetasDelDiagnostico>
        <!-- Lista de servicios seleccionados -->
        <TarjetasDelDiagnostico
          v-for="(servicio, iSer) in services"
          :key="servicio.id"
          :card-class="'teal lighten-5'"
          :show-card="!!services.length"
        >
          <template #title>
            {{ servicio.name }}
          </template>
          <template #buttons>
            <mdb-btn
              class="col-auto danger-text m-0 mr-2 p-1"
              dark-waves
              flat
              icon="trash"
              size="sm"
              title="Eliminar estudio"
              type="button"
              @click="removeDiagnostic(iSer)"
            />
          </template>
          <template #body>
            <ul
              v-if="servicio.params.length"
              class="card-text list-unstyled mb-0"
            >
              <li
                v-for="paro in servicio.params"
                :key="paro.Parameter.id"
              >
                {{ paro.Parameter.name }}:
                <div
                  v-if="paro.Parameter.type == 'file'
                    || paro.Parameter.type == 'draw'"
                  class="col-12 col-sm-6"
                >
                  <div>
                    <!--eslint-disable-next-line vue/html-self-closing -->
                    <img
                      v-if="paro.Parameter.value"
                      :src="endpoint+paro.Parameter.value"
                      class="w-100"
                    />
                  </div>
                </div>
                <span
                  v-else-if="paro.Parameter.value
                    && Array.isArray(paro.Parameter.value)"
                >
                  <span
                    v-for="o in paro.Parameter.value"
                    :key="o"
                    class="auto-coma"
                  >
                    {{ o }}
                  </span>
                </span>
                <span
                  v-else-if="paro.Parameter.value != undefined
                    && typeof paro.Parameter.value === 'boolean'"
                >
                  <span v-if="paro.Parameter.value">Verdadero</span>
                  <span v-else>Falso</span>
                </span>
                <span v-else>
                  {{ paro.Parameter.value }}
                </span>
                {{ paro.Parameter.symbol }}
              </li>
            </ul>
          </template>
          <template
            #bottom-buttons
          >
            <mdb-btn
              class="w-100 m-0 mt-2 flat-bordered"
              flat
              dark-waves
              icon="pen"
              icon-class="fa-lg"
              title="Editar"
              size="sm"
              @click="showMeasureServiceSelected(servicio)"
            >
              Anotar valores
            </mdb-btn>
          </template>
        </TarjetasDelDiagnostico>
        <!-- Plantillas de gráficos a usar -->
        <TarjetasDelDiagnostico
          v-if="chartsTemplate.length && !!cardVis.charts.vis"
          :show-body="cardVis.charts.vis == 2"
        >
          <template #title>
            {{ cardVis.charts.name }}
          </template>
          <template #buttons>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.charts.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.charts.vis == 2
                  ? cardVis.charts.vis = 1
                  : cardVis.charts.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.charts.vis = 0"
            />
          </template>
          <template
            #body
          >
            <div
              class="md-form md-outline outline-select my-1"
            >
              <ChartMixer
                :parametros="paramsWValues"
                @graficar="chartOpt=$event"
              />
              <Chart
                v-show="chartOpt.data"
                :options="chartOpt"
              />
              <mdb-btn
                v-if="chartOpt.data"
                class="col mx-0 mb-3 flat-bordered"
                dark-waves
                flat
                icon="expand"
                icon-class="fa-lg"
                size="sm"
                @click="showChartFull=true"
              >
                Ver en grande
              </mdb-btn>
            </div>
          </template>
        </TarjetasDelDiagnostico>
        <!-- Tarjeta Examen físico -->
        <TarjetasDelDiagnostico
          :show-card="!!cardVis.physicalExam.vis"
          :show-body="cardVis.physicalExam.vis == 2"
        >
          <template #title>
            {{ cardVis.physicalExam.name }}
          </template>
          <template #buttons>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.physicalExam.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.physicalExam.vis == 2
                  ? cardVis.physicalExam.vis = 1
                  : cardVis.physicalExam.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.physicalExam.vis = 0"
            />
          </template>
          <template #body>
            <mdb-input
              v-model="encounter.physicalExam"
              type="textarea"
              class="my-1"
              :rows="5"
              outline
              @blur="capitalizePhysicalExam()"
            />
          </template>
        </TarjetasDelDiagnostico>
        <!-- Tarjeta de Diagnóstico -->
        <TarjetasDelDiagnostico
          :show-card="!!cardVis.symptoms.vis"
          :show-body="cardVis.symptoms.vis == 2"
        >
          <template #title>
            {{ cardVis.symptoms.name }}
          </template>
          <template #buttons>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.symptoms.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.symptoms.vis == 2
                  ? cardVis.symptoms.vis = 1
                  : cardVis.symptoms.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.symptoms.vis = 0"
            />
          </template>
          <template #body>
            <div class="row">
              <div class="col-12 col-sm-6 card-text pr-sm-2">
                <div class="row align-items-center mx-0">
                  <header class="col px-0 font-weight-bold">
                    Síntomas
                  </header>
                  <mdb-btn
                    class="col-auto success-text m-0 mr-n2 mr-sm-0 p-1"
                    dark-waves
                    flat
                    icon="plus-circle"
                    icon-class="fs-15-mobileOnly"
                    size="sm"
                    title="Añadir síntoma"
                    type="button"
                    @click="showAddSymptom = true;"
                  />
                </div>
                <ul
                  class="angle-list mr-0"
                >
                  <li
                    v-for="(sintoma, i) in symptoms"
                    :key="i"
                    class="row align-items-center mx-0"
                  >
                    <p class="col px-0 mb-0">
                      {{ sintoma.name }}
                    </p>
                    <mdb-btn
                      class="col-auto danger-text m-0 p-1"
                      dark-waves
                      flat
                      icon="minus-circle"
                      size="sm"
                      title="Eliminar síntoma"
                      type="button"
                      @click="symptoms.splice(i, 1)"
                    />
                  </li>
                </ul>
              </div>
              <div class="col-12 col-sm-6 card-text pl-sm-2">
                <div class="row align-items-center mx-0 mr-n2">
                  <header class="col px-0 font-weight-bold">
                    Diagnóstico
                  </header>
                  <mdb-btn
                    class="col-auto success-text m-0 p-1"
                    dark-waves
                    flat
                    icon="plus-circle"
                    icon-class="fs-15-mobileOnly"
                    size="sm"
                    title="Añadir diagnóstico"
                    type="button"
                    @click="showAddDiagnosis = true"
                  />
                </div>
                <ul class="check-list-style">
                  <li
                    v-for="(diagnosi, i) in diagnosis"
                    :key="i"
                    class="row align-items-center"
                  >
                    <div class="col px-0">
                      <p>
                        {{ diagnosi.disease.name }}
                      </p>
                      <p
                        v-if="diagnosi.conclusion"
                        class="conclusion"
                      >
                        {{ diagnosi.conclusion }}
                      </p>
                    </div>
                    <mdb-btn
                      class="col-auto danger-text m-0 mr-2 p-1"
                      dark-waves
                      flat
                      icon="minus-circle"
                      size="sm"
                      title="Eliminar diagnóstico"
                      type="button"
                      @click="diagnosis.splice(i, 1)"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </TarjetasDelDiagnostico>
        <!-- Tarjeta resultado de paraclinico(s) consulta anterior -->
        <TarjetasDelDiagnostico
          :show-card="!!cardVis.previousOrdersDetails.vis"
          :show-body="cardVis.previousOrdersDetails.vis == 2"
          :card-class="'rgba-yellow-slight'"
        >
          <template #title>
            {{ cardVis.previousOrdersDetails.name }}
          </template>
          <template #buttons>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.previousOrdersDetails.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.previousOrdersDetails.vis == 2
                  ? cardVis.previousOrdersDetails.vis = 1
                  : cardVis.previousOrdersDetails.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.previousOrdersDetails.vis = 0"
            />
          </template>
          <template #body>
            <mdb-input
              v-model="encounter.previousOrdersDetails"
              type="textarea"
              :rows="5"
              class="my-1 toggle-textarea"
              outline
              @blur="capitalizePreviousOrdersDetails()"
            />
          </template>
        </TarjetasDelDiagnostico>
        <!-- Formulario de exámenes o paraclínicos -->
        <TarjetasDelDiagnostico
          v-if="!!cardVis.orders.vis"
          :show-body="cardVis.orders.vis == 2"
        >
          <template #title>
            {{ cardVis.orders.name }}
          </template>
          <template #buttons>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.orders.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.orders.vis == 2
                  ? cardVis.orders.vis = 1
                  : cardVis.orders.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.orders.vis = 0"
            />
          </template>
          <template #body>
            <div class="row">
              <div class="col pr-1">
                <div class="md-form md-outline outline-select my-1">
                  <select
                    id="newTest-select"
                    v-model="paraclinicalToUse"
                    class="custom-select"
                    @change="addParaclinicalBtn = true;"
                  >
                    <option
                      class="d-none"
                      disabled
                      :value="{}"
                    >
                      Seleccione
                    </option>
                    <option
                      v-for="paraclinical in paraclinicalItems"
                      :key="paraclinical.name"
                      :value="paraclinical.content"
                    >
                      {{ paraclinical.name }}
                    </option>
                  </select>
                  <label
                    for="newTest-select"
                    :class="paraclinicalToUse
                      ? 'label-active'
                      : 'label-inactive'"
                  >
                    Plantilla
                  </label>
                </div>
              </div>
              <mdb-btn
                :class="['col-auto success-text ml-0 my-1 mr-3 p-1']"
                dark-waves
                flat
                icon="plus-circle"
                :icon-class="['fs-15-mobileOnly',
                              {'animated pulse-bg infinite': paraclinicalToUse
                                && addParaclinicalBtn}]"
                size="sm"
                title="Añadir plantilla"
                type="button"
                @click="newOrder.custom += `${paraclinicalToUse}\n`;
                        addParaclinicalBtn = false;"
              />
            </div>
            <mdb-input
              v-model="newOrder.custom"
              :label="switchToInstitute ? 'Instrucciones' : 'Descripción'"
              type="textarea"
              class="my-1"
              :rows="3"
              outline
            />
            <!-- <div class="card-text">
              <div class="custom-control custom-switch pr-3">
                <input
                  id="switchToInstitute"
                  v-model="switchToInstitute"
                  type="checkbox"
                  class="custom-control-input cursor-pointer"
                >
                <label
                  class="custom-control-label cursor-pointer"
                  for="switchToInstitute"
                >
                  Agregar instituto
                </label>
              </div>
            </div> -->
            <div
              v-if="switchToInstitute"
              class="row"
            >
              <div class="col-12">
                <div class="md-form md-outline outline-select my-1">
                  <select
                    id="newOrder-country-select"
                    v-model="newOrder.country"
                    class="custom-select"
                  >
                    <option
                      :value="newOrder.country"
                    >
                      {{ newOrder.country }}
                    </option>
                    <option value="Venezuela">
                      Venezuela
                    </option>
                  </select>
                  <label
                    for="newOrder-country-select"
                    :class="newOrder.country ? 'label-active' :
                      'label-inactive'"
                  >
                    País
                  </label>
                </div>
              </div>
              <div class="col-12">
                <div class="md-form md-outline outline-select my-1">
                  <select
                    id="newOrder-state-select"
                    v-model="newOrder.state"
                    class="custom-select"
                  >
                    <option value=".">
                      .
                    </option>
                  </select>
                  <label
                    for="newOrder-state-select"
                    :class="newOrder.state ? 'label-active' :
                      'label-inactive'"
                  >
                    Estado
                  </label>
                </div>
              </div>
              <div class="col-12">
                <div class="md-form md-outline outline-select my-1">
                  <select
                    id="newOrder-city-select"
                    v-model="newOrder.city"
                    class="custom-select"
                  >
                    <option value="Valera">
                      .
                    </option>
                  </select>
                  <label
                    for="newOrder-city-select"
                    :class="newOrder.city ? 'label-active' :
                      'label-inactive'"
                  >
                    Ciudad
                  </label>
                </div>
              </div>
              <div class="col-12">
                <div class="md-form md-outline outline-select my-1">
                  <select
                    id="newOrder-institute-select"
                    v-model="newOrder.institute"
                    class="custom-select"
                  >
                    <option value="Instituto-Médico-Valera">
                      Instituto Médico Valera
                    </option>
                  </select>
                  <label
                    for="newOrder-institute-select"
                    :class="newOrder.institute ? 'label-active' :
                      'label-inactive'"
                  >
                    Instituto
                  </label>
                </div>
              </div>
              <div class="col-12">
                <div class="md-form md-outline outline-select my-1">
                  <select
                    id="newOrder-service-select"
                    v-model="newOrder.service"
                    class="custom-select"
                  >
                    <option
                      :value="{}"
                    >
                      Seleccione
                    </option>
                    <option :value="{name:'', id: 1}">
                      .
                    </option>
                  </select>
                  <label
                    for="newOrder-service-select"
                    :class="newOrder.service.name ? 'label-active' :
                      'label-inactive'"
                  >
                    Estudio
                  </label>
                </div>
              </div>
            </div>
          </template>
          <template #bottom-buttons>
            <mdb-btn
              class="border-right col m-0 p-1 py-2 flex-center"
              dark-waves
              flat
              icon="broom"
              icon-class="fs-15-mobileOnly"
              size="sm"
              title="Limpiar descripción"
              type="button"
              :disabled="!newOrder.custom"
              @click="newOrder.custom = ''"
            >
              Limpiar
            </mdb-btn>
            <mdb-btn
              :class="['col m-0 p-1 py-2 flex-center',
                       {'animated pulse-bg infinite': newOrder.custom}]"
              dark-waves
              flat
              icon="check"
              :icon-class="['fs-15-mobileOnly success-text']"
              size="sm"
              title="Guardar paraclínico"
              type="button"
              :disabled="!newOrder.custom"
              @click="addOrder"
            >
              Guardar
            </mdb-btn>
          </template>
        </TarjetasDelDiagnostico>
        <!-- Lista de exámenes o paraclínicos -->
        <div
          v-for="(order, i) in orders"
          :key="i"
          class="card bord-style blue lighten-5"
        >
          <div class="card-body">
            <div class="card-title row align-items-center">
              <header class="col h5-responsive">
                {{ order.service }} asdf
              </header>
              <div class="col-auto">
                <mdb-btn
                  class="d-inline-block danger-text m-0 mr-2 p-1"
                  dark-waves
                  flat
                  icon="trash"
                  size="sm"
                  title="Eliminar orden de paraclínico"
                  type="button"
                  @click="removeOrder(i)"
                />
                <mdb-btn
                  class="d-inline-block m-0 p-1"
                  dark-waves
                  flat
                  icon="print"
                  size="sm"
                  title="Imprimir"
                  type="button"
                />
              </div>
            </div>
            <pre class="card-text">{{ order.appointment.instruction }}</pre>
          </div>
        </div>
        <!-- Lista de exámenes o paraclinicos personalizados -->
        <TarjetasDelDiagnostico
          v-for="(orderC, i) in ordersCustom"
          :key="'c'+i"
          :show-card="!!ordersCustom.length"
          :card-class="'blue lighten-5'"
        >
          <template #title>
            Paraclínico complementario
          </template>
          <template #buttons>
            <mdb-btn
              class="col-auto danger-text m-0 p-2"
              dark-waves
              flat
              icon="trash"
              size="sm"
              title="Eliminar orden de paraclínico"
              type="button"
              @click="removeOrderCustom(i)"
            />
          </template>
          <template #body>
            <pre
              class="card-text roboto-text"
            >{{ orderC.appointment.instruction }}</pre>
          </template>
          <template #bottom-buttons>
            <mdb-btn
              class="col m-0 px-1 py-2 flex-center border-right"
              dark-waves
              flat
              icon="pen"
              icon-class="fa-lg mr-1"
              size="sm"
              title="Editar orden de paraclínico"
              type="button"
              @click="editOrderCustom(orderC.appointment)"
            >
              Editar
            </mdb-btn>
            <mdb-btn
              class="col m-0 px-1 py-2 flex-center"
              dark-waves
              flat
              icon="print"
              icon-class="fa-lg mr-1"
              size="sm"
              title="Imprimir orden de paraclínico"
              type="button"
              @click="toPrint.code = printParaclinico(orderC.appointment, i);
                      showPrintOptionsModal = true"
            >
              Imprimir
            </mdb-btn>
          </template>
        </TarjetasDelDiagnostico>
        <!-- Tarjeta Notas adicionales -->
        <TarjetasDelDiagnostico
          v-if="!!cardVis.notes.vis"
          :show-body="cardVis.notes.vis == 2"
        >
          <template #title>
            {{ cardVis.notes.name }}
          </template>
          <template #buttons>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.notes.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.notes.vis == 2
                  ? cardVis.notes.vis = 1
                  : cardVis.notes.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.notes.vis = 0"
            />
          </template>
          <template
            #body
          >
            <mdb-input
              v-model="encounter.notes"
              type="textarea"
              class="my-1"
              :rows="5"
              outline
              @blur="capitalizeNotes()"
            />
          </template>
        </TarjetasDelDiagnostico>
        <!-- Tarjeta Imágenes -->
        <TarjetasDelDiagnostico
          v-if="!!cardVis.images.vis"
          :show-body="cardVis.images.vis == 2"
        >
          <template #title>
            {{ cardVis.images.name }}
          </template>
          <template #buttons>
            <mdb-btn
              class="col-auto m-0 mr-2 p-2"
              dark-waves
              flat
              icon="plus-circle"
              icon-class="success-text fa-lg fs-15-mobileOnly"
              size="md"
              title="Añadir imagen"
              @click="showNewImage = true"
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.images.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.images.vis == 2
                  ? cardVis.images.vis = 1
                  : cardVis.images.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.images.vis = 0"
            />
          </template>
          <template
            #body
          >
            <div
              v-for="image in images"
              :key="image.id"
              class="row border-bottom py-1"
            >
              <div class="col-4 px-0">
                <!--eslint-disable-next-line vue/html-self-closing -->
                <img
                  :src="endpoint+image.url"
                  class="w-100"
                />
              </div>
              <div
                class="col-3 px-1"
              >
                <span v-if="image.title">
                  {{ image.title }}
                </span>
                <span
                  v-else
                  class="card-text userSelect-none"
                >
                  Sin título
                </span>
              </div>
              <div class="col-4 px-2">
                {{ image.tags ? image.tags.map((i)=>i.name).join(', ') : '' }}
              </div>
              <div class="col-1 px-0">
                <mdb-btn
                  class="m-0 p-0"
                  icon="trash"
                  icon-class="danger-text"
                  size="sm"
                  dark-waves
                  flat
                  title="Eliminar imagen"
                  @click="imagenSeleccionada=image;
                          showConfirmacionEliminar=true;"
                />
              </div>
            </div>
          </template>
        </TarjetasDelDiagnostico>
        <!-- Tarjeta Herramientas -->
        <TarjetasDelDiagnostico
          v-if="!!cardVis.tools.vis"
          :show-body="cardVis.tools.vis == 2"
        >
          <template #title>
            {{ cardVis.tools.name }}
          </template>
          <template #buttons>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.tools.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.tools.vis == 2
                  ? cardVis.tools.vis = 1
                  : cardVis.tools.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.tools.vis = 0"
            />
          </template>
          <template #body>
            <ModalLectorDicom />
          </template>
        </TarjetasDelDiagnostico>
        <!-- Tarjeta etiquetas de consulta -->
        <TarjetasDelDiagnostico
          v-if="!!cardVis.labels.vis"
          :show-body="cardVis.labels.vis == 2"
        >
          <template #title>
            {{ cardVis.labels.name }}
          </template>
          <template #buttons>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.labels.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.labels.vis == 2
                  ? cardVis.labels.vis = 1
                  : cardVis.labels.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.labels.vis = 0"
            />
          </template>
          <template #body>
            <div class="md-form md-outline outline-select my-1">
              <select
                id="newTag-select"
                v-model="newTag"
                class="custom-select"
                @change="addTag"
              >
                <option
                  class="d-none"
                  disabled
                  value=""
                >
                  Seleccione
                </option>
                <option
                  v-for="(etiqueta, i) in tagsAvailables"
                  :key="i"
                  :value="etiqueta.name"
                >
                  {{ etiqueta.name }}
                </option>
                <option value="otra">
                  Agregar nueva
                </option>
              </select>
            </div>
            <div
              v-if="newTag == 'otra'"
              class="d-flex align-items-center mb-2"
            >
              <mdb-input
                v-model="otherTag"
                placeholder="Nueva Etiqueta"
                class="col p-0 my-1"
                outline
              />
              <mdb-btn
                :class="['col-auto success-text ml-0 p-2',
                         {'animated pulse-bg infinite': newTag ||
                           (newTag == 'otra' && otherTag)}]"
                dark-waves
                flat
                icon="plus-circle"
                icon-class="fs-15-mobileOnly"
                size="sm"
                title="Añadir etiqueta"
                :disabled="(!newTag || newTag == 'otra') && !otherTag"
                @click="addTag"
              />
            </div>
            <div
              v-for="(etiqueta, i) in tags"
              :key="i"
              class="chip close-btn"
            >
              {{ etiqueta }}
              <mdb-btn
                class="m-0 ml-2 p-1"
                dark-waves
                flat
                size="sm"
                icon="times"
                title="Eliminar etiqueta"
                @click="tags.splice(i, 1)"
              />
            </div>
          </template>
        </TarjetasDelDiagnostico>
        <!-- Tarjeta agrupar por categoría -->
        <!-- <div class="card bord-style">
          <div class="card-body">
            <div class="card-title row align-items-center">
              <header class="col h5-responsive">
                Agrupar consulta
              </header>
              <mdb-btn
                class="col-auto m-0 mr-2 p-1"
                dark-waves
                flat
                icon="cog"
                size="sm"
                title="Agregar estudio"
                type="button"
              />
            </div>
            <header class="font-weight-bold card-text">
              Nombre de esta consulta en el grupo
            </header>
            <mdb-input
              type="text"
              class="my-1"
              outline
            />
            <ul class="list-unstyled card-text">
              <li class="our-checkbox2">
                <label>
                  <input
                    type="checkbox"
                    name="group-check"
                  >
                  <span />
                  Próximamente
                </label>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
      <!-- Receta e Indicaciones -->
      <TarjetasDelDiagnostico
        v-if="!!cardVis.recipe.vis"
        :show-body="cardVis.recipe.vis == 2"
      >
        <template #title>
          {{ cardVis.recipe.name }}
        </template>
        <template #buttons>
          <mdb-btn
            dark-waves
            flat
            class="my-0 p-1"
            :icon="cardVis.recipe.vis == 2 ? 'chevron-up' : 'chevron-down'"
            @click="
              cardVis.recipe.vis == 2
                ? cardVis.recipe.vis = 1
                : cardVis.recipe.vis = 2
            "
          />
          <mdb-btn
            dark-waves
            flat
            class="my-0 p-1"
            icon="eye-slash"
            @click="cardVis.recipe.vis = 0"
          />
        </template>
        <template #body>
          <div class="p-relative">
            <ul
              class="nav nav-pills pills-primary-link"
              role="tablistRecipe"
            >
              <li
                class="nav-item cursor-pointer"
                @click="indicationsTab = 0"
              >
                <span :class="['nav-link', { 'active': indicationsTab == 0 }]">
                  Medicamentos
                </span>
              </li>
              <li
                class="nav-item cursor-pointer"
                @click="indicationsTab = 1"
              >
                <span :class="['nav-link', { 'active': indicationsTab == 1 }]">
                  Indicaciones
                </span>
              </li>
              <li
                class="nav-item cursor-pointer"
                @click="indicationsTab = 2"
              >
                <span :class="['nav-link', { 'active': indicationsTab == 2 }]">
                  Tratamientos
                </span>
              </li>
            </ul>
            <div class="tab-content mt-3 px-3 px-md-0">
              <div
                :class="['tab-pane fade show',
                         { 'active': indicationsTab == 0 }]"
              >
                <!-- Lista de medicamentos con sus alternativas -->
                <transition-group
                  class="row list-unstyled m-0 mb-2"
                  tag="ul"
                  name="custom-classes-transition"
                  enter-active-class="animated fadeIn"
                  leave-active-class="animated fadeOut"
                >
                  <li
                    v-for="(medicina, iMed) in receip"
                    :key="iMed+'a'"
                    class="col-12 col-lg-6 p-0 px-lg-2"
                  >
                    <div class="row align-items-center">
                      <div class="col">
                        {{ medicina.medicine.name }}
                        {{ medicina.medicine.presentation }}
                        {{ medicina.medicine.dose }}
                      </div>
                      <div class="col-auto">
                        <mdb-btn
                          class="d-inline-block success-text m-0 mr-2 p-1"
                          dark-waves
                          flat
                          size="sm"
                          title="Añadir alternativa"
                          type="button"
                          @click="showEditMedicine(medicina)"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'plus']"
                            size="lg"
                            class="d-inline-block"
                          />
                          <font-awesome-icon
                            :icon="['fas', 'bars']"
                            size="lg"
                            class="d-inline-block"
                          />
                        </mdb-btn>
                        <mdb-btn
                          class="d-inline-block danger-text m-0 p-1"
                          dark-waves
                          flat
                          icon="minus-circle"
                          size="sm"
                          title="Eliminar medicamento"
                          type="button"
                          @click="removeMedicine(iMed)"
                        />
                      </div>
                      <ul
                        class="col-12"
                      >
                        <li
                          v-for="(alternativa, iAlt) in medicina.medicinesAlt"
                          :key="iAlt"
                          class="row align-items-center m-0"
                        >
                          <div class="col pr-2">
                            {{ alternativa.medicine.name }}
                            {{ alternativa.medicine.presentation }}
                            {{ alternativa.medicine.dose }}
                          </div>
                          <mdb-btn
                            class="col-auto danger-text m-0 p-1"
                            dark-waves
                            flat
                            icon="minus-circle"
                            size="sm"
                            title="Eliminar medicamento"
                            type="button"
                            @click="removeMedicineAlt(medicina, iAlt)"
                          />
                        </li>
                      </ul>
                    </div>
                  </li>
                </transition-group>
                <div class="row align-items-center">
                  <p class="col-auto card-text font-weight-bold mb-1">
                    Válido hasta
                  </p>
                  <div class="col-12 col-sm-5 col-lg-3">
                    <mdb-input
                      v-model="receipExpire"
                      type="date"
                      class="w-100 my-1 outline-date"
                      outline
                    />
                  </div>
                </div>
                <!-- Botón para agregar medicamento -->
                <div class="row mx-0">
                  <mdb-btn
                    class="col mx-0 mb-3 mb-md-0 flat-bordered"
                    dark-waves
                    flat
                    icon="plus"
                    icon-class="fa-lg"
                    size="sm"
                    @click="showModalMedication"
                  >
                    Agregar medicamento
                  </mdb-btn>
                </div>
              </div>
              <div
                :class="['tab-pane fade show',
                         { 'active': indicationsTab == 1 }]"
              >
                <p class="card-text mb-1">
                  Ejemplo de impresión del preformato:
                </p>
                <p class="card-text">
                  <span class="font-weight-bold cyan-text">
                    1
                  </span>
                  <span class="font-weight-bold deep-purple-text">
                    cápsula
                  </span>
                  cada
                  <span class="font-weight-bold teal-text">
                    8
                  </span>
                  horas por
                  <span class="font-weight-bold purple-text">
                    3
                  </span>
                  días.
                </p>
                <ul
                  class="list-unstyled"
                >
                  <li
                    v-for="(medicina, iMedicina) in receip"
                    :key="iMedicina"
                    class="mb-3"
                  >
                    <header class="font-weight-bold">
                      {{ medicina.medicine.name }}
                      <small class="text-muted text-uppercase">
                        {{ medicina.medicine.presentation }}
                      </small>
                    </header>
                    <div
                      v-if="!medicina.custom"
                      class="row align-items-center"
                    >
                      <div class="col-6 col-sm-3 pr-0">
                        <mdb-input
                          v-model="medicina.quantity"
                          type="number"
                          label="# Cantidad"
                          class="my-1 text-nowrap border-cyan"
                          title="Cantidad indica la cantidad de lo
                            que debe ingerir
                            o aplicar el paciente"
                          outline
                        />
                      </div>
                      <div class="col-6 col-sm-3 pr-sm-0">
                        <mdb-input
                          v-model="medicina.unit"
                          type="text"
                          label="Unidad"
                          class="my-1 text-nowrap border-deep-purple"
                          title="Unidad describe la unidad
                            de la cantidad indicada.
                            Ej: cucharadas, tabletas, ml, cápsulas."
                          outline
                        />
                      </div>
                      <div class="col-6 col-sm-3 pr-0">
                        <mdb-input
                          v-model="medicina.frecuency"
                          type="number"
                          label="# Horas"
                          class="my-1 text-nowrap border-teal"
                          title="Horas indica el intervalo
                            de tiempo en el que se debe
                            administrar la medicina durante el día"
                          outline
                        />
                      </div>
                      <div class="col-6 col-sm-3">
                        <mdb-input
                          v-model="medicina.daysLong"
                          type="number"
                          label="# Días"
                          class="my-1 text-nowrap border-purple"
                          title="Días indica el intervalo de
                            días en el que se debe
                            administrar la medicina"
                          outline
                        />
                      </div>
                      <div class="col-12 col-sm-3 our-checkbox2 text-nowrap">
                        <label :class="{'mb-0': !medicina.conditional}">
                          <input
                            v-model="medicina.conditional"
                            type="checkbox"
                            name="conditional-check"
                          >
                          <span />
                          Condicional
                        </label>
                      </div>
                      <div
                        v-if="medicina.conditional"
                        class="col-12 col-sm-9"
                      >
                        <mdb-input
                          v-model="medicina.condition"
                          type="text"
                          label="Condición"
                          class="my-1"
                          outline
                        />
                      </div>
                      <div
                        :class="['col-auto my-1 mx-auto',
                                 {'mx-sm-0': !medicina.conditional}]"
                      >
                        <mdb-btn
                          class="m-0 px-2"
                          dark-waves
                          flat
                          icon="redo-alt"
                          icon-class="fa-lg"
                          size="sm"
                          @click="medicina.quantity = '';
                                  medicina.unit = '';
                                  medicina.frecuency = '';
                                  medicina.daysLong = '';
                                  medicina.conditional = false;
                                  medicina.condition = '';
                                  medicina.custom='Ind: ';
                                  receip = [].concat(receip)"
                        >
                          Nota personalizada
                        </mdb-btn>
                      </div>
                      <div
                        :class="['col-auto my-1 mx-auto',
                                 {'mx-sm-0': !medicina.conditional}]"
                      >
                        <mdb-btn
                          class="m-0 px-2"
                          dark-waves
                          flat
                          icon="broom"
                          icon-class="fa-lg"
                          size="sm"
                          @click="medicina.quantity = '';
                                  medicina.unit = '';
                                  medicina.frecuency = '';
                                  medicina.daysLong = '';
                                  medicina.conditional = false;
                                  medicina.condition = '';
                                  medicina.custom = '';
                                  receip = [].concat(receip)"
                        >
                          Limpiar preformato
                        </mdb-btn>
                      </div>
                    </div>
                    <div v-else>
                      <mdb-input
                        v-model="medicina.custom"
                        label="Nota personalizada"
                        type="textarea"
                        class="my-1"
                        :rows="3"
                        outline
                      />
                      <mdb-btn
                        class="col m-0"
                        dark-waves
                        flat
                        icon="undo-alt"
                        icon-class="fa-lg"
                        size="sm"
                        @click="medicina.custom = '';
                                receip = [].concat(receip)"
                      >
                        Volver a preformato
                      </mdb-btn>
                    </div>
                    <ul
                      class="list-unstyled"
                    >
                      <li
                        v-for="(medicinaAlt, iMediAlt) in medicina.medicinesAlt"
                        :key="iMediAlt"
                        class="mt-2"
                      >
                        <div class="row align-items-center mt-3 mb-2 mx-0">
                          <p class="col mb-0 pl-0">
                            <font-awesome-icon
                              :icon="['fas', 'bars']"
                              class="d-inline-block"
                            />
                            {{ medicinaAlt.medicine.name }}
                            <small class="text-muted text-uppercase">
                              {{ medicinaAlt.medicine.presentation }}
                            </small>
                          </p>
                          <mdb-btn
                            class="col-auto m-0 px-2"
                            dark-waves
                            flat
                            icon="arrow-up"
                            icon-class="fa-lg"
                            size="sm"
                            title="Mismas indicaciones del medicamento anterior"
                            @click="mismasIndicaciones(medicina, medicinaAlt)"
                          >
                            Mismas indicaciones
                          </mdb-btn>
                        </div>
                        <div
                          v-if="!medicinaAlt.custom"
                          class="row align-items-center"
                        >
                          <div class="col-6 col-sm-3 pr-0">
                            <mdb-input
                              v-model="medicinaAlt.quantity"
                              type="number"
                              label="# Cantidad"
                              class="my-1 text-nowrap border-cyan"
                              title="Cantidad indica la cantidad de lo que debe
                                ingerir o aplicar el paciente"
                              outline
                            />
                          </div>
                          <div class="col-6 col-sm-3 pr-sm-0">
                            <mdb-input
                              v-model="medicinaAlt.unit"
                              type="text"
                              label="Unidad"
                              class="my-1 text-nowrap border-deep-purple"
                              title="Unidad describe la unidad de la cantidad
                                indicada.
                                Ej: cucharadas, tabletas, ml, cápsulas."
                              outline
                            />
                          </div>
                          <div class="col-6 col-sm-3 pr-0">
                            <mdb-input
                              v-model="medicinaAlt.frecuency"
                              type="number"
                              label="# horas"
                              class="my-1 text-nowrap border-teal"
                              title="Horas indica el intervalo de
                                tiempo en el que se debe
                                administrar la medicina durante el día"
                              outline
                            />
                          </div>
                          <div class="col-6 col-sm-3">
                            <mdb-input
                              v-model="medicinaAlt.daysLong"
                              type="number"
                              label="# días"
                              class="my-1 text-nowrap border-purple"
                              title="Días indica el intervalo de
                                días en el que se debe
                                administrar la medicina"
                              outline
                            />
                          </div>
                          <div
                            class="col-12 col-sm-3 our-checkbox2 text-nowrap"
                          >
                            <label :class="{'mb-0': !medicinaAlt.conditional}">
                              <input
                                v-model="medicinaAlt.conditional"
                                type="checkbox"
                                name="conditional-check"
                              >
                              <span />
                              Condicional
                            </label>
                          </div>
                          <div
                            v-if="medicinaAlt.conditional"
                            class="col-12 col-sm-9"
                          >
                            <mdb-input
                              v-model="medicinaAlt.condition"
                              type="text"
                              label="Condición"
                              class="my-1"
                              outline
                            />
                          </div>
                          <div
                            :class="['col-auto my-1 mx-auto',
                                     {'mx-sm-0': !medicinaAlt.conditional}]"
                          >
                            <mdb-btn
                              class="m-0 px-2"
                              dark-waves
                              flat
                              icon="redo-alt"
                              icon-class="fa-lg"
                              size="sm"
                              @click="medicinaAlt.quantity = '';
                                      medicinaAlt.unit = '';
                                      medicinaAlt.frecuency = '';
                                      medicinaAlt.daysLong = '';
                                      medicinaAlt.conditional = false;
                                      medicinaAlt.condition = '';
                                      medicinaAlt.custom = 'Ind: ';
                                      medicina.medicinesAlt =
                                        [].concat(medicina.medicinesAlt)"
                            >
                              Nota personalizada
                            </mdb-btn>
                          </div>
                          <div
                            :class="['col-auto my-1 mx-auto',
                                     {'mx-sm-0': !medicinaAlt.conditional}]"
                          >
                            <mdb-btn
                              class="m-0 px-2"
                              dark-waves
                              flat
                              icon="broom"
                              icon-class="fa-lg"
                              size="sm"
                              @click="medicinaAlt.quantity = '';
                                      medicinaAlt.unit = '';
                                      medicinaAlt.frecuency = '';
                                      medicinaAlt.daysLong = '';
                                      medicinaAlt.conditional = false;
                                      medicinaAlt.condition = '';
                                      medicinaAlt.custom = '';
                                      medicina.medicinesAlt =
                                        [].concat(medicina.medicinesAlt)"
                            >
                              Limpiar preformato
                            </mdb-btn>
                          </div>
                        </div>
                        <div v-else>
                          <mdb-input
                            v-model="medicinaAlt.custom"
                            label="Nota personalizada"
                            type="textarea"
                            class="my-1"
                            :rows="3"
                            outline
                          />
                          <mdb-btn
                            class="col m-0"
                            dark-waves
                            flat
                            icon="undo-alt"
                            icon-class="fa-lg"
                            size="sm"
                            @click="medicinaAlt.custom = '';
                                    medicina.medicinesAlt =
                                      [].concat(medicina.medicinesAlt)"
                          >
                            Volver a preformato
                          </mdb-btn>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
                <!-- Botón para imprimir recipe e indicaciones -->
                <div class="row mx-0">
                  <mdb-btn
                    class="col mx-0 mb-3 mb-md-0"
                    dark-waves
                    flat
                    icon="print"
                    icon-class="fa-lg"
                    size="sm"
                    @click="imprimirRecipe"
                  >
                    Imprimir
                  </mdb-btn>
                </div>
              </div>
              <div
                :class="['tab-pane fade show',
                         { 'active': indicationsTab == 2 }]"
              >
                <div v-if="treatmentsItems && treatmentsItems.length">
                  <div
                    v-for="(tra, i) in treatmentsItems"
                    :key="'tr'+i"
                  >
                    <div class="row align-items-center mx-2">
                      <div class="col-12 col-md-auto">
                        <header class="flex-align-center">
                          {{ tra.name }}
                          <mdb-btn
                            :class="['col-auto success-text m-0 mr-2 p-1']"
                            dark-waves
                            flat
                            icon="plus-circle"
                            icon-class="fs-15-mobileOnly"
                            size="sm"
                            title="Añadir tratamiento"
                            @click="addTreatment(tra.receips)"
                          />
                        </header>
                        <ul class="list-unstyled">
                          <li
                            v-for="(medicina, r) in tra.receips"
                            :key="'m'+r"
                            class="mb-3"
                          >
                            <header class="font-weight-bold">
                              {{ medicina.medicine.name }}
                              <small class="text-muted text-uppercase">
                                {{ medicina.medicine.presentation }}
                              </small>
                            </header>
                            <div v-if="!medicina.custom">
                              <p class="text-muted">
                                {{ medicina.quantity }}
                                {{ medicina.unit }} cada
                                {{ medicina.frecuency }} horas por
                                {{ medicina.daysLong }} días.
                              </p>
                            </div>
                            <div v-else>
                              <p class="text-muted">
                                {{ medicina.custom }}
                              </p>
                            </div>
                            <p
                              v-if="medicina.conditional"
                              class="text-muted font-italic fs-09 mt-n3"
                            >
                              {{ medicina.condition }}
                            </p>
                            <ul
                              class="list-unstyled"
                            >
                              <li
                                v-for="(medicinaAlt, iMediAlt)
                                  in medicina.medicinesAlt"
                                :key="'a'+iMediAlt"
                                class="mt-2"
                              >
                                <p class="mb-1">
                                  <font-awesome-icon
                                    :icon="['fas', 'bars']"
                                    class="d-inline-block"
                                  />
                                  {{ medicinaAlt.medicine.name }}
                                  <small class="text-muted text-uppercase">
                                    {{ medicinaAlt.medicine.presentation }}
                                  </small>
                                </p>
                                <div v-if="!medicinaAlt.custom">
                                  <p class="text-muted">
                                    {{ medicinaAlt.quantity }}
                                    {{ medicinaAlt.unit }} cada
                                    {{ medicinaAlt.frecuency }} horas por
                                    {{ medicinaAlt.daysLong }} días.
                                  </p>
                                </div>
                                <div v-else>
                                  <p class="text-muted">
                                    {{ medicinaAlt.custom }}
                                  </p>
                                </div>
                                <p
                                  v-if="medicinaAlt.conditional"
                                  class="text-muted font-italic fs-09 mt-n3"
                                >
                                  {{ medicinaAlt.condition }}
                                </p>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <p
                  v-else
                  class="text-muted text-center userSelect-none"
                >
                  Para agregar tratamientos con un click, configúralos en:
                  <span class="d-block fs-inherit">
                    Configuración /
                    <font-awesome-icon
                      :icon="['fas', 'pills']"
                      size="sm"
                      class="mx-1"
                    />
                    Tratamientos
                  </span>
                </p>
                <p
                  v-if="mySpTreatments.length"
                >
                  Tratamientos sugeridos por tus patrocinantes:
                </p>
                <div
                  v-for="tra in mySpTreatments"
                  :key="tra.id"
                >
                  <div class="row align-items-center mx-2">
                    <div class="col-12 col-md-auto">
                      <header class="flex-align-center">
                        {{ tra.name }}
                        <mdb-btn
                          :class="['col-auto success-text m-0 mr-2 p-1']"
                          dark-waves
                          flat
                          icon="plus-circle"
                          icon-class="fs-15-mobileOnly"
                          size="sm"
                          title="Añadir tratamiento"
                          @click="addTreatment(tra.receips)"
                        />
                      </header>
                      <ul class="list-unstyled">
                        <li
                          v-for="(medicina, r) in tra.receips"
                          :key="'m'+r"
                          class="mb-3"
                        >
                          <header class="font-weight-bold">
                            {{ medicina.medicine.name }}
                            <small class="text-muted text-uppercase">
                              {{ medicina.medicine.presentation }}
                            </small>
                          </header>
                          <div v-if="!medicina.custom">
                            <p class="text-muted">
                              {{ medicina.quantity }}
                              {{ medicina.unit }} cada
                              {{ medicina.frecuency }} horas por
                              {{ medicina.daysLong }} días.
                            </p>
                          </div>
                          <div v-else>
                            <p class="text-muted">
                              {{ medicina.custom }}
                            </p>
                          </div>
                          <p
                            v-if="medicina.conditional"
                            class="text-muted font-italic fs-09 mt-n3"
                          >
                            {{ medicina.condition }}
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div class="mx-auto">
                      <a
                        :href="tra.sponsorship.link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          :src="multimedia+'dir/'+tra.sponsorship.logoUrl"
                          alt="Logo patrocinante"
                          title="Ir al enlace sugerido por tu patrocinante"
                          height="70px"
                        >
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LogosPatrocinantes v-if="indicationsTab !== 2" />
          </div>
        </template>
      </TarjetasDelDiagnostico>
      <div class="card-columns cc-1 cc-md-2">
        <!-- Facturación -->
        <TarjetasDelDiagnostico
          :show-card="!!cardVis.bill.vis"
          :show-body="cardVis.bill.vis == 2"
        >
          <template #title>
            Facturación
          </template>
          <template #buttons>
            <mdb-badge
              v-if="bill.status"
              class="col-auto mr-2 z-depth-0 fs-09"
              :color="bill.status == 'Approved' ? 'bg-success-base' :
                bill.status == 'Checking' ? 'default' :
                bill.status == 'Pending' ? 'secondary' : 'bg-danger-base'"
              pill
            >
              {{ statusBill[bill.status] }}
            </mdb-badge>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.bill.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.bill.vis == 2
                  ? cardVis.bill.vis = 1
                  : cardVis.bill.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.bill.vis = 0"
            />
          </template>
          <template #body>
            <div v-if="monedas">
              <header class="pb-2 font-weight-bold">
                Selecciona la moneda de pago
              </header>
              <div class="row justify-content-between">
                <div
                  v-for="(moneda, i) in monedas"
                  :key="i"
                  class="col-12 col-sm-6 col-md-12 col-lg-6 our-radio2"
                  title="Marcar moneda a pagar"
                >
                  <label>
                    <input
                      v-model="bill.currency"
                      type="radio"
                      name="primaryRadio"
                      :value="moneda.name"
                      @change="bill.symbol = moneda.symbol;
                               setPricesAndTotal()"
                    >
                    <span />
                    {{ moneda.name }}
                  </label>
                </div>
              </div>
            </div>
            <ul
              v-if="services"
              class="list-unstyled mb-0"
            >
              <li class="row align-items-center mx-0">
                <div class="col col-sm-7 text-center font-weight-bold fs-09">
                  Descripción
                </div>
                <div
                  class="col-auto col-sm-5 text-center font-weight-bold
                  fs-09"
                >
                  Valor
                </div>
              </li>
              <li
                v-for="servicio in servicesBill"
                :key="servicio.id"
                class="row align-items-center"
              >
                <header class="col fs-09">
                  {{ servicio.name }}
                </header>
                <div class="col-12 col-sm-5 pl-sm-0 text-right fs-09">
                  <mdb-input
                    v-model="servicio.prices[0].amount"
                    type="number"
                    label="Precio"
                    class="my-1"
                    outline
                    @input="recalcularAmount"
                  />
                </div>
              </li>
            </ul>
            <div
              class="row align-items-center justify-content-end mx-0
              border-top"
            >
              <div
                class="col col-sm-3 col-md-4 font-weight-bold fs-09
                  text-sm-right"
              >
                Monto
              </div>
              <div
                class="col-12 col-sm-9 col-md-8 px-0 font-weight-bold fs-09"
              >
                <mdb-input
                  v-model="bill.amount"
                  type="number"
                  class="my-1"
                  size="sm"
                  outline
                  @input="totalWithTax"
                />
              </div>
              <div
                class="col col-sm-3 col-md-4 font-weight-bold fs-09
                  text-sm-right"
              >
                % Impuesto
              </div>
              <div
                class="col-12 col-sm-9 col-md-8 px-0 font-weight-bold fs-09"
              >
                <mdb-input
                  v-model.number="bill.tax"
                  type="number"
                  class="my-1"
                  size="sm"
                  outline
                  @input="totalWithTax"
                />
              </div>
              <div
                class="col col-sm-3 col-md-4 font-weight-bold fs-09
                  text-sm-right"
              >
                Total
              </div>
              <div
                class="col-12 col-sm-9 col-md-8 px-0 font-weight-bold fs-09"
              >
                <mdb-input
                  v-model="bill.total"
                  type="number"
                  class="my-1"
                  size="sm"
                  outline
                />
              </div>
            </div>
            <header class="mt-3 py-2 border-top font-weight-bold">
              Método de pago
            </header>
            <div class="row align-items-center">
              <div
                v-for="(metodo, i) in paymentMethods"
                :key="i"
                class="col-12 col-sm-6 our-radio2"
              >
                <label>
                  <input
                    v-model="payment.method"
                    type="radio"
                    name="radio-pago"
                    :value="metodo"
                    @change="payment.reference = ''; bill.quotas = ''"
                  >
                  <span />
                  {{ metodo }}
                </label>
              </div>
              <div
                v-if="payment.method == 'Transferencia'"
                class="col-12 col-sm pl-md-1 pl-lg-3"
              >
                <mdb-input
                  v-model="payment.reference"
                  type="text"
                  label="# Referencia"
                  class="my-1"
                  size="sm"
                  outline
                />
              </div>
              <div
                v-if="payment.method == 'Plan de Pago'"
                class="col-12 col-sm pl-md-1 pl-lg-3"
              >
                <mdb-input
                  v-model="bill.quotas"
                  type="number"
                  label="# Cuotas"
                  class="my-1"
                  size="sm"
                  outline
                />
              </div>
            </div>
            <header class="mt-3 pt-2 pb-1 border-top font-weight-bold">
              Estado del pago
            </header>
            <div class="md-form md-outline outline-select my-1">
              <select
                v-model="bill.status"
                class="custom-select"
              >
                <option
                  v-for="(status, key, i) in statusBill"
                  :key="i"
                  :value="key"
                >
                  {{ status }}
                </option>
              </select>
            </div>
          </template>
          <template #bottom-buttons>
            <mdb-btn
              class="col m-0 flat-bordered"
              dark-waves
              flat
              icon="print"
              icon-class="fa-lg"
              size="sm"
              @click="toPrint.code = printFacturacion();
                      showPrintOptionsModal = true"
            >
              Imprimir factura
            </mdb-btn>
          </template>
        </TarjetasDelDiagnostico>
        <!-- Documentos a expedir -->
        <TarjetasDelDiagnostico
          :show-card="!!cardVis.documents.vis"
          :show-body="cardVis.documents.vis == 2"
        >
          <template #title>
            {{ cardVis.documents.name }}
          </template>
          <template #buttons>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.documents.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.documents.vis == 2
                  ? cardVis.documents.vis = 1
                  : cardVis.documents.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.documents.vis = 0"
            />
          </template>
          <template #body>
            <ul class="angle-list">
              <li
                v-for="(document, idoc) in documentos"
                :key="idoc"
                class="cursor-pointer"
                @click="prepareDocumentTemplate(document)"
              >
                {{ document.name }}
              </li>
            </ul>
          </template>
        </TarjetasDelDiagnostico>
        <!-- Próxima cita -->
        <TarjetasDelDiagnostico
          :show-card="!!cardVis.nextAppointment.vis"
          :show-body="cardVis.nextAppointment.vis == 2"
        >
          <template #title>
            {{ cardVis.nextAppointment.name }}
          </template>
          <template #buttons>
            <mdb-btn
              class="col-auto m-0 mr-2 p-2"
              color="primary-base"
              :icon="paraCita.date ? 'sync' : 'plus-circle'"
              icon-class="fa-lg"
              size="sm"
              title="Asignar próxima cita"
              type="button"
              @click="paraCita.date
                ? showChangeDateForm = true
                : showAddAppointment = true;"
            >
              {{
                paraCita.date
                  ? 'Actualizar'
                  : 'Asignar'
              }}
            </mdb-btn>
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              :icon="cardVis.nextAppointment.vis == 2
                ? 'chevron-up' : 'chevron-down'"
              @click="
                cardVis.nextAppointment.vis == 2
                  ? cardVis.nextAppointment.vis = 1
                  : cardVis.nextAppointment.vis = 2
              "
            />
            <mdb-btn
              dark-waves
              flat
              class="my-0 p-1"
              icon="eye-slash"
              @click="cardVis.nextAppointment.vis = 0"
            />
          </template>
          <template #body>
            {{ paraCita.date
              || 'Aún no has definido una fecha para la siguiente cita' }}
          </template>
        </TarjetasDelDiagnostico>
      </div>
      <div class="row justify-content-center mt-3 mb-5">
        <mdb-btn
          class="col-auto order-sm-1"
          color="success-base"
          :icon="btnDisabled ? 'circle-notch' : 'stopwatch'"
          :icon-class="[{'fa-spin': btnDisabled}]"
          :disabled="btnDisabled"
          @click="encounterUpdate({status: 'Finished'})"
        >
          Finalizar consulta
        </mdb-btn>
        <!-- <mdb-btn
          class="col-auto flat-bordered order-sm-0"
          flat
          dark-waves
          icon="bell"
        >
          Llamar asistente
        </mdb-btn> -->
      </div>
      <mdb-btn
        v-if="paciente.citas && paciente.citas.length"
        outline="primary"
        class="btn"
        title="Ver últimas 5 citas"
        floating
        @click="showPreviousEncounters = !showPreviousEncounters"
      >
        <font-awesome-icon
          icon="list-check"
          size="lg"
        />
      </mdb-btn>
    </div>
    <!-- Modal para editar servicio -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="showSelectedServiceUpdate"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          Anotando en {{ serviceToUse.name }}
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModalSelectedServiceUpdate"
          class="close"
          @click="showSelectedServiceUpdate = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <ul class="list-unstyled params-group">
          <li
            v-for="paro in serviceToUse.params"
            :key="paro.Parameter.id"
            class="row align-items-center"
          >
            <header class="col-12 col-sm-6">
              {{ paro.Parameter.name }}
              {{ paro.Parameter.symbol
                ? `[${paro.Parameter.symbol}]`
                : '' }}
            </header>
            <div
              v-if="paro.Parameter.type == 'integer'"
              class="col-12 col-sm-6"
            >
              <mdb-input
                v-model="paro.Parameter.value"
                type="number"
                class="my-1"
                outline
              />
            </div>
            <div
              v-else-if="paro.Parameter.type == 'decimal'"
              class="col-12 col-sm-6"
            >
              <mdb-input
                v-model="paro.Parameter.value"
                type="number"
                class="my-1"
                outline
              />
            </div>
            <div
              v-else-if="paro.Parameter.type == 'date'"
              class="col-12 col-sm-6"
            >
              <mdb-input
                v-model="paro.Parameter.value"
                type="date"
                class="my-1 outline-date"
                outline
              />
            </div>
            <div
              v-else-if="paro.Parameter.type == 'datetime'"
              class="col-12 col-sm-6"
            >
              <mdb-input
                v-model="paro.Parameter.value"
                type="time"
                :step="60"
                class="my-1"
                outline
              />
            </div>
            <div
              v-else-if="paro.Parameter.type == 'boolean'"
              class="col-12 col-sm-6"
            >
              <div class="our-checkbox2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="paro.Parameter.value"
                    type="checkbox"
                  >
                  <span />
                  Verdadero
                </label>
              </div>
            </div>
            <div
              v-else-if="paro.Parameter.type == 'simpleOption'"
              class="col-12 col-sm-6"
            >
              <div
                v-for="opt in paro.Parameter.options"
                :key="opt"
                class="our-radio2 px-2"
              >
                <label class="fs-09 w-100">
                  <input
                    v-model="paro.Parameter.value"
                    type="radio"
                    :value="opt"
                  >
                  <span />
                  {{ opt }}
                </label>
              </div>
            </div>
            <div
              v-else-if="paro.Parameter.type == 'multipleOption'"
              class="col-12 col-sm-6"
            >
              <div
                v-for="opt in paro.Parameter.options"
                :key="opt"
                class="our-checkbox2 px-2"
              >
                <label class="fs-09 w-100">
                  <input
                    v-model="paro.Parameter.value"
                    type="checkbox"
                    :value="opt"
                  >
                  <span />
                  {{ opt }}
                </label>
              </div>
            </div>
            <div
              v-else-if="paro.Parameter.type == 'range'"
              class="col-12 col-sm-6"
            >
              <mdb-input
                v-model="paro.Parameter.value1"
                type="number"
                class="my-1"
                outline
                @blur="paro.Parameter.value = addParamValueDuo(paro.Parameter)"
              />
              <mdb-input
                v-model="paro.Parameter.value2"
                type="number"
                class="my-1"
                outline
                @blur="paro.Parameter.value = addParamValueDuo(paro.Parameter)"
              />
            </div>
            <div
              v-else-if="paro.Parameter.type == 'rangeDates'"
              class="col-12 col-sm-6"
            >
              <mdb-input
                v-model="paro.Parameter.value1"
                type="date"
                class="my-1 outline-date"
                outline
                @blur="paro.Parameter.value = addParamValueDuo(paro.Parameter)"
              />
              <mdb-input
                v-model="paro.Parameter.value2"
                type="date"
                class="my-1 outline-date"
                outline
                @blur="paro.Parameter.value = addParamValueDuo(paro.Parameter)"
              />
            </div>
            <div
              v-else-if="paro.Parameter.type == 'file'"
              class="col-12 col-sm-6"
            >
              <mdb-btn
                v-if="!paro.Parameter.value"
                class="col m-0 flat-bordered"
                dark-waves
                flat
                icon="print"
                icon-class="fa-lg"
                size="sm"
                @click="paramFileToUse = paro.Parameter;
                        showNewParImage = true"
              >
                Agregar imagen
              </mdb-btn>
              <div v-else>
                <!--eslint-disable-next-line vue/html-self-closing -->
                <img
                  :src="endpoint+paro.Parameter.value"
                  class="w-100"
                />
                <mdb-btn
                  class="col m-0 flat-bordered"
                  dark-waves
                  flat
                  icon="print"
                  icon-class="fa-lg"
                  size="sm"
                  @click="paramFileToUse = paro.Parameter;
                          showNewParImage = true"
                >
                  Cambiar
                </mdb-btn>
              </div>
            </div>
            <div
              v-else-if="paro.Parameter.type == 'draw'"
              class="col-12 col-sm-6"
            >
              <mdb-btn
                v-if="paro.Parameter.options && paro.Parameter.options[0]"
                class="col m-0 flat-bordered"
                dark-waves
                flat
                icon="print"
                icon-class="fa-lg"
                size="sm"
                @click="paramFileToUse = paro.Parameter;
                        showNewParImage = true"
              >
                Dibujar imagen
              </mdb-btn>
              <img
                v-if="paro.Parameter.value"
                :src="endpoint+paro.Parameter.value"
                class="w-100"
              >
            </div>
            <div
              v-else
              class="col-12 col-sm-6"
            >
              <mdb-input
                v-model="paro.Parameter.value"
                class="my-1"
                outline
              />
            </div>
          </li>
        </ul>
        <div class="modal-footer-btn">
          <mdb-btn
            flat
            dark-waves
            icon="times"
            class="flat-bordered"
            @click="showSelectedServiceUpdate = false"
          >
            Cerrar
          </mdb-btn>
          <mdb-btn
            icon="sync"
            color="success-base"
            type="submit"
            @click="editServiceSelected"
          >
            Guardar
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <!-- Modal agregar medicamento -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="showNewMedication"
      @shown="$refs.medicamentoRef.focusInput()"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          Agregar medicamento
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModalNewMedication"
          class="close"
          @click="showNewMedication = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="row">
          <div class="col-12">
            <CustomDatalist
              :id="'Medicamento'"
              ref="medicamentoRef"
              v-model="newMedication.medicine.name"
              :list="medicinesResults"
              class-name="my-1"
              label="Medicamento"
              :outline="true"
              @save="newMedication.medicine.name=$event.name;
                     newMedication.medicine.dose=$event.dose;
                     newMedication.medicine.presentation=$event.presentation;"
            >
              <template slot-scope="scope">
                <li
                  v-for="(medicr, ii) in medicinesResults"
                  :key="ii"
                  @click="scope.selected(medicr)"
                  @mousedown.prevent
                >
                  {{ medicr.name }}
                  <small>
                    -
                    {{ medicr.presentation }},
                    {{ medicr.dose }}.
                    <small
                      v-if="medicr.product && medicr.product.manufacter"
                    >
                      {{ medicr.product.manufacter.name }}
                    </small>
                  </small>
                </li>
              </template>
            </CustomDatalist>
          </div>
          <!-- @input="buscarMedicines(newMedication.medicine.name)" -->
          <div class="col-12 col-md-6">
            <mdb-input
              v-model="newMedication.medicine.presentation"
              placeholder="Presentación"
              class="my-1"
              outline
            />
          </div>
          <div class="col-12 col-md-6">
            <mdb-input
              v-model="newMedication.medicine.dose"
              placeholder="Concentración"
              class="my-1"
              outline
            />
          </div>
        </div>
        <mdb-btn
          flat
          dark-waves
          icon="plus-circle"
          icon-class="success-text mr-1"
          title="Agregar teléfono"
          class="flex-align-center m-0 mb-2 p-2"
          type="button"
          @click="addMedicineAlt"
        >
          Añadir opciones alternativas
        </mdb-btn>
        <div
          v-for="(alternativa, i) in newMedication.medicinesAlt"
          :key="i"
          class="row align-items-center"
        >
          <div class="col">
            <div class="row">
              <div class="col-12">
                <CustomDatalist
                  :id="i+'MedicamentoAlt'"
                  v-model="alternativa.medicine.name"
                  :list="medicinesOtros"
                  class-name="my-1"
                  label="Medicamento"
                  :identificador="i"
                  :outline="true"
                  @input="buscarMedicines(alternativa.medicine.name)"
                  @save="alternativa.medicine.name=$event.name;
                         alternativa.medicine.dose=$event.dose;
                         alternativa.medicine.presentation=$event.presentation;"
                >
                  <template slot-scope="scope">
                    <li
                      v-for="(medicr, ii) in medicinesOtros"
                      :key="ii"
                      @click="scope.selected(medicr)"
                      @mousedown.prevent
                    >
                      {{ medicr.name }}
                      <small>
                        -
                        {{ medicr.presentation }},
                        {{ medicr.dose }}.
                        <small
                          v-if="medicr.product && medicr.product.manufacter"
                        >
                          {{ medicr.product.manufacter.name }}
                        </small>
                      </small>
                    </li>
                  </template>
                </CustomDatalist>
              </div>
              <div class="col-12 col-md-6">
                <mdb-input
                  v-model="alternativa.medicine.presentation"
                  placeholder="Presentación"
                  class="my-1"
                  outline
                />
              </div>
              <div class="col-12 col-md-6">
                <mdb-input
                  v-model="alternativa.medicine.dose"
                  placeholder="Concentración"
                  class="my-1"
                  outline
                />
              </div>
            </div>
          </div>
          <mdb-btn
            class="col-auto danger-text m-0 mr-2 px-1 py-4"
            dark-waves
            flat
            icon="minus-circle"
            icon-class="fa-lg"
            size="sm"
            title="Eliminar medicamento"
            type="button"
            @click="removeMedicineAltInModal(i)"
          />
        </div>
        <div class="modal-footer-btn">
          <mdb-btn
            flat
            dark-waves
            icon="times"
            class="flat-bordered"
            @click="showNewMedication = false"
          >
            Cerrar
          </mdb-btn>
          <mdb-btn
            :icon="btnLabel.icon"
            color="success-base"
            type="submit"
            @click="medicineGeneral"
          >
            {{ btnLabel.label }}
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <!-- Modal agregar síntomas -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="showAddSymptom"
      @shown="$refs.symptomRef.focusInput()"
    >
      <mdb-modal-header
        class="row align-items-center m-0"
        :close="false"
      >
        <mdb-modal-title class="col pl-0">
          Agregar síntomas
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModalAddSymptom"
          class="close"
          @click="showAddSymptom = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <div
          class="row align-items-center"
        >
          <div class="col pr-2">
            <CustomDatalist
              :id="'Sintoma'"
              ref="symptomRef"
              v-model="newSymptom.name"
              :list="symptomsOtros"
              class-name="my-1"
              label="Síntoma"
              :outline="true"
              @input="buscarSintomas(newSymptom.name)"
              @save="newSymptom.name=$event.name;"
            />
          </div>
        </div>
        <div class="modal-footer-btn">
          <mdb-btn
            flat
            dark-waves
            icon="times"
            class="flat-bordered"
            @click="showAddSymptom = false"
          >
            Cerrar
          </mdb-btn>
          <mdb-btn
            icon="sync"
            color="success-base"
            type="submit"
            :disabled="!newSymptom.name"
            @click="addSymptom"
          >
            Añadir
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <!-- Modal agregar diagnosis -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="showAddDiagnosis"
      @shown="$refs.diagnosisRef.focusInput()"
    >
      <mdb-modal-header
        class="row align-items-center m-0"
        :close="false"
      >
        <mdb-modal-title class="col pl-0">
          Agregar diagnóstico
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModalAddDiagnosis"
          class="close"
          @click="showAddDiagnosis = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="row">
          <div class="col-12">
            <div class="row align-items-center">
              <div class="col pr-2">
                <CustomDatalist
                  :id="'Diagnosis'"
                  ref="diagnosisRef"
                  v-model="newDiagnosis.disease.name"
                  :list="diseasesOtros"
                  class-name="my-1"
                  label="Diagnóstico"
                  :outline="true"
                  @input="buscarDiseases(newDiagnosis.disease.name)"
                  @save="newDiagnosis.disease.name=$event.name;"
                />
                <mdb-input
                  v-model="newDiagnosis.conclusion"
                  label="Conclusión"
                  type="textarea"
                  class="my-1"
                  :rows="3"
                  outline
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer-btn">
          <mdb-btn
            flat
            dark-waves
            icon="times"
            class="flat-bordered"
            @click="showAddDiagnosis = false"
          >
            Cerrar
          </mdb-btn>
          <mdb-btn
            icon="sync"
            color="success-base"
            type="submit"
            :disabled="!newDiagnosis.disease.name"
            @click="addDiagnosis"
          >
            Añadir
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <!-- Modal próxima cita -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="showAddAppointment"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          Próxima cita
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModalAddAppointment"
          class="close"
          @click="showAddAppointment = false;
                  paraCita = {servicioId: '',date: ''};
                  appointmentReasons = [{name: ''}]"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <FormularioCitas
          :schedule="cita.encuentro.in.schedule"
          :create-appointment="true"
          :services-offered="cita.doctor.services"
          :appointment-reasons="appointmentReasons"
          enable-all-week
          @saveAppointmenDate="appointment.date=$event;
                               paraCita.date=$event"
          @saveSchedule="paraCita.schedule=$event"
          @saveTime="paraCita.time=$event"
          @saveServiceId="paraCita.servicioId=$event"
          @addReason="addReason"
          @removeReason="removeReason($event)"
          @enableAllWeek="enableWeek = $event"
        />
        <div class="modal-footer-btn">
          <mdb-btn
            flat
            dark-waves
            icon="times"
            class="flat-bordered"
            @click="showAddAppointment = false;
                    paraCita = {servicioId: '',date: ''};
                    appointmentReasons = [{name: ''}]"
          >
            Cerrar
          </mdb-btn>
          <mdb-btn
            type="submit"
            color="success-base"
            :disabled="btnDisabled"
            @click="appointmentCreate"
          >
            <font-awesome-icon
              :icon="['fas', 'check']"
              size="lg"
            />
            Asignar fecha
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <!-- Modal editar orden de paraclínico complementario -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="showEditOrder"
      @shown="$refs.paracDescripcion.focus()"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          Editar paraclínico
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModalEditOrder"
          class="close"
          @click="cancelEditOrder"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <div
          v-if="newOrder.country"
          class="row"
        >
          <div class="col-12">
            <div class="md-form md-outline outline-select my-1">
              <select
                id="newOrder-country-select"
                v-model="newOrder.country"
                class="custom-select"
              >
                <option
                  :value="newOrder.country"
                >
                  {{ newOrder.country }}
                </option>
                <option value="Venezuela">
                  Venezuela
                </option>
              </select>
              <label
                for="newOrder-country-select"
                :class="newOrder.country ? 'label-active' :
                  'label-inactive'"
              >
                País
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="md-form md-outline outline-select my-1">
              <select
                id="newOrder-state-select"
                v-model="newOrder.state"
                class="custom-select"
              >
                <option value=".">
                  .
                </option>
              </select>
              <label
                for="newOrder-state-select"
                :class="newOrder.state ? 'label-active' :
                  'label-inactive'"
              >
                Estado
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="md-form md-outline outline-select my-1">
              <select
                id="newOrder-city-select"
                v-model="newOrder.city"
                class="custom-select"
              >
                <option value="Valera">
                  .
                </option>
              </select>
              <label
                for="newOrder-city-select"
                :class="newOrder.city ? 'label-active' :
                  'label-inactive'"
              >
                Ciudad
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="md-form md-outline outline-select my-1">
              <select
                id="newOrder-institute-select"
                v-model="newOrder.institute"
                class="custom-select"
              >
                <option value="Instituto-Médico-Valera">
                  Instituto Médico Valera
                </option>
              </select>
              <label
                for="newOrder-institute-select"
                :class="newOrder.institute ? 'label-active' :
                  'label-inactive'"
              >
                Instituto
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="md-form md-outline outline-select my-1">
              <select
                id="newOrder-service-select"
                v-model="newOrder.service"
                class="custom-select"
              >
                <option
                  :value="{}"
                >
                  Seleccione
                </option>
                <option :value="{name:'', id: 1}">
                  .
                </option>
              </select>
              <label
                for="newOrder-service-select"
                :class="newOrder.service.name ? 'label-active' :
                  'label-inactive'"
              >
                Estudio
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mdb-input
              ref="paracDescripcion"
              v-model="newOrder.instruction"
              :label="newOrder.country ? 'Instrucciones' : 'Descripción'"
              type="textarea"
              class="my-1"
              :rows="4"
              outline
            />
          </div>
        </div>
        <div class="modal-footer-btn">
          <mdb-btn
            flat
            dark-waves
            icon="times"
            class="flat-bordered"
            @click="cancelEditOrder"
          >
            Cerrar
          </mdb-btn>
          <mdb-btn
            icon="sync"
            color="success-base"
            type="submit"
            @click="updateOrderCustom"
          >
            Actualizar
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <!-- Modal cambiar cita -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="showChangeDateForm"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          Cambiar cita
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModalChangeDateForm"
          class="close"
          @click="showChangeDateForm = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <FormularioCitas
          :schedule="cita.encuentro.in.schedule"
          @saveAppointmenDate="appointment.date=$event;
                               paraCita.date=$event"
          @saveSchedule="paraCita.schedule=$event"
          @saveTime="paraCita.time=$event"
          @saveConfirmDate="pacienteConfirmarFecha=$event"
        />
        <div class="modal-footer-btn">
          <mdb-btn
            flat
            dark-waves
            icon="times"
            class="flat-bordered"
            @click="showChangeDateForm = false"
          >
            Cerrar
          </mdb-btn>
          <mdb-btn
            type="submit"
            color="success-base"
            :disabled="btnDisabled"
            @click="appointmentMoveDate"
          >
            <font-awesome-icon
              :icon="['fas', 'rotate']"
              size="lg"
            />
            Cambiar
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <ModalOpcionesImpresion
      :show-print-options="showPrintOptionsModal"
      :doctor-name="useDoctorName"
      :document-name="toPrint.documentName"
      :code-to-print="toPrint.code"
      :images="toPrint.images"
      :has-two-columns="toPrint.hasTwoColumns"
      :footer="toPrint.footer"
      :header="toPrint.header"
      @close="cerrarModalOpcionesImpresion"
    />
    <!-- Modal para ver encuentros anteriores -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="showPreviousEncounters"
      @close="showPreviousEncounters = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>Últimas 5 consultas</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <p class="text-muted">
          Haz clic sobre la fila para ver la consulta completa
        </p>
        <ul class="list-unstyled">
          <li
            v-for="encuentro in paciente.citas"
            :key="encuentro.id"
            class="my-2 pr-4 cursor-pointer angle-right-icon"
            @click="showPreviusEncounter(encuentro.encounter)"
          >
            <p class="mb-0 clearfix">
              <span class="w-sm-100px d-sm-block float-sm-left">
                {{ encuentro.date.formatted | fecha_es_ve }}
              </span>
              <span class="d-block">
                <span
                  v-if="encuentro.encounter
                    && encuentro.encounter.symptoms
                    && encuentro.encounter.symptoms.length"
                >
                  {{ listEncounterDetails(encuentro.encounter.symptoms) }}
                </span>
                <span
                  v-else-if="encuentro.encounter
                    && encuentro.reasons
                    && encuentro.reasons.length"
                >
                  {{ listEncounterDetails(encuentro.reasons) }}
                </span>
                <span
                  v-else
                  class="text-muted"
                >
                  Sin información
                </span>
                <small
                  v-if="encuentro.encounter
                    && encuentro.encounter.diagnosis
                    && encuentro.encounter.diagnosis.length"
                  class="d-block font-italic text-muted text-truncate"
                >
                  Diagnóstico:
                  {{ listEncounterDetails(encuentro.encounter.diagnosis) }}.
                </small>
              </span>
            </p>
          </li>
        </ul>
      </mdb-modal-body>
      <mdb-modal-footer class="justify-content-center">
        <mdb-btn
          flat
          dark-waves
          icon="times"
          class="flat-bordered"
          @click.native="showPreviousEncounters = false"
        >
          Cerrar
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <!-- Modal para agregar imágenes al encuentro -->
    <mdb-modal
      scrollable
      :show="showNewImage"
      size="fluid"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          Nueva imagen
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarNewImage"
          class="close"
          @click="showNewImage = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <DrawImage
          :entity-id="$route.params.appointmentId"
          :entity="'Encounter'"
          @imageSaved="images.push($event);showNewImage = false;"
        />
      </mdb-modal-body>
    </mdb-modal>

    <!-- Modal para agregar imagen como parámetro -->
    <mdb-modal
      scrollable
      size="fluid"
      :show="showNewParImage"
      @shown="$refs.drawImage.fromURLtoCanvas()"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          Nueva imagen como parámetro
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarNewImage"
          class="close"
          @click="showNewParImage = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <DrawImage
          ref="drawImage"
          :entity-id="$route.params.appointmentId"
          :entity="'Encounter'"
          :image-url="
            paramFileToUse.options && paramFileToUse.options[0] ?
              endpoint+paramFileToUse.options[0] : ''"
          @imageSaved="paramFileToUse.value=$event.url;showNewParImage = false;"
        />
      </mdb-modal-body>
    </mdb-modal>

    <!-- Modal confirmar eliminar -->
    <ModalConfirmacionEliminar
      :show-confirmation="showConfirmacionEliminar"
      :name="imagenSeleccionada.title || 'Imagen sin título'"
      :btn-disabled="btnDisabled"
      @close="showConfirmacionEliminar = $event"
      @confirm-delete="imagesDelete"
    />

    <!-- Modal Gráfico Grande -->
    <ModalChartFull
      :show-chart-full="showChartFull"
      :options="chartOpt"
      @close="showChartFull = $event"
    />

    <!-- Modal confirmación para guardar diagnóstico -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="showGoBackToPatient"
      @close="showGoBackToPatient = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          Manten segura tu información
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="text-center">
          <mdb-icon
            icon="bell"
            size="4x"
            class="animated rotateIn warning-text mb-4"
          />
          <p class="h3-responsive">
            ¿Quieres guardar el diagnóstico?
          </p>
        </div>
        <div class="modal-footer-btn">
          <mdb-btn
            flat
            dark-waves
            icon="times"
            class="flat-bordered"
            @click="volverAPaciente(false)"
          >
            No
          </mdb-btn>
          <BtnLoader
            :btn-disabled="btnDisabled"
            btn-title="Si"
            @click="volverAPaciente(true)"
          />
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <!-- Modal para CKEditor -->
    <mdb-modal
      scrollable
      :show="showEditorModal"
      size="fluid"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          Confirmar la impresión de
          {{ docSelected.name }}
        </mdb-modal-title>
        <a
          class="close"
          @click="showEditorModal = false; docSelected.body=''"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <p class="text-muted">
          Los ajustes que hagas no afectarán a la plantilla original.
        </p>
        <ckeditor
          v-model="docSelected.body"
          :editor="editor"
          :config="editorConfig"
        />
        <div class="modal-footer-btn">
          <mdb-btn
            flat
            dark-waves
            icon="times"
            class="flat-bordered"
            @click="showEditorModal = false; docSelected.body=''"
          >
            Cerrar
          </mdb-btn>
          <mdb-btn
            icon="print"
            color="success-base"
            type="submit"
            @click="print(docSelected)"
          >
            Imprimir
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <AlertaMsj :alerta-msj="alertaMsj" />

    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view :paciente="paciente" />
    </transition>

    <!-- Imprimir recipe con plantilla -->
    <!-- eslint-disable vue/no-v-html -->
    <div class="d-none varpreset">
      <span
        id="cabecera"
        v-html="docSelected.header"
      />
      <span
        id="pie"
        v-html="docSelected.footer"
      />
      <span
        id="documento"
        v-html="docSelected.body"
      />
      <iframe
        id="print-data-container"
      />
    </div>
  </div>
</template>
<script>
import {mdbAlert, mdbBtn, mdbInput, mdbModal, mdbModalHeader, mdbModalTitle,
  mdbModalBody, mdbModalFooter, mdbIcon, mdbBadge} from 'mdbvue';
import CustomDatalist from '@/components/customFields/CustomDatalist.vue';
import BtnLoader from '@/components/customButtons/BtnLoader.vue';
import AlertaMsj from '@/components/AlertaMsj.vue';
import {histClasses} from '@/components/Antecedentes.vue';
import ModalOpcionesImpresion
  from '@/components/ModalOpcionesImpresion.vue';
import FormularioCitas from '@/components/FormularioCitas.vue';
import DrawImage from '@/components/DrawImage.vue';
import ChartMixer from '@/components/charts/ChartMixer.vue';
import Chart from '@/components/charts/ChartView.vue';
import ModalConfirmacionEliminar
  from '@/components/ModalConfirmacionEliminar.vue';
import TarjetasDelDiagnostico
  from '@/components/TarjetasDelDiagnostico.vue';
import ModalLectorDicom
  from '@/components/ModalLectorDicom.vue';
import AgregarAntecedente from
  '@/components/AgregarAntecedente.vue';
import LogosPatrocinantes from '@/components/LogosPatrocinantes.vue';
import ModalChartFull
  from '@/components/charts/ModalFullSize.vue';
import {identificationTypes}
  from '@/components/SelectIdentificationType.vue';
import {getPersonId, getPersonName} from '@/utils/authdecode.js';
import {todayDate, appointmentSchema, capitalize, capitalizeFirstLetter,
  diagnosisSchema, receipSchema, listEncounterDetails, validHourAtStart}
  from '@/utils/validations.js';
import {facturacionFormat, paraclinicoFormat}
  from '@/utils/printFormats.js';
import {calcConditionTemporal, difEnSemanas,
  difEnMeses, sumToDate, TemporalUnits}
  from '@/constantes/conditionTemplates.js';
import {histConfDefault}
  from '@/constantes/histConf.js';
import gql from 'graphql-tag';
import encounterUpdateGql from '@/graphql/encounterUpdate.gql';
import encounterAppointmentGql from '@/graphql/encounterAppointment.gql';
import currenciesAcceptedGql from '@/graphql/currenciesAccepted.gql';
import appointmentCreateGql from '@/graphql/appointmentCreate.gql';
import appointmentMoveDateGql from '@/graphql/appointmentMoveDate.gql';
import symptomsByNameGql from '@/graphql/symptomsByName.gql';
import diseasesByNameGql from '@/graphql/diseasesByName.gql';
import medicinesByNameGql from '@/graphql/medicinesByName.gql';
import receipTemplateGql from '@/graphql/receipTemplate.gql';
import documentTemplatesGql from '@/graphql/documentTemplates.gql';
import imagesDeleteGql from '@/graphql/imagesDelete.gql';
import histConfGql from '@/graphql/histConf.gql';
import histConfUpdateGql from '@/graphql/histConfUpdate.gql';
import mySponsorsTreatmentsGql from '@/graphql/mySponsorsTreatments.gql';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import {ClassicEditor} from '@ckeditor/ckeditor5-editor-classic';
import {ckeditorConfigEdit} from '@/utils/ckeditorConfig.js';
import {print} from '@/utils/printCKEditor.js';
import {printRecipeCk} from '@/utils/printRecipeCKEditor.js';

const multimedia = process.env.NODE_ENV === 'production'
  ? process.env.VUE_APP_MULTIMEDIA_HTTPS
  : process.env.VUE_APP_MULTIMEDIA_HTTP;
const endpoint = multimedia+getPersonId();

export default {
  name: 'PacienteDiagnostico',
  components: {
    AlertaMsj,
    BtnLoader,
    Chart,
    ChartMixer,
    DrawImage,
    ModalChartFull,
    ModalOpcionesImpresion,
    CustomDatalist,
    mdbAlert,
    mdbBtn,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbIcon,
    mdbBadge,
    ModalConfirmacionEliminar,
    TarjetasDelDiagnostico,
    ModalLectorDicom,
    AgregarAntecedente,
    LogosPatrocinantes,
    FormularioCitas,
    ckeditor: CKEditor.component,
  },
  props: {
    paciente: {
      type: Object,
      default: function() {
        return {
          persona: {},
        };
      },
    },
  },
  data() {
    return {
      doctorName: getPersonName(),
      endpoint: endpoint +'/',
      multimedia,
      enableWeek: false,
      showCardConfiguration: false,
      encounter: {
        actualDisease: '',
        notes: '',
        physicalExam: '',
        previousOrdersDetails: '',
      },
      monedas: [],
      documentos: [],
      recipes: [{
        header: '',
        footer: '',
      }],
      historias: {},
      historyItems: [],
      histClasses,
      paraclinicalItems: [],
      conditionTemplates: [],
      chartsTemplate: [],
      conditionTempToUse: {},
      treatmentsItems: [],
      allergies: [],
      immunizations: [],
      vaccines: [],
      vaccineToUse: {},
      conditionsEspecials: [],
      conditionsTemporals: [],
      receip: [],
      receipExpire: '',
      diagnosis: [],
      images: [],
      diseasesOtros: [],
      medicinesOtros: [],
      // diagnostics: [],
      symptoms: [],
      symptomsOtros: [], // Resultados de busqueda de sintomas frecuentes
      bill: {
        amount: 0,
        currency: '',
        date: '',
        tax: 0,
        total: 0,
        symbol: '',
        quotas: '',
        status: 'Approved',
      },
      statusBill: {
        Pending: 'Pendiente',
        Checking: 'En Aprobación',
        Approved: 'Aprobado',
        Rejected: 'Rechazado',
      },
      payment: {
        method: '',
        reference: '',
      },
      paymentMethods: [
        'TDD',
        'TDC',
        'Efectivo',
        'Transferencia',
        'Plan de Pago',
      ],
      tags: [],
      orders: [],
      orderReact: {},
      orderTemp: {},
      ordersCustom: [],
      paraclinicalToUse: {},
      addParaclinicalBtn: false, // Activa animated para agregar paraclinico
      newServiceToUse: {},
      serviceToUse: {
        params: [],
      },
      paramFileToUse: {},
      serviceTemp: {},
      servicesTemp: [],
      servicesBill: [],
      serviceSelected: {},
      medicinesResults: [],
      newMedication: {
        medicine: {
          name: '',
          presentation: '',
          dose: '',
        },
        medicinesAlt: [],
        conditional: false,
        custom: '',
      },
      medicineTemp: {},
      medicineSelected: {},
      newSymptom: {
        name: '',
      },
      newDiagnosis: {
        conclusion: '',
        disease: {
          name: '',
        },
      },
      newTag: '',
      otherTag: '',
      tagsAvailables: [],
      btnLabel: {
        name: '',
        icon: '',
      },
      services: [],
      cita: {
        fecha: {
          formatted: new Date().toISOString().substring(0, 10),
        },
        encuentro: {
          person: {},
          in: {
            id: '',
          },
        },
        doctor: {
          tags: [],
        },
      },
      // Usado para crear exámen externo
      newOrder: {
        country: '',
        state: '',
        city: '',
        institute: '',
        service: {
          name: '',
        },
        otherService: '',
        custom: '',
      },
      // Usado para mostrar modal de actualizar servicio seleccionado
      showSelectedServiceUpdate: false,
      showAddSymptom: false, // Para mostrar modal de agregar síntoma
      showAddDiagnosis: false, // Para mostrar modal de agregar síntoma
      anotherDiagnosis: false, // Para mostrar formulario de una nueva diagnosis
      switchToInstitute: false, // Para formulario de Exámenes externos
      showNewMedication: false, // Modal para agregar medicamento
      indicationsTab: 0,
      showAddAppointment: false, // Modal para próxima cita
      paraCita: {
        servicioId: '',
        date: '',
      },
      showChangeDateForm: false, // Abre modal de cambiar cita
      pacienteConfirmarFecha: false, // Necesita confirmacion cambiar cita
      appointment: {},
      appointmentReasons: [
        {
          name: '',
        },
      ],
      btnDisabled: false,
      alertaMsj: {
        content: '',
      },
      showEditOrder: false,
      showGoBackToPatient: false,
      showNewImage: false,
      showNewParImage: false,
      imagenSeleccionada: {},
      showConfirmacionEliminar: false,
      // Variable de Impresion
      showPrintOptionsModal: false,
      toPrint: {
        code: [],
        documentName: '',
        footer: [],
        hasTwoColumns: false,
        header: [],
      },
      useDoctorName: '', // usar en caso de mostrar el nombre del doctor
      skipEncounterAppointment: false,
      docSelected: {},
      showPreviousEncounters: false,
      listEncounterDetails,
      calcConditionTemporal,
      // Para el gráfico
      chartOpt: {},
      showChartFull: false,
      cardVis: histConfDefault,
      // Ckeditor Configuración
      editorConfig: ckeditorConfigEdit(),
      editor: ClassicEditor,
      showEditorModal: false,
      print,
      todayDate,
      sumToDate,
      TemporalUnits,
      identificationTypes, // Para mostrar el tipo de identificación
      mySpTreatments: [],
    };
  },
  computed: {
    today() {
      const date = todayDate().split('-');
      return `${date[2]}-${date[1]}-${date[0]}`;
    },
    paramsWValues() {
      if (!this.chartsTemplate || !this.chartsTemplate.length) return [];
      const params = [];
      this.services.forEach((s) => {
        if (s.params.length) {
          s.params.forEach((p) => {
            if (p.Parameter.value && ['decimal', 'integer', 'text']
                .includes(p.Parameter.type)) {
              params.push(p.Parameter);
            }
          });
        }
      });
      if (
        this.chartsTemplate
        && this.chartsTemplate.length
        && this.paciente.persona
        && this.paciente.persona.born
      ) {
        if (this.chartsTemplate
            .some((e) => e.xLabel.toLowerCase() === 'semanas')) {
          params.unshift({
            name: 'Edad en semanas',
            value: difEnSemanas(new Date(todayDate()),
                new Date(this.paciente.persona.born.formatted)),
          });
        }
        if (this.chartsTemplate
            .some((e) => e.xLabel.toLowerCase() === 'meses')) {
          params.unshift({
            name: 'Edad en meses',
            value: difEnMeses(new Date(todayDate()),
                new Date(this.paciente.persona.born.formatted)),
          });
        }
      }
      return params;
    },
  },
  watch: {
    services: {
      sync: true,
      handler(services) {
        if (services[0] !== null) {
          this.setPricesAndTotal();
        }
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.showGoBackToPatient) {
      next(false);
      this.showGoBackToPatient = true;
    } else {
      next();
    }
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
  methods: {
    beforeWindowUnload(e) {
      if (!this.showGoBackToPatient) {
        e.preventDefault();
        e.returnValue = '';
        this.showGoBackToPatient = true;
      }
    },
    recalcularAmount() {
      let totalAmount = 0;
      this.servicesBill.forEach((s) => {
        totalAmount += Number(s.prices[0].amount);
      });
      this.bill.amount = totalAmount;
      this.totalWithTax();
    },
    totalWithTax() {
      this.bill.total = this.bill.tax > 0
        ? (this.bill.amount * this.bill.tax / 100
          + Number(Number(this.bill.amount).toFixed(2)))
        : Number(this.bill.amount);
    },
    setPricesAndTotal() {
      let totalAmount = 0;
      this.servicesBill = [];
      if (!this.bill.currency && this.monedas && this.monedas.length) {
        this.bill.currency = this.monedas[0].name;
        this.bill.symbol = this.monedas[0].symbol;
      }
      this.services.forEach((s) => {
        const pricesByCurrency = s.prices.filter((p) =>
          p.Currency.name == this.bill.currency
          && p.Currency.symbol == this.bill.symbol);

        totalAmount += pricesByCurrency[0]
          ? Number(pricesByCurrency[0].amount)
          : 0;
        this.servicesBill.push({
          name: s.name,
          prices: pricesByCurrency.length
            ? JSON.parse(JSON.stringify(pricesByCurrency))
            : [{
              amount: 0,
            }],
        });
        this.bill.amount = totalAmount;
        if (!this.bill.tax) {
          this.bill.total = totalAmount;
        } else {
          this.totalWithTax();
        }
      });
    },
    volverAPaciente(answer) {
      if (answer) {
        this.encounterUpdate({status: 'InProgress'});
      } else {
        this.$router.go(-1);
      }
    },
    async encounterUpdate(options) {
      this.encounter.status = options.status;
      const servicesId = [];
      const diagnostics = [];
      this.services.forEach((s) => {
        servicesId.push(s.id);
        const observations = [];
        s.params.forEach((m) => {
          if (m.Parameter.value) {
            observations.push({
              value: typeof m.Parameter.value != 'string'
              ? JSON.stringify(m.Parameter.value) : m.Parameter.value,
              parameter: {
                id: m.Parameter.id,
              },
            });
          }
        });
        diagnostics.push({
          serviceId: s.id,
          observations,
        });
      });
      this.allergies.forEach((a, i) => {
        delete a.__typename;
        delete a.allergenic.__typename;
        if (!a.allergenic || a.allergenic.name === '') {
          this.allergies.splice(i, 1);
        }
      });
      const conditions =
        this.conditionsEspecials.concat(this.conditionsTemporals);
      conditions.map((c) => {
        delete c.__typename;
        if (Object.keys(c).includes('template')) {
          if (c.template && c.template.id) {
            c.templateId = c.template.id;
          }
          delete c.template;
        }
        return c;
      });
      const histories = [];
      this.historyItems.forEach((a, i) => {
        delete a.__typename;
        if (a.value != undefined && a.value !== '') {
          histories.push({
            value: typeof a.value != 'string'
              ? JSON.stringify(a.value) : a.value,
            item: {
              name: a.name,
              type: a.type,
              class: a.class,
              subclass: a.subclass,
            },
          });
        }
      });
      const immunizations = this.immunizations.map((imm) => {
        delete imm.__typename;
        const immu = {
          status: imm.status,
          shared: imm.shared,
          vaccineId: imm.vaccine.id,
          vaccinations: imm.vaccinations.map((vcc) => {
            delete vcc.__typename;
            return {
              appliedAt: vcc.appliedAt && vcc.appliedAt.formatted
                ? vcc.appliedAt.formatted
                : null,
              fromDate: vcc.fromDate.formatted,
              toDate: vcc.toDate && vcc.toDate.formatted
                ? vcc.toDate.formatted
                : null,
              doseOrder: vcc.dose.order,
            };
          }),
        };
        if (
          !immu.vaccinations.some((vcn)=> !(vcn.appliedAt))
          && imm.vaccine.doses.length === imm.vaccinations.length
        ) {
          immu.status = 'done';
        }
        return immu;
      });

      this.symptoms.forEach((symptom) => {
        delete symptom.__typename;
      });
      this.diagnosis.forEach((diagnosis) => {
        delete diagnosis.__typename;
        delete diagnosis.disease.__typename;
        if (!diagnosis.conclusion) delete diagnosis.conclusion;
      });
      if (this.newOrder.custom) {
        return this.alertaMsj = {
          content: 'Dejó un paraclínico complementario sin agregar',
          type: 'warning',
        };
      }
      if (this.newTag) {
        return this.alertaMsj = {
          content: 'Dejó una etiqueta del encuentro sin agregar',
          type: 'warning',
        };
      }
      if (await this.validarIndicaciones()) {
        return this.alertaMsj = {
          content: 'Debe llenar las indicaciones del récipe',
          type: 'warning',
        };
      }
      this.receip.forEach((r) => {
        delete r.__typename;
        delete r.medicine.__typename;
        r.medicine.name = capitalize(r.medicine.name);
        r.quantity = Number(r.quantity);
        r.frecuency = Number(r.frecuency);
        r.daysLong = Number(r.daysLong);
        r.expire = this.receipExpire;
        if (!r.custom) delete r.custom;
        r.medicinesAlt.forEach((a) => {
          delete a.__typename;
          delete a.medicine.__typename;
          if (!a.custom) delete a.custom;
          a.medicine.name = capitalize(a.medicine.name);
          a.quantity = Number(a.quantity);
          a.frecuency = Number(a.frecuency);
          a.daysLong = Number(a.daysLong);
        });
      });
      const orders = this.orders.map((o) => o.appointment);
      const ordersCustom = this.ordersCustom.map((o) => o.appointment);
      let timeStart = new Date((this.cita.fecha.formatted
        + 'T' + (this.cita.fecha.hour < 10
          ? '0' + this.cita.fecha.hour
          : this.cita.fecha.hour)
        + ':' + (this.cita.fecha.minute < 10
          ? '0' + this.cita.fecha.minute
          : this.cita.fecha.minute)));
      timeStart = timeStart.getTime() / 60000 + timeStart.getTimezoneOffset();
      let timeFinal = new Date();
      timeFinal = timeFinal.getTime() / 60000 + timeFinal.getTimezoneOffset();
      this.encounter.duration = Math.trunc(timeFinal - timeStart);
      this.diagnosis.forEach((d) => {
        diagnosisSchema.validate(d)
            .then((res) => {
              d.conclusion = res.conclusion;
              d.disease.name = res.disease.name;
            });
      });
      this.receip.forEach((r) => {
        receipSchema.validate(r)
            .then((res) => {
              r.medicine.name = res.medicine.name;
              r.medicine.dose = res.medicine.dose;
              r.medicine.presentation = res.medicine.presentation;
              r.custom = res.custom;
              r.unit = res.unit;
              r.medicinesAlt.forEach((ra) => {
                receipSchema.validate(ra)
                    .then((res) => {
                      ra.medicine.name = res.medicine.name;
                      ra.medicine.dose = res.medicine.dose;
                      ra.medicine.presentation = res.medicine.presentation;
                      ra.custom = res.custom;
                      ra.unit = res.unit;
                    });
              });
            });
      });
      const items = [];
      this.servicesBill.forEach((s) => {
        items.push({
          name: s.name,
          amount: Number(s.prices[0].amount),
        });
      });
      if (!this.bill.status) {
        return this.alertaMsj = {
          content: 'Elige un estado del pago',
          type: 'warning',
        };
      }
      if (items.length && !this.bill.currency) {
        return this.alertaMsj = {
          content: 'Elige una moneda para la factura',
          type: 'warning',
        };
      }
      this.bill.amount = Number(this.bill.amount);
      this.bill.tax = Number(this.bill.tax);
      this.bill.total = Number(this.bill.total);
      this.bill.quotas = Number(this.bill.quotas);
      delete this.bill.__typename;
      delete this.bill.payments;
      delete this.bill.items;
      const payments = [];
      if (items.length && this.bill.status != 'Pending') {
        const dateNow = new Date().toISOString();
        payments.push({
          amount: Number(this.bill.total),
          currency: this.bill.currency,
          symbol: this.bill.symbol,
          date: dateNow,
          method: this.payment.method,
          reference: this.payment.reference,
          identification: this.paciente.persona.identification,
          identificationType: this.paciente.persona.identificationType,
          status: this.bill.status,
        });
      }
      const appointmentId = this.$route.params.appointmentId;
      const appointmentDT = this.$route.params.aDT;
      const identification = this.$route.params.iden;
      const identificationType = this.$route.params.idenType;
      const variablesApollo = {
        encounter: this.encounter,
        diagnostics,
        diagnosis: this.diagnosis,
        servicesId,
        tags: this.tags,
        histories: histories,
        immunizations,
        allergies: this.allergies,
        conditions,
        symptoms: this.symptoms,
        receip: this.receip,
        bill: this.bill,
        items,
        orders,
        ordersCustom,
        payments,
        // duration,
        appointmentId,
        appointmentDT,
        identification,
        identificationType,
      };
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${encounterUpdateGql}`,
        variables: variablesApollo,
        context: {serializationKey: 'PERSONS'},
        optimisticResponse: {
          __typename: 'Mutation',
          encounterUpdate: {
            msg: 'success',
            code: '',
            __typename: 'Sysmsg',
          },
        },
        update: (store, {data: {encounterUpdate}}) => {
          if (encounterUpdate.msg === 'success') {
            this.alertaMsj = {
              content: 'Datos guardados',
              type: 'success',
            };
            if (options.status != 'InProgress') {
              this.$emit('encounterEnds', appointmentId);
            }
            if (this.$route.name === 'PacienteDiagnostico'
              && this.$route.params.appointmentId === appointmentId
              && !options.dontUseRouter
            ) {
              this.$router.go(-1);
            }
          } else {
            this.alertaMsj = {
              content: 'Hubo un error al guardar los datos',
              type: 'danger',
            };
          }
          this.btnDisabled = false;

          try {
            const data = store.readQuery({
              query: gql`query Appointment($appointmentId: ID!){
                Appointment(id: $appointmentId) {
                  id
                }
              }`,
              variables: {
                appointmentId,
              },
            });

            data.Appointment.encounter = this.encounter;
            if (options.status != 'InProgress') {
              data.Appointment.status = 'Fulfilled';
            }
            store.writeQuery({
              query: gql`query Appointment($appointmentId: ID!){
                Appointment(id: $appointmentId) {
                  id
                }
              }`,
              variables: {
                appointmentId,
              },
              data,
            });
          } catch {
            // 'Conéctate para sincronizar'
          }
        },
      }).catch((error) => {
        this.alertaMsj = {
          content: 'Hubo un error al guardar los datos',
          type: 'danger',
        };
        this.btnDisabled = false;
      });
    },
    addReason() {
      this.appointmentReasons.push({name: ''});
    },
    removeReason(i) {
      this.appointmentReasons.splice(i, 1);
    },
    appointmentCreate() {
      if (!(appointmentSchema.isValidSync(this.appointment))) {
        return this.alertaMsj = {
          content: 'Coloca una fecha correcta para la cita',
          type: 'warning',
        };
      }
      if (!this.enableWeek && !this.paraCita.schedule) {
        return this.alertaMsj = {
          content: 'Seleccione el horario',
          type: 'warning',
        };
      }
      if (!this.paraCita.time) {
        return this.alertaMsj = {
          content: 'Indique la hora de su preferencia',
          type: 'warning',
        };
      }
      if (!this.enableWeek
        && (!this.paraCita.time
            || !validHourAtStart(
                this.paraCita.time,
                this.paraCita.schedule.start,
            ))) {
        this.validateValue.paraCita.time = 'invalid';
        return this.alertaMsj = {
          content: 'Seleccione una hora dentro del rango del horario',
          type: 'warning',
        };
      }
      this.btnDisabled = true;
      this.appointmentReasons = this.appointmentReasons.filter((reason) =>
        reason.name !== '');
      this.appointmentReasons.forEach((vr)=>{
        vr.name = capitalize(vr.name.trim());
      });
      delete this.paraCita.schedule.__typename;
      delete this.paraCita.schedule.day.__typename;

      this.appointment.status = 'Booked';
      this.appointment.type = 'Previa cita';
      const variablesApollo = {
        appointment: this.appointment,
        patient: {
          id: this.paciente.persona.id,
          fname: this.paciente.persona.fname,
          lname: this.paciente.persona.lname,
          identification: this.paciente.persona.identification,
          identificationType: this.paciente.persona.identificationType,
        },
        serviceId: this.paraCita.servicioId,
        workPlaceId: this.$route.params.lugar || this.cita.encuentro.in.id,
        reasons: this.appointmentReasons,
        schedule: this.paraCita.schedule,
      };
      this.$apollo.mutate({
        mutation: gql`${appointmentCreateGql}`,
        variables: variablesApollo,
      }).then((res) => {
        this.paraCita.id = res.data.appointmentCreate.id;
        this.showAddAppointment = false;
        this.alertaMsj = {
          content: 'Cita agregada',
          type: 'success',
        };
        this.btnDisabled = false;
      }).catch((error) => {
        this.btnDisabled = false;
        return this.alertaMsj = {
          content: 'Hubo un error al crear cita del paciente',
          type: 'danger',
        };
      });
    },
    appointmentMoveDate() {
      const appointment = {
        id: this.paraCita.id,
        date: this.paraCita.date,
        status: this.pacienteConfirmarFecha ? 'Proposed' : 'Booked',
      };
      if (!(appointmentSchema.isValidSync(appointment))) {
        return this.alertaMsj = {
          content: 'Revisa la fecha, horario y hora de la cita',
          type: 'warning',
        };
      }
      appointment.date = appointment.date + 'T' + this.paraCita.time;
      if (!this.paraCita.schedule) {
        return this.alertaMsj = {
          content: 'Seleccione el horario',
          type: 'warning',
        };
      }
      if (!this.paraCita.time) {
        return this.alertaMsj = {
          content: 'Indique la hora de su preferencia',
          type: 'warning',
        };
      }
      const timeSelected = this.paraCita.time.split(':');
      const scheStart = this.paraCita.schedule.start.split(':');
      const scheEnd = this.paraCita.schedule.end.split(':');
      const minSelected = Number(timeSelected[0] * 60 + timeSelected[1]);
      const minStart = Number(scheStart[0] * 60 + scheStart[1]);
      const minEnd = Number(scheEnd[0] * 60 + scheEnd[1]);
      if (minSelected <= minStart || minSelected >= minEnd) {
        return this.alertaMsj = {
          content: 'Seleccione una hora dentro del rango del horario',
          type: 'warning',
        };
      }
      delete this.paraCita.schedule.__typename;
      delete this.paraCita.schedule.day.__typename;
      this.appointment.status = 'Booked';
      this.appointment.type = 'Previa cita';
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${appointmentMoveDateGql}`,
        variables: {
          appointment,
          schedule: this.paraCita.schedule,
        },
      }).then((res) => {
        switch (res.data.appointmentMoveDate.msg) {
          case 'success': {
            delete this.paraCita.schedule;
            delete this.paraCita.time;
            this.showChangeDateForm = false;
            this.pacienteConfirmarFecha = false;
            this.alertaMsj = {
              content: 'Cita cambiada',
              type: 'success',
            };
            break;
          }
          default: {
            this.alertaMsj = {
              content: 'No se cambió la fecha.'
              +' ¿Seguro que es diferente a la que ya tiene?',
              type: 'warning',
            };
            break;
          }
        }
        this.btnDisabled = false;
      }).catch((error) => {
        this.btnDisabled = false;
        return this.alertaMsj = {
          content: 'Hubo un error al cambiar la cita del paciente',
          type: 'danger',
        };
      });
    },
    buscarSintomas(name) {
      if (name.length < 3) {
        return;
      }
      this.$apollo.query({
        query: gql`${symptomsByNameGql}`,
        variables: {
          name,
        },
      }).then((res) => {
        this.symptomsOtros = res.data.symptomsByName;
      }).catch((err) => {});
    },
    buscarDiseases(name) {
      if (name.length < 3) {
        return;
      }
      this.$apollo.query({
        query: gql`${diseasesByNameGql}`,
        variables: {
          name,
        },
      }).then((res) => {
        this.diseasesOtros = res.data.diseasesByName;
      }).catch((err) => {});
    },
    buscarMedicines(name) {
      if (name.length < 3) {
        return;
      }
      this.$apollo.query({
        query: gql`${medicinesByNameGql}`,
        variables: {
          name,
        },
      }).then((res) => {
        this.medicinesOtros = res.data.medicinesByName;
      }).catch((err) => {});
    },
    showModalMedication() {
      this.newMedication = {
        medicine: {
          name: '',
          presentation: '',
          dose: '',
        },
        medicinesAlt: [],
        conditional: false,
        custom: '',
      };
      this.btnLabel = {label: 'Añadir', icon: 'plus'};
      this.showNewMedication = true;
    },
    medicineGeneral() {
      if (this.btnLabel.label == 'Añadir') {
        this.addMedicine();
      } else {
        if (!this.newMedication.medicine.name
          || !this.newMedication.medicine.presentation
          || !this.newMedication.medicine.dose) {
          return this.alertaMsj = {
            content: 'Llene nombre, presentación y concentración del '
            + 'medicamento principal',
            type: 'warning',
          };
        }
        this.newMedication.medicinesAlt =
        this.newMedication.medicinesAlt.filter((m) =>
          m.medicine.name !== '');
        this.newMedication.medicine.name =
          capitalize(this.newMedication.medicine.name);
        this.newMedication.medicine.presentation =
          capitalize(this.newMedication.medicine.presentation);
        this.medicineSelected.medicine = this.newMedication.medicine;
        if (this.newMedication.medicinesAlt.length) {
          let invalidAlt = false;
          this.newMedication.medicinesAlt.forEach((ma) => {
            if (!ma.medicine.name
              || !ma.medicine.presentation
              || !ma.medicine.dose) {
              invalidAlt = true;
            }
            ma.medicine.name = capitalize(ma.medicine.name);
            ma.medicine.presentation = capitalize(ma.medicine.presentation);
          });
          if (invalidAlt) {
            return this.alertaMsj = {
              content: 'Llene nombre, presentación y concentración del '
              + 'medicamento alternativo',
              type: 'warning',
            };
          }
        }
        this.medicineSelected.medicinesAlt = this.newMedication.medicinesAlt;
        this.newMedication = {
          medicine: {
            name: '',
            presentation: '',
            dose: '',
          },
          medicinesAlt: [],
          conditional: false,
          custom: '',
        };
        this.showNewMedication = false;
      }
    },
    addMedicine() {
      if (!this.newMedication.medicine.name
        || !this.newMedication.medicine.presentation
        || !this.newMedication.medicine.dose) {
        return this.alertaMsj = {
          content: 'Llene nombre, presentación y concentración del '
          + 'medicamento principal',
          type: 'warning',
        };
      }
      this.newMedication.medicinesAlt =
        this.newMedication.medicinesAlt.filter((m) => m.medicine.name !== '');
      this.newMedication.medicine.name =
        capitalize(this.newMedication.medicine.name);
      this.newMedication.medicine.presentation =
        capitalize(this.newMedication.medicine.presentation);
      if (this.newMedication.medicinesAlt.length) {
        let invalidAlt = false;
        this.newMedication.medicinesAlt.forEach((ma) => {
          if (!ma.medicine.name
            || !ma.medicine.presentation
            || !ma.medicine.dose) {
            invalidAlt = true;
          }
          ma.medicine.name = capitalize(ma.medicine.name);
          ma.medicine.presentation = capitalize(ma.medicine.presentation);
        });
        if (invalidAlt) {
          return this.alertaMsj = {
            content: 'Llene nombre, presentación y concentración del '
            + 'medicamento alternativo',
            type: 'warning',
          };
        }
      }
      this.receip.push(this.newMedication);
      this.newMedication = {
        medicine: {
          name: '',
          presentation: '',
          dose: '',
        },
        medicinesAlt: [],
        conditional: false,
        custom: '',
      };
      this.showNewMedication = false;
      this.medicinesOtros = [];
    },
    addMedicineAlt() {
      if (!this.newMedication.medicinesAlt) {
        this.newMedication = {
          medicine: this.newMedication.medicine,
          medicinesAlt: [],
          conditional: false,
          custom: '',
        };
        this.newMedication.medicinesAlt.push({
          medicine: {
            name: '',
            presentation: '',
            dose: '',
          },
          conditional: false,
          custom: '',
        });
      } else {
        this.newMedication.medicinesAlt.push({
          medicine: {
            name: '',
            presentation: '',
            dose: '',
          },
          conditional: false,
          custom: '',
        });
      }
    },
    removeMedicine(med) {
      this.receip.splice(med, 1);
    },
    removeMedicineAlt(med, medAlt) {
      med.medicinesAlt.splice(medAlt, 1);
    },
    removeMedicineAltInModal(i) {
      this.newMedication.medicinesAlt.splice(i, 1);
    },
    showEditMedicine(medicina) {
      this.medicineSelected = medicina;
      this.medicineTemp = JSON.parse(JSON.stringify(medicina));
      this.newMedication = this.medicineTemp;
      this.btnLabel = {
        label: 'Actualizar',
        icon: 'rotate',
      };
      this.showNewMedication = true;
    },
    addDiagnostic() {
      const service = this.services.some((s) =>
        s.id == this.newServiceToUse.id);
      if (service) {
        this.alertaMsj = {
          content: 'Esta prueba clínica ya fue agregada',
          type: 'warning',
        };
      } else {
        this.services.push(this.newServiceToUse);
      }
      this.newServiceToUse = {};
    },
    removeDiagnostic(i) {
      this.services.splice(i, 1);
    },
    showMeasureServiceSelected(service) {
      this.serviceSelected = service;
      this.serviceTemp = JSON.parse(JSON.stringify(service));
      this.serviceTemp.params.forEach((m) => {
        if (m.Parameter.type == 'multipleOption') {
          if (m.Parameter.value == undefined) {
            m.Parameter.value = [];
          }
        }
      });
      this.serviceToUse = this.serviceTemp;
      this.showSelectedServiceUpdate = true;
    },
    editServiceSelected() {
      this.serviceTemp.params.forEach((m) => {
        if (m.Parameter.type == 'boolean') {
          if (m.Parameter.value == undefined) {
            m.Parameter.value = false;
          }
        }
      });
      this.serviceSelected.params = this.serviceTemp.params;
      this.showSelectedServiceUpdate = false;
    },
    addParamValueDuo(p) {
      const value = [p.value1, p.value2];
      return value;
    },
    addSymptom() {
      this.newSymptom.name = capitalize(this.newSymptom.name);
      this.symptoms.push(this.newSymptom);
      this.newSymptom = {name: ''};
      this.showAddSymptom = false;
      this.symptomsOtros = [];
    },
    addDiagnosis() {
      this.newDiagnosis.disease.name =
        capitalize(this.newDiagnosis.disease.name);
      this.newDiagnosis.conclusion = capitalize(this.newDiagnosis.conclusion);
      this.diagnosis.push(this.newDiagnosis);
      this.newDiagnosis = {
        conclusion: '',
        disease: {
          name: '',
        },
      };
      this.showAddDiagnosis = false;
      this.diseasesOtros = [];
    },
    addTag() {
      if (this.newTag == 'otra' && !this.otherTag) return;
      const newTags = this.tags.some((t) =>
        t == this.newTag);
      const otherTags = this.tags.some((t) =>
        t == this.otherTag);
      if (newTags || otherTags) {
        this.alertaMsj = {
          content: 'Esta etiqueta ya fue agregada',
          type: 'warning',
        };
      } else {
        this.tags.push(this.otherTag
          ? capitalize(this.otherTag.trim())
          : this.newTag);
      }
      this.newTag = '';
      this.otherTag = '';
    },
    mismasIndicaciones(med, medAlt) {
      medAlt.quantity = med.quantity;
      medAlt.unit = med.unit;
      medAlt.frecuency = med.frecuency;
      medAlt.daysLong = med.daysLong;
      medAlt.custom = med.custom;
      medAlt.conditional = med.conditional;
      medAlt.condition = med.condition;
      med.medicinesAlt = [].concat(med.medicinesAlt);
    },
    addOrder() {
      const orderDescription = this.newOrder.custom.trim();
      const order = {
        appointment: {
          date: todayDate(),
          instruction: capitalizeFirstLetter(orderDescription),
        },
      };
      if (this.newOrder.service.name) {
        order.service = this.newOrder.service.name;
        order.appointment.serviceId = this.newOrder.service.id;
        this.orders.push(order);
      } else {
        this.ordersCustom.push(order);
      }
      this.newOrder = {
        country: '',
        state: '',
        city: '',
        institute: '',
        service: {
          name: '',
        },
        otherService: '',
        custom: '',
      };
    },
    removeOrder(i) {
      this.orders.splice(i, 1);
    },
    removeOrderCustom(i) {
      this.ordersCustom.splice(i, 1);
    },
    editOrderCustom(order) {
      this.orderReact = order;
      this.orderTemp = JSON.parse(JSON.stringify(order));
      this.newOrder = this.orderTemp;
      this.showEditOrder = true;
    },
    updateOrderCustom() {
      const instruction = this.orderTemp.instruction.trim();
      this.orderReact.instruction = capitalizeFirstLetter(instruction);
      this.newOrder = {
        country: '',
        state: '',
        city: '',
        institute: '',
        service: {
          name: '',
        },
        otherService: '',
        custom: '',
      };
      this.showEditOrder = false;
    },
    cancelEditOrder() {
      this.newOrder = {
        country: '',
        state: '',
        city: '',
        institute: '',
        service: {
          name: '',
        },
        otherService: '',
        custom: '',
      };
      this.showEditOrder = false;
    },
    imprimirRecipe() {
      if (this.validarIndicaciones()) {
        return this.alertaMsj = {
          content: 'Debe llenar las indicaciones del récipe',
          type: 'warning',
        };
      }
      this.docSelected = JSON.parse(JSON.stringify(this.recipes[0] || {}));
      this.$nextTick(() => {
        this.sustituirVarsPlantillas();
        this.docSelected.header = document.querySelector('#cabecera')
            .outerHTML;
        this.docSelected.footer = document.querySelector('#pie')
            .outerHTML;
        this.docSelected.body = document.querySelector('#documento')
            .outerHTML;
        this.printRecipeCkEditor();
      });
    },
    cerrarModalOpcionesImpresion() {
      this.showPrintOptionsModal = false;
      this.toPrint = {
        code: [],
        documentName: '',
        footer: [],
        hasTwoColumns: false,
        header: [],
      };
    },
    validarIndicaciones() {
      const validarIndicaciones = this.receip.some((r) => {
        const validarAlternativas = r.medicinesAlt.some((a) => {
          return (!a.quantity || !a.unit || !a.frecuency || !a.daysLong)
          && !a.custom;
        });
        return validarAlternativas || ((!r.quantity || !r.unit
          || !r.frecuency || !r.daysLong) && !r.custom);
      });
      return validarIndicaciones;
    },
    addTreatment(receips) {
      this.receip = this.receip.concat(receips);
      this.alertaMsj = {
        content: 'Tratamiento agregado al récipe',
        type: 'success',
      };
    },
    printRecipeCkEditor() {
      /** Presets de recipe */
      let bodyMedicationsPreset = '<ul>';
      this.receip.forEach((Med) => {
        bodyMedicationsPreset += `<li><strong>${Med.medicine.name}</strong>${
          Med.medicine.presentation
          ? ' en '+ Med.medicine.presentation : '' } ${Med.medicine.dose
            ? ' de ' + Med.medicine.dose
            : ''}</li>`;
        if (Med.medicinesAlt.length) {
          bodyMedicationsPreset +='<ul>';
        }
        Med.medicinesAlt.forEach((alt)=> {
          bodyMedicationsPreset += `<li>${alt.medicine.name}${
          alt.medicine.presentation
          ? ' en '+ alt.medicine.presentation : '' } ${alt.medicine.dose
            ? ' de ' + alt.medicine.dose
            : ''}</li>`;
        });
        if (Med.medicinesAlt.length) {
          bodyMedicationsPreset +='</ul>';
        }
      });
      bodyMedicationsPreset += '</ul>';
      let bodyIndicationsPreset = `<ul>`;
      this.receip.forEach((Med) => {
        bodyIndicationsPreset += `<li><strong>${Med.medicine.name}</strong>${
          Med.medicine.presentation
          ? ' en '+ Med.medicine.presentation : '' } ${Med.medicine.dose
            ? ' de ' + Med.medicine.dose
            : ''}: `;
        /** Custom */
        if (Med.custom) {
          bodyIndicationsPreset += ` ${Med.custom}`;
        } else {
          bodyIndicationsPreset += `${Med.quantity}
                                ${Med.unit}
                                cada ${Med.frecuency} horas
                                por ${Med.daysLong} días`;
        }
        bodyIndicationsPreset += `${Med.conditional
          ? `. <i>${Med.condition}</i>` : ''}`
          +'</li>';
        if (Med.medicinesAlt.length) {
          bodyIndicationsPreset +='<ul>';
        }
        /** Alternativas */
        Med.medicinesAlt.forEach((alt)=> {
          bodyIndicationsPreset+=`<li>${alt.medicine.name}${
          alt.medicine.presentation
          ? ' en '+ alt.medicine.presentation : '' } ${alt.medicine.dose
            ? ' de ' + alt.medicine.dose
            : ''}:`;
          if (alt.custom) {
            bodyIndicationsPreset += `${alt.custom}`;
          } else {
            bodyIndicationsPreset += `${alt.quantity} ${alt.unit}
                                    cada ${alt.frecuency} horas
                                    por ${alt.daysLong} días`;
          }
          bodyIndicationsPreset += `${alt.conditional
          ? `. <i>${alt.condition}</i>` : ''}`
          +'</li>';
        });
        if (Med.medicinesAlt.length) {
          bodyIndicationsPreset +='</ul>';
        }
      });
      bodyIndicationsPreset += '</ul>';
      const bodyOrder = {
        medicationList: bodyMedicationsPreset,
        indicationList: bodyIndicationsPreset,
      };
      printRecipeCk(this.docSelected, bodyOrder);
      // /** Re-start the document */
      this.docSelected.header = '';
      this.docSelected.footer = '';
      this.docSelected.body = '';
    },
    printParaclinico(order, index) {
      const orderFecha = todayDate();
      // Datos del paciente
      const paciente = {
        name: `${this.paciente.persona.fname}${this.paciente.persona.mname
            ? ' '+this.paciente.persona.mname : ''}`
          + ` ${this.paciente.persona.lname}${this.paciente.persona.llname
            ? ' '+this.paciente.persona.llname : ''}`,
        age: this.paciente.persona.age,
        identification: this.paciente.persona.identification,
        docName: `${this.paciente.persona.lname}`
          + `${this.paciente.persona.llname
            ? ' '+this.paciente.persona.llname : ''}`
          + `, ${this.paciente.persona.fname}`
          + `${this.paciente.persona.mname
            ? ' '+this.paciente.persona.mname : ''}`,
      };
      index = parseInt(index) + 1;

      const {name, viewParaclinico} = paraclinicoFormat(orderFecha,
          order.instruction, index, paciente);

      this.useDoctorName = this.doctorName;
      this.toPrint.documentName = name;
      return viewParaclinico;
    },
    printFacturacion() {
      // Nombre del paciente
      const pacienteName = `${this.paciente.persona.lname}`
      + `${this.paciente.persona.llname
        ? ' '+this.paciente.persona.llname : ''}`
      + `, ${this.paciente.persona.fname}`
      + `${this.paciente.persona.mname ? ' '+this.paciente.persona.mname : ''}`;

      // Tabla de servicios
      const bodyTable = [
        [
          // HeaderRow
          {
            text: 'Descripción',
            style: 'header6',
            alignment: 'center',
          },
          {text: 'Valor', style: 'header6', alignment: 'center'},
        ],
      ];
      this.servicesBill.forEach((servicio) => {
        bodyTable.push([
          {text: servicio.name},
          {text: servicio.prices[0].amount, alignment: 'right'},
        ]);
      });

      // Calculo del impuesto
      const calcImpuesto = this.bill.tax
        ? (this.bill.amount * this.bill.tax / 100).toFixed(2)
        : this.bill.tax;
      bodyTable.push([
        {
          text: `${this.bill.tax}% Impuesto: ${calcImpuesto} `,
          bold: true,
          colSpan: 2,
          alignment: 'right',
          fillColor: '#eeeeee',
        },
        {},
      ]);

      bodyTable.push([
        {
          text: `Total: ${this.bill.symbol} ${this.bill.total}`,
          bold: true,
          colSpan: 2,
          alignment: 'right',
        },
        {},
      ]);

      // Método de pago Facturación
      const metodoPago = [];

      if (this.payment.method) {
        metodoPago.push({text: 'Método de pago: ', bold: true});

        if (this.bill.quotas) {
          metodoPago.push({
            text: `\n${this.payment.method} en ${this.bill.quotas}`
              + ` cuota${Number(this.bill.quotas) > 1 ? 's' : ''}.\n`,
          });
        } else if (this.payment.reference) {
          metodoPago.push({
            text: `\n${this.payment.method} #${this.payment.reference}.\n`,
          });
        } else {
          metodoPago.push({
            text: this.payment.method,
          });
        }
      }

      // Estado del pago
      const billStatus = this.statusBill[this.bill.status];

      // Fecha del encuentro
      const fechaEncuentro = todayDate();

      const {name, viewFacturacion} = facturacionFormat(pacienteName, bodyTable,
          metodoPago, billStatus, fechaEncuentro);

      // Limpiando variable useDoctorName
      this.useDoctorName = '';
      this.toPrint.documentName = name;
      return viewFacturacion;
    },
    prepareDocumentTemplate(documento) {
      this.docSelected = JSON.parse(JSON.stringify(documento));
      this.$nextTick(() => {
        this.sustituirVarsPlantillas();
        this.docSelected.body = document.querySelector('#documento')
            .outerHTML;
        this.showEditorModal = true;
      });
    },
    sustituirVarsPlantillas() {
      // Sustitución del contenido de las variables:
      document.querySelectorAll('.editor-var').forEach((e) => {
        const id = e.getAttribute('data-id');
        switch (e.getAttribute('data-type')) {
          case 'Doctor':
            e.textContent =
              this.cita.doctor[e.getAttribute('data-id')];
            break;
          case 'Patient':
            if (id === 'born') {
              e.textContent =
                this.paciente.persona[e.getAttribute('data-id')].formatted;
            } else if (id === 'el_la') {
              switch (this.paciente.persona['gender']) {
                case 'Femenino':
                  e.textContent = 'la';
                  break;
                default:
                  e.textContent = 'el';
                  break;
              }
            } else {
              e.textContent =
                this.paciente.persona[e.getAttribute('data-id')];
            }
            break;
          case 'Work_Place':
            e.textContent = '';
            break;
          case 'Parameter':
            this.services.forEach((s)=>{
              s.params.forEach((p) => {
                if (id === p.Parameter.id) {
                  if (
                    p.Parameter.type === 'file'
                     || p.Parameter.type === 'draw'
                  ) {
                    e.setAttribute('src', this.endpoint+p.Parameter.value);
                  } else {
                    e.textContent =
                      p.Parameter.value;
                  }
                }
              });
            });
            break;
          case 'History':
            for (const clas in this.historias) {
              if (Object.hasOwnProperty.call(this.historias, clas)) {
                const itemsInclass = this.historias[clas];
                itemsInclass.forEach((ph)=>{
                  if (id === ph.id) {
                    if (ph.type === 'file') {
                      e.setAttribute('src', this.endpoint+ph.value);
                    } else {
                      e.textContent = ph.value;
                    }
                  }
                });
              }
            }
            break;
          case 'Encounter':
            switch (id) {
              case 'date':
                e.textContent =
                  (new Date()).toLocaleDateString();
                break;
              case 'time':
                e.textContent =
                  (new Date()).toLocaleTimeString();
                break;
              case 'actualDisease':
                e.textContent =
                  this.encounter.actualDisease;
                break;
              case 'notes':
                e.textContent =
                  this.encounter.notes;
                break;
              case 'physicalExam':
                e.textContent =
                  this.encounter.physicalExam;
                break;
              case 'receipList':
                e.textContent =
                  this.receip.map((r) =>{
                    return r.medicine ? r.medicine.name : '';
                  }).join(', ');
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      });
    },
    // Methods to capitalizeFirstLetter @blur
    capitalizeActualDisease() {
      this.encounter.actualDisease =
        capitalizeFirstLetter(this.encounter.actualDisease);
    },
    capitalizePhysicalExam() {
      this.encounter.physicalExam =
        capitalizeFirstLetter(this.encounter.physicalExam);
    },
    capitalizePreviousOrdersDetails() {
      this.encounter.previousOrdersDetails =
        capitalizeFirstLetter(this.encounter.previousOrdersDetails);
    },
    capitalizeNotes() {
      this.encounter.notes = capitalizeFirstLetter(this.encounter.notes);
    },
    showPreviusEncounter(encounter) {
      if (encounter && encounter.id) {
        this.$router.push({
          name: 'PacienteDiagnosticoDetallesPrevia',
          params: {encounterId: encounter.id},
        });
        this.showPreviousEncounters = false;
      } else {
        this.alertaMsj = {
          content: 'Esta consulta no tiene registros aún',
          type: 'warning',
        };
      }
    },
    imagesDelete() {
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${imagesDeleteGql}`,
        variables: {
          urls: [this.imagenSeleccionada.url],
        },
      }).then((res) => {
        switch (res.data.imagesDelete.msg) {
          case 'success': {
            this.images = this.images.filter((im) => {
              return im.url !== this.imagenSeleccionada.url;
            });
            this.alertaMsj = {
              content: 'Imagen eliminada',
              type: 'success',
            };
            this.showConfirmacionEliminar = false;
            this.imagenSeleccionada = {};
            break;
          }
          default: {
            this.alertaMsj = {
              content: 'No se eliminó. Intenta de nuevo.',
              type: 'warning',
            };
            break;
          }
        }
        this.btnDisabled = false;
      }).catch((error) => {
        this.btnDisabled = false;
        return this.alertaMsj = {
          content: 'Hubo un error al eliminar la imagen',
          type: 'danger',
        };
      });
    },
    setCardVis(item, visibility) {
      const cardVisConf = {
        code: item,
        vis: visibility,
      };
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${histConfUpdateGql}`,
        variables: {
          histConf: cardVisConf,
        },
        update: (store)=>{
          const data = store.readQuery({
            query: histConfGql,
            variables: {
              doctorId: getPersonId(),
            },
          });
          data.Doctor[0].historyConf.items =
            data.Doctor[0].historyConf.items.map((hi)=>{
              if (hi.code=cardVisConf.code) {
                hi.vis=cardVisConf.vis;
              }
              return hi;
            });
          store.writeQuery({
            query: histConfGql,
            variables: {
              doctorId: getPersonId(),
            },
            data,
          });
        },
      }).then((res) => {
        switch (res.data.histConfUpdate.msg) {
          case 'success': {
            this.alertaMsj = {
              content: 'Configuración guardada',
              type: 'success',
            };
            this.showConfirmacionEliminar = false;
            this.imagenSeleccionada = {};
            break;
          }
          default: {
            this.alertaMsj = {
              content: 'No se guardó o no hubo cambios.',
              type: 'warning',
            };
            break;
          }
        }
        this.btnDisabled = false;
      }).catch((error) => {
        this.btnDisabled = false;
        return this.alertaMsj = {
          content: 'Hubo un error al guardar la configuración',
          type: 'danger',
        };
      });
    },
    updateHistItemList(historyItem) {
      if (! this.historias[historyItem.class]) {
        this.historias[historyItem.class] = [];
      }
      this.historias[historyItem.class].push(historyItem);
      const tHistorias = {...this.historias};
      this.historias = tHistorias;
    },
    lostVaccine(immunn) {
      immunn.status = 'lost';
    },
    agregarDosis(immun, vac) {
      if (immun.vaccine.doses.length > immun.vaccinations.length) {
        const pos = immun.vaccinations.length;
        immun.vaccinations.push({
          dose: {
            type: immun.vaccine.doses[pos].type,
            order: immun.vaccine.doses[pos].order,
          },
          fromDate: {formatted: sumToDate(
              todayDate(),
              immun.vaccine.doses[pos].startPeriod,
              immun.vaccine.doses[pos].startPeriodUnit,
          )},
          toDate: {formatted: sumToDate(
              todayDate(),
              immun.vaccine.doses[pos].startPeriod,
              immun.vaccine.doses[pos].startPeriodUnit,
          )},
        });
      }
      immun.vaccinations = immun.vaccinations.concat([]);
    },
  },
  apollo: {
    cita() {
      return {
        query: gql`${encounterAppointmentGql}`,
        variables: {
          id: this.$route.params.appointmentId,
          doctorId: getPersonId(),
          patientId: this.$route.params.personId,
        },
        context: {serializationKey: 'PERSONS'},
        update: (data) => {
          if (!data) return this.cita;
          if ((this.encounter.actualDisease
            && this.encounter.actualDisease.length
            && data.Encounter[0].actualDisease
            && data.Encounter[0].actualDisease.length)
            || (this.encounter.notes.length
              && data.Encounter[0].notes
              && data.Encounter[0].notes.length)
            || (this.encounter.physicalExam.length
              && data.Encounter[0].physicalExam
              && data.Encounter[0].physicalExam.length)
            || (this.encounter.previousOrdersDetails.length
              && data.Encounter[0].previousOrdersDetails
              && data.Encounter[0].previousOrdersDetails.length)
          ) {
            this.alertaMsj = {
              content: 'Se buscó data más actualizada. Si habías guardado'
              +' en otro dispositivo pero no la ves, recarga.',
              type: 'warning',
            };
          }
          this.historyItems = data.Doctor[0].historyItems
              .map((h)=>h.History_Item);
          this.paraclinicalItems = data.Doctor[0].paraclinicalItems;
          this.conditionTemplates = data.Doctor[0].conditionTemplates;
          this.chartsTemplate = data.Doctor[0].chartsTemplate;
          this.vaccines = data.Doctor[0].vaccines;
          this.treatmentsItems = data.Doctor[0].treatmentsItems.map((t) => {
            t.receips.forEach((r)=>{
            });
            return t;
          });
          this.tagsAvailables = data.Doctor[0].tagsGroup.length
            ? data.Doctor[0].tagsGroup[0].tags
            : [];
          this.allergies = data.Encounter[0].appointment.person.allergies;
          let conditions = data.Encounter[0].appointment
              .person.conditions;
          if (data.Record) {
            if (
              data.Record.conditions
              && data.Record.conditions.length
            ) {
              conditions = conditions.concat(data.Record.conditions);
            }
            if (
              data.Record.immunizations
              && data.Record.immunizations.length
            ) {
              this.immunizations = data.Record.immunizations;
            }
          }
          this.conditionsEspecials = conditions
              .filter((c) => c.type == 'Especial');
          this.conditionsTemporals = conditions
              .filter((c) => c.type == 'Temporal');
          if (data.Encounter[0].appointment.forService) {
            this.services.push(data.Encounter[0].appointment.forService);
          }
          const services = [];
          data.Encounter[0].diagnostics.forEach((diagnostic) => {
            data.Doctor[0].services.forEach((s) => {
              if (diagnostic.service.id == s.id) {
                s.params.forEach((m) => {
                  diagnostic.observations.forEach((obs) => {
                    if (obs.value == 'true') {
                      obs.value = true;
                    } else if (obs.value == 'false') {
                      obs.value = false;
                    } else if (typeof obs.value == 'string'
                      && obs.value.indexOf('[') == 0 ) {
                      obs.value = JSON.parse(obs.value);
                    }
                    if (m.Parameter.id == obs.parameter.id) {
                      m.Parameter.value = obs.value;
                      if (typeof obs.value == 'object'
                        && obs.value != null) {
                        m.Parameter.value1 = obs.value[0];
                        m.Parameter.value2 = obs.value[1];
                      }
                    }
                  });
                });
                services.push(s);
              }
            });
          });
          this.services = services;
          if (data.Encounter[0].bill) {
            this.servicesBill.forEach((s) => {
              data.Encounter[0].bill.items.forEach((i) => {
                if (s.name == i.name) {
                  s.prices = [{
                    Currency: {
                      name: data.Encounter[0].bill.currency,
                      symbol: data.Encounter[0].bill.symbol,
                    },
                    amount: i.amount,
                  }];
                }
              });
            });
            Object.assign(this.bill, {...data.Encounter[0].bill});
            this.payment.method = data.Encounter[0].bill.payments.length
              ? data.Encounter[0].bill.payments[0].method
              : '';
            this.payment.reference = data.Encounter[0].bill.payments.length
              ? data.Encounter[0].bill.payments[0].reference
              : '';
          }
          data.patientHistory.forEach((h) => {
            if (h.value == 'true') {
              h.value = true;
            } else if (h.value == 'false') {
              h.value = false;
            } else if (typeof h.value == 'string'
              && h.value.indexOf('[') == 0 ) {
              h.value = JSON.parse(h.value);
            }
            if (h.item.type == 'boolean' && h.value == null) {
              h.value = false;
            }
            if (h.item.type == 'integer' || h.item.type == 'decimal') {
              h.value = Number(h.value);
            }
            this.historyItems.forEach((hi) => {
              if (hi.name == h.item.name && hi.type == h.item.type) {
                if (h.item.type == 'multipleOption') {
                  h.value.forEach((v) =>{
                    hi[v] = true;
                  });
                }
                hi.value = h.value;
              }
            });
          });
          this.historias = {};
          const histClassOrder = data.Doctor[0].historyConf
            ? data.Doctor[0].historyConf.histClassOrder
            : null;
          if (histClassOrder && histClassOrder.length) {
            histClassOrder.forEach((hco) => {
              this.historias[hco]=[];
            });
          } else {
            for (const key in histClasses) {
              if (Object.hasOwnProperty.call(histClasses, key)) {
                this.historias[key]=[];
              }
            }
          }
          this.historyItems.forEach((hih) => {
            if (hih.type == 'multipleOption' && !hih.value) {
              hih.value = [];
            }
            if (!this.historias[hih.class]) {
              this.historias[hih.class]=[];
            }
            this.historias[hih.class].push(hih);
          });
          for (const key in this.historias) {
            if (Object.hasOwnProperty.call(this.historias, key)) {
              if (!this.historias[key].length) {
                delete this.historias[key];
              }
            }
          }
          if (data.Encounter[0].symptoms) {
            this.symptoms = data.Encounter[0].symptoms;
          }
          if (data.Encounter[0].diagnosis) {
            this.diagnosis = data.Encounter[0].diagnosis;
          }
          if (data.Encounter[0].orders) {
            this.ordersCustom = data.Encounter[0].orders.map((order) => {
              order.appointment = {};
              order.appointment.instruction = order.instruction;
              return order;
            });
          }
          this.encounter.id = data.Encounter[0].id;

          if (data.Encounter[0].actualDisease
            && data.Encounter[0].actualDisease.length
          ) {
            this.encounter.actualDisease = data.Encounter[0].actualDisease;
          }
          if (data.Encounter[0].notes
            && data.Encounter[0].notes.length
          ) {
            this.encounter.notes = data.Encounter[0].notes;
          }
          if (data.Encounter[0].physicalExam
            && data.Encounter[0].physicalExam.length
          ) {
            this.encounter.physicalExam = data.Encounter[0].physicalExam;
          }
          if (data.Encounter[0].previousOrdersDetails
            && data.Encounter[0].previousOrdersDetails.length
          ) {
            this.encounter.previousOrdersDetails =
              data.Encounter[0].previousOrdersDetails;
          }
          this.tags = [];
          if (data.Encounter[0].tags) {
            data.Encounter[0].tags.forEach((t) => {
              this.tags.push(t.name);
            });
          }
          this.images = [];
          if (data.Encounter[0].images) {
            this.images = data.Encounter[0].images;
          }
          if (data.Encounter[0].receip) {
            this.receip = data.Encounter[0].receip.map((r) => {
              return r;
            });
          }
          this.skipEncounterAppointment = true;
          return {
            fecha: data.Encounter[0].date,
            encuentro: data.Encounter[0].appointment,
            doctor: data.Doctor[0],
          };
        },
        skip() {
          return this.skipEncounterAppointment;
        },
        fetchPolicy: 'cache-and-network',
      };
    },
    recipes() {
      return {
        query: gql`${receipTemplateGql}`,
        variables: {
          doctorId: getPersonId(),
        },
        update: (data) => data.Receip_Template,
      };
    },
    documentos() {
      return {
        query: documentTemplatesGql,
        update: (data) => data.documentTemplates,
      };
    },
    monedas() {
      return {
        query: gql`${currenciesAcceptedGql}`,
        update: (data) => data.currenciesAccepted,
      };
    },
    mySpTreatments() {
      return {
        query: gql`${mySponsorsTreatmentsGql}`,
        variables: {
          personId: getPersonId(),
        },
        update: (data) => data.mySponsorsTreatments,
      };
    },
    histConf() {
      return {
        query: gql`${histConfGql}`,
        variables: {
          doctorId: getPersonId(),
        },
        update: (data) => {
          if (
            data.Doctor[0].historyConf
            && data.Doctor[0].historyConf.items
            && data.Doctor[0].historyConf.items.length
          ) {
            data.Doctor[0].historyConf.items.forEach((hi)=>{
              this.cardVis[hi.code].vis = hi.vis;
              this.cardVis[hi.code].name =
                hi.name || this.cardVis[hi.code].name;
            });
          }
        },
        fetchPolicy: 'cache',
      };
    },
    medicinesResults() {
      return {
        query: gql`${medicinesByNameGql}`,
        variables: ()=>{
          return {
            name: this.newMedication.medicine.name,
          };
        },
        update: (data) => data.medicinesByName,
        debounce: 700,
        skip() {
          return this.newMedication.medicine.name.length < 3;
        },
      };
    },
  },
};
</script>
<style lang="scss">
  .paciente-diagnostico {
    background-color: $white;
    height: 100vh;
    padding-top: 56px;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 4;

    .angle-list.reasons {
      margin-left: 0;
      li {
        margin: .25rem 0 !important;
      }
      li p {
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        overflow-wrap: break-word;
        word-break: break-word;
      }
    }

    .modal {
      height: calc(100% - 50px);
      top: 50px;
    }

    .check-list-style {
      margin-bottom: 0;

      li {
        margin-bottom: 0;
      }
      li::before {
        align-self: flex-start;
      }
      p {
        margin-bottom: 0;
      }
      p.conclusion {
        text-indent: 8px;
        word-break: break-word;
      }
      p.conclusion::before  {
        content: '\f105';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        margin-left: -8px;
      }
    }
    .p-relative {
      position: relative;
    }
    ul.nav-pills[role='tablistRecipe'] {
      flex-direction: column;
      position: sticky;
      top: 4.5rem;
      background-color: white;
      z-index: 1;
      @media (min-width: 576px) {
        flex-direction: row;
      }
      @media (max-width: 390px) {
        flex-wrap: nowrap;
        overflow-x: auto;
      }
    }

    .compress-form input[type="checkbox"].hidden-check {
      display: none;

      & ~ label {
        cursor: pointer;
        margin-bottom: 0;
        user-select: none;
      }
      & ~ .toggle-textarea {
        display: none;
      }

      &:checked ~ label {
        margin-bottom: 0.75rem;
      }
      &:checked ~ .toggle-textarea {
        display: block;
      }
    }

    .btn-floating {
      background-color: #fff !important;
      bottom: 2rem;
      left: 2rem;
      position: fixed;
      z-index: 3;

      &:hover{
        background-color: #fff !important;
      }
    }

    .angle-right-icon {
      position: relative;

      &::after {
        color: $muted-color;
        content: '\f105';
        font-family: 'Font Awesome 5 Free';
        font-size: 1.5rem;
        font-weight: 900;
        position: absolute;
        right: 0;
        bottom: 0.25rem;
      }
    }

    // .registro-diagnostico {
    //   @media screen and (min-width: 576px) {height: calc(100vh - 6rem);}
    // }
  }
  .top-bar {
    position: sticky;
    top: 0;
    z-index: 100;
    .top-bar-menu {
      height: calc(100vh / 3);
      overflow: scroll;
      @media (min-width: 768px) {
        height: auto;
        overflow: auto;
      }
    }
    .top-bar-actions {
      position: relative;
      z-index: 1;
      background-color: #fff;
    }
    .top-bar-options {
      position: sticky;
      top: 0;
      z-index: 0;
      background-color: #f7f7f7;
      // margin: 0 0.05rem;
    }
  }

  .card.card-columns-spacing {
    margin-bottom: .75rem;
    margin-top: 5px;
  }

  .params-group li {
    border-bottom: 1px dotted #dedede;
  }
  .fadeIn-enter-active {
    animation: slice 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  .fadeIn-leave-active {
    animation: slice 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both reverse;
  }
  @keyframes slice {
    0% {
      opacity: 0;
      transform: translateY(-250px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .line-height-minus {
    line-height: 12px;
  }
</style>
