<template>
  <div class="agregar-paciente">
    <mdb-btn
      v-if="!$apolloData.queries.lugares.loading || !!showAddAppointment"
      ref="clickAggPaciente"
      color="success-base"
      icon="plus"
      icon-class="fa-lg"
      rounded
      class="col-auto fixed-br-mobile"
      @click="showAddAppointment = true"
    >
      Agregar paciente
    </mdb-btn>
    <!-- Modal agregar paciente a espera -->
    <mdb-modal
      ref="modalAddAppointment"
      centered
      elegant
      scrollable
      size="lg"
      :show="showAddAppointment"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          Agregar a lista de espera
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModalAddAppointment"
          class="close"
          @click="showAddAppointment = false; clearAppointmentform()"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body class="overflowX-hidden">
        <transition
          name="custom-classes-transition"
          enter-active-class="animated fadeInRight"
          leave-active-class="animated fadeOutLeft"
          mode="out-in"
        >
          <!-- Opciones para seleccionar consultorio
            en caso de que no exista -->
          <fieldset
            v-if="step === 'consultorio'"
            key="consultorio"
          >
            <template v-if="lugares.length">
              <p>
                <font-awesome-icon icon="circle-question" />
                Selecciona el consultorio donde ves al paciente
              </p>
              <div class="d-flex flex-wrap row-gap">
                <div
                  v-for="(lug, iLug) in lugares"
                  :key="'lu'+iLug"
                  class="col-12 col-sm-4"
                >
                  <mdb-card class="px-1">
                    <label>
                      <mdb-card-body class="our-radio2">
                        <mdb-card-title>
                          <input
                            v-model="workPlaceId"
                            type="radio"
                            name="search-worPlace-radio"
                            :value="lug.id"
                          >
                          <span />
                          {{ lug.name }}
                        </mdb-card-title>
                        <mdb-card-text>
                          {{ lug.in.location.name }} - {{ lug.in.name }}
                        </mdb-card-text>
                      </mdb-card-body>
                    </label>
                  </mdb-card>
                </div>
              </div>
            </template>
            <div
              v-else
              class="d-flex flex-column flex-lg-row align-items-center
              justify-content-center text-muted my-4"
            >
              <font-awesome-icon
                :icon="['fas', 'inbox']"
                class="my-2 fa-7x"
              />
              <p class="h2-responsive text-center px-4">
                Sin lugares de consulta
                <router-link
                  :to="{ name: 'LugaresDeTrabajo' }"
                  tag="span"
                  class="d-block fs-inherit cursor-pointer d-flex
                    justify-content-center align-items-center
                    link-icon-hover"
                >
                  Agrega uno
                  <span class="ml-1 fs-inherit text-underline">
                    aquí
                  </span>
                  <font-awesome-icon
                    :icon="['fas', 'angle-right']"
                    class="my-2 fa-md icon"
                  />
                </router-link>
              </p>
            </div>
          </fieldset>
          <!-- Opciones para agregar o crear paciente a espera -->
          <fieldset
            v-if="step === 'Agregar/Crear'"
            key="Agregar/Crear"
          >
            <div
              class="d-flex flex-column flex-lg-row align-items-center
                justify-content-center text-muted mb-2"
            >
              <font-awesome-icon
                icon="address-book"
                class="my-2 fa-5x"
              />
              <p class="h4-responsive text-center px-4">
                <span class="d-lg-block fs-inherit">
                  Selecciona si quieres agregar
                </span>
                <span class="d-lg-block fs-inherit">
                  un nuevo paciente o buscar
                </span>
                <span class="d-lg-block fs-inherit">
                  en los ya registrados
                </span>
              </p>
            </div>
            <div
              class="d-flex flex-column flex-lg-row
              align-items-center justify-content-center"
            >
              <div class="col-auto our-radio2 my-2">
                <mdb-badge
                  pill
                  color="tertiary"
                >
                  <label>
                    <input
                      v-model="optionSelected"
                      type="radio"
                      name="search-radio"
                      value="name"
                      @change="stepper(true)"
                    >
                    <span class="text-muted">
                      <font-awesome-icon
                        icon="users"
                        class="ml-2"
                      />
                      Buscar por nombre
                    </span>
                  </label>
                </mdb-badge>
              </div>
              <div class="col-auto our-radio2 my-2">
                <mdb-badge
                  pill
                  color="tertiary"
                >
                  <label>
                    <input
                      v-model="optionSelected"
                      type="radio"
                      name="search-radio"
                      value="newpatient"
                      @change="stepper(true)"
                    >
                    <span class="text-muted">
                      <font-awesome-icon
                        icon="user-plus"
                        class="ml-2"
                      />
                      Agregar nuevo paciente
                    </span>
                  </label>
                </mdb-badge>
              </div>
            </div>
          </fieldset>
          <!-- Formulario por Nuevo paciente -->
          <fieldset
            v-if="step === 'NuevoPaciente'"
            key="nuevo-paciente"
          >
            <div>
              <div class="our-checkbox2">
                <label>
                  <input
                    v-model="childSelected"
                    type="checkbox"
                    name="esMenor-check"
                  >
                  <span />
                  Si es menor sin identificación,
                  <u>haga clic aquí</u>.
                </label>
              </div>
              <header
                v-if="childSelected"
                class="font-weight-bold"
              >
                Datos del representante
              </header>
              <!-- Identification & Type -->
              <div class="row">
                <div class="col-12 col-sm-6">
                  <SelectIdentificationType
                    ref="modalSave"
                    v-model="person.identificationType"
                    class-name="md-outline outline-select my-2"
                    :validate-value="validateValue"
                    @save="identificationType=$event;
                           personIdentificationInitialValue()"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <mdb-input
                    ref="inputIdentification"
                    v-model="person.identification"
                    label="Identificación"
                    :class="['my-2',
                             {'class-valid':
                               validateValue.identification == 'valid'},
                             {'class-invalid':
                               validateValue.identification == 'invalid'}]"
                    outline
                  />
                </div>
              </div>
              <!-- buscarAdulto loading -->
              <div
                v-if="$apolloData.queries.buscarAdulto.loading"
                class="text-muted d-flex
                align-items-center justify-content-center"
              >
                <font-awesome-icon
                  :icon="['fas', 'circle-notch']"
                  class="fa-spin"
                />
                <small class="text-uppercase ml-2">
                  Buscando paciente en sistema
                </small>
              </div>
              <!-- Campos de parent -->
              <div
                v-if="childSelected"
                class="row"
              >
                <div class="col-12 col-sm-6">
                  <mdb-input
                    v-model="parent.fname"
                    label="Primer nombre"
                    :class="['my-2',
                             {'class-valid':
                               validateValue.parent.fname == 'valid'},
                             {'class-invalid':
                               validateValue.parent.fname == 'invalid'}]"
                    outline
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <mdb-input
                    v-model="parent.mname"
                    label="Segundo nombre"
                    class="my-2"
                    outline
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <mdb-input
                    v-model="parent.lname"
                    label="Primer apellido"
                    :class="['my-2',
                             {'class-valid':
                               validateValue.parent.lname == 'valid'},
                             {'class-invalid':
                               validateValue.parent.lname == 'invalid'}]"
                    outline
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <mdb-input
                    v-model="parent.llname"
                    label="Segundo apellido"
                    class="my-2"
                    outline
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <SelectGender
                    v-model="parent.gender"
                    class-name="md-outline outline-select my-2"
                    name-select="parent"
                    :validate-value="validateValue.parent.gender"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <SelectBloodType
                    v-model="parent.bloodType"
                    class-name="md-outline outline-select my-2"
                    name-select="parent"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <mdb-input
                    v-model="parent.born"
                    type="date"
                    label="F de nacimiento"
                    :class="['my-2 outline-date',
                             {'class-valid':
                               validateValue.parent.born == 'valid'},
                             {'class-invalid':
                               validateValue.parent.born == 'invalid'}]"
                    outline
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <SelectKinship
                    v-model="parent.kinship"
                    class-name="md-outline outline-select my-2"
                    name-select="parent"
                    :validate-value="validateValue.parent.kinship"
                  />
                </div>
              </div>
              <header
                v-if="childSelected"
                class="font-weight-bold mt-2"
              >
                Datos del menor
              </header>
              <!-- Campos de person y N° de hijo -->
              <div class="row">
                <div
                  v-if="childSelected"
                  class="col-12 col-sm-6"
                >
                  <mdb-input
                    v-model.number="childNumber"
                    type="number"
                    label="N° de hijo"
                    :min="1"
                    :step="1"
                    :class="['my-2',
                             {'class-valid':
                               validateValue.childNumber == 'valid'},
                             {'class-invalid':
                               validateValue.childNumber == 'invalid'}]"
                    outline
                  />
                </div>
                <div
                  :class="['col-12', childSelected
                    ? 'col-sm-6' : 'order-1']"
                >
                  <mdb-input
                    v-model="person.born"
                    type="date"
                    label="F de nacimiento"
                    :class="['my-2 outline-date',
                             {'class-valid': validateValue.born == 'valid'},
                             {'class-invalid':
                               validateValue.born == 'invalid'}]"
                    outline
                  />
                </div>
                <div
                  v-if="$apolloData.queries.buscarMenor.loading"
                  class="col-12 text-muted d-flex align-items-center
                    justify-content-center"
                >
                  <font-awesome-icon
                    :icon="['fas', 'circle-notch']"
                    class="fa-spin"
                  />
                  <small class="text-uppercase ml-2">
                    Buscando paciente en sistema
                  </small>
                </div>
                <div class="col-12 col-sm-6">
                  <mdb-input
                    v-model="person.fname"
                    label="Primer nombre"
                    :class="['my-2',
                             {'class-valid': validateValue.fname == 'valid'},
                             {'class-invalid':
                               validateValue.fname == 'invalid'}]"
                    outline
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <mdb-input
                    v-model="person.mname"
                    label="Segundo nombre"
                    class="my-2"
                    outline
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <mdb-input
                    v-model="person.lname"
                    label="Primer apellido"
                    :class="['my-2',
                             {'class-valid': validateValue.lname == 'valid'},
                             {'class-invalid':
                               validateValue.lname == 'invalid'}]"
                    outline
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <mdb-input
                    v-model="person.llname"
                    label="Segundo apellido"
                    class="my-2"
                    outline
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <SelectGender
                    v-model="person.gender"
                    class-name="md-outline outline-select my-2"
                    name-select="person"
                    :validate-value="validateValue.gender"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <SelectBloodType
                    v-model="person.bloodType"
                    class-name="md-outline outline-select my-2"
                    name-select="person"
                  />
                </div>
              </div>
              <!-- Fuente del paciente -->
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="md-form md-outline outline-select my-2">
                    <select
                      id="newTag-select"
                      v-model="tag"
                      class="custom-select"
                    >
                      <option
                        class="d-none"
                        disabled
                        value=""
                      >
                        Seleccione medio de contacto inicial
                      </option>
                      <option
                        v-for="(etiqueta, i) in tagsAvailables"
                        :key="i"
                        :value="etiqueta.name"
                      >
                        {{ etiqueta.name }}
                      </option>
                      <option value="otra">
                        Agregar nueva
                      </option>
                      <option
                        v-if="tag !== 'otra'"
                        class="d-none"
                        :value="tag"
                      >
                        {{ tag }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  v-if="tag == 'otra'"
                  class="col-12 col-sm-6 d-flex align-items-center mb-2"
                >
                  <mdb-input
                    v-model="otherTag"
                    placeholder="Nueva Etiqueta"
                    class="col p-0 my-1"
                    outline
                  />
                  <mdb-btn
                    :class="['col-auto success-text ml-0 p-2',
                            {'animated pulse-bg infinite': otherTag.length,}]"
                    dark-waves
                    flat
                    icon="plus-circle"
                    icon-class="fs-15-mobileOnly"
                    size="sm"
                    title="Añadir etiqueta"
                    :disabled="(!tag || tag == 'otra') && !otherTag"
                    @click="addTag"
                  />
                </div>
              </div>
              <!-- Telefonos, emails, alergias,
                condiciones especiales y temporales -->
              <div class="d-flex flex-wrap">
                <!-- Campos para Teléfonos -->
                <div class="col-12 col-lg-6 px-0 px-lg-2">
                  <mdb-btn
                    ref="btnAddTelNumber"
                    flat
                    dark-waves
                    icon="plus-circle"
                    icon-class="success-text mr-1"
                    title="Agregar teléfonos"
                    class="fs-inherit tt-inherit flex-align-center mx-0 p-2"
                    type="button"
                    @click="addTelNumber"
                  >
                    Teléfonos
                  </mdb-btn>
                  <div
                    v-for="(tel, iTel) in personTels"
                    :key="'te'+iTel"
                    class="row align-items-center"
                  >
                    <div class="col-auto our-radio2">
                      <label title="Marcar como número primario">
                        <input
                          ref="changeTel"
                          v-model="tel.primary"
                          type="radio"
                          name="phone-primary"
                          :value="tel.primary == false
                            ? null
                            : true"
                          @change="telPrimary(tel)"
                        >
                        <span />
                      </label>
                    </div>
                    <div class="col pl-0">
                      <mdb-input
                        v-model="tel.number"
                        class="my-2 icon-input-group"
                        placeholder="Teléfono"
                        type="tel"
                        outline
                      >
                        <mdb-btn
                          ref="removeTel"
                          slot="append"
                          dark-waves
                          flat
                          group
                          icon="trash"
                          size="md"
                          type="button"
                          :disabled="personTels.length <= 1"
                          @click="removeTelNumber(iTel)"
                        />
                      </mdb-input>
                    </div>
                  </div>
                </div>
                <!-- Campos para Emails -->
                <div class="col-12 col-lg-6 px-0 px-lg-2">
                  <mdb-btn
                    ref="addEmail"
                    flat
                    dark-waves
                    icon="plus-circle"
                    icon-class="success-text mr-1"
                    title="Agregar emails"
                    class="fs-inherit tt-inherit flex-align-center mx-0 p-2"
                    type="button"
                    @click="addEmail"
                  >
                    Emails
                  </mdb-btn>
                  <div
                    v-for="(email, iEmail) in personEmails"
                    :key="'e'+iEmail"
                    class="row align-items-center"
                  >
                    <div class="col-auto our-radio2">
                      <label title="Marcar como email primario">
                        <input
                          ref="emailPrimary"
                          v-model="email.primary"
                          type="radio"
                          name="email-primary"
                          :value="email.primary == false
                            ? null
                            : true"
                          @change="emailPrimary(email)"
                        >
                        <span />
                      </label>
                    </div>
                    <div class="col pl-0">
                      <mdb-input
                        v-model="email.email"
                        class="my-2 icon-input-group"
                        placeholder="Email"
                        type="email"
                        outline
                      >
                        <mdb-btn
                          ref="removeEmail"
                          slot="append"
                          dark-waves
                          flat
                          group
                          icon="trash"
                          size="md"
                          type="button"
                          :disabled="personEmails.length <= 1"
                          @click="removeEmail(iEmail)"
                        />
                      </mdb-input>
                    </div>
                  </div>
                </div>
                <!-- Campos para Alergias -->
                <div class="col-12 col-lg-6 px-0 px-lg-2">
                  <mdb-btn
                    ref="addAllergy"
                    flat
                    dark-waves
                    icon="plus-circle"
                    icon-class="success-text mr-1"
                    title="Agregar alergias"
                    class="fs-inherit tt-inherit flex-align-center mx-0 p-2"
                    type="button"
                    @click="addAllergy"
                  >
                    Alergias
                  </mdb-btn>
                  <div
                    v-for="(alergia, iAlegia) in personAllergies"
                    :key="'al'+iAlegia"
                    class="row"
                  >
                    <div class="col-12">
                      <mdb-input
                        v-model="alergia.allergenic.name"
                        class="my-2 icon-input-group"
                        placeholder="Alergia"
                        outline
                      >
                        <mdb-btn
                          ref="removeAllergy"
                          slot="append"
                          dark-waves
                          flat
                          group
                          icon="trash"
                          icon-class="fa-lg"
                          size="md"
                          @click="removeAllergy(iAlegia)"
                        />
                      </mdb-input>
                    </div>
                  </div>
                </div>
                <!-- Campos para Condiciones Especiales -->
                <div class="col-12 col-lg-6 px-0 px-lg-2">
                  <mdb-btn
                    ref="addConditionEspecial"
                    flat
                    dark-waves
                    icon="plus-circle"
                    icon-class="success-text mr-1"
                    title="Agregar condiciones especiales"
                    class="fs-inherit tt-inherit flex-align-center mx-0 p-2"
                    type="button"
                    @click="addConditionEspecial"
                  >
                    Condiciones especiales
                  </mdb-btn>
                  <div
                    v-for="(condicionEs, iEs) in personConditionsEspecials"
                    :key="`iEs-${iEs}`"
                    class="row"
                  >
                    <div
                      v-if="condicionEs.type == 'Especial'"
                      class="col-12"
                    >
                      <mdb-input
                        v-model="condicionEs.name"
                        class="my-2 icon-input-group"
                        placeholder="Condición especial"
                        outline
                      >
                        <mdb-btn
                          ref="removeConditionEspecial"
                          slot="append"
                          dark-waves
                          flat
                          group
                          icon="trash"
                          icon-class="fa-lg"
                          size="md"
                          @click="removeConditionEspecial(iEs)"
                        />
                      </mdb-input>
                    </div>
                  </div>
                </div>
                <!-- Campos para Condiciones Temporales -->
                <div class="col-12 px-0 px-lg-2">
                  <mdb-btn
                    ref="addConditionTemporal"
                    flat
                    dark-waves
                    icon="plus-circle"
                    icon-class="success-text mr-1"
                    title="Agregar condiciones temporales"
                    class="fs-inherit tt-inherit flex-align-center mx-0 p-2"
                    type="button"
                    @click="addConditionTemporal"
                  >
                    Condiciones temporales
                  </mdb-btn>
                  <div
                    v-for="(condicionTemp, iTemp) in personConditionsTemporals"
                    :key="`iTemp${iTemp}`"
                  >
                    <div
                      v-if="condicionTemp.type == 'Temporal'"
                      class="row align-items-center"
                    >
                      <div class="col pr-0">
                        <div class="row">
                          <div class="col-12">
                            <mdb-input
                              v-model="condicionTemp.name"
                              class="my-1"
                              placeholder="Condición temporal"
                              outline
                            />
                          </div>
                          <div class="col-6">
                            <mdb-input
                              v-model="condicionTemp.start"
                              type="date"
                              label="Desde"
                              class="my-1 outline-date"
                              outline
                            />
                          </div>
                          <div class="col-6">
                            <mdb-input
                              v-model="condicionTemp.end"
                              type="date"
                              label="Hasta"
                              class="my-1 outline-date"
                              outline
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-auto">
                        <mdb-btn
                          ref="removeConditionTemporal"
                          class="m-0 p-2"
                          dark-waves
                          flat
                          icon="trash"
                          icon-class="fa-lg"
                          size="md"
                          @click="removeConditionTemporal(iTemp)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <!-- Formulario citas
            Mostrar si se selecciona una opción -->
          <fieldset
            v-if="step === 'formularioCitas'"
            key="formulario-citas"
          >
            <FormularioCitas
              ref="formularioCitas"
              :schedule="workPlace[0].schedule"
              :create-appointment="true"
              :services-offered="workPlace[0].servicesOffered"
              :appointment-reasons="appointmentReasons"
              :validate-value="validateValue.paraCita"
              enable-all-week
              @saveAppointmenDate="appointment.date=$event;
                                   paraCita.date=$event"
              @saveSchedule="paraCita.schedule=$event"
              @saveTime="paraCita.time=$event"
              @saveServiceId="paraCita.servicioId=$event"
              @addReason="addReason"
              @removeReason="removeReason($event)"
              @enableAllWeek="enableWeek = $event"
            />
          </fieldset>
          <!-- Formulario por Nombre -->
          <fieldset
            v-if="step === 'formularioNombre'"
            key="formulario-nombre"
          >
            <div
              v-if="optionSelected == 'name'"
              class="row"
            >
              <header class="col-12 font-weight-bold">
                Nombre del paciente
              </header>
              <div class="col-12 icon-inner-input">
                <mdb-input
                  ref="keyup"
                  v-model="patientNameSearch"
                  :class="['my-2', {'class-valid':
                             validateValue.patientNameSearch == 'valid'},
                           {'class-invalid':
                             validateValue.patientNameSearch == 'invalid'}]"
                  outline
                />
                <font-awesome-icon
                  v-if="$apolloData.queries.buscadorPacientes.loading"
                  :icon="['fas', 'circle-notch']"
                  class="fa-spin"
                />
              </div>
            </div>
            <!-- Resultado de la búsqueda -->
            <div v-if="optionSelected == 'name' && pacientes.length">
              <header class="text-center font-weight-bold my-2">
                Resultado de la búsqueda
              </header>
              <ul class="list-unstyled">
                <li
                  v-for="paciente in pacientes"
                  :key="paciente.id"
                  class="our-radio2 mb-3"
                >
                  <label class="row align-items-sm-center">
                    <input
                      ref="resultPerson"
                      v-model="paraCita.paciente"
                      :value="paciente"
                      type="radio"
                      name="resultado-persona"
                    >
                    <span class="col-auto pr-0" />
                    <div class="col pl-0">
                      <div class="row">
                        <p class="mb-1 col-12 text-center">
                          {{ paciente.fname }} {{ paciente.mname }}
                          {{ paciente.lname }} {{ paciente.llname }}
                          <font-awesome-icon
                            :icon="['fas', genderIcons[paciente.gender]]"
                            size="lg"
                            :class="['mr-1 d-inline-block float-right',
                                     genderIconColors[paciente.gender]]"
                            :title="paciente.gender"
                          />
                        </p>
                        <p class="mb-1 col-12 col-sm-4 text-center">
                          {{ identificationTypes[paciente.identificationType] }}
                          <small class="d-block text-muted">
                            Tipo de identificación
                          </small>
                        </p>
                        <p class="mb-1 col-12 col-sm-4 text-center">
                          {{ paciente.identification }}
                          <small class="d-block text-muted">
                            Identificación
                          </small>
                        </p>
                        <p
                          class="mb-1 col-12 col-sm-4 text-center"
                        >
                          {{ paciente.born.formatted }}
                          <small class="d-block text-muted">
                            F. Nacimiento
                          </small>
                        </p>
                      </div>
                    </div>
                  </label>
                </li>
              </ul>
            </div>
            <div v-if="optionSelected == 'name' && pacientesNoEncontrados">
              <div class="d-flex flex-column align-items-center my-2">
                <font-awesome-icon
                  :icon="['fas', 'triangle-exclamation']"
                  size="3x"
                  class="text-muted mb-3"
                />
                <p class="text-muted text-center h1-responsive px-3">
                  Pacientes no encontrados
                </p>
              </div>
            </div>
          </fieldset>
        </transition>
        <div class="modal-footer-btn">
          <div
            v-if="step !== 'consultorio' && step !== 'Agregar/Crear'"
            class="col"
          >
            <mdb-btn
              ref="btnShowAddAppointment"
              flat
              dark-waves
              icon="angle-left"
              class="d-block flat-bordered mx-auto"
              @click="stepper(false)"
            >
              Atrás
            </mdb-btn>
          </div>
          <div
            v-if="step !== 'formularioCitas'"
            class="col"
          >
            <mdb-btn
              color="success-base"
              class="d-block mx-auto"
              @click="stepper(true)"
            >
              Siguiente
              <font-awesome-icon icon="angle-right" />
            </mdb-btn>
          </div>
          <BtnLoader
            v-if="step == 'formularioCitas'"
            ref="appointmentCreate"
            :btn-disabled="btnDisabled"
            btn-title="Añadir"
            @click="appointmentCreate"
          />
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <!-- Modal de confirmación -->
    <mdb-modal
      ref="showConfirmationEmail"
      centered
      elegant
      scrollable
      :show="showConfirmationEmail"
      @close="showConfirmationEmail = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          Confirmación de email
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <header
          class="h3-responsive warning-text text-center
            d-flex flex-column align-items-center mb-3"
        >
          <font-awesome-icon
            :icon="['fas', 'triangle-exclamation']"
            size="3x"
            class="animated pulse infinite mb-2"
          />
          ¡Advertencia!
        </header>
        <p class="text-center mb-0">
          Es importante agregar un correo electrónico al paciente
          para enviar notificaciones de la cita.
          ¿Quieres continuar sin agregarlo?
        </p>
        <div class="modal-footer-btn large">
          <mdb-btn
            ref="btnEscribirCorreo"
            class="flex-align-center flat-bordered"
            flat
            dark-waves
            icon="arrow-left"
            icon-class="mr-1"
            @click="showConfirmationEmail = false"
          >
            Escribir correo
          </mdb-btn>
          <BtnLoader
            ref="ConfirmarCorreo"
            :btn-disabled="btnDisabled"
            btn-title="Confirmar"
            @click="showConfirmationEmail = false;
                    confirmacionEmail = true;stepper(true)"
          />
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <AlertaMsj :alerta-msj="alertaMsj" />
  </div>
</template>
<script>
import AlertaMsj from '@/components/AlertaMsj.vue';
import BtnLoader from '@/components/customButtons/BtnLoader.vue';
import gql from 'graphql-tag';
import {v4 as uuidv4} from 'uuid';
import {todayDate, calculateAge, appointmentSchema, personSchema, emailSchema,
  validHourInRange, validHourAtStart, validateIdentification,
  parentSchema, capitalize, capitalizeAll} from '@/utils/validations.js';
import {mdbBtn, mdbCard, mdbCardBody, mdbCardTitle, mdbCardText,
  mdbInput, mdbModal, mdbModalHeader, mdbModalTitle,
  mdbModalBody, mdbBadge} from 'mdbvue';
import SelectIdentificationType, {identificationTypes}
  from '@/components/SelectIdentificationType.vue';
import SelectGender, {genderIcons, genderIconColors}
  from '@/components/customFields/SelectGender.vue';
import SelectBloodType
  from '@/components/customFields/SelectBloodType.vue';
import SelectKinship
  from '@/components/customFields/SelectKinship.vue';
import appointmentCreateGql from '@/graphql/appointmentCreate.gql';
import appointmentMoveDateGql from '@/graphql/appointmentMoveDate.gql';
import patientsGql from '@/graphql/patients.gql';
import workPlaceGql from '@/graphql/workPlace.gql';
import personCreateGql from '@/graphql/personCreate.gql';
import {getPersonId} from '@/utils/authdecode.js';
import patientByIdentificationGql from '@/graphql/patientByIdentification.gql';
import doctorWorkPlacesGql from '@/graphql/doctorWorkPlaces.gql';
import {tagsDefault} from '@/constantes/tagsDefault.js';
import FormularioCitas
  from '@/components/FormularioCitas.vue';
export default {
  name: 'AgregarPaciente',
  components: {
    AlertaMsj,
    BtnLoader,
    mdbBtn,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbBadge,
    SelectIdentificationType,
    SelectGender,
    SelectBloodType,
    SelectKinship,
    FormularioCitas,
  },
  props: {
    idWorkPlace: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tagsDefault,
      alertaMsj: {
        content: '',
      },
      fecha: todayDate(),
      showAddAppointment: false,
      optionSelected: '', // Usada en el modal para agregar paciente
      childSelected: false, // Paciente menor ID en agregar paciente
      tagsAvailables: [],
      tag: '',
      newTag: false,
      otherTag: '',
      person: {
        identificationType: '',
        identification: '',
        gender: '',
        born: '',
        bloodType: '',
      },
      parent: {
        identificationType: '',
        identification: '',
        gender: '',
        born: '',
        bloodType: '',
        kinship: '',
      },
      validateValue: {
        identification: '',
        identificationType: '',
        paraCita: {
          servicioId: '',
          date: '',
          schedule: '',
          time: '',
          appointmentReason: '',
        },
        parent: {
          fname: '',
          lname: '',
          gender: '',
          born: '',
          kinship: '',
        },
      },
      identificationType: {},
      childNumber: '',
      personTels: [
        {
          number: '',
          country: '',
          primary: true,
        },
      ],
      personEmails: [
        {
          email: '',
          primary: true,
        },
      ],
      personAllergies: [],
      personConditionsEspecials: [],
      personConditionsTemporals: [],
      appointmentReasons: [
        {
          name: '',
        },
      ],
      lugares: [],
      paraCita: {
        paciente: {
          id: '',
        },
        servicioId: '',
        date: todayDate(),
      },
      enableWeek: false,
      patientNameSearch: '', // Variable para buscar persona en agregar paciente
      pacientes: [],
      pacientesNoEncontrados: false,
      identificationTypes, // Para mostrar el tipo de identificación
      genderIcons, genderIconColors, // Para mostrar icon y color de gender
      btnDisabled: false,
      showConfirmationEmail: false, // Abre modal de confirmación
      confirmacionEmail: false,
      appointment: {},
      buscarAdulto: {},
      buscarMenor: {},
      workPlaceId: this.idWorkPlace,
      step: 'consultorio',
    };
  },
  computed: {
    workPlace() {
      return this.lugares.filter((lug) => lug.id === this.workPlaceId);
    },
  },
  watch: {
    showAddAppointment: function name() {
      if (this.showAddAppointment && !this.isOnline) {
        this.optionSelected = 'newpatient';
      } /* else this.optionSelected = ''; */
    },
    isOnline: function() {
      if (this.isOnline) {
        this.alertaMsj = {
          content: 'Estamos en línea nuevamente',
          type: 'success',
        };
      } else {
        this.alertaMsj = {
          content: 'Sin conexión a Internet, es importante estar en línea para '
          + 'la sincronización de los datos',
          type: 'warning',
        };
      }
    },
  },
  mounted() {
    this.stepInicial();
  },
  methods: {
    stepInicial() {
      if (this.idWorkPlace.length) {
        this.step = 'Agregar/Crear';
      } else {
        this.step = 'consultorio';
        this.optionSelected = 'newpatient';
      }
    },
    stepper(direction) {
      if (direction) {
        // Agregar paciente a la cita o crear nuevo
        if (this.step == 'Agregar/Crear') {
          if (!this.optionSelected) {
            return this.alertaMsj = {
              content: 'Elige una opción para crear una cita',
              type: 'warning',
            };
          } else {
            return this.optionSelected == 'name'
              ? this.step = 'formularioNombre'
              : this.step = 'NuevoPaciente';
          }
        }

        // si no hay consultorio previamente seleccionado,
        // se verifica que se selecciono uno
        // para crear el paciente
        if (this.step == 'consultorio') {
          if (!this.workPlace.length) {
            return this.alertaMsj = {
              content: 'Debes seleccionar un consultorio para continuar',
              type: 'warning',
            };
          } else {
            return this.step = 'NuevoPaciente';
          }
        }
        // Valida que se haya seleccionado un paciente en la busqueda por nombre
        if (this.step == 'formularioNombre') {
          if (!this.paraCita.paciente.id && this.optionSelected == 'name') {
            this.validateValue.patientNameSearch = this.patientNameSearch
              ? 'valid'
              : 'invalid';
            this.validateValue.patientNameSearch = 'invalid';
            return this.alertaMsj = {
              content: 'Busque y seleccione un paciente para crear cita',
              type: 'warning',
            };
          } else {
            return this.step = 'formularioCitas';
          }
        }

        // Validando this.person
        if (this.step == 'NuevoPaciente') {
          this.validateValue = {
            fname: this.person.fname
              ? 'valid'
              : 'invalid',
            lname: this.person.lname
              ? 'valid'
              : 'invalid',
            gender: this.person.gender
              ? 'valid'
              : 'invalid',
            born: this.person.born
              ? 'valid'
              : 'invalid',
            identificationType: this.person.identificationType
              ? 'valid'
              : 'invalid',
            identification: this.person.identification
              ? 'valid'
              : 'invalid',
            paraCita: {},
            parent: {
              fname: '',
              lname: '',
              gender: '',
              born: '',
              kinship: '',
            },
          };
          // Validando campos childNumber y this.parent
          if (this.childSelected) {
            this.validateValue.childNumber = this.childNumber
              ? 'valid'
              : 'invalid';
            // Validando this.parent
            this.validateValue.parent = {
              fname: this.parent.fname
                ? 'valid'
                : 'invalid',
              lname: this.parent.lname
                ? 'valid'
                : 'invalid',
              gender: this.parent.gender
                ? 'valid'
                : 'invalid',
              born: this.parent.born
                ? 'valid'
                : 'invalid',
              kinship: this.parent.kinship
                ? 'valid'
                : 'invalid',
            };
          }
          if (!this.person.identificationType
            || !this.person.identification) {
            return this.alertaMsj = {
              content: 'Completa la identificación y el tipo',
              type: 'warning',
            };
          }
          if (!validateIdentification(
              this.identificationType,
              this.person.identification)
            || !this.identificationType.value
          ) {
            this.validateValue.identificationType = 'valid';
            this.validateValue.identification = 'invalid';
            return this.alertaMsj = {
              content: this.identificationType.mensaje,
              type: 'warning',
            };
          }
          if (this.childSelected) {
            this.parent.identificationType = this.person.identificationType;
            this.parent.identification = this.person.identification;

            if (!(parentSchema.isValidSync(this.parent))) {
              parentSchema.validate(this.parent)
                  .catch((error) => {
                    let campo;
                    switch (error.path) {
                      case 'fname':
                        campo = 'Primer nombre';
                        this.validateValue.parent.fname = 'invalid';
                        break;
                      case 'lname':
                        campo = 'Primer apellido';
                        this.validateValue.parent.lname = 'invalid';
                        break;
                      case 'gender':
                        campo = 'Género';
                        this.validateValue.parent.gender = 'invalid';
                        break;
                      case 'born':
                        campo = '';
                        this.validateValue.parent.born = 'invalid';
                        break;
                    }
                    return this.alertaMsj = {
                      content: error.errors[0].key
                        ? `Representante: ${campo} ${error.errors[0].key}`
                        : `Representante: ${error.errors[0]}`,
                      type: 'warning',
                    };
                  });
              return;
            }

            // Estandarizando nombres ingresados de Parent
            this.parent.fname = capitalizeAll(this.parent.fname.trim());
            if (this.parent.mname) {
              this.parent.mname = capitalizeAll(this.parent.mname.trim());
            }
            this.parent.lname = capitalizeAll(this.parent.lname.trim());
            if (this.parent.llname) {
              this.parent.llname = capitalizeAll(this.parent.llname.trim());
            }

            if (!this.parent.kinship) {
              return this.alertaMsj = {
                content: 'Representante: Debe seleccionar un'
                  + ' parentesco con el menor',
                type: 'warning',
              };
            }
            if (!this.childNumber) {
              return this.alertaMsj = {
                content: 'Menor: Debe indicar el número de'
                  + ' hijo que corresponda',
                type: 'warning',
              };
            }
            if (isNaN(this.childNumber)
              || Math.floor(this.childNumber) != this.childNumber) {
              this.validateValue.childNumber = 'invalid';
              return this.alertaMsj = {
                content: 'Menor: N° de hijo debe ser numérico entero',
                type: 'warning',
              };
            }
          }
          if (!this.person.born) {
            return this.alertaMsj = {
              content: 'Ingresa la fecha de nacimiento, es requerido',
              type: 'warning',
            };
          }
          if (!(personSchema.isValidSync(this.person))) {
            personSchema.validate(this.person)
                .catch((error) => {
                  let campo;
                  switch (error.path) {
                    case 'fname':
                      campo = 'Primer nombre';
                      this.validateValue.fname = 'invalid';
                      break;
                    case 'lname':
                      campo = 'Primer apellido';
                      this.validateValue.lname = 'invalid';
                      break;
                    case 'gender':
                      campo = 'Género';
                      this.validateValue.gender = 'invalid';
                      break;
                    case 'born':
                      campo = '';
                      this.validateValue.born = 'invalid';
                      break;
                  }
                  let menor = '';
                  if (this.childSelected) menor = 'Menor: ';
                  return this.alertaMsj = {
                    content: error.errors[0].key
                      ? `${menor}${campo} ${error.errors[0].key}`
                      : `${menor}${error.errors[0]}`,
                    type: 'warning',
                  };
                });
            return;
          }

          // Estandarizando nombres ingresados de Person
          this.person.fname = capitalizeAll(this.person.fname.trim());
          if (this.person.mname) {
            this.person.mname = capitalizeAll(this.person.mname.trim());
          }
          this.person.lname = capitalizeAll(this.person.lname.trim());
          if (this.person.llname) {
            this.person.llname = capitalizeAll(this.person.llname.trim());
          }

          // Validacion de emails
          let hayEmailPrimario = 0;
          let hayEmailInvalido = 0;
          this.personEmails.filter((e) => {
            if (emailSchema.isValidSync(e)) {
              if (e.primary) hayEmailPrimario++;
              return e;
            } else {
              hayEmailInvalido++;
            }
          });

          if (!this.confirmacionEmail && this.personEmails.length == 1
              && this.personEmails[0].email == '') {
            return this.showConfirmationEmail = true;
          }
          if (!(this.confirmacionEmail && this.personEmails.length == 1
              && this.personEmails[0].email == '')) {
            let content = '';
            if (hayEmailPrimario !== 1) {
              content = 'Debe seleccionar 1 email principal';
            }
            if (hayEmailInvalido) {
              content = `El email ${hayEmailInvalido} es inválido`;
            }
            if (content !== '') {
              return this.alertaMsj = {
                content,
                type: 'warning',
              };
            }
          }

          // Validacion de teléfonos
          let hayTelPrimario = 0;
          this.personTels.filter((t) => {
            if (t.number) {
              if (t.primary) hayTelPrimario++;
              return t;
            }
          });
          if (!(this.personTels.length == 1
            && this.personTels[0].number == '')) {
            let content = '';
            if (hayTelPrimario != 1) {
              content = 'Debe seleccionar 1 teléfono principal';
            }
            if (content !== '') {
              return this.alertaMsj = {
                content,
                type: 'warning',
              };
            }
          }
          return this.step = 'formularioCitas';
        }

        return this.alertaMsj = {
          content: 'Completa los datos solicitados',
          type: 'warning',
        };
      } else {
        if (this.step == 'formularioCitas') {
          return this.optionSelected == 'name'
            ? this.step = 'formularioNombre'
            : this.step = 'NuevoPaciente';
        }
        if (this.idWorkPlace.length) {
          return this.step = 'Agregar/Crear';
        } else {
          return this.step = 'consultorio';
        }
      }
    },
    async appointmentCreate() {
      // Validando this.paraCita
      this.validateValue.paraCita = {
        date: this.appointment.date
          ? 'valid'
          : 'invalid',
        schedule: this.paraCita.schedule
          ? 'valid'
          : 'invalid',
        time: this.paraCita.time
          ? 'valid'
          : 'invalid',
      };
      if (!(await appointmentSchema.isValid(this.appointment))) {
        this.validateValue.paraCita.date = 'invalid';
        return this.alertaMsj = {
          content: 'Coloca una fecha correcta para la cita',
          type: 'warning',
        };
      }

      if (!this.paraCita.schedule) {
        return this.alertaMsj = {
          content: 'Seleccione el horario',
          type: 'warning',
        };
      }
      if (!this.paraCita.time) {
        return this.alertaMsj = {
          content: 'Indique la hora de su preferencia',
          type: 'warning',
        };
      }
      if (!this.enableWeek
        && (!this.paraCita.time
            || !validHourAtStart(
                this.paraCita.time,
                this.paraCita.schedule.start,
            ))) {
        this.validateValue.paraCita.time = 'invalid';
        return this.alertaMsj = {
          content: 'Seleccione una hora dentro del rango del horario',
          type: 'warning',
        };
      }
      this.appointmentReasons = this.appointmentReasons.filter((reason) =>
        reason.name !== '');
      this.appointmentReasons.forEach((vr)=>{
        vr.name = capitalize(vr.name.trim());
      });

      // Preparar datos a ser enviados:
      if (this.optionSelected == 'newpatient') {
        const allergies = this.personAllergies.filter((a) => {
          return a.allergenic.name.length;
        });
        this.personAllergies = allergies;

        const conditionsE = this.personConditionsEspecials.filter((cE) =>
          cE.name);
        this.personConditionsEspecials = conditionsE;

        const conditionsT = this.personConditionsTemporals.filter((cT) =>
          cT.name && cT.start && cT.end);
        this.personConditionsTemporals = conditionsT;

        this.person.mname = this.person.mname
          ? this.person.mname
          : null;
        this.person.llname = this.person.llname
          ? this.person.llname
          : null;
        if (!this.person.bloodType || !this.person.bloodType.length) {
          delete this.person.bloodType;
        }

        let pacienteId = null;
        if (this.isOnline) {
          pacienteId = await this.personCreate();
        } else {
          this.personCreate();
        }
        if (this.isOnline && !pacienteId) {
          return this.alertaMsj = {
            content: 'Hubo un error creando al paciente',
            type: 'danger',
          };
        }
        this.paraCita.paciente = this.person;
        this.paraCita.paciente.id = pacienteId;
      } else if (this.optionSelected == 'name') {
        this.paraCita.paciente.born = this.paraCita.paciente.born.formatted;
        delete this.paraCita.paciente.__typename;
      }
      delete this.paraCita.paciente.counters;

      this.appointment.status = 'Booked';
      this.appointment.type = this.paraCita.date == todayDate()
        ? 'En sitio'
        : 'Previa cita';
      this.appointment.date = this.paraCita.date + 'T' + this.paraCita.time;
      delete this.paraCita.schedule.__typename;
      delete this.paraCita.schedule.day.__typename;
      const variablesApollo = {
        appointment: this.appointment,
        patient: this.paraCita.paciente,
        serviceId: this.paraCita.servicioId,
        workPlaceId: this.workPlaceId,
        firstContactFrom: this.tag,
        reasons: this.appointmentReasons,
        schedule: this.paraCita.schedule,
      };

      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${appointmentCreateGql}`,
        variables: variablesApollo,
        context: {serializationKey: 'PERSONS'},
        optimisticResponse: {
          __typename: 'Mutation',
          appointmentCreate: {
            id: uuidv4(),
            insolvencyStatus: '',
            ...variablesApollo.appointment,
            __typename: 'Appointment',
          },
        },
        update: (store, {data: {appointmentCreate}}) => {
          const paciente = JSON.parse(JSON.stringify(variablesApollo.patient));
          if (!paciente.born.formatted) {
            paciente.born = {
              formatted: paciente.born,
              year: paciente.born.substring(0, 4),
              month: paciente.born.substring(5, 7),
              day: paciente.born.substring(8, 10),
              __typename: '_Neo4jDate',
            };
          }
          paciente.age = calculateAge(paciente.born);
          paciente.__typename = 'Patient';
          const time = variablesApollo.appointment.date
              .substring(11, 16).split(':');
          const hour = time[0];
          const minute = time[1];
          appointmentCreate.type = variablesApollo.appointment.type;
          appointmentCreate.status = variablesApollo.appointment.status;
          appointmentCreate.person = paciente;
          appointmentCreate.encounter = null;
          appointmentCreate.insolvencyStatus = '';
          appointmentCreate.date = {
            formatted: variablesApollo.appointment.date,
            hour,
            minute,
            __typename: '_Neo4jDateTime',
          };
          appointmentCreate.reasons =
            (JSON.parse(JSON.stringify(variablesApollo.reasons))).map((r)=>{
              r.__typename = 'Reason';
              return r;
            });
          this.showAddAppointment = false;
          this.confirmacionEmail = false;
          this.btnDisabled = false;

          // Actualización del día del lugar de trabajo en caso
          // de que haya sido visitado previamente
          try {
            const data = store.readQuery({
              query: gql`${workPlaceGql}`,
              variables: {
                date: variablesApollo.appointment.date.substring(0, 10),
                personId: getPersonId(),
                workPlaceId: this.workPlaceId,
              },
            });
            data.Doctor[0].works_at[0].appointments.push(appointmentCreate);
            data.Doctor[0].works_at[0].schedule =
              data.Doctor[0].works_at[0].schedule.map((s) => {
                s.__typename = 'Schedule';
                s.day.__typename = 'Day';
                return s;
              });

            store.writeQuery({
              query: gql`${workPlaceGql}`,
              variables: {
                date: variablesApollo.appointment.date.substring(0, 10),
                personId: getPersonId(),
                workPlaceId: this.workPlaceId,
              },
              data,
            });
          } catch (error) {
            // 'Conéctate para sincronizar'
          }

          // Actualización de ficha del paciente en caso
          // de que haya sido visitado previamente
          // Comentado porque da error de invalidación y no sé por qué. RDM
          // try {
          //   const data = store.readQuery({
          //     query: gql`${patientGql}`,
          //     variables: {
          //       id: this.paraCita.paciente.id,
          //       first: 5,
          //     },
          //   });
          //   console.log(data);
          //   data.AppointmentWithMeBy.push(appointmentCreate);
          //   console.log(data.AppointmentWithMeBy);
          //   store.writeQuery({
          //     query: gql`${patientGql}`,
          //     variables: {
          //       id: this.paraCita.paciente.id,
          //       first: 5,
          //     },
          //     data,
          //   });
          // } catch (error) {
          //   console.warn(error);
          //   // 'Conéctate para sincronizar'
          // }

          this.clearAppointmentform();
        },
      }).then((res) => {
        this.appointment = {
          date: todayDate(),
        };
        this.alertaMsj = {
          content: 'Cita agregada',
          type: 'success',
        };
        this.showAddAppointment = false;
      }).catch((error) => {
        this.btnDisabled = false;
        return this.alertaMsj = {
          content: 'Hubo un error al crear cita del paciente',
          type: 'danger',
        };
      });
    },
    async personCreate() {
      const conditions =
        this.personConditionsEspecials.concat(this.personConditionsTemporals);
      const varsApollo = {
        person: this.person,
        tels: this.personTels,
        emails: this.personEmails,
        allergies: this.personAllergies,
        conditions,
      };
      if (this.childSelected) {
        if (!this.childNumber) return null;
        varsApollo.parent = {};
        varsApollo.person = {};
        Object.assign(varsApollo.parent, this.parent);
        Object.assign(varsApollo.person, this.person);
        varsApollo.person.identification += '-' + this.childNumber;
        varsApollo.person.childNumber = Number(this.childNumber);
        if (!this.parent.bloodType || !this.parent.bloodType.length) {
          delete varsApollo.parent.bloodType;
        }
      }
      if (!this.personTels[0].number) delete varsApollo.tels;
      if (!this.personEmails[0].email) delete varsApollo.emails;
      if (!this.personAllergies.length) delete varsApollo.allergies;
      if (!conditions.length) delete varsApollo.conditions;
      if (!varsApollo.person.bloodType || !varsApollo.person.bloodType.length) {
        delete varsApollo.person.bloodType;
      }
      this.btnDisabled = true;
      const pacienteId = await this.$apollo.mutate({
        mutation: gql`${personCreateGql}`,
        variables: varsApollo,
        context: {serializationKey: 'PERSONS'},
        optimisticResponse: {
          __typename: 'Mutation',
          personCreate: {
            id: uuidv4(),
            ...varsApollo.person,
            __typename: 'Person',
          },
        },
      }).then((res) => {
        this.btnDisabled = false;
        return res.data.personCreate.id;
      }).catch((error) => {
        this.btnDisabled = false;
        return null;
      });
      return pacienteId;
    },
    personIdentificationInitialValue() {
      if (!this.person.identificationType) return;
      switch (this.person.identificationType) {
        case 'ven_cedula':
          if (this.person.identification.length > 3
            && this.person.identification.includes('V-')) break;
          this.person.identification = 'V-';
          break;
        default:
          if (this.person.identification.length > 3
            && !this.person.identification.includes('V-')) break;
          this.person.identification = '';
      }
      this.$refs.inputIdentification.focus();
    },
    addTelNumber() {
      this.personTels.push({
        number: '',
        country: '',
        primary: false,
      });
    },
    removeTelNumber(i) {
      this.personTels.splice(i, 1);
    },
    telPrimary(tel) {
      this.personTels.forEach((tel) => {
        if (tel.primary == true) {
          tel.primary = false;
        }
      });
      tel.primary = true;
    },
    addEmail() {
      this.personEmails.push({email: '', primary: false});
    },
    removeEmail(i) {
      this.personEmails.splice(i, 1);
    },
    emailPrimary(e) {
      this.personEmails.forEach((email) => {
        if (email.primary == true) {
          email.primary = false;
        }
      });
      e.primary = true;
    },
    addAllergy() {
      this.personAllergies.push({allergenic: {name: ''}});
    },
    removeAllergy(i) {
      this.personAllergies.splice(i, 1);
    },
    addConditionEspecial() {
      this.personConditionsEspecials.push({name: '', type: 'Especial'});
    },
    removeConditionEspecial(i) {
      this.personConditionsEspecials.splice(i, 1);
    },
    addConditionTemporal() {
      this.personConditionsTemporals.push({
        name: '',
        start: '',
        end: '',
        type: 'Temporal',
      });
    },
    removeConditionTemporal(i) {
      this.personConditionsTemporals.splice(i, 1);
    },
    addReason() {
      this.appointmentReasons.push({name: ''});
    },
    removeReason(i) {
      this.appointmentReasons.splice(i, 1);
    },
    buscadorPacientes() {
      return {
        query: gql`${patientsGql}`,
        variables() {
          return {
            fullname: this.patientNameSearch,
          };
        },
        update: (data) => data.patients,
        skip: () => this.patientNameSearch.length > 1 ? false : true,
        debounce: 1300,
        result: ({data}) => {
          if (
            !data
            || !data.patients
          ) return;
          this.pacientesNoEncontrados = data.patients.length
            ? false
            : true;
          this.pacientes = data.patients;
          this.btnDisabled = false;
        },
      };
    },
    appointmentMoveDate() {
      const appointment = {
        id: this.citaTemp.id,
        date: this.paraCita.date,
        status: this.pacienteConfirmarFecha ? 'Proposed' : 'Booked',
      };
      if (!(appointmentSchema.isValidSync(appointment))) {
        return this.alertaMsj = {
          content: 'Revisa la fecha, horario y hora de la cita',
          type: 'warning',
        };
      }
      appointment.date = appointment.date + 'T' + this.paraCita.time;
      if (!this.paraCita.schedule) {
        return this.alertaMsj = {
          content: 'Seleccione el horario',
          type: 'warning',
        };
      }
      if (!this.paraCita.time) {
        return this.alertaMsj = {
          content: 'Indique la hora de su preferencia',
          type: 'warning',
        };
      }
      if (!this.paraCita.time
        || !validHourInRange(
            this.paraCita.time,
            this.paraCita.schedule.start,
            this.paraCita.schedule.end,
        )) {
        return this.alertaMsj = {
          content: 'Seleccione una hora dentro del rango del horario',
          type: 'warning',
        };
      }
      delete this.paraCita.schedule.__typename;
      delete this.paraCita.schedule.day.__typename;
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${appointmentMoveDateGql}`,
        variables: {
          appointment,
          schedule: this.paraCita.schedule,
        },
      }).then((res) => {
        switch (res.data.appointmentMoveDate.msg) {
          case 'success': {
            if (this.paraCita.date != this.fecha) {
              this.citasPendientes.splice(
                  this.citasPendientes.indexOf(this.citaTemp), 1);
            }
            this.paraCita.date = todayDate();
            delete this.paraCita.schedule;
            delete this.paraCita.time;
            this.showChangeDateForm = false;
            this.pacienteConfirmarFecha = false;
            this.alertaMsj = {
              content: 'Cita cambiada',
              type: 'success',
            };
            break;
          }
          default: {
            this.alertaMsj = {
              content: 'No se cambió la fecha.'
              + ' ¿Seguro que es diferente a la que ya tiene?',
              type: 'warning',
            };
            break;
          }
        }
        this.btnDisabled = false;
      }).catch((error) => {
        this.btnDisabled = false;
        return this.alertaMsj = {
          content: 'Hubo un error al cambiar la cita del paciente',
          type: 'danger',
        };
      });
    },
    clearAppointmentform() {
      this.appointmentReasons = [{name: ''}];
      this.person = {
        identificationType: '',
        identification: '',
        gender: '',
        born: '',
        bloodType: '',
      };
      this.parent = {
        identificationType: '',
        identification: '',
        gender: '',
        born: '',
        bloodType: '',
        kinship: '',
      };
      this.personTels = [{number: '', country: '', primary: true}];
      this.personEmails = [{email: '', primary: true}];
      this.personAllergies = [];
      this.personConditionsEspecials = [];
      this.personConditionsTemporals = [];
      this.paraCita = {
        paciente: {
          id: '',
        },
        date: todayDate(),
      };
      this.validateValue = {
        identification: '',
        identificationType: '',
        paraCita: {
          servicioId: '',
          date: '',
          schedule: '',
          time: '',
          appointmentReason: '',
        },
        parent: {
          fname: '',
          lname: '',
          gender: '',
          born: '',
          kinship: '',
        },
      };
      this.childNumber = '';
      this.childSelected = '';
      this.optionSelected = '';
      this.patientNameSearch = '';
      this.pacientes = [];
      this.tag = '';
      this.workPlaceId = this.idWorkPlace,
      this.stepInicial();
    },
    addTag() {
      if (this.tag == 'otra' && !this.otherTag) return;
      this.tag = capitalize(this.otherTag.trim());
      this.otherTag = '';
    },
  },
  apollo: {
    lugares() {
      return {
        query: gql`${doctorWorkPlacesGql}`,
        variables: {
          id: getPersonId(),
        },
        update: (data) => {
          const contactFromTgs = data.Doctor[0].contactFromTgs;
          const tagsSelect = contactFromTgs.concat(tagsDefault);
          this.tagsAvailables = tagsSelect.filter((e) => e != null);
          return data.Doctor[0].works_at
              .filter((w) => w.status !== 'Borrado');
        },
      };
    },
    buscadorPacientes() {
      return {
        query: gql`${patientsGql}`,
        variables() {
          return {
            fullname: this.patientNameSearch,
          };
        },
        update: (data) => data.patients,
        skip: () => this.patientNameSearch.length > 1 ? false : true,
        debounce: 1300,
        result: ({data}) => {
          if (
            !data
            || !data.patients
          ) return;
          this.pacientesNoEncontrados = data.patients.length
            ? false
            : true;
          this.pacientes = data.patients;
          this.btnDisabled = false;
        },
      };
    },
    buscarAdulto() {
      return {
        query: gql`${patientByIdentificationGql}`,
        variables() {
          return {
            identification: this.person.identification,
            identificationType: this.person.identificationType,
          };
        },
        update: (data) => data.patientByIdentification,
        skip: () => !this.isOnline || (this.person.identification.length > 2
          ? false : true),
        debounce: 1500,
        result: ({data}) => {
          if (
            !data
            || !data.patientByIdentification
            || !data.patientByIdentification.born
          ) return;
          const person = {...data.patientByIdentification};
          person.born =
            data.patientByIdentification.born.formatted;
          delete person.__typename;
          if (this.childSelected) {
            Object.assign(this.parent, {...person});
          } else {
            Object.assign(this.person, {...person});
          }
        },
      };
    },
    buscarMenor() {
      return {
        query: gql`${patientByIdentificationGql}`,
        variables() {
          return {
            identification: this.person.identification + '-' + this.childNumber,
            identificationType: this.person.identificationType,
          };
        },
        update: (data) => data.patientByIdentification,
        skip: () => this.person.identification.length > 3
          && this.childNumber > 0
          && this.childSelected
          ? false : true,
        debounce: 1500,
        result: ({data}) => {
          if (
            !data
            || !data.patientByIdentification
            || !data.patientByIdentification.born
          ) return;
          const person = {...data.patientByIdentification};
          person.born =
            data.patientByIdentification.born.formatted;
          delete person.__typename;
          Object.assign(this.person, {...person});
        },
      };
    },
  },
};
</script>
<style lang="scss">

.agregar-paciente {
  .row-gap {
    row-gap: .5rem;
  }
  .overflowX-hidden {
    overflow-x: hidden;
  }
}
</style>
