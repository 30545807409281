import {getRole} from '@/utils/authdecode.js';
import {object, string, date, array as arraySchema,
  boolean, number, setLocale} from 'yup';

setLocale({
  mixed: {
    default: 'Campo inválido',
  },
  number: {
    max: ({max}) => ({
      key: 'debe tener un valor máximo de ${max}',
      values: {max},
    }),
    min: ({min}) => ({
      key: `debe tener un valor mínimo de ${min}`,
      values: {min},
    }),
  },
  string: {
    max: ({max}) => ({
      key: `debe tener como máximo ${max} caracteres`,
      values: {max},
    }),
    min: ({min}) => ({
      key: `debe tener como mínimo ${min} caracteres`,
      values: {min},
    }),
  },
});

/**
 * Esquemas de validación de entradas para formularios.
 * Ordenadas por categorías y alfabéticamente.
 * @returns {schema}
 */
/* Categoría: Entidades */
const parentSchema = object().shape({
  identification: string().required('Ingresa tu identificación, es requerida'),
  identificationType: string()
      .required('Selecciona un tipo de identificación, es requerido'),
  gender: string().required('Selecciona un género, es requerido'),
  llname: string().max(20).trim().ensure(),
  lname: string().min(2).max(20).trim()
      .required('Ingresa el primer apellido, es requerido'),
  mname: string().max(20).trim().ensure(),
  fname: string().min(2).max(20).trim()
      .required('Ingresa el primer nombre es requerido'),
});

const personSchema = object().shape({
  identification: string().required('Ingresa tu identificación, es requerida'),
  identificationType: string()
      .required('Selecciona un tipo de identificación, es requerido'),
  gender: string().required('Selecciona un género, es requerido'),
  llname: string().max(20).trim().ensure(),
  lname: string().min(2).max(20).trim()
      .required('Ingresa el primer apellido, es requerido'),
  mname: string().max(20).trim().ensure(),
  fname: string().min(2).max(20).trim()
      .required('Ingresa el primer nombre es requerido'),
  born: date('Y-m-d').required('Ingresa la fecha de nacimiento, es requerido')
      .max(todayDate(), 'La fecha de nacimiento debe ser hoy, o días/meses/años'
        + ' anteriores.'),
});

const doctorSchema = object(personSchema).shape({
  matricula: string().required(function() {
    throw new Error('Indicar la matrícula.');
  }).min(2).max(20).trim(),
});

const appointmentSchema = object().shape({
  date: date('Y-m-d').required('Coloca la fecha de la cita')
      .min(todayDate(), 'La fecha de la cita debe ser para hoy '
        +'ó días siguientes'),
});

const emailSchema = object().shape({
  email: string().required().email().trim(),
  primary: boolean(),
  status: string().trim(),
});

const diagnosisSchema = object().shape({
  conclusion: string().trim(),
  disease: object().shape({
    name: string().trim(),
  }),
});

const receipSchema = object().shape({
  medicine: object().shape({
    name: string().trim(),
    dose: string().trim(),
    presentation: string().trim(),
  }),
  custom: string().trim(),
  unit: string().trim(),
  quantity: number().integer().min(0),
  frecuency: number().integer().min(0),
  daysLong: number('a').integer('b').min(0),
  medicinesAlt: arraySchema().of(object().shape({
    unit: string().trim(),
    quantity: number().integer().min(0),
    frecuency: number().integer().min(0),
    daysLong: number('a').integer('b').min(0),
    medicine: object().shape({
      name: string().trim(),
      dose: string().trim(),
      presentation: string().trim(),
    }),
  })),
});

/* Categoría: Formatos */
/**
 * @return {Date} fecha actual
 */
function todayDate() {
  let fecha = new Date();
  const z = fecha.getTimezoneOffset();
  const v = fecha.valueOf();
  const r = v - z * 1000*60;
  fecha = new Date(r);
  return fecha.toISOString().split('T')[0];
}

/**
 * Calcular edad
 * @param {Object} born Objeto {year, month, day, formatted}
 * @return {String} Edad
 */
function calculateAge(born) {
  let b;
  let y;
  let m;
  let d;
  if (born.year && born.month && born.day) {
    y = born.year;
    m = born.month;
    d = born.day;
  } else if (born.formatted && born.formatted.split('-').length == 3) {
    b = born.formatted.split('-');
    y = b[0];
    m = b[1];
    d = b[2];
  } else {
    return 'N/A';
  }

  const today = todayDate().split('-');
  const ty = today[0];
  const tm = today[1];
  const td = today[2];

  const danos = ty - y;
  const dmeses = tm - m;
  const ddias = td - d;
  const edad = danos + (dmeses < 0 ? -1 : dmeses == 0
    ? (ddias < 0 ? -1 : 0) : 0);

  let sedad = edad+'a';
  if (edad < 6) {
    sedad += ', ' + (dmeses < 0 ? 12 + dmeses : tm - m) + 'm';
    if (edad < 2) {
      const ndias = {
        '1': 31,
        '2': y % 4 == 0 ? 29 : 28,
        '3': 31,
        '4': 30,
        '5': 31,
        '6': 30,
        '7': 31,
        '8': 31,
        '9': 30,
        '10': 31,
        '11': 30,
        '12': 31,
      };
      sedad += ', ' + (ddias < 0 ? ndias[m] + ddias : td - d) + 'd';
    }
  }
  return sedad;
}
/* Categoría rangos */
/**
 *
 * @param {String} start Hora en formato HH:mm
 * @param {String} end Hora en formato HH:mm
 * @return {Boolean} True si es valido, false si no lo es
 */
function validHoursRange(start, end) {
  start = start.split(':');
  end = end.split(':');
  return (start[0] > end[0] || (start[0] == end[0] && start[1] >= end[1]))
    ? false : true;
}
/**
 *
 * @param {String} hour Hora en formato HH:mm
 * @param {String} start Hora en formato HH:mm
 * @param {String} end Hora en formato HH:mm
 * @return {Boolean} True si es valido, false si no lo es
 */
function validHourInRange(hour, start, end) {
  hour = hour.split(':');
  start = start.split(':');
  end = end.split(':');
  const minHour = Number(hour[0] * 60 + hour[1]);
  const minStart = Number(start[0] * 60 + start[1]);
  const minEnd = Number(end[0] * 60 + end[1]);
  return (minHour < minStart || minHour > minEnd)
    ? false : true;
}

/**
 *
 * @param {String} hour Hora en formato HH:mm
 * @param {String} start Hora en formato HH:mm
 * @return {Boolean} True si es valido, false si no lo es
 */
function validHourAtStart(hour, start) {
  hour = hour.split(':');
  start = start.split(':');
  const minHour = Number(hour[0] * 60 + hour[1]);
  const minStart = Number(start[0] * 60 + start[1]);
  return (minHour < minStart)
    ? false : true;
}

/**
 *
 * @param {String} hour Hora en formato HH:mm
 * @param {String} end Hora en formato HH:mm
 * @return {Boolean} True si es valido, false si no lo es
 */
function validHourAtEnd(hour, end) {
  hour = hour.split(':');
  end = end.split(':');
  const minHour = Number(hour[0] * 60 + hour[1]);
  const minEnd = Number(end[0] * 60 + end[1]);
  return (minHour > minEnd)
    ? false : true;
}

/**
 * Debouncer para que una funcion se ejecute al cabo de b tiempo
 * @param {Function} a Funcion a ser llamada una vez se cumpla el timeout
 * @param {Int} b Tiempo a esperar por el timeout
 * @param {Boolean} c Indica si es inmediato o no
 * @return {Function} Funcion a ser ejecutada
 */
const debounce =
  (a, b=250, c)=>(...d)=>clearTimeout(c, c=setTimeout(()=>a(...d), b));

/**
 * Capitaliza la primera letra de una cadena string
 * @param {String} string Cadena de texto a capitalizar
 * @return {String}
 */
function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

/**
 * Capitaliza la primera letra de cada palabra de una cadena string
 * @param {String} string Cadena de texto a capitalizar
 * @return {String}
 */
function capitalizeAll(string) {
  if (string) {
    const textotoLowerCase = string.toLowerCase();
    const textoCapitalize =
      textotoLowerCase.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
    const textoSinEspacios = textoCapitalize.trim().replace(/\s+/g, ' ');
    string = textoSinEspacios;
    return string;
  }
}

/**
 * Capitaliza la primera letra de una cadena string sin
 * alterar el contenido restante de la cadena de texto
 * @param {String} string Cadena de texto a capitalizar
 * @return {String}
 */
function capitalizeFirstLetter(string) {
  if (string) {
    const textoSinEspacios = string.trim().replace(/\s+/g, ' ');
    string = textoSinEspacios.charAt(0).toUpperCase()
      + textoSinEspacios.slice(1);
    return string;
  }
}

/**
 * errorMessages Selecciona mensajes en base al error
 * @param {Error} error error recibido
 * @param {String} mensaje mensaje a mostrar por default
 * @param {String} tipo tipo de alerta a mostrar para el color
 * @return {Object}
 */
function errorMessages(error, mensaje, tipo) {
  if (error.networkError && (error.networkError.statusCode == 400
    || error.message === 'Network error: Failed to fetch')) {
    mensaje = window.navigator && !navigator.onLine
      ? 'Conéctate a internet para sincronizar'
      : 'Error de conexión. Verifica tu conexión e intenta de nuevo';
    tipo = 'danger';
  }
  if (error.graphQLErrors) {
    if (error.message.indexOf('You are not authorized') !== -1) {
      if (getRole() == 'DoctorSinValidar') {
        mensaje = 'Tu cuenta no ha sido validada. Te avisaremos por email '
        +'cuando lo hagamos para que puedas usar esta opción. '
        +'La seguridad de los pacientes es primordial para nosotros';
      } else {
        mensaje = 'Tu plan no incluye acceso a esta opción';
      }
    } else if (error.message.indexOf('Your token is expired') !== -1) {
      // if (getRole() == 'Doctor') {
      mensaje = 'Parece que olvidaste pagar el servicio de AMII. '
      +'Ve al menú de pagos para seguir disfrutando de las ventajas que '
      +'AMII tiene para ti';
      // }
    }
    tipo = 'warning';
  }
  return {
    content: mensaje,
    type: tipo || 'warning',
  };
}
/** Devuelve una fecha en formato legible es_VE
 * @param {String} string Fecha en cadena de texto
 * @return {String}
 */
function fechaEsVe(string) {
  if (!string) return '';
  let fecha = new Date(string);
  const z = fecha.getTimezoneOffset();
  const v = fecha.valueOf();
  const r = z * 1000*60 + v;
  fecha = new Date(r);
  const opt = {
    day: '2-digit', month: '2-digit', year: 'numeric',
    hour: 'numeric', minute: 'numeric',
  };
  if (string.indexOf('T') == -1 && string.indexOf(' ') == -1) {
    delete opt.hour;
    delete opt.minute;
  }
  return fecha.toLocaleDateString('es-VE', opt);
}

/**
 * Devuelve la fecha en formato DD-MM-YYYY
 * @param {String} date Fecha en formato YYYY-MM-DD
 * @return {Date}
 */
function reverseDate(date) {
  if (date) {
    return date.split('-').reverse().join('-');
  } else return '';
}

/**
 * Devuelve la fecha en formato DD-MM-YYYY HH:MM
 * @param {String} datetime Fecha en formato yyyy-mm-ddThh:mmZ
 * @return {Date}
 */
function formatDate(datetime) {
  if (datetime) {
    if (datetime.indexOf('T') === -1) {
      return datetime.split('-').reverse().join('-');
    } else {
      let [date] = datetime.split('T');
      date = reverseDate(date);
      return `${date}`;
    }
  } else return '';
}

/**
 * Devuelve un true si cumple con la validacion, caso contrario false
 * @param {String} identificationType Objeto con Expresion Regular a comparar
 * @param {String} identification Identificacion del Usuario
 * @return {Boolean}
 */
function validateIdentification(identificationType, identification) {
  const regExp = new RegExp(identificationType.regExp);
  return regExp.test(identification);
}

/**
 * Agrega acentos en el día de la semana
 * @param {String} day Día de la semana a verificar para añadir acento
 * @return {String}
 */
function accentInWeekDay(day) {
  if (!day) return '';
  switch (day) {
    case 'Miercoles':
      return 'Miércoles';
    case 'Sabado':
      return 'Sábado';
    default:
      return day;
  }
}

/**
 * Estructura el array de objetos en string legible de un encuentro
 * @param {Array} listToFormat array para formatear
 *  cita.encounter.symptoms|cita.reasons|cita.encounter.diagnosis
 * @return {String}
 */
function listEncounterDetails(listToFormat) {
  let list = '';
  switch (listToFormat[0].__typename) {
    case 'Reason':
    case 'Symptom':
      list = listToFormat.map((s) => {
        return s.name;
      });
      return list.join(', ');
    case 'Diagnosis':
      list = listToFormat.map((d) => {
        if (d.conclusion) return `${d.disease.name} (${d.conclusion})`;
        else return `${d.disease.name}`;
      });
      return list.join('; ');

    default:
      break;
  }
}

/**
 * encounterLimitAlert
 * @param {String} insolvencyCode en formato causa/encuentros extras/límite
 * @return {String}
 */
function encounterLimitAlert(insolvencyCode) {
  const inscyStatus = insolvencyCode.split('/');
  const plan = inscyStatus[0] === 'd'
    ? 'plan profesional'
    : 'plan de instituto';
  const sobreencuentros = inscyStatus[1];
  const limite = inscyStatus[2];
  const restantes = Number(limite) - Number(sobreencuentros);

  // const msg = 'Tienes '
  // + sobreencuentros + ' de un máximo de ' + limite
  // + ' historias desde que venció tu '
  // + plan + '. '
  const msg = 'Te quedan '
    + restantes + ' historias extras desde que venció tu '
    + plan + '. '
    + 'Permitimos historias extras para no causarte contratiempos'
    + ' imprevistos. '
    + 'Usa el menú de Pagos o contacta a soporte para seguir'
    + ' disfrutando de AMII sin límites. '
    + 'Nos complace trabajar para ti :)';
  return msg;
}

export {
  accentInWeekDay,
  arraySchema,
  calculateAge,
  capitalize,
  capitalizeAll,
  capitalizeFirstLetter,
  fechaEsVe,
  formatDate,
  debounce,
  doctorSchema,
  emailSchema,
  listEncounterDetails,
  parentSchema,
  personSchema,
  appointmentSchema,
  todayDate,
  validHoursRange,
  validHourInRange,
  validHourAtStart,
  validHourAtEnd,
  diagnosisSchema,
  receipSchema,
  errorMessages,
  validateIdentification,
  encounterLimitAlert,
};
