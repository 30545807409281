<template>
  <div class="mt-lg-3">
    <!-- Listado de consultorios -->
    <section class="consultorios">
      <div class="flex-align-center mb-2">
        <header class="col order-2 h3-responsive text-center">
          Lugares de servicio
        </header>
        <!-- Botón mostrar la papelera -->
        <mdb-btn
          v-if="lugaresBorrados.length"
          class="col-auto order-1 m-0 mr-n4 px-2 flex-align-center"
          flat
          dark-waves
          icon="trash"
          size="sm"
          title="Ver papelera"
          @click="showTrash = !showTrash"
        >
          <span class="d-none d-lg-inline fs-inherit">Ver papelera</span>
        </mdb-btn>
        <!-- Botón para agregar lugar -->
        <mdb-btn
          class="col-auto order-3 m-0 fixed-br-mobile"
          color="success-base"
          icon="plus"
          rounded
          @click="showFormInstitute = true; addInstitute()"
        >
          Agregar lugar
        </mdb-btn>
      </div>
      <div class="row justify-content-center">
        <div
          v-for="lugar in lugaresActivos"
          :key="lugar.id"
        >
          <!-- Card -->
          <div class="card bord-style institute m-2">
            <!-- v-if="lugar.status !== 'Borrado'" -->
            <div class="card-body">
              <!-- Title -->
              <p class="card-title">
                {{ lugar.in.location.name }}
                <small class="text-muted d-block">
                  {{ lugar.name }}
                </small>
              </p>
              <!-- Horario -->
              <p class="card-text font-weight-bold">
                Horario:
              </p>
              <ul class="card-text list-unstyled">
                <li
                  v-for="(horario, i) in lugar.schedule"
                  :key="i"
                >
                  {{ accentInWeekDay(horario.day.name) }} de {{ horario.start }}
                  hasta {{ horario.end }}
                  <p class="mb-2">
                    Máx. pacientes diarios: {{ horario.data }}
                  </p>
                </li>
              </ul>
              <!-- Teléfonos -->
              <p
                v-if="lugar.tels && lugar.tels.length
                  && lugar.tels[0].number.length"
                class="card-text font-weight-bold"
              >
                Teléfono(s):
              </p>
              <ul class="card-text list-unstyled">
                <li
                  v-for="(tel, iTel) in lugar.tels"
                  :key="iTel"
                >
                  +{{ tel.country }} {{ tel.number }}
                </li>
              </ul>
              <!-- Fechas no laborables -->
              <div class="d-flex align-items-center justify-content-between">
                <p class="card-text font-weight-bold">
                  Fechas no laborables:
                </p>
                <mdb-btn
                  dark-waves
                  flat
                  class="px-2 m-0 flat-bordered"
                  size="sm"
                  title="Seleccionar fechas no laborables"
                  type="button"
                  @click="showServices = true;
                          fillServices(lugar)"
                >
                  Seleccionar
                </mdb-btn>
              </div>
              <ul
                v-if="lugar.noWorkingDates && lugar.noWorkingDates.length"
                class="card-text list-unstyled"
              >
                <li
                  v-for="(noWorDate, inoWorDate) in lugar.noWorkingDates"
                  :key="inoWorDate"
                >
                  {{ noWorDate | format_date }}
                </li>
              </ul>
              <p v-else class="text-muted text-center">
                Sin fechas seleccionadas
              </p>
              <!-- Card's buttons -->
              <div class="row justify-content-around">
                <mdb-btn
                  dark-waves
                  flat
                  class="px-2"
                  icon="pen"
                  size="sm"
                  title="Editar lugar"
                  type="button"
                  @click="showFormInstitute = true; fillModalInstitute(lugar)"
                />
                <mdb-btn
                  dark-waves
                  flat
                  class="p-0"
                  size="sm"
                  :title="lugar.status == 'Activado' ? 'Desactivar lugar'
                    : 'Activar lugar'"
                  type="button"
                  :disabled="btnDisabled"
                  @click="workplaceUpdate(lugar)"
                >
                  <span class="fa-stack">
                    <font-awesome-icon
                      class=" fa-stack-1x"
                      :icon="['far', 'square']"
                    />
                    <transition
                      name="custom-classes-transition"
                      enter-active-class="animated rotateInDownLeft"
                      leave-active-class="animated rotateOutUpLeft"
                      :duration="300"
                    >
                      <font-awesome-icon
                        v-if="lugar.status == 'Activado'"
                        :class="['fa-stack-1x', lugar.status == 'Activado' ?
                          'check success-text' : null]"
                        :icon="['fas', 'check']"
                      />
                    </transition>
                  </span>
                </mdb-btn>
                <mdb-btn
                  dark-waves
                  flat
                  class="px-2"
                  icon="trash"
                  size="sm"
                  :title="lugar.servicesOffered.length ? 'Ocultar lugar'
                    : 'Borrar lugar'"
                  type="button"
                  :disabled="btnDisabled"
                  @click="workplaceDelete(lugar)"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!lugares.length && !$apolloData.queries.lugares.loading"
          class="d-flex flex-column flex-lg-row
            align-items-center text-muted my-4"
        >
          <font-awesome-icon
            :icon="['fas', 'box-archive']"
            class="my-2 fa-7x"
          />
          <p class="text-center px-4">
            <span class="h2-responsive d-block">No hay lugares</span>
            <span class="h2-responsive d-block">registrados</span>
          </p>
        </div>
        <p
          v-if="$apolloData.queries.lugares.loading"
          class="text-center text-muted"
        >
          <font-awesome-icon
            :icon="['fas', 'circle-notch']"
            class="fa-spin fa-5x my-4"
          />
          <small class="d-block">
            CARGANDO
          </small>
        </p>
      </div>
    </section>

    <!-- Modal para  editar fechas no laborables -->
    <mdb-modal
      centered
      elegant
      :show="showServices"
      @close="showServices = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          Fechas no laborables
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <p class="text-muted text-center">
          Los pacientes no podrán reservar citas en las fechas que selecciones
        </p>
        <vue-cal
          class="vuecal--date-picker"
          xsmall
          hide-view-selector
          :time="false"
          :transitions="false"
          active-view="month"
          :disable-views="['week']"
          :disable-days="newWorkplace.noWorkingDates || []"
          :min-date="minDate"
          style="width: auto;"
          @cell-focus="setNoWorkingDate($event)"
        />
        <template
          v-if="newWorkplace.noWorkingDates
            && newWorkplace.noWorkingDates.length"
        >
          <p
            v-for="(day, d) in newWorkplace.noWorkingDates"
            :key="d"
            class="text-center"
          >
            {{ day | format_date }}
          </p>
        </template>
        <p
          v-else
          class="text-center text-muted"
        >
          No has indicado fechas en las cuales los pacientes
          no puedan agendar citas desde AMII
        </p>
        <div class="row justify-content-center">
          <mdb-btn
            flat
            dark-waves
            icon="times"
            size="sm"
            class="flat-bordered"
            @click="showServices = false"
          >
            Cerrar
          </mdb-btn>
          <mdb-btn
            color="success"
            icon="sync"
            size="sm"
            @click="noWorkingDaysUpdate"
          >
            Guardar
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <!-- Modal para agregar / modificar lugar -->
    <mdb-modal
      centered
      elegant
      size="lg"
      :show="showFormInstitute"
      @shown="modalInstitute.label == 'Añadir'
        ? $refs.selectPais.focus() : $refs.selectConsultorio.focus()"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          {{ modalInstitute.modalTitle }}
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModalFormInstitute"
          class="close"
          @click="showFormInstitute = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <form @submit.prevent="workPlaceGeneral(modalInstitute.label)">
          <p
            v-if="doctorRole[0] === 'DoctorSinValidar'"
            class="text-muted"
          >
            Solo médicos verificados pueden crear nuevos estados y ciudades.
          </p>
          <div class="row justify-content-center">
            <div
              v-if="modalInstitute.label == 'Añadir'"
              class="col-12 col-sm-6 col-lg-4"
            >
              <div class="md-form md-outline outline-select my-2">
                <select
                  id="pais"
                  ref="selectPais"
                  v-model="countryId"
                  class="custom-select"
                  @change="cleanSelectsLugar"
                >
                  <option
                    class="d-none"
                    disabled
                    value=""
                  >
                    Seleccione
                  </option>
                  <option
                    v-for="country in countries"
                    :key="country.id"
                    :value="country.id"
                  >
                    {{ country.name }}
                  </option>
                </select>
                <label
                  for="pais"
                  :class="countryId.length ? 'label-active' : 'label-inactive'"
                >
                  País
                </label>
              </div>
            </div>
            <div
              v-if="modalInstitute.label == 'Añadir'"
              class="col-12 col-sm-6 col-lg-4"
            >
              <div class="md-form md-outline outline-select my-2">
                <select
                  id="estado"
                  v-model="stateId"
                  :class="['custom-select', {'no-background':
                    $apolloData.queries.states.loading}]"
                  :disabled="$apolloData.queries.states.loading"
                  @change="fillShowOtherState"
                >
                  <option
                    class="d-none"
                    disabled
                    value=""
                  >
                    Seleccione
                  </option>
                  <option
                    v-for="state in states"
                    :key="state.id"
                    :value="state.id"
                  >
                    {{ state.name }}
                  </option>
                  <option
                    :disabled="doctorRole[0] === 'DoctorSinValidar'"
                    value="otro en pais"
                  >
                    Otro en el país
                  </option>
                </select>
                <label
                  for="estado"
                  :class="stateId.length ? 'label-active' : 'label-inactive'"
                >
                  Estado
                </label>
                <span
                  v-if="$apolloData.queries.states.loading"
                  class="form-loader"
                >
                  <font-awesome-icon
                    :icon="['fas', 'circle-notch']"
                    class="fa-spin"
                  />
                </span>
              </div>
            </div>
            <div
              v-if="modalInstitute.label == 'Añadir'"
              class="col-12 col-sm-6 col-lg-4"
            >
              <div class="md-form md-outline outline-select my-2">
                <select
                  id="ciudad"
                  v-model="cityId"
                  :class="['custom-select', {'no-background':
                    $apolloData.queries.cities.loading}]"
                  :disabled="$apolloData.queries.cities.loading"
                  @change="fillShowOtherCity"
                >
                  <option
                    class="d-none"
                    disabled
                    value=""
                  >
                    Seleccione
                  </option>
                  <option
                    v-for="city in cities"
                    :key="city.id"
                    :value="city.id"
                  >
                    {{ city.name }}
                  </option>
                  <option
                    :disabled="doctorRole[0] === 'DoctorSinValidar'"
                    value="otro en estado"
                  >
                    Otra en el estado
                  </option>
                </select>
                <label
                  for="ciudad"
                  :class="cityId.length ? 'label-active' : 'label-inactive'"
                >
                  Ciudad
                </label>
                <span
                  v-if="$apolloData.queries.cities.loading"
                  class="form-loader"
                >
                  <font-awesome-icon
                    :icon="['fas', 'circle-notch']"
                    class="fa-spin"
                  />
                </span>
              </div>
            </div>
            <div
              v-if="modalInstitute.label == 'Añadir'"
              class="col-12 col-sm-6 col-lg-4"
            >
              <div class="md-form md-outline outline-select my-2">
                <select
                  id="instituto"
                  v-model="newWorkplace.medical_instituteID"
                  :class="['custom-select', {'no-background':
                    $apolloData.queries.institutes.loading}]"
                  :disabled="$apolloData.queries.institutes.loading"
                  @click="fillShowOtherInstitute"
                >
                  <option
                    class="d-none"
                    disabled
                    value=""
                  >
                    Seleccione
                  </option>
                  <option
                    v-for="institute in institutes"
                    :key="institute.id"
                    :value="institute.id"
                  >
                    {{ institute.name }}
                  </option>
                  <option
                    value="otro en ciudad"
                  >
                    Otro en la Ciudad
                  </option>
                </select>
                <label
                  for="instituto"
                  :class="newWorkplace.medical_instituteID.length
                    ? 'label-active' : 'label-inactive'"
                >
                  Instituto
                </label>
                <span
                  v-if="$apolloData.queries.institutes.loading"
                  class="form-loader"
                >
                  <font-awesome-icon
                    :icon="['fas', 'circle-notch']"
                    class="fa-spin"
                  />
                </span>
              </div>
            </div>
            <div
              class="col-12 col-lg-4"
            >
              <div class="md-form md-outline outline-select my-2">
                <select
                  id="servicio"
                  ref="selectConsultorio"
                  v-model="newWorkplace.in.name"
                  class="custom-select"
                  :disabled="$apolloData.queries.consultorings.loading"
                >
                  <option
                    class="d-none"
                    disabled
                    value=""
                  >
                    Seleccione
                  </option>
                  <option
                    v-for="consul in consultorings"
                    :key="consul.name"
                    :value="consul.name"
                  >
                    {{ consul.name }}
                  </option>
                  <option
                    value="Otro en el instituto"
                  >
                    Otro en el Instituto
                  </option>
                </select>
                <label
                  for="servicio"
                  :class="newWorkplace.in.name ? 'label-active'
                    : 'label-inactive'"
                >
                  Consultorio
                </label>
              </div>
            </div>
            <div
              v-if="newWorkplace.in.name == 'Otro en el instituto'"
              :class="['col-12', modalInstitute.label == 'Añadir' ?
                'col-lg-4' : 'col-lg-6']"
            >
              <mdb-input
                v-model="newWorkplace.otherConsultoringName"
                class="my-2"
                label="Nombre del consultorio"
                outline
              />
            </div>
            <div
              :class="newWorkplace.in.name == 'Otro en el instituto' ?
                'col-12 col-lg-6' : 'col-12 col-lg-4'"
            >
              <mdb-input
                v-model="newWorkplace.name"
                class="my-2"
                label="Alias del lugar"
                outline
              />
            </div>
            <div
              class="col-12 col-lg-4"
            >
              <div class="md-form md-outline outline-select my-2">
                <select
                  id="tipo"
                  v-model="newWorkplace.type"
                  class="custom-select"
                  :disabled="newWorkplace.type === 'Gestionado'"
                >
                  <option
                    class="d-none"
                    disabled
                    value=""
                  >
                    Seleccione
                  </option>
                  <option
                    v-for="tipo in tipos"
                    :key="tipo"
                    :value="tipo"
                  >
                    {{ tipo }}
                  </option>
                </select>
                <label
                  for="tipo"
                  :class="newWorkplace.type.length ? 'label-active'
                    : 'label-inactive'"
                >
                  Tipo
                </label>
              </div>
            </div>
          </div>

          <!-- Para añadir números telefónicos -->
          <div class="row align-items-center mt-2 mx-0">
            <div class="col text-center font-weight-bold">
              Teléfono(s) público(s)
            </div>
            <mdb-btn
              flat
              dark-waves
              type="button"
              class="col-auto success-text cursor-pointer m-0 p-2"
              icon="plus-circle"
              icon-class="fs-20-mobileOnly"
              title="Nuevo teléfono"
              @click="nuevoTelefono"
            />
          </div>
          <div
            v-for="(tel, iTel) in newWorkplace.tels"
            :key="'t'+iTel"
            class="row align-items-center"
          >
            <div class="col">
              <div class="row align-items-center">
                <div
                  class="col-auto our-radio2"
                  title="Marcar como número primario"
                >
                  <label>
                    <input
                      v-model="tel.primary"
                      type="radio"
                      name="primaryTelRadio"
                      :value="true"
                    >
                    <span />
                  </label>
                </div>
                <div
                  class="col"
                >
                  <CustomDatalist
                    :id="iTel+'tel'"
                    ref="customDatalist"
                    v-model="tel.country"
                    :identificador="iTel"
                    :list="telCodes"
                    :type="'number'"
                    class-name="my-2"
                    label="País"
                    :outline="true"
                    @save="tel.country=$event.code;"
                  />
                </div>
                <div class="col pl-0">
                  <mdb-input
                    v-model="tel.number"
                    type="number"
                    label="Teléfono"
                    class="my-2 icon-input-group"
                    outline
                    required
                  >
                    <mdb-btn
                      slot="append"
                      class="m-0"
                      icon="trash"
                      dark-waves
                      flat
                      group
                      :disabled="newWorkplace.tels.length < 2"
                      title="Eliminar teléfono"
                      @click="eliminarNuevoTelefono(tel)"
                    />
                  </mdb-input>
                </div>
              </div>
            </div>
          </div>

          <!-- Para añadir nuevo horario -->
          <div class="row align-items-center mt-2 mx-0">
            <div class="col text-center font-weight-bold">
              Horario de servicio
            </div>
          </div>
          <div
            v-for="(day, d) in days"
            :key="d"
          >
            <div
              class="row align-items-center justify-content-end
                px-3 px-lg-4 my-1 grey lighten-4"
            >
              <p class="col m-0 pl-0">
                {{ accentInWeekDay(day) }}
              </p>
              <mdb-btn
                v-if="day == 'Lunes'"
                flat
                dark-waves
                type="button"
                class="col-auto cursor-pointer m-0 p-2"
                icon="clone"
                title="Copia los turnos del lunes en todos los demás días"
                @click="duplicarHorario()"
              >
                <small>Usar en todos</small>
              </mdb-btn>
              <mdb-btn
                flat
                dark-waves
                type="button"
                class="col-auto success-text cursor-pointer m-0 p-2"
                icon="plus-circle"
                title="Agregar turno"
                @click="nuevoHorario(day)"
              >
                <small
                  v-if="day == 'Lunes'"
                  class="d-none d-sm-inline"
                >
                  Agregar turnos
                </small>
              </mdb-btn>
              <mdb-btn
                type="button"
                flat
                dark-waves
                class="col-auto cursor-pointer m-0 p-2"
                icon="minus-circle"
                title="Eliminar turnos de este día"
                @click="eliminarHorarioDelDia(day)"
              >
                <small
                  v-if="day == 'Lunes'"
                  class="d-none d-sm-inline"
                >
                  Eliminar turnos
                </small>
              </mdb-btn>
            </div>
            <template
              v-for="(schedule, i) in newWorkplace.schedule"
            >
              <div
                v-if="schedule.day.name == day"
                :key="i"
                class="row justify-content-center align-items-center"
              >
                <div class="col">
                  <div class="row">
                    <div class="col-12 col-sm-4 px-sm-3">
                      <mdb-input
                        v-model="schedule.data"
                        type="number"
                        label="# Máx. Pacientes por turno"
                        title="Cantidad máxima de pacientes por turno"
                        class="my-2 label-mr-0"
                        outline
                      />
                    </div>
                    <div class="col-12 col-sm-4 px-sm-3">
                      <div class="my-2 md-form md-outline">
                        <input
                          v-model="schedule.start"
                          type="time"
                          name="startTime"
                          step="60"
                          class="form-control"
                        >
                        <label class="active">
                          Inicio jornada
                        </label>
                      </div>
                    </div>
                    <div class="col-12 col-sm-4 px-sm-3">
                      <div class="my-2 md-form md-outline">
                        <input
                          v-model="schedule.end"
                          type="time"
                          name="endTime"
                          step="60"
                          class="form-control"
                        >
                        <label class="active">
                          Fin jornada
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="newWorkplace.schedule.length > 1"
                  class="col-auto pl-0 pr-2 d-flex"
                >
                  <mdb-btn
                    type="button"
                    class="px-2 m-0"
                    flat
                    dark-waves
                    icon="trash"
                    title="Eliminar horario"
                    @click="eliminarNuevoHorario(schedule)"
                  />
                </div>
              </div>
            </template>
          </div>
          <div class="row justify-content-between my-2 mx-sm-1 mx-md-2">
            <mdb-btn
              flat
              dark-waves
              class="flat-bordered px-3"
              icon="times"
              @click="showFormInstitute = false"
            >
              Cerrar
            </mdb-btn>
            <BtnLoader
              btn-type="submit"
              btn-classes="px-3"
              :btn-disabled="btnDisabled"
              :btn-icon="modalInstitute.icon"
              :btn-title="modalInstitute.label"
            />
          </div>
        </form>
      </mdb-modal-body>
    </mdb-modal>

    <!-- Listado de consultorios en papelera -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <section
        v-if="showTrash"
        class="container-fluid trash"
      >
        <div class="d-flex flex-column flex-sm-row mb-2">
          <header class="col order-2 h3-responsive text-center">
            Historial de lugares inactivos
          </header>
          <mdb-btn
            class="col-auto order-1 m-0 px-2 flex-align-center"
            flat
            dark-waves
            icon="chevron-left"
            size="sm"
            title="Atrás"
            @click="showTrash = !showTrash"
          >
            Atrás
          </mdb-btn>
        </div>
        <div class="row justify-content-center">
          <div
            v-for="lugar in lugaresBorrados"
            :key="lugar.id"
          >
            <div
              class="card bord-style institute m-2"
            >
              <!-- v-if="lugar.status == 'Borrado'" -->
              <!-- Card content -->
              <div class="card-body">
                <!-- Title -->
                <p class="card-title">
                  {{ lugar.in.location.name }}
                  <small class="text-muted d-block">
                    {{ lugar.name }}
                  </small>
                </p>
                <!-- Text -->
                <p class="card-text font-weight-bold">
                  Horario:
                </p>
                <ul class="card-text list-unstyled">
                  <li
                    v-for="(horario, i) in lugar.schedule"
                    :key="i"
                  >
                    {{ accentInWeekDay(horario.day.name) }} de
                    {{ horario.start }} hasta {{ horario.end }}
                    <p class="mb-2">
                      Max. pacientes diarios: {{ horario.data }}
                    </p>
                  </li>
                </ul>
                <!-- Card's buttons -->
                <div class="row justify-content-around">
                  <mdb-btn
                    dark-waves
                    flat
                    class="px-2"
                    icon="pen"
                    size="sm"
                    title="Editar lugar"
                    type="button"
                    @click="showFormInstitute = true; fillModalInstitute(lugar)"
                  />
                  <mdb-btn
                    dark-waves
                    flat
                    class="px-2"
                    icon="redo"
                    size="sm"
                    title="Restaurar lugar"
                    type="button"
                    :disabled="btnDisabled"
                    @click="workplaceUpdate(lugar)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!lugaresBorrados.length &&
              !$apolloData.queries.lugares.loading"
            class="d-flex flex-column flex-lg-row
              align-items-center text-muted my-4"
          >
            <font-awesome-icon
              :icon="['fas', 'inbox']"
              class="my-2 fa-7x"
            />
            <p class="text-center px-4">
              <span class="h2-responsive d-block">Papelera</span>
              <span class="h2-responsive d-block">vacía</span>
            </p>
          </div>
        </div>
      </section>
    </transition>
    <NuevasUbicaciones
      :show-other-state="showOtherState"
      :show-other-city="showOtherCity"
      :show-other-institute="showOtherInstitute"
      @newState="newState"
      @newCity="newCity"
      @newInstitute="newInstitute"
      @closeForm="showOtherState={};
                  showOtherCity={};
                  showOtherInstitute={}"
    />
    <AlertaMsj :alerta-msj="alertaMsj" />
  </div>
</template>
<script>
import {mdbBtn, mdbInput, mdbModal, mdbModalHeader,
  mdbModalTitle, mdbModalBody} from 'mdbvue';
import NuevasUbicaciones from '@/components/NuevasUbicaciones.vue';
import gql from 'graphql-tag';
import {getPersonId, getRole} from '@/utils/authdecode.js';
import {validHoursRange, accentInWeekDay} from '@/utils/validations.js';
import BtnLoader from '@/components/customButtons/BtnLoader.vue';
import AlertaMsj from '@/components/AlertaMsj.vue';
import CustomDatalist from '@/components/customFields/CustomDatalist.vue';
import doctorWorkPlacesGql from '@/graphql/doctorWorkPlaces.gql';
import countriesGql from '@/graphql/countries.gql';
import citiesGql from '@/graphql/cities.gql';
import statesGql from '@/graphql/states.gql';
import medicalInstitutesInCityGql from '@/graphql/medicalInstitutesInCity.gql';
import medicalInstituteConsultoringsGql
  from '@/graphql/medicalInstituteConsultorings.gql';
import workplaceCreateGql from '@/graphql/workplaceCreate.gql';
import workplaceUpdateGql from '@/graphql/workplaceUpdate.gql';
import workplaceDeleteGql from '@/graphql/workplaceDelete.gql';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/i18n/es.js';
import {telCodes} from '@/constantes/telephoneCodes.js';
export default {
  name: 'LugaresDeTrabajo',
  components: {
    mdbBtn,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    NuevasUbicaciones,
    BtnLoader,
    AlertaMsj,
    VueCal,
    CustomDatalist,
  },
  data() {
    return {
      telCodes,
      accentInWeekDay,
      alertaMsj: {
        content: '',
      },
      lugarTemp: {},
      btnDisabled: false,
      showServices: false, // Muestra modal de servicios
      service: {
        name: '',
        servicesOffered: [],
      },
      modalInstitute: { // Contiene title, label e icon para showFormInstitute
        modalTitle: '',
        label: '',
        icon: 'plus',
      },
      days: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes',
        'Sabado', 'Domingo'],
      // Muestra modal para agregar / modificar instituto
      showFormInstitute: false,
      showTrash: false,
      // Componente NuevasUbicaciones
      showOtherState: {},
      showOtherCity: {},
      showOtherInstitute: {},
      tipos: ['Consulta'/* , 'Hospitalización' */],
      // New Workplace
      countryId: '',
      stateId: '',
      cityId: '',
      newWorkplace: {
        id: '',
        in: {
          name: '',
        },
        name: '',
        consultoringName: '',
        otherConsultoringName: '',
        schedule: [
          {
            day: {
              name: 'Lunes',
            },
            start: '',
            end: '',
            data: '',
          },
        ],
        tels: [
          {
            number: '',
            country: '',
          },
        ],
        medical_instituteID: '',
        type: '',
        noWorkingDates: [],
      },
      lugares: [],
      consultorings: [],
      doctorRole: getRole(),
    };
  },
  computed: {
    lugaresActivos() {
      return this.lugares.filter((w)=>{
        return w.status != 'Borrado';
      });
    },
    lugaresBorrados() {
      return this.lugares.filter((w)=>{
        return w.status == 'Borrado';
      });
    },
    minDate() {
      const now = new Date();
      return now;
    },
  },
  methods: {
    fillServices(lugar) {
      this.lugarTemp = lugar;
      this.newWorkplace = JSON.parse(JSON.stringify(lugar));
      this.newWorkplace.medical_instituteID = lugar.in.location.id;
    },
    fillModalInstitute(lugar) {
      this.modalInstitute = {
        modalTitle: 'Edición del lugar',
        label: 'Guardar',
        icon: 'rotate',
      };

      this.lugarTemp = lugar;
      this.newWorkplace = JSON.parse(JSON.stringify(lugar));
      this.newWorkplace.medical_instituteID = lugar.in.location.id;
    },
    workPlaceGeneral(btnLabel) {
      btnLabel == 'Añadir' ? this.workplaceCreate() : this.workplaceUpdate();
    },
    workplaceCreate() {
      this.alertaMsj.content = '';

      if (!this.newWorkplace.medical_instituteID
        || this.newWorkplace.medical_instituteID == 'otro en ciudad') {
        return this.alertaMsj = {
          content: 'Selecciona un instituto médico',
          type: 'warning',
        };
      }
      // El valor -1 lo asigna el optimistic response cuando está offline
      if (this.newWorkplace.medical_instituteID == -1) {
        return this.alertaMsj = {
          content: 'Intenta de nuevo, al parecer no se ha guardado el'
          + ' instituto',
          type: 'warning',
        };
      }
      if (this.newWorkplace.in.name == 'Otro en el instituto') {
        if (!this.newWorkplace.otherConsultoringName) {
          return this.alertaMsj = {
            content: 'Llena el nombre del Consultorio',
            type: 'warning',
          };
        }
      }
      if (!this.newWorkplace.in.name) {
        return this.alertaMsj = {
          content: 'Selecciona un consultorio médico',
          type: 'warning',
        };
      }
      if (!this.newWorkplace.type.length) {
        return this.alertaMsj = {
          content: 'Selecciona el tipo de lugar',
          type: 'warning',
        };
      }
      let validSchedules = true;
      this.newWorkplace.schedule.forEach((sched) => {
        if (!sched.day.name || !sched.start
          || !sched.end || !sched.data) {
          validSchedules = false;
          return this.alertaMsj = {
            content: 'Llena los datos de tu horario',
            type: 'warning',
          };
        }
        if (!validHoursRange(sched.start, sched.end)) {
          validSchedules = false;
          return this.alertaMsj = {
            content: 'La hora de inicio debe ser menor a la hora final',
            type: 'warning',
          };
        }
      });
      if (!validSchedules) return;
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${workplaceCreateGql}`,
        variables: {
          medicalInstituteId: this.newWorkplace.medical_instituteID,
          workPlace: {
            name: this.newWorkplace.name,
            type: this.newWorkplace.type,
          },
          consultoring: {
            name: this.newWorkplace.in.name == 'Otro en el instituto'
            ? this.newWorkplace.otherConsultoringName
            : this.newWorkplace.in.name,
          },
          schedules: this.newWorkplace.schedule.map((e) => {
            delete e.__typename;
            delete e.day.__typename;
            return e;
          }),
          tels: this.newWorkplace.tels.map((e) => {
            delete e.__typename;
            return e;
          }),
        },
        context: {
          serializationKey: 'Lugares',
        },
        update: (store, {data: {workplaceCreate}}) => {
          const data = store.readQuery({
            query: gql`${doctorWorkPlacesGql}`,
            variables: {
              id: getPersonId(),
            },
          });
          const schedule =
            JSON.parse(JSON.stringify(this.newWorkplace.schedule))
                .map((e) => {
                  e.__typename = 'Schedule';
                  e.day.__typename = 'Day';
                  return e;
                });
          const tels =
            JSON.parse(JSON.stringify(this.newWorkplace.tels))
                .map((e) => {
                  e.__typename = 'Tel';
                  return e;
                });
          const addedWorkplace = {
            __typename: 'Work_Place',
            id: workplaceCreate.id,
            name: this.newWorkplace.name
              ? this.newWorkplace.name
              : '',
            type: this.newWorkplace.type,
            in: {
              location: {
                id: this.newWorkplace.medical_instituteID,
                name: this.institutes.filter((i) =>
                  i.id == this.newWorkplace.medical_instituteID)[0].name,
                __typename: 'Medical_Institute',
                address: {
                  city: {
                    __typename: 'City',
                    id: this.cityId,
                    name: '',
                    state: {
                      __typename: 'State',
                      id: this.stateId,
                      name: '',
                      country: {
                        __typename: 'Country',
                        id: this.countryId,
                        name: '',
                      },
                    },
                  },
                  __typename: 'Address',
                },
                consultorings: [],
              },
              name: this.newWorkplace.in.name == 'Otro en el instituto'
                ? this.newWorkplace.otherConsultoringName
                : this.newWorkplace.in.name,
              __typename: 'Consultoring',
            },
            schedule,
            tels,
            status: 'Activado',
            servicesOffered: [],
          };
          data.Doctor[0].works_at.push(addedWorkplace);
          this.lugares = data.Doctor[0].works_at;
          store.writeQuery({
            query: doctorWorkPlacesGql,
            variables: {
              id: getPersonId(),
            },
            data,
          });
          this.showFormInstitute = false;
          this.alertaMsj = {
            content: 'Lugar agregado',
            type: 'success',
          };
          this.btnDisabled = false;
        },
        optimisticResponse: {
          __typename: 'Mutation',
          workplaceCreate: {
            __typename: 'Work_Place',
            id: -1,
          },
        },
      }).catch((error) => {
        this.alertaMsj = {
          content: 'Hubo un error al agregar nuevo lugar',
          type: 'danger',
        };
        this.btnDisabled = false;
      });
    },
    workplaceUpdate(lugar) {
      let status = '';
      if (typeof lugar !== 'undefined') {
        if (lugar.status == 'Activado') {
          status='Desactivado';
        }
        if (lugar.status == 'Desactivado') {
          status='Activado';
        }
        if (lugar.status == 'Borrado') {
          status='Desactivado';
        }
      } else {
        if (this.newWorkplace.in.name == 'Otro en el instituto') {
          if (!this.newWorkplace.otherConsultoringName) {
            return this.alertaMsj = {
              content: 'Llena el nombre del consultorio',
              type: 'warning',
            };
          }
        }
        if (!this.newWorkplace.type.length) {
          return this.alertaMsj = {
            content: 'Selecciona el tipo de lugar',
            type: 'warning',
          };
        }
        let validSchedules = true;
        this.newWorkplace.schedule.forEach((sched) => {
          if (!sched.day.name || !sched.start
            || !sched.end || !sched.data) {
            validSchedules = false;
            return this.alertaMsj = {
              content: 'Llena los datos de tu horario',
              type: 'warning',
            };
          }
          if (!validHoursRange(sched.start, sched.end)) {
            validSchedules = false;
            return this.alertaMsj = {
              content: 'La hora de inicio debe ser menor a la hora final',
              type: 'warning',
            };
          }
        });
        if (!validSchedules) return;
      }
      let variablesApollo = {};
      if (typeof lugar !== 'undefined') {
        variablesApollo = {
          workPlace: {
            id: lugar.id,
            status: status,
          },
        };
      } else {
        this.newWorkplace.schedule.forEach((sched) => {
          delete sched.__typename;
          delete sched.day.__typename;
        });
        this.newWorkplace.schedule.forEach((sched) => {
          delete sched.__typename;
          delete sched.day.__typename;
        });
        variablesApollo = {
          workPlace: {
            id: this.newWorkplace.id,
            name: this.newWorkplace.name,
            type: this.newWorkplace.type,
          },
          consultoring: {
            name: this.newWorkplace.in.name == 'Otro en el instituto'
            ? this.newWorkplace.otherConsultoringName
            : this.newWorkplace.in.name,
          },
          schedules: this.newWorkplace.schedule,
          tels: this.newWorkplace.tels,
        };
      }
      this.newWorkplace.tels.forEach((tel) => {
        delete tel.__typename;
      });
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${workplaceUpdateGql}`,
        variables: variablesApollo,
      }).then((res) => {
        const msg = res.data.workplaceUpdate.msg;
        switch (msg) {
          case 'success':
            this.showFormInstitute = false;
            if (lugar) {
              // Verificar si se restaura de Borrado a Desactivado:
              if (lugar.status == 'Borrado') {
                this.lugaresBorrados.splice(this.lugares.indexOf(lugar), 1);
                this.lugares.push(lugar);
              }
              // luego actualiza  el status de la tarjeta
              lugar.status = status;
            }
            this.alertaMsj = {
              content: '',
            };
            this.alertaMsj = {
              content: 'Lugar actualizado',
              type: 'success',
            };
            Object.assign(this.lugarTemp, {...this.newWorkplace});
            this.lugares = [].concat(this.lugares);
            this.btnDisabled = false;
            break;
          default:
            this.alertaMsj = {
              content: 'Hubo un error al actualizar el lugar',
              type: 'danger',
            };
            this.btnDisabled = false;
            break;
        }
      }).catch((error) => {
        this.alertaMsj = {
          content: 'Hubo un error al actualizar el lugar',
          type: 'danger',
        };
        this.btnDisabled = false;
      });
    },
    workplaceDelete(lugar) {
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${workplaceDeleteGql}`,
        variables: {
          workPlaceId: lugar.id,
        },
      }).then((res) => {
        const msg = res.data.workplaceDelete.msg;
        switch (msg) {
          case 'success':
            this.lugares.splice(this.lugares.indexOf(lugar), 1);
            this.alertaMsj = {
              content: '',
            };
            this.alertaMsj = {
              content: 'Lugar eliminado',
              type: 'success',
            };
            this.btnDisabled = false;
            break;
          case 'partial':
            lugar.status = 'Borrado';
            this.lugaresBorrados.push(lugar);
            this.lugares.splice(this.lugares.indexOf(lugar), 1);
            this.alertaMsj = {
              content: 'Lugar movido a la papelera',
              type: 'success',
            };
            this.btnDisabled = false;
            break;
          default:
            this.alertaMsj = {
              content: 'Hubo un error al eliminar el lugar',
              type: 'danger',
            };
            this.btnDisabled = false;
            break;
        }
      }).catch((error) => {
        this.alertaMsj = {
          content: 'Hubo un error al eliminar el lugar',
          type: 'danger',
        };
        this.btnDisabled = false;
      });
    },
    noWorkingDaysUpdate() {
      this.noWorkingDaysVerification();
      const variablesApollo = {
        workPlace: {
          id: this.lugarTemp.id,
          noWorkingDates: this.newWorkplace.noWorkingDates,
        },
      };
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${workplaceUpdateGql}`,
        variables: variablesApollo,
      }).then((res) => {
        const msg = res.data.workplaceUpdate.msg;
        switch (msg) {
          case 'success':
            this.showServices = false;
            this.alertaMsj = {
              content: '',
            };
            this.alertaMsj = {
              content: 'Fechas no laborables establecidas',
              type: 'success',
            };
            Object.assign(this.lugarTemp, {...this.newWorkplace});
            this.lugares = [].concat(this.lugares);
            this.btnDisabled = false;
            break;
          default:
            this.alertaMsj = {
              content: 'Hubo un error al actualizar las fechas no laborables',
              type: 'warning',
            };
            this.btnDisabled = false;
            break;
        }
      }).catch((error) => {
        this.alertaMsj = {
          content: 'Hubo un error al actualizar las fechas no laborables',
          type: 'danger',
        };
        this.btnDisabled = false;
      });
    },
    noWorkingDaysVerification() {
      const dates = this.newWorkplace.noWorkingDates.filter((w) => {
        if (new Date(w) >= new Date(((new Date()).getTime() - 86400000))) {
          return w;
        }
      });
      this.newWorkplace.noWorkingDates = dates;
    },
    nuevoHorario(dayName) {
      this.newWorkplace.schedule.push({
        day: {
          name: dayName,
        },
        start: '',
        end: '',
      });
    },
    duplicarHorario() {
      const schedule = [];
      this.days.forEach((day) => {
        this.newWorkplace.schedule.forEach((lun) => {
          if (lun.day.name === 'Lunes') {
            schedule.push({
              day: {
                name: day,
              },
              start: lun.start,
              end: lun.end,
              data: lun.data,
            });
          }
        });
      });
      this.newWorkplace.schedule = schedule;
    },
    eliminarNuevoHorario(horario) {
      this.newWorkplace.schedule.splice(
          this.newWorkplace.schedule.indexOf(horario), 1);
    },
    eliminarHorarioDelDia(dayName) {
      this.newWorkplace.schedule =
        this.newWorkplace.schedule.filter((horario) => {
          return horario.day.name !== dayName;
        });
    },
    nuevoTelefono() {
      this.newWorkplace.tels.push({
        country: '',
        number: '',
      });
    },
    eliminarNuevoTelefono(telefono) {
      this.newWorkplace.tels.splice(
          this.newWorkplace.tels.indexOf(telefono), 1);
    },
    addInstitute() {
      this.modalInstitute = {
        modalTitle: 'Agrega los lugares donde prestas servicios',
        label: 'Añadir',
        icon: 'plus',
      };
      this.newWorkplace.schedule = [
        {
          day: {
            name: 'Lunes',
          },
          start: '',
          end: '',
        },
      ];
      this.newWorkplace.tels = [
        {
          country: '',
          number: '',
        },
      ];
      this.countryId='';
      this.stateId='';
      this.cityId='';
      this.newWorkplace.medical_instituteID='';
      this.newWorkplace.in.name='';
      this.newWorkplace.otherConsultoringName='';
      this.newWorkplace.name = '';
      this.newWorkplace.type = '';
    },
    cleanSelectsLugar() {
      this.stateId = '';
      this.cityId = '';
      this.newWorkplace.medical_instituteID = '';
    },
    fillShowOtherState() {
      this.showOtherState = {
        stateId: this.stateId,
        countryId: this.countryId,
      };
      this.cityId = '';
      this.newWorkplace.medical_instituteID = '';
    },
    fillShowOtherCity() {
      this.showOtherCity = {
        cityId: this.cityId,
        stateId: this.stateId,
      };
      this.newWorkplace.medical_instituteID = '';
    },
    fillShowOtherInstitute() {
      this.showOtherInstitute = {
        medical_instituteID: this.newWorkplace.medical_instituteID,
        cityId: this.cityId,
      };
    },
    // Emits del Componente NuevaUbicaciones
    newState(state) {
      this.showOtherState = {};
      this.stateId = state.id;
    },
    newCity(city) {
      this.showOtherCity = {};
      this.cityId = city.id;
    },
    newInstitute(institute) {
      this.showOtherInstitute = {};
      this.newWorkplace.medical_instituteID = institute.id;
    },
    setNoWorkingDate(date) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      if (!this.newWorkplace.noWorkingDates) {
        this.newWorkplace.noWorkingDates = [];
      }
      const existeFecha =
        this.newWorkplace.noWorkingDates.includes(`${year}-${month}-${day}`);
      if (!existeFecha) {
        this.newWorkplace.noWorkingDates.push([year, month, day].join('-'));
      } else {
        this.newWorkplace.noWorkingDates =
          this.newWorkplace.noWorkingDates.filter((fecha) => {
            return fecha !== `${year}-${month}-${day}`;
          });
      }
    },
  },
  apollo: {
    lugares() {
      return {
        query: gql`${doctorWorkPlacesGql}`,
        variables: {
          id: getPersonId(),
        },
        update: (data) => data.Doctor[0].works_at,
      };
    },
    countries() {
      return {
        query: gql`${countriesGql}`,
      };
    },
    states() {
      return {
        query: gql`${statesGql}`,
        variables() {
          return {
            id: this.countryId,
          };
        },
        skip() {
          return this.countryId ? false : true;
        },
      };
    },
    cities() {
      return {
        query: gql`${citiesGql}`,
        variables() {
          return {
            id: this.stateId,
          };
        },
        skip() {
          return this.stateId ? false : true;
        },
      };
    },
    institutes() {
      return {
        query: gql`${medicalInstitutesInCityGql}`,
        variables() {
          return {
            cityId: this.cityId,
          };
        },
        skip() {
          return this.cityId ? false : true;
        },
        update: (data) => data.medicalInstitutesInCity,
      };
    },
    consultorings() {
      return {
        query: gql`${medicalInstituteConsultoringsGql}`,
        variables() {
          return {
            medicalInstituteId: this.newWorkplace.medical_instituteID,
          };
        },
        skip() {
          return this.newWorkplace.medical_instituteID.length ? false : true;
        },
        update: (data) => data.Medical_Institute[0]
          ? data.Medical_Institute[0].consultorings
          : [],
      };
    },
  },
};
</script>
<style lang="scss">
  .consultorios {
    margin-bottom: 80px;
    .check.success-text {
      margin-bottom: .60rem;
      margin-left: .85rem;
    }
  }
  .card.institute {
    width: 290px;

    .card-body .card-text {margin-bottom: 0;}
  }
  .container-fluid.trash {
    background-color: $white;
    height: calc(100vh);
    overflow-y: auto;
    padding-top: 70px;
    position: fixed;
    top: 0;
    width: 100%;

    @media screen and (max-width: 991px) {left: 0;}
    @media screen and (min-width: 992px) {width: calc(100% - 220px);}
  }
  .label-mr-0.md-form .form-control + label.mr-5 {margin-right: 0 !important;}
</style>
