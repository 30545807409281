import gql from 'graphql-tag';

export const typeDefs = gql`
  type EstatusNotification {
    permiso: String!
    esCompatible: Boolean!
    existeSuscripcion: Boolean!
  }
  type Mutation {
    estatusNotificacionesCompatible(esCompatible: Boolean!): Boolean
    estatusNotificacionesPermiso(permiso: String!): String
    estatusNotificacionesSuscripcion(existe: Boolean!): Boolean
  }
`;
const estatusNotificacionesGql = gql`
  {
    estatusNotificaciones @client {
      permiso
      esCompatible
      existeSuscripcion
    }
  }
`;
export const resolvers = {
  Mutation: {
    estatusNotificacionesCompatible: (_, {esCompatible}, {cache}) => {
      const data = cache.readQuery({
        query: estatusNotificacionesGql,
      });
      const currentEstatus = data.estatusNotificaciones;
      currentEstatus.esCompatible = esCompatible;
      cache.writeQuery({
        query: estatusNotificacionesGql, data,
      });
      return currentEstatus.esCompatible;
    },
    estatusNotificacionesPermiso: (_, {permiso}, {cache}) => {
      const data = cache.readQuery({
        query: estatusNotificacionesGql,
      });
      const currentEstatus = data.estatusNotificaciones;
      currentEstatus.permiso = permiso;
      cache.writeQuery({
        query: estatusNotificacionesGql, data,
      });
      return currentEstatus.permiso;
    },
    estatusNotificacionesSuscripcion: (_, {existe}, {cache}) => {
      const data = cache.readQuery({
        query: estatusNotificacionesGql,
      });
      const currentEstatus = data.estatusNotificaciones;
      currentEstatus.existeSuscripcion = existe;
      cache.writeQuery({
        query: estatusNotificacionesGql, data,
      });
      return currentEstatus.existeSuscripcion;
    },
  },
};
