<template>
  <div
    class="mas"
    data-type="submenu"
  >
    <div class="d-lg-none">
      <mdb-dropdown>
        <mdb-dropdown-toggle
          slot="toggle"
          class="flex-align-center py-2 px-3"
          flat
          dark-waves
        >
          <font-awesome-icon
            :icon="['fas', 'bars-staggered']"
            size="3x"
          />
          <div class="mx-2 text-left">
            <small class="d-block">
              Más
            </small>
            {{ vistaActiva }}
            <mdb-badge
              v-if="insolvencia && vistaActiva == 'Pagos'"
              class="ml-2 px-2 fs-07"
              color="bg-danger-base"
              title="Estás insolvente"
              pill
            >
              <font-awesome-icon
                :icon="['fas', 'exclamation']"
                class="d-inline-block"
              />
            </mdb-badge>
          </div>
        </mdb-dropdown-toggle>
        <mdb-dropdown-menu>
          <router-link
            v-for="(link, i) in sideBarLinks"
            :key="`dropdown-${i}`"
            :to="link.to"
            :class="['dropdown-item', {'divider': addDividerAfter(i)},
                     {'disabled': link.disable}]"
            exact
          >
            <font-awesome-icon
              :icon="['fas', link.icon]"
              class="mr-1"
              size="sm"
            />
            {{ link.name }}
            <mdb-badge
              v-if="insolvencia && link.name == 'Pagos'"
              class="ml-2 px-0 fs-07"
              color="bg-danger-base"
              title="Estás insolvente"
              pill
            >
              <font-awesome-icon
                :icon="['fas', 'exclamation']"
                class="d-inline-block"
              />
            </mdb-badge>
          </router-link>
        </mdb-dropdown-menu>
      </mdb-dropdown>
    </div>
    <aside class="aside-content">
      <header class="my-3">
        <font-awesome-icon
          :icon="['fas', 'bars-staggered']"
          size="lg"
          class="ml-2"
        />
        <span class="h5-responsive ml-2">Más</span>
      </header>
      <ul class="aside-nav">
        <router-link
          v-for="(link, a) in sideBarLinks"
          :key="`aside-${a}`"
          :to="link.to"
          :class="['aside-item', {'divider': addDividerAfter(a)},
                   {'disabled': link.disable}]"
          tag="li"
          exact
        >
          <font-awesome-icon
            :icon="['fas', link.icon]"
            size="sm"
            class="mr-1"
          />
          {{ link.name }}
          <mdb-badge
            v-if="insolvencia && link.name == 'Pagos'"
            class="px-0 fs-07"
            color="bg-danger-base"
            title="Estás insolvente"
            pill
          >
            <font-awesome-icon
              :icon="['fas', 'exclamation']"
              class="d-inline-block"
            />
          </mdb-badge>
        </router-link>
      </ul>
    </aside>
    <div class="col-lg pl-lg-0">
      <router-view />
    </div>
  </div>
</template>

<script>
import {getSolvency} from '@/utils/authdecode.js';
import {mdbBadge, mdbDropdown, mdbDropdownMenu, mdbDropdownToggle}
  from 'mdbvue';
export default {
  name: 'Mas',
  components: {
    mdbBadge,
    mdbDropdown,
    mdbDropdownMenu,
    mdbDropdownToggle,
  },
  data() {
    return {
      insolvencia: (getSolvency()) < new Date().getTime(),
      sideBarLinks: [
        {
          name: 'Análisis',
          to: '/mas/analisis',
          icon: 'chart-simple',
          disable: true,
        },
        {
          name: 'Finanzas',
          to: '/mas/finanzas',
          icon: 'money-bill-wave',
          disable: false,
        },
        {
          name: 'Pagos',
          to: '/mas/pagos',
          icon: 'hand-holding-dollar',
          disable: false,
        },
      ],
    };
  },
  computed: {
    vistaActiva() {
      const vista = this.$route.path.slice(1).split('/');
      switch (vista[1]) {
        case 'finanzas':
          return 'Finanzas';
        case 'pagos':
          return 'Pagos';
        default:
          return 'Análisis';
      }
    },
  },
  methods: {
    addDividerAfter(item) {
      switch (item) {
        case 0: // Después de Análisis
          return true;
        default:
          return false;
      }
    },
  },
};
</script>

<style lang="scss">
</style>
