<template>
  <!-- Modal para las opciones de impresión -->
  <mdb-modal
    centered
    elegant
    scrollable
    :show="showPrintOptions"
  >
    <mdb-modal-header :close="false">
      <mdb-modal-title>
        Opciones de impresión
      </mdb-modal-title>
      <a
        ref="mdbModalCerrarModalPrintOptions"
        class="close"
        @click="$emit('close');"
      >×</a>
    </mdb-modal-header>
    <mdb-modal-body>
      <div>
        <header class="font-weight-bold mb-2">
          Márgenes
        </header>
        <div class="row">
          <template v-if="printMargins && printMargins.length">
            <div
              v-for="margin in printMargins"
              :key="`margin-${margin.name}`"
              class="col-12 col-sm-6 col-md-4 our-radio2"
            >
              <label
                class="pl-4"
                @click="print.mt = margin.top;
                        print.mr = margin.right;
                        print.mb = margin.bottom;
                        print.ml = margin.left;
                        addCustomMargin = false;"
              >
                <input
                  type="radio"
                  name="radio-margin"
                  :value="margin.name"
                >
                <span class="ml-n4 mr-1" />
                {{ margin.name }}
                <small
                  class="d-block text-muted"
                  :title="`Superior: ${margin.top} mm `
                    + `- Inferior: ${margin.bottom} mm `
                    + `- Izquierdo: ${margin.left} mm `
                    + `- Derecho: ${margin.right} mm`"
                >
                  {{ margin.top }}, {{ margin.bottom }}, {{ margin.left }},
                  {{ margin.right }}
                </small>
              </label>
            </div>
            <div
              class="col-12 col-sm-6 col-md-4 our-radio2"
            >
              <label
                class="pl-4"
                @click="addCustomMargin = true;"
              >
                <input
                  type="radio"
                  name="radio-margin"
                >
                <span class="ml-n4 mr-1" />
                Personalizados
              </label>
            </div>
            <div class="col-12" />
          </template>
          <template v-if="addCustomMargin">
            <div class="col-12 col-md-6">
              <mdb-input
                v-model.number="print.mt"
                type="number"
                :min="0"
                class="my-1"
                label="Superior"
                outline
              >
                <span
                  slot="append"
                  class="input-group-text md-addon px-2"
                >
                  mm
                </span>
              </mdb-input>
            </div>
            <div class="col-12 col-md-6">
              <mdb-input
                v-model.number="print.mb"
                type="number"
                :min="0"
                class="my-1"
                label="Inferior"
                outline
              >
                <span
                  slot="append"
                  class="input-group-text md-addon px-2"
                >
                  mm
                </span>
              </mdb-input>
            </div>
            <div class="col-12 col-md-6">
              <mdb-input
                v-model.number="print.ml"
                type="number"
                :min="0"
                class="my-1"
                label="Izquierdo"
                outline
              >
                <span
                  slot="append"
                  class="input-group-text md-addon px-2"
                >
                  mm
                </span>
              </mdb-input>
            </div>
            <div class="col-12 col-md-6">
              <mdb-input
                v-model.number="print.mr"
                type="number"
                :min="0"
                class="my-1"
                label="Derecho"
                outline
              >
                <span
                  slot="append"
                  class="input-group-text md-addon px-2"
                >
                  mm
                </span>
              </mdb-input>
            </div>
          </template>
        </div>
        <header class="font-weight-bold mt-3 mb-2">
          Tamaño de hoja
        </header>
        <div class="row">
          <div
            v-for="size in printSizes"
            :key="`print-${size.name}`"
            class="col-12 col-sm-6 col-md-4 our-radio2"
          >
            <label
              class="pl-4"
              @click="print.width = size.width;
                      print.height = size.height"
            >
              <input
                v-model="print.orientacion"
                type="radio"
                name="radio-orientacion"
                :value="size.name"
                checked
              >
              <span class="ml-n4 mr-1" />
              {{ size.name }}
              <div>
                <img
                  v-if="size.width === size.height"
                  src="../../public/img/vectores/icon-square.svg"
                  alt=""
                >
                <img
                  v-else-if="size.width > size.height"
                  src="../../public/img/vectores/icon-landscape.svg"
                  alt=""
                >
                <img
                  v-else
                  src="../../public/img/vectores/icon-portrait.svg"
                  alt=""
                >
                <small
                  class="text-muted"
                  :title="`Ancho: ${size.width} mm - Alto: ${size.height} mm`"
                >
                  {{ size.width }} x {{ size.height }}
                </small>
              </div>
            </label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 our-radio2">
            <label
              class="pl-4"
              @click="print.width = 0;print.height = 0"
            >
              <input
                v-model="print.orientacion"
                type="radio"
                name="radio-orientacion"
                value="landscape"
                checked
              >
              <span class="ml-n4 mr-1" />
              Horizontal
              <div>
                <img
                  src="../../public/img/vectores/icon-landscape.svg"
                  alt=""
                >
                <small
                  class="text-muted"
                  title="Tamaño horizontal automático"
                >
                  AUTO
                </small>
              </div>
            </label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 our-radio2">
            <label
              class="pl-4"
              @click="print.width = 0;print.height = 0"
            >
              <input
                v-model="print.orientacion"
                type="radio"
                name="radio-orientacion"
                value="portrait"
              >
              <span class="ml-n4 mr-1" />
              Vertical
              <div>
                <img
                  src="../../public/img/vectores/icon-portrait.svg"
                  alt=""
                >
                <small
                  class="text-muted"
                  title="Tamaño vertical automático"
                >
                  AUTO
                </small>
              </div>
            </label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 our-radio2">
            <label
              class="pl-4"
              @click="print.width = '';print.height = ''"
            >
              <input
                v-model="print.orientacion"
                type="radio"
                name="radio-orientacion"
                value="personalizada"
              >
              <span class="ml-n4 mr-1" />
              Personalizado
              <div>
                <img
                  src="../../public/img/vectores/icon-custom-size.svg"
                  alt=""
                >
                <small
                  class="text-muted"
                  title="Tamaño horizontal automático"
                >
                  DEFINIR
                </small>
              </div>
            </label>
          </div>
        </div>
        <div
          v-if="print.orientacion == 'personalizada'"
          class="row"
        >
          <div class="col-12 col-md-6">
            <mdb-input
              v-model.number="print.width"
              class="my-1"
              label="Ancho"
              type="number"
              :min="0"
              outline
            >
              <span
                slot="append"
                class="input-group-text md-addon px-2"
              >
                mm
              </span>
            </mdb-input>
          </div>
          <div class="col-12 col-md-6">
            <mdb-input
              v-model.number="print.height"
              class="my-1"
              label="Alto"
              type="number"
              :min="0"
              outline
            >
              <span
                slot="append"
                class="input-group-text md-addon px-2"
              >
                mm
              </span>
            </mdb-input>
          </div>
        </div>
      </div>
      <div class="modal-footer-btn">
        <mdb-btn
          ref="btnEmitClose"
          flat
          dark-waves
          icon="times"
          class="flat-bordered"
          @click="$emit('close');"
        >
          Cerrar
        </mdb-btn>
        <mdb-btn
          ref="btnEmitImprimir"
          type="submit"
          color="success-base"
          icon="print"
          @click="printView();"
        >
          Imprimir
        </mdb-btn>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import {mdbBtn, mdbInput, mdbModal, mdbModalHeader, mdbModalTitle,
  mdbModalBody} from 'mdbvue';

// PDFmake
const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: 'ModalOpcionesImpresion',
  components: {
    mdbBtn,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
  },
  props: {
    showPrintOptions: {
      type: Boolean,
      required: true,
    },
    codeToPrint: {
      type: Array,
      required: true,
    },
    doctorName: {
      type: String,
      default: '',
    },
    documentName: {
      type: String,
      required: true,
    },
    hasTwoColumns: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Array,
      default: () => [],
    },
    footer: {
      type: Array,
      default: () => [],
    },
    images: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localStorageAvailable: false,
      print: {
        orientacion: 'landscape',
        mt: 15,
        mr: 15,
        mb: 15,
        ml: 15,
        width: 0,
      },
      printMargins: [],
      printSizes: [],
      addCustomMargin: true,
    };
  },
  watch: {
    showPrintOptions: function() {
      if (this.showPrintOptions) {
        // Revisa disponibilidad localStorage
        if (typeof(Storage) !== 'undefined') {
          this.localStorageAvailable = true;
          const savedMargins = localStorage.getItem('printMargins');
          const savedSizes = localStorage.getItem('printSizes');

          // Asigna valores al arreglo si los hay
          if (savedMargins !== null) {
            this.printMargins = JSON.parse(savedMargins);
            this.addCustomMargin = false;
          }
          if (savedSizes !== null) {
            this.printSizes = JSON.parse(savedSizes);
          }
        } else this.localStorageAvailable = false;
      }
    },
  },
  methods: {
    unitConverter(mm) {
      const cm = mm / 10;
      const pts = cm / 2.54 * 72; // Fórmula que convierte de cm a pt
      return pts;
    },
    setFooter() {
      let footer = {};
      const margin = [
        this.unitConverter(this.print.ml), 0,
        this.unitConverter(this.print.mr), 0,
      ];

      if (this.footer && this.footer.length) {
        footer = this.footer.concat({
          text: 'https://amii.app',
          color: '#002196',
          fontSize: 9,
        });
      } else {
        footer = {
          text: 'https://amii.app',
          color: '#002196',
          fontSize: 9,
        };
      }

      if (this.hasTwoColumns) {
        footer ={
          columns: [
            footer,
            footer,
          ],
        };
      }
      footer.margin = margin;

      return footer;
    },
    setHeader() {
      let header = {};
      const margin = [
        this.unitConverter(this.print.ml), 5,
        this.unitConverter(this.print.mr), 0,
      ];

      if (this.header && this.header.length) {
        if (this.hasTwoColumns) {
          header = {
            columns: [
              this.header,
              this.header,
            ],
          };
        } else {
          header = this.header;
        }

        header.margin = margin;
      }

      return header;
    },
    printView() {
      const footerFormat = this.setFooter();
      const headerFormat = this.setHeader();

      // Variable de impresión
      const docDefinition = {
        info: {
          title: this.documentName,
          author: 'AMII - www.amii.app - App para Médicos y Pacientes',
        },
        header: headerFormat,
        footer: footerFormat,
        content: this.codeToPrint,
        images: this.images,
        styles: {
          'header1': {
            fontSize: 16,
            bold: true,
          },
          'header2': {
            fontSize: 15,
            bold: true,
          },
          'header3': {
            fontSize: 14,
          },
          'header4': {
            fontSize: 13,
          },
          'header5': {
            fontSize: 12,
            bold: true,
          },
          'header6': {
            fontSize: 11,
            bold: true,
          },
          // Estilos para conversor de pdfmake
          'html-h1': {
            fontSize: 16,
            bold: true,
          },
          'html-h2': {
            fontSize: 15,
            bold: true,
          },
          'html-h3': {
            fontSize: 14,
          },
          'html-h4': {
            fontSize: 13,
          },
          'html-h5': {
            fontSize: 12,
            bold: true,
          },
          'html-h6': {
            fontSize: 11,
            bold: true,
          },
          'html-span': {
            fontSize: 10,
          },
          'html-p': {
            fontSize: 10,
          },
          'ql-align-left': {
            alignment: 'left',
          },
          'ql-align-center': {
            alignment: 'center',
          },
          'ql-align-right': {
            alignment: 'right',
          },
        },
        defaultStyle: {
          columnGap: 10,
          fontSize: 10,
        },
        pageSize: this.print.width
          ? {width: this.unitConverter(this.print.width),
            height: this.unitConverter(this.print.height)}
          : 'LETTER',
        pageOrientation: this.print.orientacion == 'landscape'
          || this.print.orientacion == 'portrait'
              ? this.print.orientacion
              : 'auto',
        pageMargins: [
          this.unitConverter(this.print.ml),
          this.unitConverter(this.print.mt),
          this.unitConverter(this.print.mr),
          this.unitConverter(this.print.mb),
        ],
      };

      pdfMake.createPdf(docDefinition).print();

      this.$emit('close');
    },
  },
  apollo: {},
};
</script>

<style lang="scss">
  .md-form.md-outline label.active {
    z-index: 3;
  }
</style>
