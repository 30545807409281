<template>
  <!--
    Modal para confirmar la
    eliminación de un documento
  -->
  <mdb-modal
    class="be-bottom"
    direction="bottom"
    :show="showConfirmation"
    @close="$emit('close', false)"
  >
    <mdb-modal-body class="delete-document">
      <p class="my-2 text-center">
        ¿Está seguro que desea eliminar
        <span class="font-weight-bold">
          {{ name }}
        </span>?
      </p>
      <div class="buttons">
        <mdb-btn
          ref="btnCancelar"
          class="flat-bordered order-2 order-sm-1"
          flat
          icon="times"
          dark-waves
          @click="$emit('close', false)"
        >
          Cancelar
        </mdb-btn>
        <BtnLoader
          ref="btnConfirmar"
          btn-color="danger-base"
          btn-classes="order-1 order-sm-2"
          :btn-disabled="btnDisabled"
          btn-icon="circle-minus"
          btn-title="Eliminar"
          @click="$emit('confirm-delete', name)"
        />
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>
<script>
import {mdbBtn, mdbModal, mdbModalBody} from 'mdbvue';
import BtnLoader from '@/components/customButtons/BtnLoader.vue';
// import gql from 'graphql-tag';
// import AlertaMsj from '@/components/AlertaMsj.vue';
// import Gql from '@/graphql/.gql';

export default {
  name: 'ModalConfirmacionEliminar',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    BtnLoader,
  },
  props: {
    showConfirmation: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    btnDisabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
  apollo: {},
};
</script>
<style lang="scss">
  //Clase para forzar al modal al fondo de la página
  .be-bottom .modal-dialog {
    bottom: 0;
    margin: 1.75rem auto 0;
    max-width: 100%;
    position: fixed;
    width: 100%;

    .delete-document {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;

      .buttons {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
      }
    }
  }
</style>
