<template>
  <div class="mt-lg-3 galeria">
    <div class="filtros">
      <!-- Etiquetas -->
      <div
        :class="['col-12 col-md-10 col-lg-6 col-xl-4',
                 {'order-lg-1': tags && tags.length}]"
      >
        <div class="row align-items-center my-2">
          <div class="col pr-2">
            <div class="md-form md-outline outline-select my-1">
              <select
                id="newTag-select"
                v-model="newTag"
                class="custom-select"
                @change="addTag"
              >
                <option
                  class="d-none"
                  disabled
                  value=""
                >
                  Seleccione
                </option>
                <option
                  v-for="(etiqueta, i) in especialidades"
                  :key="i"
                  :value="etiqueta.name"
                >
                  {{ etiqueta.name }}
                </option>
              </select>
              <label
                for="newTag-select"
                class="label-active"
              >
                Buscar por especialidad
              </label>
            </div>
          </div>
          <mdb-btn
            :class="['col-auto m-0 mr-2 p-2',
                     {'animated pulse-bg infinite': newTag}]"
            dark-waves
            flat
            icon="plus-circle"
            icon-class="success-text fs-15-mobileOnly"
            size="sm"
            title="Añadir etiqueta"
            :disabled="!newTag"
            @click="addTag"
          />
        </div>
      </div>
      <!-- Lista de etiquetas -->
      <div
        v-if="tags && tags.length"
        :class="['col-12 col-md-10 col-lg-12 col-xl-8',
                 {'order-lg-3': tags && tags.length}]"
      >
        <div
          v-for="(etiqueta, i) in tags"
          :key="i"
          class="chip close-btn mb-2"
        >
          {{ etiqueta }}
          <mdb-btn
            class="m-0 ml-2 p-1"
            dark-waves
            flat
            icon="times"
            size="sm"
            title="Eliminar etiqueta"
            @click="tags.splice(i, 1)"
          />
        </div>
      </div>
      <!-- Nombre -->
      <div
        :class="['col-12 col-md-10 col-lg-6 col-xl-4',
                 {'order-lg-2': tags && tags.length}]"
      >
        <mdb-input
          ref="input"
          v-model="name"
          class="my-2 icon-input-group"
          label="Nombre del grupo de parámetros"
          placeholder="Escribe el nombre de la prueba"
          outline
        >
          <mdb-btn
            ref="mdbBtnResultSearch"
            slot="append"
            dark-waves
            flat
            group
            :icon="btnDisabled ? 'circle-notch' : 'search'"
            :icon-class="btnDisabled ? 'fa-spin' : ''"
            size="md"
            type="button"
            :disabled="btnDisabled"
          />
        </mdb-input>
      </div>
      <!-- Orden -->
      <!-- <div
        :class="['col-12 radio-tabs text-center my-2',
                 {'order-lg-4': tags && tags.length}]"
      >
        <input
          id="radio-popular"
          v-model="ordersBy"
          type="radio"
          value="Popular"
        >
        <label for="radio-popular">
          Popular
        </label>
        <input
          id="radio-puntaje"
          v-model="ordersBy"
          type="radio"
          value="Puntaje"
        >
        <label for="radio-puntaje">
          Puntaje
        </label>
      </div> -->
    </div>
    <!-- Loading -->
    <div
      v-if="$apolloData.queries.pruebas.loading"
      class="text-center text-muted vertical-centered vc-160px"
    >
      <font-awesome-icon
        :icon="['fas', 'circle-notch']"
        class="fa-spin fa-5x my-4"
      />
      <small class="d-block">
        CARGANDO
      </small>
    </div>
    <div v-else>
      <div
        v-if="pruebas.length"
        :class="[pruebas.length < 3
          ? 'row justify-content-center'
          : 'card-columns cc-1 cc-md-2 cc-xl-3 cc-xxl-4 mb-4']"
      >
        <div
          v-for="(prueba,i) in pruebas"
          :key="'p'+i"
          :class="['card-bordered',
                   {'relative col-11 col-md-5 col-xl-4 m-2 p-0':
                     pruebas.length < 3}]"
        >
          <div class="card-body">
            <!-- Título -->
            <header class="card-title">
              {{ prueba.name }}
            </header>
            <!-- Comunidad -->
            <div class="votos-popularidad">
              <div class="card-text font-italic">
                {{ prueba.rating }}
                <font-awesome-icon
                  :icon="['fas', 'star']"
                  class="text-warning"
                />
                <div>
                  <span>con</span>
                  <span>{{ prueba.votes }} votos</span>
                </div>
              </div>
              <div class="card-text font-italic">
                +{{ prueba.popularity }}
                <div>
                  <span>veces</span>
                  <span>compartida</span>
                </div>
              </div>
            </div>
            <!-- Btn Vista previa -->
            <router-link
              :to="{name: 'GaleriaPruebaClinica',
                    params: {serviceId: prueba.id}}"
              tag="div"
              class="col px-2"
            >
              <mdb-btn
                class="my-2 px-1 flat-bordered flex-center"
                data-type="btn-preview"
                block
                dark-waves
                flat
                icon="eye"
                icon-class="fa-lg"
                size="sm"
                @click="servicioSeleccionado = prueba"
              >
                Vista previa en toma de diagnóstico
              </mdb-btn>
            </router-link>
            <!-- Parámetros -->
            <p class="card-text mb-0">
              <span class="fs-inherit font-weight-bold mr-1">Parámetros:</span>
              <span
                v-for="(param, ip) in prueba.measure"
                :key="ip"
                class="fs-inherit auto-coma"
              >
                {{ param.name }}
              </span>
            </p>
            <!-- Autor -->
            <p class="card-text mb-2">
              <small class="font-weight-bold">Autor:</small>
              <small class="font-italic">
                {{ prueba.offersBy.fname }} {{ prueba.offersBy.lname }}
              </small>
            </p>
            <div class="card-buttons">
              <mdb-btn
                class="col m-0 flex-center"
                icon="share-alt"
                size="sm"
                dark-waves
                flat
                title="Compartir parámetros"
                data-button-type="hover-default"
                @click="compartir(prueba)"
              >
                Compartir
              </mdb-btn>
              <mdb-btn
                class="col m-0 flex-center"
                icon="bookmark"
                icon-class="success-text"
                size="sm"
                dark-waves
                flat
                title="Agregar parámetros"
                data-button-type="hover-success"
                @click="agregarPrueba(prueba)"
              >
                Agregar
              </mdb-btn>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="container my-4"
      >
        <div class="flex-align-center flex-column text-muted">
          <header class="h4-responsive text-center mb-2">
            Todavía no hay grupos con tus parámetros de búsqueda
          </header>
          <p class="mb-0">
            Se el primero, hazte popular y ¡gana premios!.
          </p>
          <!--
          <p>
            Para ver tus Pruebas clínicas compartidas ve a
            <router-link
              :to="{ name: 'GaleriaPerfil' }"
              tag="a"
              class="fs-inherit d-inline-flex justify-content-center
                align-items-center link-icon-hover text-muted"
            >
              <font-awesome-icon
                :icon="['fas', 'grip-horizontal']"
                size="sm"
                class="mx-1"
              />
              <span class="mx-1 fs-inherit">
                Mis elementos
              </span>
              <font-awesome-icon
                :icon="['fas', 'angle-right']"
                class="my-2 fa-md icon"
              />
            </router-link>
          </p>
          <p class="font-italic text-center mx-4">
            Para más información sobre la galería, cómo usarla y cómo ganar
            premios, <span class="d-md-block"> visita el centro de ayuda
              <router-link
                :to="{ name: 'Ayuda', hash: '#galeria-pruebas-clinicas-x'}"
                tag="a"
                class="fs-inherit d-inline-flex justify-content-center
                  align-items-center link-icon-hover text-muted"
              >
                <span class="mx-1 fs-inherit text-underline">
                  ¿Cómo usar la galería?
                </span>
                <font-awesome-icon
                  :icon="['fas', 'angle-right']"
                  class="my-2 fa-md icon"
                />
              </router-link>
            </span>
          </p>
          -->
        </div>
      </div>
    </div>
    <!-- Modal para Nuevo / Modificar servicio -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="showServiceForm"
      @shown="$refs.servicioRef.focus()"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          {{ serviceForm.title }}
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModalServiceForm"
          class="close"
          @click="showServiceForm = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <form @submit.prevent="serviceCopy()">
          <div class="w-100">
            <mdb-input
              v-model="servicioTemp.name"
              class="mt-0 mb-3"
              label="Nombre del grupo de parámetros"
              outline
            />
          </div>
          <div class="w-100">
            <mdb-input
              ref="servicioRef"
              v-model="servicioTemp.description"
              type="textarea"
              class="my-2"
              label="Descripción"
              :rows="4"
              outline
            />
          </div>
          <div class="our-checkbox2 userSelect-none text-muted">
            <label>
              <input
                v-model="servicioTemp.shareable"
                type="checkbox"
                name="input-shareable"
              >
              <span />
              Compartir grupo de parámetros
            </label>
            <mdb-tooltip
              class="ml-2"
              trigger="hover"
              :options="{placement: 'left'}"
            >
              <div slot="tip">
                <small>
                  Otros usuarios podrán elegir tu formato de parámetros para
                  usarlo en su toma de consulta. Puedes ganar premios si tu
                  formato se convierte en el más popular. Esto no comparte los
                  datos de tus pacientes. Para ver formatos compartidos por
                  otros, visita el menú Galería.
                </small>
              </div>
              <a slot="reference">
                <font-awesome-icon
                  :icon="['fas', 'circle-question']"
                  size="sm"
                  class="d-inline mr-1"
                />
              </a>
            </mdb-tooltip>
          </div>
          <div class="row mt-3">
            <header class="col font-weight-bold">
              Precios
            </header>
            <mdb-btn
              class="col-auto m-0 mr-3 p-1"
              flat
              dark-waves
              icon="plus-circle"
              icon-class="success-text"
              size="sm"
              title="Añadir precio"
              type="button"
              @click="addPrice"
            />
          </div>
          <div
            v-for="(precio,i) in servicioTemp.prices"
            :key="i"
            class="row"
          >
            <div
              :class="['col', {'pr-1': servicioTemp.prices.length > 1}]"
            >
              <div class="row">
                <div class="col-12">
                  <mdb-input
                    v-model="precio.amount"
                    type="number"
                    class="my-2"
                    label="Precio"
                    :min="0"
                    :step="0.01"
                    outline
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <CustomDatalist
                    v-model="precio.Currency.name"
                    :list="monedas"
                    class-name="my-1"
                    label="Moneda"
                    :identificador="i"
                    :outline="true"
                    @input="precio.Currency.symbol='';
                            buscarMonedas(precio.Currency.name)"
                    @save="precio.Currency.symbol=$event.symbol;
                           precio.Currency.name=$event.name;"
                  >
                    <template
                      slot-scope="scope"
                    >
                      <li
                        v-for="(mon, ii) in monedas"
                        :key="i+'.'+ii"
                        @click="scope.selected(mon)"
                        @mousedown.prevent
                      >
                        {{ mon.name }}
                        <small> - </small>
                        <small>
                          {{ mon.symbol }}.
                        </small>
                      </li>
                    </template>
                  </CustomDatalist>
                </div>
                <div class="col-12 col-sm-6">
                  <mdb-input
                    v-model="precio.Currency.symbol"
                    class="my-1"
                    label="Símbolo"
                    outline
                  />
                </div>
              </div>
            </div>
            <mdb-btn
              v-if="servicioTemp.prices.length > 1"
              class="col-auto px-2"
              flat
              dark-waves
              icon="times"
              title="Eliminar precio"
              @click="cancelPrecio(precio)"
            />
          </div>
          <!-- Botonces del modal -->
          <div class="modal-footer-btn">
            <mdb-btn
              flat
              dark-waves
              icon="times"
              class="flat-bordered"
              @click="showServiceForm = false"
            >
              Cerrar
            </mdb-btn>
            <BtnLoader
              btn-type="submit"
              :btn-disabled="btnDisabled"
              :btn-icon="serviceForm.btnIcon"
              :btn-title="serviceForm.btnLabel"
            />
          </div>
        </form>
      </mdb-modal-body>
    </mdb-modal>
    <router-view
      :service="servicioSeleccionado"
      @detalles-cargados="servicioSeleccionado=$event"
      @agregar-prueba="agregarPrueba($event)"
    />
    <AlertaMsj :alerta-msj="alertaMsj" />
  </div>
</template>
<script>
import {mdbBtn, mdbInput, mdbModal, mdbModalHeader, mdbModalTitle,
  mdbModalBody, mdbTooltip} from 'mdbvue';
import BtnLoader from '@/components/customButtons/BtnLoader.vue';
import AlertaMsj from '@/components/AlertaMsj.vue';
import CustomDatalist from '@/components/customFields/CustomDatalist.vue';
import {getPersonId} from '@/utils/authdecode.js';
import {capitalizeFirstLetter} from '@/utils/validations.js';
import gql from 'graphql-tag';
import servicesGalleryGql from
  '@/graphql/servicesGallery.gql';
import currenciesAcceptedGql from '@/graphql/currenciesAccepted.gql';
import tagsDegreesGql from '@/graphql/tagsDegrees.gql';
import serviceCopyGql from '@/graphql/serviceCopy.gql';
import doctorGql from '@/graphql/doctor.gql';
import currenciesByNameGql from '@/graphql/currenciesByName.gql';
export default {
  name: 'GaleriaPruebasClinicas',
  components: {
    mdbBtn,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbTooltip,
    BtnLoader,
    AlertaMsj,
    CustomDatalist,
  },
  data() {
    return {
      // Render:
      showVistaPrevia: false,
      showModalAgregar: false,
      pruebas: [],
      tags: [],
      especialidades: [],
      profileInfo: {},
      otrasMonedas: [],
      monedasAceptadas: [],
      ordersBy: 'Popular',
      name: '',
      newTag: '',
      serviceId: '',
      servicioTemp: {
        description: '',
        prices: [
          {
            amount: '',
            Currency: {
              name: '',
              symbol: '',
            },
          },
        ],
        shareable: true,
      },
      servicioSeleccionado: {
        measure: [],
      },
      showServiceForm: false,
      serviceForm: {
        title: '',
        btnLabel: '',
        btnIcon: 'plus',
      },
      alertaMsj: {
        content: '',
      },
      btnDisabled: false,
    };
  },
  computed: {
    monedas() {
      let monedas = [];
      if (this.monedasAceptadas.length) {
        monedas = monedas.concat(this.monedasAceptadas);
      }
      this.otrasMonedas.forEach((p) => {
        if (!monedas.some((e) => {
          return JSON.stringify({name: e.name, symbol: e.symbol})
            === JSON.stringify({name: p.name, symbol: p.symbol});
        })) {
          monedas.push({
            name: p.name,
            symbol: p.symbol,
          });
        }
      });
      return monedas;
    },
  },
  watch: {
    profileInfo() {
      if (this.profileInfo && this.profileInfo.searchTags) {
        this.profileInfo.searchTags.forEach((t) => {
          if (!this.tags.some((e) => {
            return e.name === t.name;
          })) {
            this.tags.push(t.name);
          }
        });
      }
    },
  },
  methods: {
    agregarPrueba(prueba) {
      this.showServiceForm = true;
      this.serviceId = prueba.id;
      this.servicioTemp = {
        name: prueba.name,
        description: '',
        prices: [
          {
            amount: '',
            Currency: {
              name: '',
              symbol: '',
            },
          },
        ],
        shareable: true,
      };
      this.serviceForm = {
        title: 'Selecciona un lugar de trabajo',
        btnLabel: 'Añadir',
        btnIcon: 'plus',
      };
    },
    compartir(prueba) {
      if (navigator.share) {
        navigator.share({
          title: 'AMII - Parámetros en galería',
          text: 'Estos parámetros pueden servirte para tus consultas',
          url: 'https://medico.amii.app/configuracion/pruebas-clinicas/catalogo/'
            + prueba.id,
        })
            .then(() => {
              try {
                this.$gtag.event('share', {
                  'content_type': 'url',
                  'content_id': event.target.id,
                });
              // eslint-disable-next-line no-empty
              } catch (error) {}
            })
            .catch((error) => alert('Error', error));
      } else {
        try {
          navigator.clipboard.writeText('https://medico.amii.app/configuracion/pruebas-clinicas/catalogo/'
            + prueba.id)
              .then(()=>{
                return this.alertaMsj = {
                  content: 'Copiado al portapapeles. Usa pegar para '
                  + 'compartir el enlace',
                  type: 'success',
                };
              })
              .catch(()=>{
                return this.alertaMsj = {
                  content: 'Esta opción solo está disponible para'
                  + ' navegadores en móviles, o tú navegador no es compatible.'
                  + ' Recomendamos las últimas versiones de Chrome,'
                  + ' Firefox o Safari.',
                  type: 'warning',
                };
              });
        } catch ($e) {
          alert('No se pudo copiar para compartir');
        }
      }
    },
    pruebaMutate() {
      (this.serviceForm.btnLabel == 'Añadir')
        ? this.serviceCopy()
        : this.pruebaItemUpdate();
    },
    serviceCopy() {
      if (!this.servicioTemp.name) {
        return this.alertaMsj = {
          content: 'Llena nombre del grupo de parámetros',
          type: 'warning',
        };
      }
      this.servicioTemp.name =
        capitalizeFirstLetter(this.servicioTemp.name.trim());
      this.servicioTemp.description = this.servicioTemp.description
        ? capitalizeFirstLetter(this.servicioTemp.description.trim())
        : '';
      let validPrice = true;
      this.servicioTemp.prices.forEach((price) => {
        if (isNaN(price.amount) || !price.Currency.name.length
          || !price.Currency.symbol.length) {
          this.alertaMsj = {
            content: 'Precio, Moneda y Símbolo son campos requeridos',
            type: 'warning',
          };
          validPrice = false;
        } else {
          price.amount = Number(price.amount);
        }
      });
      if (!validPrice) return;
      const service = {...this.servicioTemp};
      const variablesApollo = {
        service,
        serviceId: this.serviceId,
      };
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${serviceCopyGql}`,
        variables: variablesApollo,
      }).then((res) => {
        if (res.data.serviceCopy.msg == 'success') {
          this.alertaMsj = {
            content: 'Grupo de parámetros agregado',
            type: 'success',
          };
          // this.pruebas.push(pruebaItem);
          this.showServiceForm = false;
          this.servicioTemp = {
            name: '',
            description: '',
            prices: [
              {
                amount: '',
                Currency: {
                  name: '',
                  symbol: '',
                },
              },
            ],
            shareable: true,
          };
        } else {
          this.alertaMsj = {
            content: 'Hubo un error al agregar el grupo de parámetros',
            type: 'warning',
          };
        }
        this.btnDisabled = false;
      }).catch((error) => {
        this.btnDisabled = false;
        this.alertaMsj = {
          content: 'Hubo un error al agregar el grupo de parámetros',
          type: 'danger',
        };
      });
    },
    addPrice() {
      this.servicioTemp.prices.push({
        amount: '',
        Currency: {
          name: '',
          symbol: '',
        },
      });
    },
    cancelPrecio(precio) {
      this.servicioTemp.prices.splice(
          this.servicioTemp.prices.indexOf(precio), 1);
    },
    addTag() {
      if (this.newTag == '') return;
      const newTags = this.tags.some((t) =>
        t == this.newTag);
      if (newTags) {
        this.alertaMsj = {
          content: 'Esta etiqueta ya fue agregada',
          type: 'warning',
        };
      } else {
        this.tags.push(this.newTag);
      }
      this.newTag = '';
    },
    buscarMonedas(name) {
      if (name.length < 2) {
        return;
      }
      this.$apollo.query({
        query: gql`${currenciesByNameGql}`,
        variables: {
          name,
        },
      }).then((res) => {
        this.otrasMonedas = res.data.currenciesByName;
      }).catch((err) => {});
    },
  },
  apollo: {
    pruebas() {
      return {
        query: gql`${servicesGalleryGql}`,
        variables: () => {
          return {
            name: this.name.trim(),
            ordersBy: this.ordersBy,
            tags: this.tags,
          };
        },
        update: (data) => data.servicesGallery,
        debounce: 1000,
      };
    },
    especialidades() {
      return {
        query: gql`${tagsDegreesGql}`,
        variables: {
          name: 'Especialidades',
        },
        update: (data) => data.Tags_Group[0].tags,
      };
    },
    monedasAceptadas() {
      return {
        query: gql`${currenciesAcceptedGql}`,
        update: (data) => data.currenciesAccepted,
      };
    },
    profileInfo() {
      return {
        query: gql`${doctorGql}`,
        variables: {
          id: getPersonId(),
        },
        update: (data) => data.Doctor[0].profileInfo,
      };
    },
  },
};
</script>
<style lang="scss">
</style>
