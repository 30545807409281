<template>
  <div class="overflow-hidden">
    <p class="card-text text-center userSelect-none my-0">
      Selecciona una imagen para modificar o guardar
    </p>
    <!--eslint-disable vue/no-parsing-error -->
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-lg-4">
        <mdb-input
          v-model="imageTitle"
          class="my-1 mt-3"
          label="Título"
          placeholder="Nombre de imagen"
          outline
        />
      </div>
      <div class="col-12 col-lg-4">
        <div class="input-group">
          <div class="input-group-prepend">
            <span
              class="input-group-text"
            >Imagen principal:</span>
          </div>
          <div class="custom-file">
            <input
              ref="background"
              type="file"
              class="custom-file-input"
              id="inputGroupFile01"
              :disabled="drawing"
              @change="backgroundImage"
            >
            <label
              class="custom-file-label"
              for="inputGroupFile01"
            ></label>
          </div>
          <mdb-tooltip
            class="ml-2"
            trigger="hover"
            :options="{placement: 'right'}"
          >
            <div slot="tip">
              <small>
                Será la base de tu imagen, sobre ella puedes rayar, marcar
                o colocar otras imágenes, pero no la puedes cambiar de tamaño.
              </small>
            </div>
            <a slot="reference">
              <font-awesome-icon
                :icon="['fas', 'circle-question']"
                size="sm"
                class="d-inline mr-1"
              />
            </a>
          </mdb-tooltip>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="input-group">
          <div class="input-group-prepend">
            <span
              class="input-group-text"
            >Imagen extra:</span>
          </div>
          <div class="custom-file">
            <input
              ref="image"
              type="file"
              class="custom-file-input"
              outline
              @change="addImage"
            >
            <label
              class="custom-file-label"
              for="inputGroupFile01"
            />
          </div>
          <mdb-tooltip
            class="ml-2"
            trigger="hover"
            :options="{placement: 'right'}"
          >
            <div slot="tip">
              <small>
                Agrega sobre la imagen principal otra imagen que puedes
                redimensionar o rotar.
              </small>
            </div>
            <a slot="reference">
              <font-awesome-icon
                :icon="['fas', 'circle-question']"
                size="sm"
                class="d-inline mr-1"
              />
            </a>
          </mdb-tooltip>
        </div>
      </div>
      <div
        class="col-12 col-lg-auto d-flex flex-wrap
          justify-content-center align-items-center"
      >
        <label
          for="color-palette"
          class="d-flex p-2 mt-1 flat-bordered"
        >
          <font-awesome-icon icon="palette" />
          <input
            v-model="color"
            type="color"
            class="color-picker"
            id="color-palette"
          >
        </label>
        <mdb-btn
          :class="[
            'px-1 mx-0 mb-0 mb-md-0 mt-0 flat-bordered',
            {
              'pulse-bg animated infinite': drawing,
            }
          ]"
          dark-waves
          flat
          icon="pen"
          icon-class="fa-lg"
          size="sm"
          @click="dibujar"
        >
          {{ drawing ? 'Dejar de dibujar' : 'Dibujar' }}
        </mdb-btn>
        <mdb-btn
          class="px-1 mx-0 mb-0 mb-md-0 mt-0 flat-bordered"
          dark-waves
          flat
          icon="plus"
          icon-class="fa-lg"
          size="sm"
          :disabled="drawing"
          @click="addCircumference"
        >
          Círculo
        </mdb-btn>
        <mdb-btn
          class="px-1 mx-0 mb-0 mb-md-0 mt-0 flat-bordered"
          dark-waves
          flat
          icon="plus"
          icon-class="fa-lg"
          size="sm"
          :disabled="drawing"
          @click="addRect"
        >
          Rectángulo
        </mdb-btn>
        <mdb-btn
          class="px-1 mx-0 mb-0 mb-md-0 mt-0 flat-bordered"
          dark-waves
          flat
          icon="plus"
          icon-class="fa-lg"
          size="sm"
          :disabled="drawing"
          @click="addText"
        >
          Texto
        </mdb-btn>
        <div class="group-btn flat-bordered">
          <mdb-btn
            class="px-1 mx-0 mb-0 mb-md-0 mt-0"
            dark-waves
            flat
            icon="minus"
            icon-class="fa-lg"
            size="sm"
            :disabled="drawing"
            @click="resizeTo(0.9)"
          />
          <span class="btn-text">
            Tamaño
          </span>
          <mdb-btn
            class="px-1 mx-0 mb-0 mb-md-0 mt-0"
            dark-waves
            flat
            icon="plus"
            icon-class="fa-lg"
            size="sm"
            :disabled="drawing"
            @click="resizeTo(1.1)"
          />
        </div>
        <mdb-btn
          class="px-1 mx-0 mb-0 mb-md-0 mt-0 flat-bordered"
          dark-waves
          flat
          icon="trash"
          icon-class="fa-lg"
          size="sm"
          :disabled="drawing"
          @click="deleteObject"
        >
          Borrar selección
        </mdb-btn>
      </div>
    </div>
    <div class="canvas__scroll">
      <div class="canvas">
        <canvas
          id="fcanvas"
          ref="fcanvas"
          width="auto"
          height="320"
        />
      </div>
    </div>
    <!-- <div
      class="container-sm mensaje text-muted my-3 py-md-4 px-md-5"
    >
      <font-awesome-icon
        icon="image"
        size="6x"
        class="my-3"
      />
      <div class="px-sm-5">
        <h4 class="text-center">
          ¡Selecciona una imagen!
        </h4>
        <p>
          Al subir una imagen podrás editarla por aca.
        </p>
      </div>
    </div> -->
    <!-- <mdb-input
      v-model="imageTags"
      class="my-1"
      label="Etiquetas"
      placeholder="Una o varias"
      outline
    /> -->
    <mdb-btn
      color="success-base col"
      icon="save"
      icon-class="fa-lg"
      size="sm"
      @click="uploadImage"
    >
      Guardar
    </mdb-btn>
  </div>
</template>

<script>
import {fabric} from 'fabric';
import {
  blobToFile,
} from '@/utils/imageCompress.js';
import {
  mdbBtn,
  mdbInput,
  mdbTooltip,
} from 'mdbvue';
let canvas = null;

export default {
  name: 'DrawImage',
  components: {
    mdbBtn,
    mdbInput,
    mdbTooltip,
  },
  props: {
    entity: {
      type: String,
      require: true,
      default: '',
    },
    entityId: {
      type: String,
      require: true,
      default: '',
    },
    imageUrl: {
      type: String,
      require: false,
      default: '',
    },
  },
  data() {
    return {
      drawing: false,
      color: '#FF0000',
      imageTitle: '',
      imageTags: '',
      // imageTags: [],
    };
  },
  mounted() {
    canvas = new fabric.Canvas(this.$refs.fcanvas, {
      isDrawingMode: this.drawing,
    });
    document.querySelector('.canvas-container').style.margin =
      'auto';
  },
  methods: {
    dibujar() {
      this.drawing =! this.drawing;
      canvas.isDrawingMode = this.drawing;
      canvas.freeDrawingBrush.color = this.color;
    },
    addCircumference() {
      const circum = new fabric.Circle({
        top: 100,
        radius: 15,
        stroke: this.color,
        strokeWidth: 2,
        fill: 'rgba(0,0,0,0)',
      });
      canvas.add(circum);
      canvas.setActiveObject(circum);
    },
    addRect() {
      const rect= new fabric.Rect({
        top: 100,
        width: 50,
        height: 50,
        stroke: this.color,
        strokeWidth: 2,
        fill: 'rgba(0,0,0,0)',
      });
      canvas.add(rect);
    },
    addText() {
      const text= new fabric.Textbox('Escribe aquí', {
        top: 100,
        fontSize: 20,
        fill: this.color,
      });
      canvas.add(text);
    },
    addImage: function() {
      const file = this.$refs.image.files[0];
      const reader = new FileReader();
      reader.onload = function(f) {
        const data = f.target.result;
        fabric.Image.fromURL(data, function(img) {
          const oImg = img.set({
            left: 0, top: 0,
            angle: 0/* , width: 100, height: 100 */}).scale(0.9);
          canvas.add(oImg).renderAll();
          // const a = canvas.setActiveObject(oImg);
          // const dataURL = canvas.toDataURL({format: 'png', quality: 0.8});
        });
      };
      reader.readAsDataURL(file);
    },
    resizeTo(sizeModify) {
      const myCanva = document.querySelector('#fcanvas');
      const upperCanva = document.querySelector('.upper-canvas');
      if (this.$refs.background.files[0]) {
        console.log('debug1', sizeModify, myCanva.width);
        myCanva.width *= sizeModify;
        myCanva.height *= sizeModify;
        this.backgroundImage('modify', myCanva.width, myCanva.height);
      } else {
        console.log('debug2', sizeModify, myCanva.width);
        const tempCanvas = document.createElement('canvas');
        const tctx = tempCanvas.getContext('2d');

        const cw = myCanva.width;
        const ch = myCanva.height;
        tempCanvas.width = cw;
        tempCanvas.height = ch;
        tctx.drawImage(myCanva, 0, 0);
        const ctx=canvas.getContext('2d');
        canvas.getObjects().forEach((o)=>{
          canvas.remove(o);
        });
        ctx.drawImage(tempCanvas, 0, 0, cw, ch, 0, 0,
            cw*sizeModify, ch*sizeModify);
        this.canvasToBlob()
            .then((file)=>{
              file = blobToFile(file, Date.now());
              const reader = new FileReader();
              reader.onload = function(f) {
                const data = f.target.result;
                fabric.Image.fromURL(data, function(img) {
                  const oImg = img.set({
                    left: 0, top: 0,
                    angle: 0,
                    width: myCanva.width*sizeModify,
                    height: myCanva.height*sizeModify,
                  });

                  myCanva.width *= sizeModify;
                  myCanva.height *= sizeModify;
                  upperCanva.width = myCanva.width;
                  upperCanva.height = myCanva.height;
                  canvas.add(oImg).renderAll();
                });
              };
              reader.readAsDataURL(file);
            });
      }
    },
    backgroundImage: function(sizeModify, w, h) {
      if (!this.$refs.background.files[0]) return;
      const file = this.$refs.background.files[0];
      const reader = new FileReader();
      reader.onload = function(f) {
        const data = f.target.result;
        fabric.Image.fromURL(data, function(img) {
          if (sizeModify !== 'modify') {
            canvas.setWidth(img.width);
            canvas.setHeight(img.height);
          } else {
            canvas.setWidth(w);
            canvas.setHeight(h);
          }
          canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
            scaleX: canvas.width / img.width,
            scaleY: canvas.height / img.height,
          });
        });
      };
      reader.readAsDataURL(file);
    },
    deleteObject() {
      const active = canvas.getActiveObject();
      if (active) {
        canvas.remove(active);
        if (active.type == 'activeSelection') {
          active.getObjects().forEach((x) => canvas.remove(x));
          canvas.discardActiveObject().renderAll();
        }
      }
    },
    uploadImage() {
      canvas.discardActiveObject().renderAll();
      this.canvasToBlob()
          .then((image) => {
            const formData = new FormData();

            formData.append('file',
                blobToFile(image, Date.now()),
            );
            formData.append('entityId', this.entityId);
            formData.append('entity', this.entity);
            formData.append('title', this.imageTitle);
            formData.append('tags', this.imageTags);
            // formData.append('tags', this.imageTags.join('|'));

            const endpoint = process.env.NODE_ENV === 'production'
                      ? process.env.VUE_APP_MULTIMEDIA_SUBIR_S
                      : process.env.VUE_APP_MULTIMEDIA_SUBIR;
            fetch(endpoint, {
              method: 'POST',
              headers: {'Authorization': 'Bearer '
                + localStorage.getItem('apollo-token')},
              body: formData,
            })
                .then((res) => res.json())
                .then((res)=>{
                  const data = res.data;
                  if (data.imageCreate[0] && data.imageCreate[0].id) {
                    this.$emit('imageSaved', data.imageCreate[0]);
                    this.alertaMsj = {
                      content: 'Imagen guardada',
                      type: 'success',
                    };
                    this.showConfirmacionEliminar = false;
                  } else {
                    this.alertaMsj = {
                      content: 'Intenta guardar la imagen de nuevo',
                      type: 'warning',
                    };
                  }
                }).catch((err)=>{
                  this.alertaMsj = {
                    content: 'No se guardó la imagen. Intenta de nuevo o '
                    +' comunícate con soporte.',
                    type: 'error',
                  };
                });
          });
      // const fcanvas = document.querySelector('#fcanvas');
      // fcanvas.toBlob(
      //     async (blob) => {
      //       const newImg = document.createElement('img');
      //       const url = URL.createObjectURL(blob);

      //       newImg.onload = () => {
      //         // no longer need to read the blob so it's revoked
      //         URL.revokeObjectURL(url);
      //       };

      //       newImg.src = url;
      //       document.body.appendChild(newImg);
      //     },
      //     'image/png',
      //     0.8,
      // );
    },
    canvasToBlob() {
      return new Promise((resolve, reject) =>{
        const fcanvas = document.querySelector('#fcanvas');
        fcanvas.toBlob(
            async (blob) => {
              if (blob) {
                resolve(blob);
              }
            },
            'image/jpeg',
            0.8,
        );
      });
    },
    fromURLtoCanvas() {
      if (this.imageUrl) {
        fetch(this.imageUrl)
            .then((result) => result.blob())
            .then((blob) => {
              const url = URL.createObjectURL(blob);
              fabric.Image.fromURL(url, function(img) {
                canvas.setWidth(img.width);
                canvas.setHeight(img.height);
                canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                });
              });
            });
      }
    },
  },

};
</script>

<style>
  .btn-text {
    font-size: small;
    text-transform: uppercase;
  }
  .fabric {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .canvas__scroll {
    width: 100%;
    height: 55vh;
    overflow: scroll;
    margin: 5px auto;
  }

  canvas {
    overflow-x: scroll;
    border: 1px solid #cbcbcb;
  }
  .canvas {
    height: auto;
    width: auto;

  }
  #fcanvas, #fcanvas~* {
    background-size: cover !important;

    height: auto !important;
    width: auto !important;
  }
  .color-picker {
    background-color: transparent;
    border-style: none;
    border-radius: 2rem;
    height: 1rem;
    width: 2rem;
  }
</style>
