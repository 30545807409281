<template>
  <section>
    <ul class="list-unstyled">
      <li>
        <mdb-btn
          flat
          dark-waves
          class="py-1 px-2 w-100"
          @click="showDicomReader = true"
        >
          <font-awesome-icon icon="x-ray" />
          Lector de Dicom
        </mdb-btn>
      </li>
    </ul>
    <mdb-modal
      centered
      size="fluid"
      :show="showDicomReader"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          Lector de Dicom
        </mdb-modal-title>
        <a
          class="close"
          @click="showDicomReader = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <LectorDicom />
        <div class="modal-footer-btn">
          <mdb-btn
            flat
            dark-waves
            icon="times"
            class="flat-bordered"
            @click="showDicomReader = false"
          >
            Cerrar
          </mdb-btn>
          <mdb-btn
            type="submit"
            color="success-base"
            icon="check"
            :disabled="true"
            @click="console.log('guardar lectura')"
          >
            Añadir
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>
  </section>
</template>
<script>
import {mdbBtn, mdbModal, mdbModalHeader, mdbModalTitle,
  mdbModalBody} from 'mdbvue';
import LectorDicom from './Dicom/LectorDicom.vue';
export default {
  name: 'ModalLectorDicom',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    LectorDicom,
  },
  data() {
    return {
      showDicomReader: false,
    };
  },
};
</script>
