/**
 * Ckeditor instance
 * @param {Object} doc
 * @param {Object} bodyContent
*/
export function printRecipeCk(doc, bodyContent) {
  const snippetCSSElement = [...document.querySelectorAll('link')]
      .find((linkElement) => linkElement.href.endsWith('snippet.css'));

  const iframeElement = document.querySelector('#print-data-container');

  iframeElement.srcdoc = '<html>'
          +'<head>'
          +`<title>${document.title}</title>`
          + `<link rel='stylesheet' href="
          ${snippetCSSElement.href}" type='text/css'>`
          +`<style>`
          +`.bg-edit {height:95vh;}`
          +`.bg-edit::before {content: ' '; opacity: ${doc.bgImgOpacity};`
          +` position: absolute; display: block; left: 0; top: 0;`
          +` width: 100%; height: 90vh;`
          +` background-position: ${doc.bgImgPosition};`
          +` background-repeat: ${doc.bgImgRepeat};`
          +` background-size: ${doc.bgImgSize};`
          +` background-color: ${doc.bgColor};`
          +` background-image: url('${doc.bgImgUrl}');  z-index: -1;}`
          + `.container-recipe{`
          + `display: grid;`
          + `grid-template-columns: 1fr 1fr;`
          + `}`
          + `.header-recipe{ position: fixed; top: 0; width: 50%;}`
          + `.content-recipe li{margin-bottom:10px;}`
          + `.footer-recipe{ position: fixed; bottom: 0; width: 50%}`
          + `.on-header-p{ margin: 0; }`
          +`</style>`
          +'</head>'
          + `<body class="ck-content"`
          + `style="print-color-adjust: exact;">`
          + `<div class="container-recipe">`
          + `<div class="first-colum">`
          + `<div class="header-recipe bg-edit">`
          + doc.header
          + '<div class="content-recipe">'
          + '<p class="on-header-p">Medicamentos</p>'
          + bodyContent?.medicationList
          + '</div>'
          + '</div>'
          + `<div class="footer-recipe">`
          + doc.footer
          + '</div>'
          + '</div>'
          + `<div class="second-colum">`
          + `<div class="header-recipe bg-edit">`
          + doc.header
          + '<div class="content-recipe">'
          + '<p class="on-header-p">Indicaciones</p>'
          + bodyContent.indicationList
          + '</div>'
          + '</div>'
          + `<div class="footer-recipe">`
          + doc.footer
          + '</div>'
          + '</div>'
          + '</div>'
          + (doc.bgImgUrl
            ? `<img src='${doc.bgImgUrl}' style="height:0px">` : '')
          +'<script>'
          +'window.addEventListener( \'load\', '
          +'() => { window.print(); } );'
          +'</script>'
          + '</body>'
           +'</html>';
}
