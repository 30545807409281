const histConfDefault = {
  reasons: {
    name: 'Motivos',
    vis: 2,
  },
  actualDisease: {
    name: 'Enfermedad actual',
    vis: 2,
  },
  historyItems: {
    name: 'Antecedentes',
    vis: 2,
  },
  immunizations: {
    name: 'Vacunas',
    vis: 1,
  },
  allergies: {
    name: 'Alergias',
    vis: 2,
  },
  conditionsEspecials: {
    name: 'Condiciones especiales',
    vis: 2,
  },
  conditionsTemporals: {
    name: 'Condiciones temporales',
    vis: 2,
  },
  service: {
    name: 'Grupos de parámetros',
    vis: 2,
  },
  charts: {
    name: 'Gráficas',
    vis: 2,
  },
  physicalExam: {
    name: 'Examen físico',
    vis: 2,
  },
  symptoms: {
    name: 'Diagnóstico',
    vis: 2,
  },
  previousOrdersDetails: {
    name: 'Paraclínicos anteriores',
    vis: 2,
  },
  orders: {
    name: 'Paraclínicos complementarios',
    vis: 2,
  },
  notes: {
    name: 'Notas adicionales',
    vis: 2,
  },
  images: {
    name: 'Imágenes',
    vis: 2,
  },
  tools: {
    name: 'Herramientas',
    vis: 1,
  },
  labels: {
    name: 'Etiquetas',
    vis: 2,
  },
  recipe: {
    name: 'Recipe',
    vis: 2,
  },
  bill: {
    name: 'Facturación',
    vis: 2,
  },
  documents: {
    name: 'Documentos',
    vis: 2,
  },
  nextAppointment: {
    name: 'Próxima cita',
    vis: 2,
  },
};

export {
  histConfDefault,
};
