<template>
  <div class="mt-lg-3 pruebas-clinicas-view">
    <!-- Loading -->
    <div
      v-if="$apolloData.queries.services.loading"
      class="text-center text-muted vertical-centered"
    >
      <font-awesome-icon
        :icon="['fas', 'circle-notch']"
        class="fa-spin fa-5x my-4"
      />
      <small class="d-block">
        CARGANDO
      </small>
    </div>
    <div v-else>
      <header class="mb-2 h3-responsive text-center
        d-flex">
        <div
          class="col fs-inherit"
          @click="$apollo.queries.services.refresh()"
        >
          <router-link
            class="col d-block fs-inherit cursor-pointer d-flex
              justify-content-center align-items-center
              link-icon-hover tab-link"
            to="/configuracion/pruebas-clinicas"
          >
            Mis parámetros
          </router-link>
        </div>
        <router-link
          class="col d-block fs-inherit cursor-pointer d-flex
            justify-content-center align-items-center
            link-icon-hover tab-link"
          to="/configuracion/pruebas-clinicas/catalogo"
        >
          Catálogo
        </router-link>
      </header>
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
        <section v-if="!rutaHija">
          <mdb-btn
            class="m-0 px-3 fixed-br-mobile"
            color="success-base"
            icon="plus"
            icon-class="fa-lg"
            rounded
            @click="newPruebaClinica"
          >
            Crear grupo
          </mdb-btn>
          <!-- Contenido de los servicios -->
          <div v-if="services.length" class="d-flex flex-wrap my-3">
            <div
              v-for="servicio in services"
              :key="servicio.id"
              class="col-12 col-md-6"
            >
              <!-- Descripción -->
              <mdb-card
                class="mb-2"
              >
                <mdb-card-body>
                  <div class="d-flex align-items-center">
                    <mdb-card-title class="flex-fill mb-0">
                      {{ servicio.name }}
                    </mdb-card-title>
                    <mdb-btn
                      class="m-0 my-1 p-2 flex-align-center"
                      flat
                      dark-waves
                      icon="pen"
                      size="md"
                      title="Editar grupo"
                      @click="editService(servicio);
                        servicioSeleccionado = servicio"
                    />
                    <mdb-btn
                      class="m-0 my-1 p-2 flex-align-center"
                      flat
                      dark-waves
                      icon="trash"
                      icon-class="danger-text"
                      size="md"
                      type="button"
                      title="Eliminar grupo"
                      @click="showConfirmacionEliminar = true;
                              name = servicio.name;
                              servicioSeleccionado=servicio;
                              aEliminar = { tipo: 'servicio'}"
                    />
                  </div>
                  <mdb-card-text>
                    <section>
                      <span class="font-weight-bold">
                        Descripción:
                      </span>
                      {{ servicio.description }}
                      <p class="mt-2 mb-1">
                        <span class="font-weight-bold">
                          Información adicional
                        </span>
                        <mdb-badge
                          :color="servicio.status == 'Activado'
                            || true ?
                              'bg-success-base' : 'grey'"
                          class="z-depth-0 ml-1"
                        >
                          {{ servicio.status
                            ? 'Activado' : 'Desactivado' }}
                        </mdb-badge>
                        <mdb-badge
                          :color="servicio.shareable
                            ? 'info' : 'blue-grey lighten-2'"
                          class="z-depth-0 ml-1"
                        >
                          {{ servicio.shareable
                            ? 'Compartida' : 'No compartida' }}
                        </mdb-badge>
                      </p>
                    </section>
                    <!-- Precios -->
                    <section>
                      <span class="font-weight-bold">
                        Lista de precios
                      </span>
                      <ul
                        v-if="servicio.prices
                          && servicio.prices.length"
                        class="list-unstyled row mx-0"
                      >
                        <li
                          v-for="(precio,i) in servicio.prices"
                          :key="i"
                          :title="precio.Currency.name"
                          class="col-12 col-lg-6 col-lg-4 pl-0 pr-2"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'money-bill-wave']"
                            size="lg"
                          />
                          {{ precio.Currency.symbol }} {{ precio.amount }}
                        </li>
                      </ul>
                      <div
                        v-else
                        class="row justify-content-center mx-0"
                      >
                        <p class="col-auto text-muted">
                          No tiene precios registrados
                        </p>
                      </div>
                    </section>
                    <!-- Añadir parámetros -->
                    <section>
                      <span class="font-weight-bold">
                        Lista de parámetros
                      </span>
                      <div
                        class="d-flex flex-wrap">
                        <mdb-btn
                          class="col-12 col-sm p-1 m-0"
                          dark-waves
                          flat
                          icon="plus-circle"
                          icon-class="success-text"
                          size="sm"
                          title="Agregar parámetro"
                          @click="newParameter();
                                    servicioSeleccionado = servicio"
                          >
                          Agregar parámetro
                        </mdb-btn>
                        <mdb-btn
                          v-if="servicio.params
                            && servicio.params.length"
                          class="col-12 col-sm p-1 m-0
                            flat-bordered flex-center"
                          dark-waves
                          flat
                          icon="eye"
                          icon-class="fa-lg"
                          size="sm"
                          @click="showModalService = true;
                                  servicioSeleccionado=servicio"
                        >
                          Vista previa y reordenar
                        </mdb-btn>
                      </div>
                      <div
                        v-if="servicio.params
                          && servicio.params.length"
                        class="row mx-0"
                      >
                        <div
                          v-for="(parOr, i) in servicio.params"
                          :key="i"
                          class="col-12 col-xl-6 px-0"
                        >
                          <div
                            class="row justify-content-between
                              align-items-center flex-nowrap
                              w-100 mx-0 pr-2"
                          >
                            <div class="col p-0 text-break">
                              {{ parOr.Parameter.name }}
                            </div>
                            <div class="col-auto pl-2 pr-0">
                              <!--
                                Mostrar el tooltip SOLO si tiene
                                información referencial asociada
                              -->
                              <mdb-tooltip
                                v-if="
                                  infoReference(parOr.Parameter.reference)"
                                class="p-1"
                                trigger="hover"
                                :options="{placement: 'left'}"
                              >
                                <div slot="tip">
                                  <small class="font-weight-bold text-nowrap">
                                    <font-awesome-icon
                                      :icon="['fas', 'circle-info']"
                                      class="d-inline"
                                    />
                                    Información Referencial
                                  </small>
                                  <small
                                    v-if="
                                      parOr.Parameter.reference.quantityHigh"
                                    class="mb-1"
                                  >
                                    Alta:
                                    {{ parOr.Parameter.
                                      reference.quantityHigh }}
                                  </small>
                                  <small
                                    v-if="
                                      parOr.Parameter.reference.quantityLow"
                                    class="mb-1"
                                  >
                                    Baja:
                                    {{ parOr.Parameter.
                                      reference.quantityLow }}
                                  </small>
                                  <small
                                    v-if="parOr.Parameter.reference.text"
                                    class="mb-1 text-break"
                                  >
                                    Interpretiva:
                                    {{ parOr.Parameter.reference.text }}
                                  </small>
                                </div>
                                <a slot="reference">
                                  <font-awesome-icon
                                    :icon="['fas', 'circle-info']"
                                    class="d-inline mr-1"
                                  />
                                </a>
                              </mdb-tooltip>
                              <mdb-btn
                                class="m-1 p-1"
                                icon="pen"
                                size="sm"
                                dark-waves
                                flat
                                title="Editar parámetro"
                                @click="editParameter(parOr.Parameter);
                                        servicioSeleccionado=servicio"
                              />
                              <mdb-btn
                                class="m-0 p-1"
                                icon="minus-circle"
                                icon-class="danger-text"
                                size="sm"
                                dark-waves
                                flat
                                title="Eliminar parámetro"
                                @click="showConfirmacionEliminar = true;
                                        name = parOr.Parameter.name;
                                        servicioSeleccionado=servicio;
                                        aEliminar = {
                                          objeto: parOr.Parameter,
                                          tipo: 'parametro'
                                        }"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else
                        class="container"
                      >
                        <div class="flex-align-center flex-column text-muted">
                          <p class="mb-2">
                            Emplea los pasos a continuación
                            para crear algunos parámetros:
                          </p>
                          <ol class="px-4">
                            <li>
                              Haz clic en el botón
                              <span
                                class="fs-08 m-0 p-2 btn btn-flat
                                  ripple-parent userSelect-none"
                                title="Agregar parámetro"
                                @click="newParameter;
                                        servicioSeleccionado=servicio"
                              >
                                <font-awesome-icon
                                  :icon="['fas', 'circle-plus']"
                                  class="success-text"
                                />
                                Agregar parámetro
                              </span>
                            </li>
                            <li>
                              Completa los datos solicitados en el formulario
                            </li>
                            <li>
                              Finalmente, haz clic en
                              <span
                                class="btn btn-success-base btn-sm m-0 px-3
                                userSelect-none"
                                @click="newParameter;
                                        servicioSeleccionado=servicio"
                              >
                                <font-awesome-icon
                                  :icon="['fas', 'plus']"
                                />
                                Añadir
                              </span>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </section>
                  </mdb-card-text>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
          <div
            v-else
            class="container"
          >
            <div class="flex-align-center flex-column text-muted">
              <p class="h3-responsive text-center mb-2">
                Crea un grupo de parametros para comenzar
              </p>
              <ol class="px-4">
                <li>
                  Haz clic en el botón
                  <mdb-btn
                    class="m-0 px-3 d-inline-flex align-items-center"
                    color="success-base"
                    icon="plus"
                    icon-class="fa-lg"
                    size="sm"
                    rounded
                    @click="newPruebaClinica"
                  >
                    Crear grupo
                  </mdb-btn>
                </li>
                <li>
                  Completa los datos solicitados en el formulario
                </li>
                <li>
                  Haz clic en
                  <span
                    class="btn btn-success-base btn-sm m-0 px-3"
                    @click="newPruebaClinica"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'plus']"
                    />
                    Añadir
                  </span>
                </li>
              </ol>
              <p class="font-italic text-center mx-4">
                Para más información ver en el Centro de Ayuda:
                <router-link
                  :to="{ name: 'Ayuda', hash: '#parametros-0'}"
                  tag="span"
                  class="fs-inherit cursor-pointer d-inline-flex
                    justify-content-center align-items-center
                    link-icon-hover"
                >
                  <span class="mx-1 fs-inherit text-underline">
                    ¿Cómo agregar un parámetro a la prueba clínica?
                  </span>
                  <font-awesome-icon
                    :icon="['fas', 'angle-right']"
                    class="my-2 fa-md icon"
                  />
                </router-link>
              </p>
              <p class="h3-responsive text-center mb-2">
                Tambien puedes ir al
                <router-link
                  to="/configuracion/pruebas-clinicas/catalogo"
                  tag="span"
                  class="fs-inherit cursor-pointer d-inline-flex
                    justify-content-center align-items-center
                    link-icon-hover text-primary"
                >
                  <span class="mx-1 fs-inherit text-underline">
                    Catálogo
                  </span>
                  <font-awesome-icon
                    :icon="['fas', 'angle-right']"
                    class="my-2 fa-md icon"
                  />
                </router-link>
              </p>
              <p class="text-center">
                y buscar en los grupos de parametros
                creados por otros profesionales
              </p>
            </div>
          </div>
        </section>
        <section v-else>
          <router-view />
        </section>
      </Transition>
    </div>
    <!-- Modal para Nuevo / Modificar servicio -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="showServiceForm"
      @shown="$refs.servicioRef.focus()"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          {{ serviceForm.title }}
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModalServiceForm"
          class="close"
          @click="showServiceForm = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <form @submit.prevent="serviceMutate()">
          <div class="w-100">
            <mdb-input
              ref="servicioRef"
              v-model.trim="servicioTemp.name"
              class="mt-0 mb-3"
              label="Nombre del grupo"
              placeholder="Ej: Consulta"
              outline
            />
          </div>
          <div class="w-100">
            <mdb-input
              v-model="servicioTemp.description"
              type="textarea"
              class="my-2"
              label="Descripción"
              :rows="4"
              outline
            />
          </div>
          <div class="our-checkbox2 userSelect-none text-muted">
            <label>
              <input
                v-model="servicioTemp.shareable"
                type="checkbox"
                name="input-shareable"
              >
              <span />
              Compartir parámetros
            </label>
            <mdb-tooltip
              class="ml-2"
              trigger="hover"
              :options="{placement: 'left'}"
            >
              <div slot="tip">
                <small>
                  Otros usuarios podrán elegir tu formato de parámetros para
                  usarlo en su toma de consulta. Puedes ganar premios si tu
                  formato se convierte en el más popular. Esto no comparte los
                  datos de tus pacientes. Para ver formatos compartidos por
                  otros, visita el menú Galería.
                </small>
              </div>
              <a slot="reference">
                <font-awesome-icon
                  :icon="['fas', 'circle-question']"
                  size="sm"
                  class="d-inline mr-1"
                />
              </a>
            </mdb-tooltip>
          </div>
          <div class="row mt-3">
            <header class="col font-weight-bold">
              Precios
            </header>
            <mdb-btn
              class="col-auto m-0 mr-3 p-1"
              flat
              dark-waves
              icon="plus-circle"
              icon-class="success-text"
              size="sm"
              title="Añadir precio"
              type="button"
              @click="addPrice"
            />
          </div>
          <div
            v-for="(precio,i) in servicioTemp.prices"
            :key="i"
            class="row"
          >
            <div
              :class="['col', {'pr-1': servicioTemp.prices.length > 1}]"
            >
              <div class="row">
                <div class="col-12">
                  <mdb-input
                    v-model="precio.amount"
                    type="number"
                    class="my-2"
                    label="Precio"
                    :min="0"
                    :step="0.01"
                    outline
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <CustomDatalist
                    v-model="precio.Currency.name"
                    :list="monedas"
                    class-name="my-1"
                    label="Moneda"
                    :identificador="i"
                    :outline="true"
                    @input="precio.Currency.symbol='';
                            buscarMonedas(precio.Currency.name)"
                    @save="precio.Currency.symbol=$event.symbol;
                           precio.Currency.name=$event.name;"
                  >
                    <template
                      slot-scope="scope"
                    >
                      <li
                        v-for="(mon, ii) in monedas"
                        :key="i+'.'+ii"
                        @click="scope.selected(mon)"
                        @mousedown.prevent
                      >
                        {{ mon.name }}
                        <small> - </small>
                        <small>
                          {{ mon.symbol }}.
                        </small>
                      </li>
                    </template>
                  </CustomDatalist>
                </div>
                <div class="col-12 col-sm-6">
                  <mdb-input
                    v-model="precio.Currency.symbol"
                    class="my-1"
                    label="Símbolo"
                    outline
                  />
                </div>
              </div>
            </div>
            <mdb-btn
              v-if="servicioTemp.prices.length > 1"
              class="col-auto px-2"
              flat
              dark-waves
              icon="times"
              title="Eliminar precio"
              @click="cancelPrecio(precio)"
            />
          </div>
          <div
            v-if="serviceForm.btnLabel == 'Actualizar'"
            class="d-flex align-items-center mt-2"
          >
            <header class="col pl-0 font-weight-bold">
              Estado de la prueba clínica
            </header>
            <div class="col-auto pr-0 custom-control custom-switch">
              <input
                id="service-state"
                v-model="servicioTemp.status"
                type="checkbox"
                class="custom-control-input"
              >
              <label
                v-if="servicioTemp.status"
                for="service-state"
                class="custom-control-label cursor-pointer"
              >
                Activado
              </label>
              <label
                v-else
                for="service-state"
                class="custom-control-label cursor-pointer"
              >
                Desactivado
              </label>
            </div>
          </div>
          <!-- Botonces del modal -->
          <div class="modal-footer-btn">
            <mdb-btn
              flat
              dark-waves
              icon="times"
              type="button"
              class="flat-bordered"
              @click="showServiceForm = false"
            >
              Cerrar
            </mdb-btn>
            <BtnLoader
              btn-type="submit"
              :btn-disabled="btnDisabled"
              :btn-icon="serviceForm.btnIcon"
              :btn-title="serviceForm.btnLabel"
            />
          </div>
        </form>
      </mdb-modal-body>
    </mdb-modal>
    <!-- Modal para agregar / modificar parámetro -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="showParameterForm"
      @shown="$refs.parametroRef.focusInput()"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          {{ parameterForm.title }}
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModalParameterForm"
          class="close"
          @click="showParameterForm = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <form @submit.prevent="parameterMutate()">
          <div class="row justify-content-center">
            <div class="col-12">
              <CustomDatalist
                ref="parametroRef"
                v-model="parametroTemp.name"
                :list="parametrosOtros"
                class-name="my-1"
                label="Nombre del parámetro"
                :outline="true"
                @input="buscarParametros(parametroTemp.name)"
                @save="parametroTemp.symbol=$event.symbol || '';
                       parametroTemp.unit=$event.unit;
                       parametroTemp.type=$event.type;
                       parametroTemp.options=$event.options;
                       parametroTemp.name=$event.name;"
              >
                <template
                  slot-scope="scope"
                >
                  <li
                    v-for="(par, ii) in parametrosOtros"
                    :key="'p.'+ii"
                    @click="scope.selected(par)"
                    @mousedown.prevent
                  >
                    {{ par.name }}
                    <small>
                      - {{ par.symbol }} - {{ par.unit }}.
                    </small>
                  </li>
                </template>
              </CustomDatalist>
            </div>
            <div class="col-12 col-md-6">
              <mdb-input
                v-model="parametroTemp.unit"
                class="my-1"
                label="Unidad"
                type="text"
                outline
              />
            </div>
            <div class="col-12 col-md-6">
              <mdb-input
                v-model="parametroTemp.symbol"
                class="my-1"
                label="Símbolo"
                type="text"
                outline
              />
            </div>
          </div>
          <p class="font-weight-bold text-center mt-2 mb-1">
            Tipo de parámetro
          </p>
          <!-- Lista de parámetros -->
          <div class="row justify-content-between">
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="parametroTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="boolean"
                  >
                  <span />
                  Verdadero / Falso
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="parametroTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="date"
                  >
                  <span />
                  Fecha
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="parametroTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="datetime"
                  >
                  <span />
                  Fecha y hora
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="parametroTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="decimal"
                  >
                  <span />
                  Numérico decimal
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="parametroTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="integer"
                  >
                  <span />
                  Numérico entero
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="parametroTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="rangeDates"
                  >
                  <span />
                  Rango de fechas
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="parametroTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="range"
                  >
                  <span />
                  Rango numérico
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="parametroTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="simpleOption"
                  >
                  <span />
                  Selección simple
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="parametroTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="multipleOption"
                  >
                  <span />
                  Selección múltiple
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="parametroTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="text"
                  >
                  <span />
                  Texto
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="parametroTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="file"
                  >
                  <span />
                  Imagen
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="our-radio2 px-2">
                <label class="fs-09 w-100">
                  <input
                    v-model="parametroTemp.type"
                    type="radio"
                    name="radioSelect"
                    value="draw"
                  >
                  <span />
                  Lienzo
                </label>
              </div>
            </div>
          </div>
          <!-- Mostrar cuando el parámetro sea de tipo selección simple -->
          <div
            v-if="parametroTemp.type=='simpleOption'
              || parametroTemp.type=='multipleOption'"
          >
            <p class="font-weight-bold text-center mt-2 mb-1">
              Opciones para la selección
            </p>
            <div class="row justify-content-center">
              <div class="col-12">
                <mdb-input
                  v-model="newOption"
                  class="my-2 icon-input-group"
                  placeholder="Añadir opción"
                  outline
                >
                  <mdb-btn
                    slot="append"
                    :icon-class="['success-text',
                                  {'animated pulse-bg infinite': newOption}]"
                    dark-waves
                    flat
                    group
                    icon="plus-circle"
                    size="sm"
                    title="Añadir"
                    type="button"
                    :disabled="!newOption"
                    @click="addOption(newOption)"
                  />
                </mdb-input>
              </div>
              <div
                v-for="(option,i) in parametroTemp.options"
                :key="'po'+i"
                class="col-12"
              >
                <mdb-input
                  class="my-2 icon-input-group"
                  :value="option"
                  outline
                >
                  <mdb-btn
                    slot="append"
                    icon-class="danger-text"
                    dark-waves
                    flat
                    group
                    icon="minus-circle"
                    size="sm"
                    title="Eliminar"
                    type="button"
                    @click="optionParameterDelete(i)"
                  />
                </mdb-input>
              </div>
            </div>
          </div>
          <!-- Mostrar cuando el parámetro sea de tipo Draw -->
          <div
            v-if="parametroTemp.type=='draw'"
          >
            <header class="mb-2 mt-3 font-weight-bold text-center">
              Establece un fondo
            </header>
            <p class="text-center">
              Agrega una imagen de fondo para dibujar sobre ella.
            </p>
            <img
              v-if="parametroTemp.options && parametroTemp.options[0]"
              :src="endpoint+parametroTemp.options[0]"
              crossorigin="anonymous"
              alt=""
            >
            <div class="row justify-content-between align-items-center">
              <mdb-btn
                class="col m-0 flat-bordered"
                dark-waves
                flat
                icon="print"
                icon-class="fa-lg"
                size="sm"
                @click="
                  showNewParImage = true"
              >
                Agregar fondo
              </mdb-btn>
            </div>
          </div>
          <!-- Mostrar cuando el parámetro sea de tipo Rango -->
          <div
            v-if="parametroTemp.type=='range'"
          >
            <header class="mb-2 mt-3 font-weight-bold text-center">
              Establece el rango
            </header>
            <div class="row justify-content-between align-items-center">
              <div class="col-12 col-sm-6">
                <mdb-input
                  v-model="range.rangeLow"
                  type="number"
                  class="my-2"
                  label="Desde"
                  outline
                />
              </div>
              <div class="col-12 col-sm-6">
                <mdb-input
                  v-model="range.rangeHigh"
                  type="number"
                  class="my-2"
                  label="Hasta"
                  outline
                />
              </div>
            </div>
          </div>
          <!-- Información referencial -->
          <template
            v-if="parametroTemp.type=='integer'
              || parametroTemp.type=='decimal'"
          >
            <div class="row mb-2 mt-3 ">
              <header class="col font-weight-bold text-center">
                Valores de referencia
              </header>
              <span class="col">(opcional)</span>
              <mdb-btn
                v-if="parameterForm.btnLabel == 'Actualizar'"
                class="col-auto m-0 mr-3 p-1"
                flat
                dark-waves
                icon="broom"
                icon-class="fa-flip-horizontal"
                size="sm"
                title="Borrar información referencial"
                type="button"
                :disabled="btnDisabled ||
                  !infoReference(parametroSeleccionado.reference)"
                @click="referenceDelete(parametroTemp.reference.id)"
              />
            </div>
            <div class="row justify-content-between align-items-center">
              <div class="col-12 col-sm-6">
                <mdb-input
                  v-model="parametroTemp.reference.quantityHigh"
                  type="number"
                  class="my-2"
                  label="Cantidad alta"
                  outline
                />
              </div>
              <div class="col-12 col-sm-6">
                <mdb-input
                  v-model="parametroTemp.reference.quantityLow"
                  type="number"
                  class="my-2"
                  label="Cantidad baja"
                  outline
                />
              </div>
              <div class="col-12">
                <mdb-input
                  v-model="parametroTemp.reference.text"
                  class="my-2"
                  label="Interpretativa"
                  outline
                />
              </div>
            </div>
          </template>
          <!-- Botonces del modal -->
          <div class="modal-footer-btn">
            <mdb-btn
              flat
              dark-waves
              icon="times"
              type="button"
              class="flat-bordered"
              @click="showParameterForm = false;
                servicioSeleccionado={}"
            >
              Cerrar
            </mdb-btn>
            <BtnLoader
              btn-type="submit"
              :btn-disabled="btnDisabled"
              :btn-icon="parameterForm.btnIcon"
              :btn-title="parameterForm.btnLabel"
            />
          </div>
        </form>
      </mdb-modal-body>
    </mdb-modal>
    <!-- Modal para agregar imagen como parámetro -->
    <mdb-modal
      scrollable
      :show="showNewParImage"
      @shown="$refs.drawImage.fromURLtoCanvas()"
      size="fluid"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          Nueva imagen como parámetro
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarNewImage"
          class="close"
          @click="showNewParImage = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body
        v-if="parametroTemp.options"
      >
        <DrawImage
          ref="drawImage"
          :entity-id="servicioSeleccionado.id"
          :entity="'Service'"
          :image-url="parametroTemp.options[0]
            ? endpoint+parametroTemp.options[0] : ''"
          @imageSaved="guardarUrl($event.url)"
        />
      </mdb-modal-body>
    </mdb-modal>
    <!--
        Modal para Copiar
        parámetros del servicio
    -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="showCopyToForm"
      @close="showCopyToForm = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          Copiar parámetros a
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form @submit.prevent="serviceAddParameters">
          <div
            class="md-form md-outline outline-select my-2"
          >
            <select
              id="copyService-select"
              v-model="copyTemp.serviceId"
              class="custom-select"
            >
              <option
                class="d-none"
                disabled
                value=""
              >
                Seleccione
              </option>
              <option
                v-for="servicio in services"
                :key="servicio.id"
                :value="servicio.id"
              >
                {{ servicio.name }}
              </option>
              <option
                v-if="!services.length"
                disabled
              >
                No hay pruebas clínicas asociadas
              </option>
            </select>
            <label
              for="copyService-select"
              class="label-active"
            >
              Prueba clínica
            </label>
          </div>
          <!-- Botonces del modal -->
          <div class="modal-footer-btn">
            <mdb-btn
              flat
              dark-waves
              icon="times"
              type="button"
              class="flat-bordered"
              @click="showCopyToForm = false"
            >
              Cerrar
            </mdb-btn>
            <BtnLoader
              btn-type="submit"
              :btn-disabled="btnDisabled"
              btn-icon="plus"
              btn-title="Añadir"
            />
          </div>
        </form>
      </mdb-modal-body>
    </mdb-modal>
    <!--Modal para Confirmar al eliminar -->
    <ModalConfirmacionEliminar
      :show-confirmation="showConfirmacionEliminar"
      :name="name"
      :btn-disabled="btnDisabled"
      @close="showConfirmacionEliminar = $event"
      @confirm-delete="generalDelete"
    />
    <!-- Modal para ver servicio en diagnostico -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="showModalService"
      @close="showModalService = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          Vista previa de
          <span class="text-italic font-weight-bold fs-inherit">
            {{ servicioSeleccionado.name }}
          </span> en la historia
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="list-unstyled params-group">
          <draggable
            :list="servicioSeleccionado.params"
            :disabled="btnDisabled"
            class="list-group"
            ghost-class="ghost"
            handle=".handle"
            @end="saveParamsOrder"
          >
            <div
              v-for="parOr in servicioSeleccionado.params"
              :key="parOr.Parameter.id"
              class="d-flex align-items-center"
            >
              <mdb-btn
                flat
                dark-waves
                icon="grip-lines"
                class="col-1 p-0 m-0 handle"
              />
              <div class="d-flex flex-fill flex-wrap align-items-center">
                <header class="col-12 col-sm-5">
                  {{ parOr.Parameter.name }}
                </header>
                <div
                  v-if="parOr.Parameter.type == 'integer'"
                  class="col-12 col-sm-7"
                >
                  <mdb-input
                    type="number"
                    class="my-1"
                    outline
                  />
                </div>
                <div
                  v-else-if="parOr.Parameter.type == 'decimal'"
                  class="col-12 col-sm-7"
                >
                  <mdb-input
                    type="number"
                    class="my-1"
                    outline
                  />
                </div>
                <div
                  v-else-if="parOr.Parameter.type == 'date'"
                  class="col-12 col-sm-7"
                >
                  <mdb-input
                    type="date"
                    class="my-1 outline-date"
                    outline
                  />
                </div>
                <div
                  v-else-if="parOr.Parameter.type == 'datetime'"
                  class="col-12 col-sm-7"
                >
                  <mdb-input
                    type="time"
                    :step="60"
                    class="my-1"
                    outline
                  />
                </div>
                <div
                  v-else-if="parOr.Parameter.type == 'boolean'"
                  class="col-12 col-sm-7"
                >
                  <div class="our-checkbox2 px-2">
                    <label class="fs-09 w-100">
                      <input
                        type="checkbox"
                      >
                      <span />
                      Verdadero
                    </label>
                  </div>
                </div>
                <div
                  v-else-if="parOr.Parameter.type == 'simpleOption'"
                  class="col-12 col-sm-7"
                >
                  <div
                    v-for="opt in parOr.Parameter.options"
                    :key="opt"
                    class="our-radio2 px-2"
                  >
                    <label class="fs-09 w-100">
                      <input
                        type="radio"
                        :value="opt"
                        :name="parOr.Parameter.id"
                      >
                      <span />
                      {{ opt }}
                    </label>
                  </div>
                </div>
                <div
                  v-else-if="parOr.Parameter.type == 'multipleOption'"
                  class="col-12 col-sm-7"
                >
                  <div
                    v-for="opt in parOr.Parameter.options"
                    :key="opt"
                    class="our-checkbox2 px-2"
                  >
                    <label class="fs-09 w-100">
                      <input
                        type="checkbox"
                        :value="opt"
                      >
                      <span />
                      {{ opt }}
                    </label>
                  </div>
                </div>
                <div
                  v-else-if="parOr.Parameter.type == 'range'"
                  class="col-12 col-sm-7"
                >
                  <mdb-input
                    type="number"
                    class="my-1"
                    outline
                  />
                  <mdb-input
                    type="number"
                    class="my-1"
                    outline
                  />
                </div>
                <div
                  v-else-if="parOr.Parameter.type == 'rangeDates'"
                  class="col-12 col-sm-7"
                >
                  <mdb-input
                    type="date"
                    class="my-1 outline-date"
                    outline
                  />
                  <mdb-input
                    type="date"
                    class="my-1 outline-date"
                    outline
                  />
                </div>
                <div
                  v-else-if="parOr.Parameter.type == 'file'"
                  class="col-12 col-sm-7"
                >
                  <mdb-input
                    type="file"
                    class="my-1"
                    disabled
                    outline
                  />
                </div>
                <div
                  v-else
                  class="col-12 col-sm-7"
                >
                  <mdb-input
                    class="my-1"
                    outline
                  />
                </div>
              </div>
            </div>
          </draggable>
        </div>
        <div class="row justify-content-center">
          <mdb-btn
            class="col-auto flat-bordered"
            flat
            dark-waves
            icon="times"
            size="sm"
            @click="showModalService = false"
          >
            Cerrar
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <AlertaMsj :alerta-msj="alertaMsj" />
  </div>
</template>
<script>
import {mdbBadge, mdbBtn, mdbInput, mdbModal, mdbCard,
  mdbCardBody, mdbCardTitle, mdbCardText, mdbModalHeader,
  mdbModalTitle, mdbModalBody, mdbTooltip} from 'mdbvue';
import draggable from 'vuedraggable';
import BtnLoader from '@/components/customButtons/BtnLoader.vue';
import DrawImage from '@/components/DrawImage.vue';
import AlertaMsj from '@/components/AlertaMsj.vue';
import CustomDatalist from '@/components/customFields/CustomDatalist.vue';
import ModalConfirmacionEliminar from
  '@/components/ModalConfirmacionEliminar.vue';
import {getPersonId} from '@/utils/authdecode.js';
import {capitalize} from '@/utils/validations.js';
import gql from 'graphql-tag';
import doctorServicesInWorkplaceGql from
  '@/graphql/doctorServicesInWorkplace.gql';
import serviceInWPCreateGql from '@/graphql/serviceInWPCreate.gql';
import serviceUpdateGql from '@/graphql/serviceUpdate.gql';
import parameterCreateGql from '@/graphql/parameterCreate.gql';
import parameterUpdateGql from '@/graphql/parameterUpdate.gql';
import referenceCreateUpdateGql from '@/graphql/referenceCreateUpdate.gql';
import parameterDeleteGql from '@/graphql/parameterDelete.gql';
import serviceDeleteGql from '@/graphql/serviceDelete.gql';
import referenceDeleteGql from '@/graphql/referenceDelete.gql';
import serviceAddParametersGql from '@/graphql/serviceAddParameters.gql';
import currenciesByNameGql from '@/graphql/currenciesByName.gql';
import parametersByNameGql from '@/graphql/parametersByName.gql';
import paramsInServiceOrderGql from '@/graphql/paramsInServiceOrder.gql';
const endpoint = process.env.NODE_ENV === 'production'
              ? process.env.VUE_APP_MULTIMEDIA_HTTPS+getPersonId()
              : process.env.VUE_APP_MULTIMEDIA_HTTP+getPersonId();
export default {
  name: 'PruebasClinicas',
  components: {
    mdbBadge,
    mdbBtn,
    mdbInput,
    mdbModal,
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    ModalConfirmacionEliminar,
    mdbTooltip,
    BtnLoader,
    DrawImage,
    AlertaMsj,
    CustomDatalist,
    draggable,
  },
  data() {
    return {
      services: [],
      otrasMonedas: [],
      parametrosOtros: [],
      lugarSeleccionado: {
        servicesOffered: [],
      },
      servicioSeleccionado: {},
      servicioTemp: {
        description: '',
        prices: [
          {
            amount: '',
            Currency: {
              name: '',
              symbol: '',
            },
          },
        ],
        shareable: true,
      },
      parametroSeleccionado: {
        options: [],
      },
      parametroTemp: {
        reference: {},
        symbol: '',
        unit: '',
        options: [],
      },
      // Parameters to copy in other service
      parametersToCopy: [],
      // Render:
      showServiceForm: false,
      showModalService: false,
      serviceForm: {
        title: '',
        btnLabel: '',
        btnIcon: 'plus',
      },
      showEditPrice: false,
      showParameterForm: false,
      parameterForm: {
        title: '',
        btnLabel: '',
        btnIcon: 'plus',
      },
      showCopyToForm: false,
      copyTemp: {
        inLocation: '',
        serviceId: '',
      },
      alertaMsj: {
        content: '',
      },
      showConfirmacionEliminar: false,
      showNewParImage: false,
      aEliminar: {
        objeto: '',
        tipo: '',
      },
      name: '',
      btnDisabled: false,
      newOption: '',
      showVistaGeneral: true,
      range: {
        rangeLow: '',
        rangeHigh: '',
      },
      endpoint: endpoint +'/',
    };
  },
  computed: {
    parametros() {
      const ps = [];
      this.services.forEach((e)=>{
        e.params.forEach((p) => {
          if (ps.indexOf(p.Parameter) < 0) {
            ps.push(p.Parameter);
          }
        });
      });
      return ps;
    },
    monedas() {
      const monedas = [];
      if (this.services.length) {
        this.services.forEach((s) => {
          s.prices.forEach((p) => {
            if (!monedas.some((e) => {
              return JSON.stringify(
                  {name: e.name, symbol: e.symbol}) === JSON.stringify(
                  {name: p.Currency.name, symbol: p.Currency.symbol});
            })) {
              monedas.push({
                name: p.Currency.name,
                symbol: p.Currency.symbol,
              });
            }
          });
        });
      }
      this.otrasMonedas.forEach((p) => {
        if (!monedas.some((e) => {
          return JSON.stringify({name: e.name, symbol: e.symbol})
            === JSON.stringify({name: p.name, symbol: p.symbol});
        })) {
          monedas.push({
            name: p.name,
            symbol: p.symbol,
          });
        }
      });
      return monedas;
    },
    rutaHija() {
      const vista = this.$route.path.slice(1).split('/');
      return vista[2];
    },
  },
  methods: {
    infoReference(reference) {
      return (reference && (
        reference.quantityHigh || reference.quantityLow || reference.text
      ))
        ? true
        : false;
    },
    serviceMutate() {
      (this.serviceForm.btnLabel == 'Añadir')
        ? this.serviceCreate()
        : this.serviceUpdate();
    },
    newPruebaClinica() {
      this.showServiceForm = true;
      this.servicioTemp={
        description: '',
        prices: [
          {Currency: {
            name: '',
            symbol: '',
          }},
        ],
        shareable: true,
      };
      this.serviceForm.title = 'Nuevo grupo de parámetros';
      this.serviceForm.btnIcon = 'plus';
      this.serviceForm.btnLabel = 'Añadir';
    },
    serviceCreate() {
      if (!this.servicioTemp.name) {
        return this.alertaMsj = {
          content: 'Llena los datos de la prueba clínica',
          type: 'warning',
        };
      }
      let validPrice = true;
      this.servicioTemp.prices.forEach((price) => {
        if (isNaN(price.amount) || !price.Currency.name.length
          || !price.Currency.symbol.length) {
          this.alertaMsj = {
            content: 'Precio, Moneda y Símbolo son campos requeridos',
            type: 'warning',
          };
          validPrice = false;
        } else {
          price.amount = Number(price.amount);
        }
      });
      if (!validPrice) return;
      this.servicioTemp.status = 'Activado';
      this.servicioTemp.name = capitalize(this.servicioTemp.name);
      const variablesApollo = {
        service: this.servicioTemp,
      };
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${serviceInWPCreateGql}`,
        variables: variablesApollo,
        update: (store, {data: {serviceInWPCreate}}) => {
          const data = store.readQuery({
            query: gql`${doctorServicesInWorkplaceGql}`,
            variables: {
              id: getPersonId(),
            },
          });
          const prices = JSON.parse(JSON.stringify(this.servicioTemp.prices))
              .map((w) => {
                w.__typename = '_ServicePrices';
                w.Currency.__typename = 'Currency';
                return w;
              });
          const element = {
            __typename: 'Service',
            id: serviceInWPCreate.id,
            name: this.servicioTemp.name,
            description: this.servicioTemp.description,
            status: this.servicioTemp.status,
            shareable: this.servicioTemp.shareable,
            prices,
            params: [],
          };
          data.Doctor[0].services.push(element);
          store.writeQuery({
            query: gql`${doctorServicesInWorkplaceGql}`,
            variables: {
              id: getPersonId(),
            },
            data,
          });
          this.btnDisabled = false;
          this.showServiceForm = false;
          this.servicioSeleccionado = this.services[this.services.length-1];
          this.showVistaGeneral = false;
          this.alertaMsj = {
            content: 'Grupo de parámetros agregado',
            type: 'success',
          };
        },
        // Comentado porque no sustituía el -1
        // y generaba error al agregar el 1er parámetro
        // RDM 2024-03-14
        // optimisticResponse: {
        //   __typename: 'Mutation',
        //   serviceInWPCreate: {
        //     __typename: 'Service',
        //     id: -1,
        //     name: this.servicioTemp.name,
        //     description: this.servicioTemp.description,
        //     status: this.servicioTemp.status,
        //     prices: this.servicioTemp.prices,
        //     params: [],
        //   },
        // },
      }).catch((error) => {
        this.btnDisabled = false;
        this.alertaMsj = {
          content: 'Hubo un error al agregar la nueva prueba clínica',
          type: 'danger',
        };
      });
    },
    editService(servicio) {
      this.showServiceForm = true;
      this.serviceForm = {
        title: 'Editar prueba clínica',
        btnIcon: 'rotate',
        btnLabel: 'Actualizar',
      };
      this.servicioTemp = JSON.parse(
          JSON.stringify(servicio));
      this.servicioTemp.status =
        this.servicioSeleccionado.status == 'Activado'
          ? true
          : false;
    },
    editParameter(parametro) {
      if (!parametro.reference) {
        parametro.reference = {};
      }
      this.showParameterForm = true;
      this.parametroSeleccionado = parametro;
      this.parametroTemp = JSON.parse(
          JSON.stringify(parametro));
      if (this.parametroTemp.type == 'range'
        && this.parametroTemp.options.length >= 2) {
        this.range = {
          rangeLow: this.parametroTemp.options[0],
          rangeHigh: this.parametroTemp.options[1],
        };
      }
      this.parameterForm = {
        title: 'Edición del parámetro',
        btnLabel: 'Actualizar',
        btnIcon: 'rotate',
      };
    },
    serviceUpdate() {
      if (!this.servicioTemp.name) {
        return this.alertaMsj = {
          content: 'Llena los datos de la prueba clínica',
          type: 'warning',
        };
      }
      let validPrice = true;
      this.servicioTemp.prices.forEach((price) => {
        if (isNaN(price.amount) || !price.Currency.name.length
          || !price.Currency.symbol.length) {
          this.alertaMsj = {
            content: 'Llena los datos de precio',
            type: 'warning',
          };
          validPrice = false;
        } else {
          price.amount = Number(price.amount);
          delete price.__typename;
          delete price.Currency.__typename;
        }
      });
      if (!validPrice) return;
      const serviceStatus = this.servicioTemp.status == true
        ? 'Activado'
        : 'Desactivado';
      const service = {
        id: this.servicioTemp.id,
        name: capitalize(this.servicioTemp.name),
        prices: this.servicioTemp.prices,
        description: this.servicioTemp.description,
        shareable: this.servicioTemp.shareable,
        status: serviceStatus,
      };
      const variablesApollo = {
        service,
      };
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${serviceUpdateGql}`,
        variables: variablesApollo,
      }).then((res) => {
        const msg = res.data.serviceUpdate.msg;
        switch (msg) {
          case 'success':
            Object.assign(this.servicioSeleccionado, {...this.servicioTemp});
            this.btnDisabled = false;
            this.showServiceForm = false;
            this.alertaMsj = {
              content: 'Prueba clínica actualizada',
              type: 'success',
            };
            break;
          default:
            this.btnDisabled = false;
            this.alertaMsj = {
              content: 'Hubo un error al actualizar la prueba clínica',
              type: 'danger',
            };
            break;
        }
      }).catch((error) => {
        this.btnDisabled = false;
        this.alertaMsj = {
          content: 'Hubo un error al actualizar la prueba clínica',
          type: 'danger',
        };
      });
    },
    cancelPrecio(precio) {
      this.servicioTemp.prices.splice(
          this.servicioTemp.prices.indexOf(precio), 1);
    },
    parameterMutate() {
      (this.parameterForm.btnLabel == 'Añadir')
        ? this.parameterCreate()
        : this.parameterUpdate();
    },
    parameterCreate() {
      if (!this.parametroTemp.name || !this.parametroTemp.type) {
        return this.alertaMsj = {
          content: 'Llena y selecciona los datos para el nuevo parámetro',
          type: 'warning',
        };
      }
      if ((this.parametroTemp.type == 'simpleOption'
        || this.parametroTemp.type == 'multipleOption')
        && !this.parametroTemp.options) {
        return this.alertaMsj = {
          content: 'Llena las opciones de la selección',
          type: 'warning',
        };
      }
      if (this.parametroTemp.type == 'range') {
        if (!this.range.rangeLow && !this.range.rangeHigh) {
          return this.alertaMsj = {
            content: 'Llena los campos de rango numérico',
            type: 'warning',
          };
        }
        if (Number(this.range.rangeLow) < Number(this.range.rangeHigh)) {
          this.parametroTemp.options = [];
          this.parametroTemp.options.push(this.range.rangeLow);
          this.parametroTemp.options.push(this.range.rangeHigh);
        } else {
          return this.alertaMsj = {
            content: 'El rango inicial debe ser menor al rango final',
            type: 'warning',
          };
        }
      }
      if (this.parametroTemp.type == 'draw') {
        if (!this.parametroTemp.options[0]) {
          return this.alertaMsj = {
            content: 'Agrega un fondo',
            type: 'warning',
          };
        }
      }
      const parametro = {...this.parametroTemp};
      delete parametro.reference;
      const variablesApollo = {
        parameter: parametro,
        serviceId: this.servicioSeleccionado.id,
      };
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${parameterCreateGql}`,
        variables: variablesApollo,
        update: (store, {data: {parameterCreate}}) => {
          const data = store.readQuery({
            query: gql`${doctorServicesInWorkplaceGql}`,
            variables: {
              id: getPersonId(),
            },
          });
          const element = {
            __typename: '_ServiceParams',
            order: null,
            Parameter: {
              __typename: 'Parameter',
              id: parameterCreate.id,
              ...parametro,
              reference: null,
              options: this.parametroTemp.options || [],
            },
          };
          data.Doctor[0].services
              .filter((s) => {
                return s.id
                  === this.servicioSeleccionado.id;
              })[0].params
              .push(element);
          store.writeQuery({
            query: gql`${doctorServicesInWorkplaceGql}`,
            variables: {
              id: getPersonId(),
            },
            data,
          });
          this.alertaMsj = {
            content: 'Parámetro agregado',
            type: 'success',
          };
          this.btnDisabled = false;
          this.showParameterForm = false;
        },
      }).then(async (res) => {
        this.parametroTemp.id = res.data.parameterCreate.id;
        if (this.parametroTemp.reference.quantityLow
          || this.parametroTemp.reference.quantityHigh
          || this.parametroTemp.reference.text
        ) {
          const referenceId = await
          this.referenceCreate(this.parametroTemp.reference,
              this.parametroTemp.id);
          if (!referenceId) {
            this.alertaMsj = {
              content: `Parámetro agregado pero hubo problema
               con la referencia`,
              type: 'danger',
            };
          } else {
            this.parametroTemp.reference.id = referenceId;
          }
        }
        this.servicioSeleccionado = {};
      }).catch((error) => {
        this.btnDisabled = false;
        this.alertaMsj = {
          content: 'Hubo un error al crear el parámetro',
          type: 'danger',
        };
      });
    },
    parameterUpdate() {
      if (!this.parametroTemp.name || !this.parametroTemp.type) {
        return this.alertaMsj = {
          content: 'Llena y selecciona los datos para el nuevo parámetro',
          type: 'warning',
        };
      }
      if ((this.parametroTemp.type == 'simpleOption'
        || this.parametroTemp.type == 'multipleOption')
        && !this.parametroTemp.options) {
        return this.alertaMsj = {
          content: 'Llena las opciones de la selección',
          type: 'warning',
        };
      }
      if (this.parametroTemp.type == 'range') {
        if (!this.range.rangeLow && !this.range.rangeHigh) {
          return this.alertaMsj = {
            content: 'Llena los campos de rango numérico',
            type: 'warning',
          };
        }
        if (Number(this.range.rangeLow) < Number(this.range.rangeHigh)) {
          this.parametroTemp.options = [];
          this.parametroTemp.options.push(this.range.rangeLow);
          this.parametroTemp.options.push(this.range.rangeHigh);
        } else {
          return this.alertaMsj = {
            content: 'El rango inicial debe ser menor al rango final',
            type: 'warning',
          };
        }
      }
      const parametro = {...this.parametroTemp};
      delete parametro.reference;
      delete parametro.__typename;
      const variablesApollo = {
        parameter: parametro,
      };
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${parameterUpdateGql}`,
        variables: variablesApollo,
        update: (store) => {
          const data = store.readQuery({
            query: gql`${doctorServicesInWorkplaceGql}`,
            variables: {
              id: getPersonId(),
            },
          });
          data.Doctor[0].services = data.Doctor[0].services
              .map((s) => {
                if (s.id === this.servicioSeleccionado.id) {
                  s.params = s.params
                      .map((p) => {
                        if (p.Parameter.id === parametro.id) {
                          p.Parameter = {
                            ...parametro,
                            reference: null,
                            __typename: 'Parameter',
                          };
                        }
                        return p;
                      });
                }
                return s;
              });
          store.writeQuery({
            query: gql`${doctorServicesInWorkplaceGql}`,
            variables: {
              id: getPersonId(),
            },
            data,
          });
          this.btnDisabled = false;
          this.showParameterForm = false;
        },
      }).then(async (res) => {
        const msg = res.data.parameterUpdate.msg;
        switch (msg) {
          case 'success':
            this.showParameterForm = false;
            this.alertaMsj = {
              content: 'Parámetro actualizado',
              type: 'success',
            };
            if (this.parametroTemp.reference.quantityLow
              || this.parametroTemp.reference.quantityHigh
              || this.parametroTemp.reference.text
            ) {
              const referenceId = await
              this.referenceCreate(this.parametroTemp.reference,
                  this.parametroTemp.id);
              if (!referenceId) {
                this.alertaMsj = {
                  content: 'Parámetro agregado pero hubo problema con la '
                  + 'referencia',
                  type: 'danger',
                };
              } else {
                this.parametroTemp.reference.id = referenceId;
              }
            }
            this.btnDisabled = false;
            break;
          default:
            this.btnDisabled = false;
            this.alertaMsj = {
              content: 'Hubo un error al actualizar el parámetro',
              type: 'danger',
            };
            break;
        }
        this.servicioSeleccionado = {};
      }).catch((error) => {
        this.btnDisabled = false;
        this.alertaMsj = {
          content: 'Hubo un error al actualizar el parámetro',
          type: 'danger',
        };
      });
    },
    generalDelete() {
      this.aEliminar.tipo == 'servicio'
        ? this.serviceDelete()
        : this.parameterDelete(this.aEliminar.objeto);
    },
    serviceDelete() {
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${serviceDeleteGql}`,
        variables: {
          serviceId: this.servicioSeleccionado.id,
        },
        update: (store) => {
          const data = store.readQuery({
            query: gql`${doctorServicesInWorkplaceGql}`,
            variables: {
              id: getPersonId(),
            },
          });
          data.Doctor[0].services = data.Doctor[0].services.filter((s) => {
            return s.id !== this.servicioSeleccionado.id;
          });
          store.writeQuery({
            query: gql`${doctorServicesInWorkplaceGql}`,
            variables: {
              id: getPersonId(),
            },
            data,
          });
          this.btnDisabled = false;
          this.showServiceForm = false;
          this.alertaMsj = {
            content: 'Grupo eliminado',
            type: 'success',
          };
        },
      }).then((res) => {
        const msg = res.data.serviceDelete.msg;
        switch (msg) {
          case 'success':
            this.btnDisabled = false;
            this.showConfirmacionEliminar = false;
            this.showVistaGeneral = true;
            this.alertaMsj = {
              content: 'Prueba clínica eliminada',
              type: 'success',
            };
            break;
          default:
            this.btnDisabled = false;
            this.alertaMsj = {
              content: 'Hubo un error al eliminar la prueba clínica',
              type: 'warning',
            };
            break;
        }
      }).catch((res) => {
        this.btnDisabled = false;
        this.alertaMsj = {
          content: 'Hubo un error al eliminar la prueba clínica',
          type: 'danger',
        };
      });
    },
    parameterDelete(parameter) {
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${parameterDeleteGql}`,
        variables: {
          parameterId: parameter.id,
          serviceId: this.servicioSeleccionado.id,
        },
        update: (store) => {
          const data = store.readQuery({
            query: gql`${doctorServicesInWorkplaceGql}`,
            variables: {
              id: getPersonId(),
            },
          });
          data.Doctor[0].services = data.Doctor[0].services
              .map((s) => {
                if (s.id === this.servicioSeleccionado.id) {
                  s.params = s.params
                      .filter((p) => {
                        return p.Parameter.id !== parameter.id;
                      });
                }
                return s;
              });
          store.writeQuery({
            query: gql`${doctorServicesInWorkplaceGql}`,
            variables: {
              id: getPersonId(),
            },
            data,
          });
          this.btnDisabled = false;
          this.showParameterForm = false;
        },
      }).then((res) => {
        const msg = res.data.parameterDelete.msg;
        switch (msg) {
          case 'success':
            this.btnDisabled = false;
            this.showConfirmacionEliminar = false;
            this.alertaMsj = {
              content: 'Parámetro eliminado',
              type: 'success',
            };
            break;
          default:
            this.btnDisabled = false;
            this.alertaMsj = {
              content: 'Hubo un error al eliminar el parámetro',
              type: 'danger',
            };
            break;
        }
      }).catch((error) => {
        this.btnDisabled = false;
        this.alertaMsj = {
          content: 'Hubo un error al eliminar el parámetro',
          type: 'danger',
        };
      });
    },
    async referenceCreate(reference, parameterId) {
      delete reference.__typename;
      reference.quantityLow = Number(reference.quantityLow);
      reference.quantityHigh = Number(reference.quantityHigh);
      const variablesApollo = {
        reference: reference,
        parameterId: parameterId,
        serviceId: this.servicioSeleccionado.id,
      };
      const referenceId = await this.$apollo.mutate({
        mutation: gql`${referenceCreateUpdateGql}`,
        variables: variablesApollo,
      }).then((res) => {
        const referenceId = res.data.referenceCreateUpdate.id;
        return referenceId;
      }).catch((error) => {
        this.alertaMsj = {
          content: 'Hubo un error al crear la referencia',
          type: 'danger',
        };
      });
      return referenceId;
    },
    referenceDelete(referenceId) {
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${referenceDeleteGql}`,
        variables: {
          referenceId,
          serviceId: this.servicioSeleccionado.id,
        },
      }).then((res) => {
        const msg = res.data.referenceDelete.msg;
        switch (msg) {
          case 'success': {
            this.parametroTemp.reference = {};
            this.parametroSeleccionado.reference = {};
            this.servicioSeleccionado.params =
              [].concat(this.servicioSeleccionado.params);
            this.btnDisabled = false;
            this.alertaMsj = {
              content: 'Referencia borrada',
              type: 'success',
            };
            break;
          }
          default: {
            this.btnDisabled = false;
            this.alertaMsj = {
              content: 'Hubo un error al eliminar la referencia',
              type: 'danger',
            };
            break;
          }
        }
      }).catch((error) => {
        this.btnDisabled = false;
        this.alertaMsj = {
          content: 'Hubo un error al eliminar la referencia',
          type: 'danger',
        };
      });
    },
    // Copy parameters to other service
    serviceAddParameters() {
      const parametersId = [];
      this.parametersToCopy.forEach((parameter) => {
        parametersId.push(parameter.id);
      });
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${serviceAddParametersGql}`,
        variables: {
          serviceId: this.copyTemp.serviceId,
          parametersId,
        },
      }).then((res) => {
        const msg = res.data.serviceAddParameters.msg;
        switch (msg) {
          case 'success': {
            const service = this.copyTemp.inLocation
                .servicesOffered.filter((s) =>
                  s.id == this.copyTemp.serviceId)[0];
            this.parametersToCopy.forEach((parameter) => {
              service.params.push({Parameter: parameter});
            });
            this.parametersToCopy = [];
            this.showCopyToForm = false;
            this.btnDisabled = false;
            this.alertaMsj = {
              content: 'Parámetros copiados',
              type: 'success',
            };
            break;
          }
          default: {
            this.btnDisabled = false;
            this.alertaMsj = {
              content: 'Hubo un error al copiar parámetros a la prueba clínica',
              type: 'danger',
            };
            break;
          }
        }
      }).catch((error) => {
        this.btnDisabled = false;
        this.alertaMsj = {
          content: 'Hubo un error al copiar parámetros a la prueba clínica',
          type: 'danger',
        };
      });
    },
    buscarMonedas(name) {
      if (name.length < 2) {
        return;
      }
      this.$apollo.query({
        query: gql`${currenciesByNameGql}`,
        variables: {
          name,
        },
        debounce: 500,
      }).then((res) => {
        this.otrasMonedas = res.data.currenciesByName;
      }).catch((err) => {});
    },
    buscarParametros(name) {
      if (name.length < 2) {
        return;
      }
      this.$apollo.query({
        query: gql`${parametersByNameGql}`,
        variables: {
          name,
        },
        debounce: 1000,
      }).then((res) => {
        this.parametrosOtros = res.data.parametersByName;
      }).catch((err) => {});
    },
    addPrice() {
      this.servicioTemp.prices.push({
        amount: '',
        Currency: {
          name: '',
          symbol: '',
        },
      });
    },
    addOption(newOption) {
      if (!this.parametroTemp.options) {
        this.parametroTemp.options = [];
      }
      this.parametroTemp.options.push(newOption);
      this.newOption = '';
    },
    optionParameterDelete(i) {
      this.parametroTemp.options.splice(i, 1);
    },
    newParameter() {
      this.showParameterForm = true;
      this.parametroTemp = {
        reference: {},
        symbol: '',
        unit: '',
        options: [],
      };
      this.range = {};
      this.parameterForm = {
        title: 'Agregar parámetro',
        btnLabel: 'Añadir',
        btnIcon: 'plus',
      };
    },
    showFormCopy() {
      this.showCopyToForm = true;
      this.copyTemp = {
        inLocation: '',
        serviceId: '',
      };
    },
    saveParamsOrder() {
      const paramsOrder = this.servicioSeleccionado.params.map((p, i) => {
        return {
          order: i,
          paramId: p.Parameter.id,
        };
      });
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${paramsInServiceOrderGql}`,
        variables: {
          serviceId: this.servicioSeleccionado.id,
          paramsOrder,
        },
        update: (store) => {
        },
      }).then((res) => {
        const msg = res.data.paramsInServiceOrder.msg;
        switch (msg) {
          case 'success':
            this.btnDisabled = false;
            this.alertaMsj = {
              content: 'Orden guardado',
              type: 'success',
            };
            break;
          default:
            this.btnDisabled = false;
            this.alertaMsj = {
              content: 'Hubo un error al guardar el orden',
              type: 'warning',
            };
            break;
        }
      }).catch((error) => {
        this.btnDisabled = false;
        this.alertaMsj = {
          content: 'Hubo un error al guardar el orden',
          type: 'danger',
        };
      });
    },
    guardarUrl(url) {
      this.parametroTemp.options = [url];
      this.showNewParImage = false;
    },
  },
  apollo: {
    services() {
      return {
        query: gql`${doctorServicesInWorkplaceGql}`,
        variables: {
          id: getPersonId(),
        },
        update: (data) => data.Doctor[0].services,
        fetchPolicy: 'cache-and-network',
      };
    },
  },
};
</script>
<style lang="scss">
  .pruebas-clinicas-view {
    @media screen and (min-width: 992px) {max-width: calc(100vw - 250px);}
  }
  .tab-item-badge {
    @media (min-width: 768px) {
      width: 50%;
    }
  }
  .tab-consultorios.nav.nav-pills.pills-primary-link,
  .tab-servicios.nav.nav-pills.pills-primary {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    @media (min-width: 768px) {
      flex-wrap: nowrap;
    }

    .nav-item {
      margin-bottom: .5rem;

      .nav-link {
        cursor: pointer;
        font-size: .9rem;
        line-height: 1.2;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        box-shadow: 0px 4px 4px 0px $gris-separador;
      }
      .nav-link-buttom {
        font-weight: 300;
        margin: auto;
        padding: .5rem 1rem;
        text-transform: inherit;

        i {padding-left: 0 !important;}
      }
    }
  }
  .params-group .list-group > div {
    border-bottom: 1px dotted #dedede;
  }
  .pills-primary-link .nav-item .nav-link.active {
    background-color: $primary-color;
  }
  .tab-consultorios.nav.nav-pills.pills-primary-link .nav-item {
    @media screen and (min-width: 992px) {min-width: 187px;}
  }
  .tab-servicios.nav.nav-pills.pills-primary .nav-item{
    @media screen and (min-width: 992px) {min-width: 116px;}
  }
  .tab-servicios.nav.nav-pills.pills-primary .nav-item:first-child {
    @media screen and (min-width: 992px) {min-width: 210px;}
  }
</style>
