<template>
  <section
    v-if="estatusNotificaciones.esCompatible
      && estatusNotificaciones.permiso === 'default'"
    class="container-sm mensaje text-muted my-3 py-md-4 px-md-5"
  >
    <font-awesome-icon
      icon="bell"
      size="6x"
      class="my-3"
    />
    <div class="px-sm-5">
      <h4 class="text-center">
        ¡Activa las notificaciones!
      </h4>
      <p>
        Así podremos informarte cuando tengas citas pendientes por confirmar.
      </p>
      <div class="text-center">
        <mdb-btn
          color="success"
          icon="check"
          class="mx-0 py-2 px-3"
          :disabled="!estatusNotificaciones.esCompatible"
          @click="pedirPermisoNotificacion"
        >
          Activar notificaciones
        </mdb-btn>
      </div>
    </div>
  </section>
</template>

<script>
import Notificaciones from '@/components/Notificaciones.vue';
import estatusNotificacionesGql from '../graphql/estatusNotificaciones.gql';
import {mdbBtn} from 'mdbvue';
export default {
  name: 'AvisoNotificaciones',
  components: {
    mdbBtn,
  },
  mixins: [Notificaciones],
  data() {
    return {
      estatusNotificaciones: {},
    };
  },
  apollo: {
    estatusNotificaciones() {
      return {
        query: estatusNotificacionesGql,
      };
    },
  },
};
</script>

<style lang="scss">
.mensaje {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    max-width: 720px !important;
  }
}
</style>
