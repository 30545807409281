/**
 * Mezcla los datasets con los valores a graficar
 * @param {*} chartTemplate La plantilla general
 * @param {*} dataToChart Los puntos a graficar
 * @param {*} opts Las opciones de la función
 * @return {Object}
 */
function mixDatasets(chartTemplate, dataToChart = [], opts) {
  const currentDS =
    JSON.parse(JSON.stringify(chartTemplate.undataset));
  const allDS =
    JSON.parse(JSON.stringify(chartTemplate.datasets));
  currentDS.data = dataToChart;

  // Si la gráfica es numérica, asegurar el tipo de dato
  if (chartTemplate.labelType === 'number') {
    if (chartTemplate.labels) {
      chartTemplate.labels = chartTemplate.labels.map((l) => Number(l));
    }
    allDS.forEach((ds) => {
      ds.data = ds.data.map((d) => Number(d));
      if (ds.dataX && ds.dataX.length) {
        ds.dataX = ds.dataX.map((d) => Number(d));
      }
    });
    currentDS.data = currentDS.data.map((xy) => {
      return {x: Number(xy.x), y: Number(xy.y)};
    });
  }
  allDS.forEach((ds)=>{
    if (ds.type == 'scatter') {
      ds.data = ds.data.map((dy, i) => {
        return {x: ds.dataX[i], y: dy};
      });
    }
  });
  allDS.unshift(currentDS);

  const options = {
    type: 'line',
    data: {
      labels: chartTemplate.labels,
      datasets: allDS,
    },
    options: {
      tooltips: {
        enabled: true,
        callbacks: {
          title: function() {
            return chartTemplate.tooltipTitle;
          },
          label: function(e) {
            return chartTemplate.tooltipLabel + e.value;
          },

        },

      },
      scales: {
        y: {
          title: {
            text: chartTemplate.yLabel,
            display: true,
          },
          beginAtZero: chartTemplate.beginAtZero,
        },

        x: {
          type: 'linear',
          position: 'bottom',
          title: {
            text: chartTemplate.xLabel,
            display: true,
          },
        },

      },
      bezierCurve: false,
      animation: chartTemplate.animation
        ? chartTemplate.animation
        : false,
      elements: {
        point: {radius: chartTemplate.pointRadius},
        line: {tension: 0},
      },
      plugins: {
        title: {
          text: chartTemplate.name,
          display: true,
        },
        // legend: {
        //   display: function() {
        //     if (window.innerWidth < 600) {
        //       return false;
        //     }
        //   },
        //   position: function() {
        //     // return position based on inner width of your display area
        //     if (window.innerWidth < 600) {
        //       return 'bottom';
        //     }
        //   },
        // },
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  };

  return options;
}

export {
  mixDatasets,
};
