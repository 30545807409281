/* eslint-disable no-console */

import {register} from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
          'App usada desde la cache por el service worker.\n'
        + 'Más detalles en https://goo.gl/AFskqB',
      );
    },
    registered(register) {
      console.log('Service worker se ha registrado.');
      // Buscado por cambios cada hora
      setInterval(() => {
        register.update();
      }, 1000 * 60 * 60 * 3);
    },
    cached() {
      console.log('Se guardó el contenido para uso sin conexión.');
    },
    updatefound() {
      console.log('Descargando nueva versión.');
    },
    updated(register) {
      console.log('Nueva versión disponible, por favor actualiza.');
      document.dispatchEvent(
          new CustomEvent('actualizacionServiceWorker', {detail: register}),
      );
    },
    offline() {
      console.log('Conexión de internet no disponible. '
        +'App ejecutando en modo Sin conexión.');
    },
    error(error) {
      console.error('Error durante el registro del service worker:', error);
    },
  });
}
