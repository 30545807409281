<template>
  <transition
    name="custom-classes-transition"
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
  >
    <div
      v-if="showCard"
      :class="['card bord-style', cardClass]"
    >
      <div class="card-header d-flex align-items-center">
        <span class="h5-responsive flex-fill">
          <slot name="title" />
        </span>
        <slot name="buttons" />
      </div>
      <div
        v-if="showBody"
        class="card-body"
      >
        <slot
          name="body"
        />
        <div class="row justify-content-around mx-0">
          <slot name="bottom-buttons" />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'TarjetasDelDiagnostico',
  components: {},
  props: {
    cardClass: {
      type: String,
      default: '',
    },
    showBody: {
      type: Boolean,
      default: true,
    },
    showCard: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
