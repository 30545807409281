<template>
  <div class="mt-2">
    <!-- Loading -->
    <div
      v-if="$apolloData.queries.lugares.loading && !lugares.length"
      class="text-center text-muted vertical-centered"
    >
      <font-awesome-icon
        :icon="['fas', 'circle-notch']"
        class="fa-spin fa-5x my-4"
      />
      <small class="d-block">
        CARGANDO
      </small>
    </div>
    <!-- Primeros pasos -->
    <div
      v-if="lugares && (!lugares.length || !hayPruebas)"
      class="row justify-content-around text-muted my-4 mx-0"
    >
      <div class="container">
        <div class="d-flex flex-column align-items-center">
          <header class="h3-responsive text-center mb-2">
            Comienza dando estos 3 pasos
          </header>
          <ol class="px-4">
            <li v-if="!lugares.length">
              Registra un consultorio en
              <router-link
                :to="{ name: 'LugaresDeTrabajo' }"
                tag="span"
                class="fs-inherit cursor-pointer d-inline-flex
                  justify-content-center align-items-center
                  link-icon-hover"
              >
                Configuración /
                <font-awesome-icon
                  :icon="['fas', 'hospital']"
                  size="sm"
                  class="mx-1"
                />
                Consultorios
                <font-awesome-icon
                  :icon="['fas', 'angle-right']"
                  class="my-2 fa-md icon"
                />
              </router-link>
            </li>
            <li v-else>
              <span class="font-weight-bold">
                ¡Primer paso listo!
              </span>
              Ahora que tienes el consultorio configurado,
              <span class="d-sm-block d-xl-inline">
                continúa con los pasos siguientes:
              </span>
            </li>
            <li>
              Agrega parámetros que mides con frecuencia
              <span class="d-sm-block d-xl-inline">
                en
                <router-link
                  :to="{ name: 'PruebasClinicas' }"
                  tag="span"
                  class="fs-inherit cursor-pointer d-inline-flex
                    justify-content-center align-items-center
                    link-icon-hover text-nowrap"
                >
                  Configuración /
                  <font-awesome-icon
                    :icon="['fas', 'stethoscope']"
                    size="sm"
                    class="mx-1"
                  />
                  Parámetros
                  <font-awesome-icon
                    :icon="['fas', 'angle-right']"
                    class="my-2 fa-md icon"
                  />
                </router-link>
              </span>
            </li>
            <li>
              Visita el menú Salas de espera, elige un consultorio y agrega a
              <span class="d-sm-block d-xl-inline">
                tus pacientes
              </span>
            </li>
          </ol>
        </div>
        <p class="text-center">
          También puedes solicitar una demo guiada en:
        </p>
        <ul class="list-unstyled row justify-content-center">
          <li
            class="col-12 col-sm-8 col-md-5 col-lg-4
             col-xl-3 mb-3 mr-xl-4 pr-md-0
              text-center clearfix"
          >
            <img
              src="/img/ayuda/QR-chat-whatsapp.png"
              alt="Enlace QR al chat en WhatsApp"
              class="float-sm-left mr-sm-1"
              width="120px"
              height="auto"
            >
            <div>
              <span class="d-block font-weight-bold mt-2 mt-sm-3">
                WhatsApp</span>
              <mdb-btn
                outline="primary"
                icon="whatsapp"
                fab
                class="z-depth-0 px-3"
                tag="a"
                href="https://wa.me/584122747740?text=¡Hola%20AMII!,%20¿me%20guias%20en%20los%20primeros%20pasos?"
                rel="noopener noreferrer"
                target="_blank"
              >
                Ir al chat
              </mdb-btn>
            </div>
          </li>
          <li
            class="col-12 col-sm-8 col-md-5 col-lg-4
             col-xl-3 mb-3 pr-md-0 pl-xl-1
              text-center clearfix"
          >
            <img
              src="/img/ayuda/QR-canal-telegram.png"
              alt="Enlace QR al chat en Telegram"
              class="float-sm-left mr-sm-1"
              width="120px"
              height="auto"
            >
            <div>
              <span class="d-block font-weight-bold mt-2 mt-sm-3">
                Telegram</span>
              <mdb-btn
                outline="primary"
                icon="telegram"
                fab
                class="z-depth-0 px-3"
                tag="a"
                href="https://t.me/amii_app"
                rel="noopener noreferrer"
                target="_blank"
              >
                Ir al canal
              </mdb-btn>
            </div>
          </li>
        </ul>
        <router-link
          :to="{ name: 'Ayuda' }"
          tag="p"
          class="fs-inherit cursor-pointer d-flex
            justify-content-center align-items-center"
        >
          Busca el
          <font-awesome-icon
            :icon="['fas', 'circle-question']"
            class="mx-2"
          />
          <span class="d-none d-sm-inline mr-1">
            en el menú
          </span>
          para más información.
        </router-link>
        <!-- <div class="d-flex flex-column align-items-center mb-2">
          <header class="font-weight-bold mb-2">
            Mira un vídeo de configuración
          </header>
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope;
              picture-in-picture"
            allowfullscreen
            class="video-help"
            frameborder="0"
            src="https://www.youtube.com/embed/IFBsyfPUMp4"
          />
        </div> -->
        <router-link
          :to="{ name: 'Ayuda' }"
          tag="p"
          class="fs-inherit cursor-pointer d-flex
            justify-content-center align-items-center
            link-icon-hover"
        >
          Aprende más en la sección de
          <span class="ml-1 fs-inherit text-underline">
            Ayuda
          </span>
          <font-awesome-icon
            :icon="['fas', 'angle-right']"
            class="my-2 fa-md icon"
          />
        </router-link>
      </div>
    </div>
    <!-- Aviso para activar notificaciones -->
    <AvisoNotificaciones />
    <!-- Lista lugares de trabajo agrupado por tipo -->
    <template
      v-if="!$apolloData.queries.lugares.loading && (lugares && lugares.length)"
    >
      <p
        v-if="lugares.length"
        class="text-center pt-3 mx-3 text-muted"
      >
        Selecciona un lugar para agregar o ver pacientes en espera
      </p>
      <div
        v-for="workplace in workplaces"
        :key="workplace.type"
        class="container"
      >
        <template
          v-if="workplace.places.length"
        >
          <header class="mt-4 mb-2 font-weight-bold text-center">
            {{ workplace.type }}
          </header>
          <div
            :class="[workplace.places.length < 3
              ? 'row justify-content-center mx-0'
              : 'card-columns cc-1 cc-md-2 cc-lg-3']"
          >
            <div
              v-for="lugar in workplace.places"
              :key="lugar.id"
              :class="['card SE', {'col-12 col-md-5 m-2 px-0':
                workplace.places.length < 3}]"
            >
              <div class="card-body">
                <div class="row">
                  <router-link
                    :to="{ name: 'SalaDeEspera', params: { lugar: lugar.id }}"
                    tag="header"
                    class="col mb-1 card-title cursor-pointer"
                  >
                    {{ lugar.in.location.name }} - {{ lugar.in.name }}
                    <small class="d-block text-muted">
                      {{ lugar.name }}
                    </small>
                  </router-link>
                  <div class="col-auto">
                    <mdb-btn
                      ref="btnShowMessage"
                      type="button"
                      class="m-0 mr-1 p-2"
                      flat
                      dark-waves
                      icon="comment-alt"
                      title="Enviar mensaje"
                      @click="showMessageForm = true;lugarSeleccionado = lugar"
                    />
                    <router-link
                      :to="{ name: 'LugaresDeTrabajo'}"
                      class="text-btn"
                    >
                      <mdb-btn
                        type="button"
                        class="m-0 p-2"
                        flat
                        dark-waves
                        icon="pen"
                        title="Editar horario"
                      />
                    </router-link>
                  </div>
                </div>
                <router-link
                  ref="routerLink"
                  tag="div"
                  :to="{ name: 'SalaDeEspera', params: { lugar: lugar.id }}"
                  class="card-text"
                >
                  <p class="more-info">
                    {{ lugar.peopleWaiting.length }}
                    Paciente<span
                      v-if="lugar.peopleWaiting.length > 1
                        || !lugar.peopleWaiting.length"
                      class="fs-inherit"
                    >s</span> en espera hoy
                  </p>
                  <div
                    v-for="(sch, i) in lugar.schedule"
                    :key="i"
                    class="mr-2 description"
                  >
                    <small class="col pl-0 pr-2 mb-0">
                      {{ accentInWeekDay(sch.day.name) }}
                    </small>
                    <small class="col-auto p-0">
                      {{ sch.start }} - {{ sch.end }}
                    </small>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <LogosPatrocinantes />
    <!-- Calendario -->
    <!--
    <div
      v-if="!$apolloData.queries.lugares.loading"
      class="container"
    >
      <header class="my-2 font-weight-bold text-center">
        Calendario
      </header>
      <vue-cal
        hide-view-selector
        :time="false"
        default-view="month"
        small
        locale="es"
        :disable-views="['week','year','years','day']"
        @cell-focus="setDate($event)"
      />
    </div>
    -->

    <!-- Modal para envío de mensaje -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="showMessageForm"
      @shown="$refs.messageRef.focus()"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          Redactar mensaje
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModal"
          class="close"
          @click="showMessageForm = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-input
          ref="messageRef"
          v-model="mensaje.message"
          type="textarea"
          class="my-0"
          label="Escribe tu mensaje"
          :rows="5"
          outline
        />
        <p class="text-center font-weight-bold mb-2 mt-3">
          Destinatario(s)
        </p>
        <div class="row justify-content-around">
          <div class="col-auto">
            <div class="our-checkbox2">
              <label>
                <input
                  v-model="aAsistentes"
                  type="checkbox"
                  name="message-check"
                >
                <span />
                Asistentes
              </label>
            </div>
          </div>
          <div class="col-auto">
            <div class="our-checkbox2">
              <label>
                <input
                  ref="pacientesCheckbox"
                  v-model="aPacientes"
                  type="checkbox"
                  name="message-check"
                >
                <span />
                Pacientes en espera
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer-btn">
          <mdb-btn
            ref="btnCerrar"
            type="button"
            flat
            dark-waves
            class="flat-bordered"
            @click="showMessageForm = false"
          >
            <font-awesome-icon
              class="mr-1"
              :icon="['fas', 'xmark']"
              size="lg"
            />
            Cerrar
          </mdb-btn>
          <mdb-btn
            ref="btnAvion"
            type="button"
            color="success-base"
            :disabled="btnDisabled"
            @click="enviarMensaje"
          >
            <font-awesome-icon
              class="mr-1"
              :icon="['fas', 'paper-plane']"
              size="lg"
            />
            Enviar
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <VersionAMII hide-update-btn />

    <AlertaMsj :alerta-msj="alertaMsj" />
  </div>
</template>
<script>
import {mdbBtn, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody,
  mdbInput} from 'mdbvue';
import {getPersonId} from '@/utils/authdecode.js';
import {todayDate, accentInWeekDay} from '@/utils/validations.js';
// import VueCal from 'vue-cal';
// import 'vue-cal/dist/vuecal.css';
// import 'vue-cal/dist/i18n/es.js';
import gql from 'graphql-tag';
import AlertaMsj from '@/components/AlertaMsj.vue';
import AvisoNotificaciones from '@/components/AvisoNotificaciones.vue';
import LogosPatrocinantes from '@/components/LogosPatrocinantes.vue';
import VersionAMII from '@/components/VersionAMII.vue';
import doctorWorkPlacesSEGql from '@/graphql/doctorWorkPlacesSE.gql';
import sendMessageToManyGql from '@/graphql/sendMessageToMany.gql';


export default {
  name: 'SalasDeEspera',
  components: {
    AlertaMsj,
    AvisoNotificaciones,
    LogosPatrocinantes,
    VersionAMII,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbInput,
    // VueCal,
  },
  data() {
    return {
      accentInWeekDay,
      lugarSeleccionado: {},
      lugares: [],
      mensaje: {
        subject: 'AMMI - Mensaje de sala de espera',
        toPersonsId: [],
        message: '',
      },
      aAsistentes: false,
      aPacientes: false,
      // Render
      showMessageForm: false,
      btnDisabled: false,
      alertaMsj: {
        content: '',
      },
      hayPruebas: false,
    };
  },
  computed: {
    workplaces() {
      return [
        {
          type: 'Consulta',
          places: this.lugares.filter((l) => l.type === 'Consulta'),
        },
        {
          type: 'Gestionado',
          places: this.lugares.filter((l) => l.type === 'Gestionado'),
        },
        {
          type: 'Hospitalización',
          places: this.lugares.filter((l) => l.type === 'Hospitalización'),
        },
      ];
    },
  },
  methods: {
    enviarMensaje() {
      this.mensaje.toPersonsId = [];
      if (this.aAsistentes) {
        const asistentes = this.lugarSeleccionado.employees.map((e) => {
          return e.id;
        });
        this.mensaje.toPersonsId = this.mensaje.toPersonsId.concat(asistentes);
      }
      if (this.aPacientes) {
        const pacientes = this.lugarSeleccionado.peopleWaiting.map((e) => {
          return e.id;
        });
        this.mensaje.toPersonsId = this.mensaje.toPersonsId.concat(pacientes);
      }
      if (!this.mensaje.toPersonsId.length || !this.mensaje.message.length) {
        return this.alertaMsj = {
          content: !this.mensaje.message.length
            ? 'Escribe un mensaje'
            : this.aPacientes && !this.mensaje.toPersonsId.length
            ? 'No hay pacientes en espera a quienes les puedas escribir'
            : 'Selecciona tus destinatarios',
          type: 'warning',
        };
      }
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${sendMessageToManyGql}`,
        variables: {
          mensaje: this.mensaje,
        },
      }).then((res) => {
        const msg = res.data.sendMessageToMany.msg;
        switch (msg) {
          case 'success':
            this.showMessageForm = false;
            this.alertaMsj = {
              content: 'Mensaje enviado',
              type: 'success',
            };
            this.mensaje = {};
            this.aPacientes = false;
            this.aAsistentes = false;
            this.btnDisabled = false;
            break;
          default:
            this.alertaMsj = {
              content: 'Hubo un error enviando el mensaje',
              type: 'danger',
            };
            this.btnDisabled = false;
            break;
        }
      }).catch((error) => {
        this.alertaMsj = {
          content: 'Hubo un error enviando el mensaje',
          type: 'danger',
        };
        this.btnDisabled = false;
      });
    },
  },
  apollo: {
    lugares() {
      return {
        query: gql`${doctorWorkPlacesSEGql}`,
        variables: {
          id: getPersonId(),
          date: todayDate(),
        },
        update: (data) => data.Doctor[0].works_at,
        result: ({data}) => {
          if (!data) return;
          data.Doctor[0].works_at.forEach((w)=>{
            if (w.servicesOffered.length) {
              this.hayPruebas = true;
            }
          });
        },
        fetchPolicy: 'cache-and-network',
      };
    },
  },
};
</script>
<style lang="scss">
  .link-icon-hover {
    svg.icon {
      opacity: 0;
      transform: translateX(0);
      transition: all .5s ease;
    }
    &:hover svg.icon {
      opacity: 1;
      transform: translateX(5px);
    }
  }
  .card.SE {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    box-shadow: none;

    .card-body {
      font-weight: 300;
      padding: .8rem .8rem 2rem;
      position: relative;

      .description {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        p {
          font-size: .86rem;
          margin-bottom: .25rem;
        }
      }
    }
  }
  .video-help {
    border-radius: 10px;
    height: 200px;
    width: 280px;
  }
</style>
