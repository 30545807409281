<template>
  <div>
    <mdb-navbar
      color="bg-primary-dark-base"
      dark
      expand="lg"
      position="top"
      scrolling
    >
      <div class="col px-0 d-flex justify-content-between">
        <router-link to="/">
          <mdb-navbar-brand>
            AMII
            <mdb-badge
              v-if="!isOnline"
              class="fs-07"
              color="bg-danger-base"
              title="No estás conectado a internet"
            >
              <font-awesome-icon
                :icon="['fas', 'times']"
              />
              Sin internet
            </mdb-badge>
          </mdb-navbar-brand>
        </router-link>
        <div
          v-if="$route.name != 'Login'"
          class="nav-item ripple-parent d-inline-block d-lg-none"
        >
          <span
            class="nav-link navbar-link cursor-pointer"
            title="Notificaciones"
            @click="showNotifications = !showNotifications"
          >
            <font-awesome-icon
              :icon="['fas', 'bell']"
              size="lg"
              class="d-inline-block white-text w-27px"
            />
            <mdb-badge
              v-if="notificaciones.length"
              tag="small"
              color="danger"
              class="hover-bell"
              pill
            >
              {{ notificaciones.length }}
            </mdb-badge>
          </span>
        </div>
      </div>
      <mdb-navbar-toggler>
        <mdb-navbar-nav
          v-if="$route.name != 'Login'"
          right
        >
          <mdb-nav-item
            to="/salas-de-espera/"
            exact
          >
            <font-awesome-icon
              :icon="['fas', 'couch']"
              size="lg"
              class="d-inline-block d-lg-none w-27px"
            />
            Salas de espera
          </mdb-nav-item>
          <mdb-nav-item
            to="/citas-sin-confirmar"
            exact
          >
            <font-awesome-icon
              :icon="['fas', 'clipboard-list']"
              size="lg"
              class="d-inline-block d-lg-none w-27px"
            />
            <span class="mr-1">
              Citas por confirmar
            </span>
            <mdb-badge
              v-if="citasPorConfirmar"
              class="fs-07 float-right mt-n1"
              color="bg-danger-base"
              title="Estás insolvente"
              pill
            >
              {{ citasPorConfirmar }}
            </mdb-badge>
          </mdb-nav-item>
          <mdb-nav-item
            to="/pacientes"
            exact
          >
            <font-awesome-icon
              :icon="['fas', 'users']"
              size="lg"
              class="d-inline-block d-lg-none w-27px"
            />
            Pacientes
          </mdb-nav-item>
          <mdb-nav-item
            to="/configuracion/lugares-de-trabajo"
            :active="esConfiguracion"
          >
            <font-awesome-icon
              :icon="['fas', 'gear']"
              size="lg"
              class="d-inline-block d-lg-none w-27px"
            />
            Configuración
          </mdb-nav-item>
          <mdb-nav-item
            to="/mas/finanzas"
            :active="esMas"
          >
            <font-awesome-icon
              :icon="['fas', 'bars-staggered']"
              size="lg"
              class="d-inline-block d-lg-none w-27px"
            />
            <span class="mr-1">
              Más
            </span>
            <mdb-badge
              v-if="insolvencia"
              class="fs-07 float-right mt-n1"
              color="bg-danger-base"
              title="Estás insolvente"
              pill
            >
              <font-awesome-icon
                :icon="['fas', 'exclamation']"
                class="d-inline-block"
              />
            </mdb-badge>
          </mdb-nav-item>
          <li
            class="nav-item ripple-parent d-none d-lg-block"
            @click="showNotifications = !showNotifications"
          >
            <span
              class="nav-link navbar-link cursor-pointer"
              title="Notificaciones"
            >
              <font-awesome-icon
                :icon="['fas', 'bell']"
                size="lg"
                class="d-inline-block w-27px"
              />
              <mdb-badge
                v-if="notificaciones.length"
                tag="small"
                color="danger"
                class="hover-bell"
                pill
              >
                {{ notificaciones.length }}
              </mdb-badge>
            </span>
          </li>
          <mdb-nav-item
            to="/ayuda"
            exact
          >
            <font-awesome-icon
              :icon="['fas', 'circle-question']"
              size="lg"
              class="d-inline-block w-27px"
            />
            <span class="d-lg-none">
              Ayuda
            </span>
          </mdb-nav-item>
          <mdb-nav-item
            ref="navItemClick"
            to="/login"
            exact
            @click="logout"
          >
            <font-awesome-icon
              :icon="['fas', 'right-to-bracket']"
              size="lg"
              class="d-inline-block d-lg-none w-27px"
            />
            <span
              class="fs-inherit"
            >
              Salir
            </span>
          </mdb-nav-item>
          <hr class="b-white my-2">
          <VersionAMII
            class-list="navbar-text white-text py-0 d-lg-none"
            icon-only
            inverted-color
          />
        </mdb-navbar-nav>
      </mdb-navbar-toggler>
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInRight"
        leave-active-class="animated fadeOutRight"
      >
        <Notificaciones
          v-if="showNotifications"
          :notificaciones-l="notificaciones"
          @notificaciones-borrar="notificacionesBorrar"
          @cerrar="showNotifications = !showNotifications"
        />
      </transition>
    </mdb-navbar>
  </div>
</template>
<script>
import {getSolvency} from '@/utils/authdecode.js';
import {mdbNavbar, mdbNavbarBrand, mdbNavbarToggler,
  mdbNavbarNav, mdbNavItem, mdbBadge} from 'mdbvue';
import VersionAMII from '@/components/VersionAMII.vue';
import Notificaciones from '@/components/Notificaciones.vue';
import {onLogout} from '@/vue-apollo.js';
import appointmentsAskedToMeQGql from
  '@/graphql/appointmentsAskedToMeQ.gql';

export default {
  name: 'Navbar',
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem,
    mdbBadge,
    Notificaciones,
    VersionAMII,
  },
  mixins: [Notificaciones],
  data() {
    return {
      insolvencia: !!(Number(getSolvency()) < (new Date().getTime())),
      showNotifications: false,
      notificaciones: [],
      citasPorConfirmar: 0,
    };
  },
  computed: {
    esConfiguracion() {
      const vista = this.$route.path.slice(1).split('/');
      return vista[0] == 'configuracion' ? true : false;
    },
    esMas() {
      const vista = this.$route.path.slice(1).split('/');
      return vista[0] == 'mas' ? true : false;
    },
  },
  mounted() {
    this.comprobarCompatibilidad();
  },
  methods: {
    logout() {
      onLogout(this.$apolloProvider.clients.defaultClient);
      this.$router.push('/login');
    },
    notificacionesBorrar() {
      this.notificaciones = [];
    },
  },
  apollo: {
    citasPorConfirmar() {
      return {
        query: appointmentsAskedToMeQGql,
        update: (data) => data.appointmentsAskedToMeQ,
        pollInterval: 45 *60*1000, // minutos
        skip() {
          return !(this.$route.name) || this.$route.name === 'Login';
        },
      };
    },
  },
};
</script>
<style lang="scss">
  nav ul.navbar-nav li.nav-item a.active.nav-link.navbar-link {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .w-27px { width: 27px !important; }
  .hover-bell {
    position: absolute;
    right: 0;
    top: 0;
  }
</style>
