<template>
  <div :class="['text-center', classList]">
    <small
      class="cursor-pointer"
      @click.stop="checkForUpdate()"
    >
      ©
      <span class="font-weight-bold fs-inherit letter-spacing-1">
        AMII
      </span>
      | Versión {{ fechaCompilacion() }}
    </small>
    <mdb-btn
      v-if="!hideUpdateBtn"
      :outline="invertedColor ? 'white': 'blue-grey'"
      class="z-depth-0 px-3"
      size="sm"
      dark-waves
      title="Comprobar actualización disponible"
      @click="checkForUpdate()"
    >
      <font-awesome-icon
        icon="rotate"
        :class="{'fa-spin': isInSync}"
      />
      <span
        v-if="!iconOnly"
        class="ml-1 fs-inherit"
      >
        Actualizar
      </span>
    </mdb-btn>
  </div>
</template>

<script>
import {mdbBtn} from 'mdbvue';
export default {
  name: 'VersionAMII',
  components: {mdbBtn},
  props: {
    classList: {
      type: String,
      default: 'text-muted',
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    invertedColor: {
      type: Boolean,
      default: false,
    },
    hideUpdateBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isInSync: false,
    };
  },
  methods: {
    checkForUpdate() {
      this.isInSync = true;
      if (navigator && navigator.serviceWorker) {
        navigator.serviceWorker.register('/service-worker.js').then((reg) => {
          reg.update();
        });
      }
      setTimeout(() => this.isInSync = false, 600);
    },
    fechaCompilacion() {
      const [, month, date, year, hour] = document.head
          .querySelector('[name=updated]').content.split(' ');
      let mes;
      switch (month) {
        case 'Jan':
          mes = '01';
          break;
        case 'Feb':
          mes = '02';
          break;
        case 'Mar':
          mes = '03';
          break;
        case 'Apr':
          mes = '04';
          break;
        case 'May':
          mes = '05';
          break;
        case 'Jun':
          mes = '06';
          break;
        case 'Jul':
          mes = '07';
          break;
        case 'Aug':
          mes = '08';
          break;
        case 'Sep':
          mes = '09';
          break;
        case 'Oct':
          mes = '10';
          break;
        case 'Nov':
          mes = '11';
          break;
        default:
          mes = '12';
          break;
      }
      return `${date}/${mes}/${year} ${hour.substring(0, 5)}`;
    },
  },
};
</script>

<style>
.btn-outline-white {
  color: #fafafa;
  border-color: #fafafa;
}
</style>
