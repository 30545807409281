<template>
  <div
    class="configuracion"
    data-type="submenu"
  >
    <div class="d-lg-none">
      <mdb-dropdown>
        <mdb-dropdown-toggle
          slot="toggle"
          class="flex-align-center py-2 px-3"
          flat
          dark-waves
        >
          <font-awesome-icon
            :icon="['fas', 'gear']"
            size="3x"
          />
          <div class="mx-2 text-left">
            <small class="d-block">
              Configuración
            </small>
            {{ vistaActiva }}
          </div>
        </mdb-dropdown-toggle>
        <mdb-dropdown-menu>
          <router-link
            v-for="(link, i) in sideBarLinks"
            :key="`dropdown-${i}`"
            :to="link.to"
            :class="['dropdown-item', {'divider': addDividerAfter(i)},
                     {'disabled': link.disable}]"
            exact
          >
            <font-awesome-icon
              :icon="['fas', link.icon]"
              class="mr-1"
              size="sm"
            />
            {{ link.name }}
          </router-link>
        </mdb-dropdown-menu>
      </mdb-dropdown>
    </div>
    <aside class="aside-content">
      <header class="my-3">
        <font-awesome-icon
          :icon="['fas', 'gear']"
          size="lg"
          class="ml-2"
        />
        <span class="h5-responsive ml-2">Configuración</span>
      </header>
      <ul class="aside-nav">
        <router-link
          v-for="(link, a) in sideBarLinks"
          :key="`aside-${a}`"
          :to="link.to"
          :class="['aside-item', {'divider': addDividerAfter(a)},
                   {'disabled': link.disable}]"
          tag="li"
          exact
        >
          <font-awesome-icon
            :icon="['fas', link.icon]"
            size="sm"
            class="mr-1"
          />
          {{ link.name }}
        </router-link>
      </ul>
    </aside>
    <div class="col col-lg-9 col-xl">
      <router-view />
    </div>
  </div>
</template>

<script>
import {mdbDropdown, mdbDropdownMenu, mdbDropdownToggle}
  from 'mdbvue';
export default {
  name: 'Configuracion',
  components: {
    mdbDropdown,
    mdbDropdownMenu,
    mdbDropdownToggle,
  },
  data() {
    return {
      sideBarLinks: [
        {
          name: 'Consultorios',
          to: '/configuracion/lugares-de-trabajo',
          icon: 'hospital',
          disable: false,
        },
        {
          name: 'Parámetros',
          to: '/configuracion/pruebas-clinicas',
          icon: 'stethoscope',
          disable: false,
        },
        {
          name: 'Antecedentes',
          to: '/configuracion/antecedentes',
          icon: 'folder-open',
          disable: false,
        },
        {
          name: 'Paraclínicos',
          to: '/configuracion/paraclinicos',
          icon: 'file-lines',
          disable: false,
        },
        {
          name: 'Tratamientos',
          to: '/configuracion/tratamientos',
          icon: 'pills',
          disable: false,
        },
        {
          name: 'Condiciones Temp',
          to: '/configuracion/condiciones',
          icon: 'clock',
          disable: false,
        },
        {
          name: 'Gráficos',
          to: '/configuracion/graficos',
          icon: 'chart-line',
          disable: false,
        },
        {
          name: 'Vacunas',
          to: '/configuracion/vacunas',
          icon: 'syringe',
          disable: false,
        },
        {
          name: 'Plantillas',
          to: '/configuracion/plantillas/recipe',
          icon: 'file-powerpoint',
          disable: false,
        },
        {
          name: 'Perfil público',
          to: '/configuracion/perfil-publico',
          icon: 'user-doctor',
          disable: false,
        },
        {
          name: 'Perfil',
          to: '/configuracion/perfil',
          icon: 'circle-user',
          disable: false,
        },
        {
          name: 'Asistentes',
          to: '/configuracion/asistentes',
          icon: 'id-badge',
          disable: false,
        },
        {
          name: 'Patrocinadores',
          to: '/configuracion/patrocinadores',
          icon: 'handshake',
          disable: false,
        },
        {
          name: 'Plugin para citas',
          to: '/configuracion/plugin',
          icon: 'puzzle-piece',
          disable: false,
        },
        {
          name: 'Métodos de pago',
          to: '/configuracion/metodos-de-pago',
          icon: 'money-check',
          disable: true,
        },
      ],
    };
  },
  computed: {
    vistaActiva() {
      const vista = this.$route.path.slice(1).split('/');
      switch (vista[1]) {
        case 'antecedentes':
          return 'Antecedentes';
        case 'asistentes':
          return 'Asistentes';
        case 'metodos-de-pago':
          return 'Métodos de pago';
        case 'paraclinicos':
          return 'Paraclínicos';
        case 'perfil':
          return 'Perfil';
        case 'perfil-publico':
          return 'Perfil publico';
        case 'plantillas':
          return 'Plantillas';
        case 'pruebas-clinicas':
          return 'Parámetros';
        case 'tratamientos':
          return 'Tratamientos';
        default:
          return 'Consultorios';
      }
    },
  },
  methods: {
    addDividerAfter(item) {
      switch (item) {
        case 0: // Después de Consultorios
          return true;
        case 8: // Después de plantillas
          return true;
        case 12: // Después de Patrocinadores
          return true;
        default:
          return false;
      }
    },
  },
};
</script>

<style lang="scss">
</style>
