/**
 * Calcular diferencia en días
 * @param {Date} fechaIzquierda
 * @param {Date} fechaDerecha
 * @return {Int}
 */
function difEnDias(fechaIzquierda, fechaDerecha) {
  const dif = fechaIzquierda - fechaDerecha;
  const dias = 1 * 1000 * 60 * 60 * 24;


  const difcompleta = dif/dias;
  const diffloored = Math.floor(difcompleta);

  return diffloored;
}
/**
 * Calcular diferencia en semanas
 * @param {Date} fechaIzquierda
 * @param {Date} fechaDerecha
 * @return {Int}
 */
function difEnSemanas(fechaIzquierda, fechaDerecha) {
  const dias = difEnDias(fechaIzquierda, fechaDerecha);

  return dias >= 0
    ? Math.floor(dias/7)
    : Math.ceil(dias/7);
}
/**
 * Calcular diferencia en meses
 * @param {Date} fechaIzquierda
 * @param {Date} fechaDerecha
 * @return {Int}
 */
function difEnMeses(fechaIzquierda, fechaDerecha) {
  const linealDay = fechaIzquierda.getDate() -
    fechaDerecha.getDate();
  const linealMonth = fechaIzquierda.getMonth() -
    fechaDerecha.getMonth();
  let sumadoFallo = 0;
  if (linealMonth < 0) {
    if (linealDay > 0) {
      sumadoFallo = 1;
    }
  } else if (linealMonth > 0) {
    if (linealDay < 0) {
      sumadoFallo = -1;
    }
  }
  return (
    linealMonth + sumadoFallo
    + 12*(fechaIzquierda.getFullYear() - fechaDerecha.getFullYear())
  );
}
/**
 * Calcular diferencia en años
 * @param {Date} fechaIzquierda
 * @param {Date} fechaDerecha
 * @return {Int}
 */
function difEnAnhos(fechaIzquierda, fechaDerecha) {
  const linealMonth = fechaIzquierda.getMonth() -
    fechaDerecha.getMonth();
  const linealYear= fechaIzquierda.getFullYear() -
    fechaDerecha.getFullYear();
  let sumadoFallo = 0;
  if (linealYear< 0) {
    if (linealMonth > 0) {
      sumadoFallo = 1;
    }
  } else if (linealYear> 0) {
    if (linealMonth < 0) {
      sumadoFallo = -1;
    }
  }
  return (
    linealYear+ sumadoFallo
  );
}

/**
 * Calcular resultado de plantilla
 * @param {Object} condicion
 * @return {void}
 */
function calcConditionTemporal(condicion) {
  if (!condicion.start) return;
  const ts = new Date();
  const hoy = new Date(ts.getFullYear()+'-'
    +('0'+(ts.getMonth()+1)).slice(-2)+'-'
    +('0'+ts.getDate()).slice(-2)
    +'T00:00:00');
  const start = new Date(condicion.start
    +'T00:00:00');
  let hasta = '';
  // En caso sea comparación
  let diffloored = 0;
  let difsub = 0;
  let difDias = 0;
  let difSemanas = 0;
  let difMeses = 0;
  switch (condicion.operation) {
    case 'sum':
      switch (condicion.temporalUnit) {
        case 'day':
          hasta = new Date(start.setDate(
              start.getDate()+condicion.quantity),
          );
          break;
        case 'week':
          hasta = new Date(start.setDate(
              start.getDate()+condicion.quantity*7),
          );
          break;
        case 'month':
          hasta = new Date(start.setMonth(
              start.getMonth()+condicion.quantity),
          );
          break;
        case 'year':
          hasta = new Date(start.setFullYear(
              start.getFullYear()+condicion.quantity),
          );
          break;
        default:
          break;
      }
      condicion.end = hasta.toISOString();
      break;
    case 'sub':
      switch (condicion.temporalUnit) {
        case 'day':
          hasta = new Date(start.setDate(
              start.getDate()-condicion.quantity),
          );
          break;
        case 'week':
          hasta = new Date(start.setDate(
              start.getDate()-condicion.quantity*7),
          );
          break;
        case 'month':
          hasta = new Date(start.setMonth(
              start.getMonth()-condicion.quantity),
          );
          break;
        case 'year':
          hasta = new Date(start.setFullYear(
              start.getFullYear()-condicion.quantity),
          );
          break;
        default:
          break;
      }
      condicion.end = hasta.toISOString();
      break;
    case 'com':
      condicion.end = difEnDias(hoy, start) < 0 ? 'Faltan ' : '';
      switch (condicion.temporalUnit) {
        case 'day':
          difDias = Math.abs(difEnDias(hoy, start));
          if (difDias) {
            condicion.end += difDias+' día';
            if (difDias > 1) {
              condicion.end += 's';
            }
          }
          break;
        case 'week':
          difDias = Math.abs(difEnDias(hoy, start));
          difSemanas = Math.abs(difEnSemanas(hoy, start));
          difsub = difDias % 7;
          if (difSemanas) {
            condicion.end += difSemanas+ ' semana';
            if (difSemanas > 1) {
              condicion.end += 's';
            }
          }
          if (difSemanas && difsub) {
            condicion.end += ' y ';
          }
          if (difsub) {
            condicion.end += difsub+' día';
            if (difsub > 1) {
              condicion.end += 's';
            }
          }
          break;
        case 'month':
          difMeses = Math.abs(difEnMeses(hoy, start));
          difsub = Math.abs(difEnDias(hoy, start)) % 31;
          if (difMeses) {
            condicion.end += difMeses+' mes';
            if (difMeses > 1) {
              condicion.end += 'es';
            }
          }
          if (difMeses && difsub) {
            condicion.end += ' y ';
          }
          if (difsub) {
            condicion.end += difsub+' día';
            if (difsub > 1) {
              condicion.end += 's';
            }
          }
          break;
        case 'year':
          diffloored = Math.abs(difEnAnhos(hoy, start));
          difsub = Math.abs(difEnMeses(hoy, start)) % 12;
          if (diffloored) {
            condicion.end += diffloored+' año';
            if (diffloored > 1) {
              condicion.end += 's';
            }
          }
          if (diffloored && difsub) {
            condicion.end += ' y ';
          }
          if (difsub) {
            condicion.end += difsub+' mes';
            if (difsub > 1) {
              condicion.end += 'es';
            }
          }
          break;
        default:
          break;
      }
      if (!condicion.end) {
        condicion.end += 'Sin diferencia aún';
      }
      break;
    default:
      break;
  }

  return condicion.end;
}

/**
 * Sumar a una fecha
 * @param {string} fecha
 * @param {number} cantidad
 * @param {string} unidad
 * @return {string} la fecha resultante en string
 */
function sumToDate(fecha, cantidad, unidad) {
  if (!fecha) return null;
  const actual = new Date(fecha);
  switch (unidad) {
    case 'day':
      actual.setDate(actual.getDate() + cantidad);
      break;
    case 'week':
      actual.setDate(actual.getDate() + cantidad);
      break;
    case 'month':
      actual.setMonth(actual.getMonth() + cantidad);
      break;
    case 'year':
      actual.setFullYear(actual.getFullYear() + cantidad);
      break;

    default:
      break;
  }
  return actual.toISOString().split('T')[0];
}

const MathOperations = {
  sum: 'Sumar',
  sub: 'Restar',
  com: 'Diferencia hasta hoy',
};

const TemporalUnits = {
  day: 'Días',
  week: 'Semanas',
  month: 'Meses',
  year: 'Años',
};

export {
  difEnSemanas,
  difEnMeses,
  calcConditionTemporal,
  MathOperations,
  TemporalUnits,
  sumToDate,
};
