import Vue from 'vue';
import Router from 'vue-router';
import SalasDeEspera from './views/SalasDeEspera.vue';
import LugaresDeTrabajo from './views/Configuracion/LugaresDeTrabajo.vue';
import PruebasClinicas from './views/Configuracion/PruebasClinicas.vue';
import SalaDeEspera from './views/SalaDeEspera.vue';
import Paciente from './views/Paciente.vue';
import PacienteDiagnostico from './components/PacienteDiagnostico.vue';
import Configuracion from './views/Configuracion/Configuracion.vue';
import GaleriaPruebasClinicas
  from './views/Galerias/GaleriaPruebasClinicas.vue';
import Mas from './views/Mas/Mas.vue';
import {isAuthenticated, isNotAuthenticated} from './vue-apollo';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login/:code?',
      name: 'Login',
      component: () => import('./views/Login.vue'),
      beforeEnter: isNotAuthenticated,
      meta: {title: 'AMII Login'},
    },
    {
      path: '/',
      name: 'Dashboard',
      component: SalasDeEspera,
      beforeEnter: isAuthenticated,
      meta: {title: 'AMII Dashboard'},
    },
    {
      path: '/configuracion',
      name: 'Configuracion',
      component: Configuracion,
      beforeEnter: isAuthenticated,
      meta: {title: 'AMII Configuración'},
      children: [
        {
          path: 'antecedentes',
          name: 'Antecedentes',
          component: () => import('./views/Configuracion/Antecedentes.vue'),
          meta: {title: 'AMII Configuración Antecedentes'},
          children: [
            {
              path: 'catalogo',
              name: 'GaleriaAntecedentes',
              component: () =>
                import('./views/Galerias/GaleriaAntecedentes.vue'),
              meta: {title: 'AMII Catálogo Antecedentes'},
            },
          ],
        },
        {
          path: 'asistentes',
          name: 'Asistentes',
          component: () => import('./views/Configuracion/Asistentes.vue'),
          meta: {title: 'AMII Configuración Asistentes'},
        },
        {
          path: 'patrocinadores/:invitacionId?',
          name: 'Patrocinadores',
          component: () => import('./views/Configuracion/Patrocinadores.vue'),
          meta: {title: 'AMII Configuración Patrocinadores'},
        },
        {
          path: 'condiciones',
          name: 'Condiciones',
          component:
            () => import('./views/Configuracion/CondicionesTemporales.vue'),
          meta: {title: 'AMII Configuración Condiciones temporales'},
        },
        {
          path: 'graficos',
          name: 'Graficos',
          component:
            () => import('./views/Configuracion/Graficos.vue'),
          meta: {title: 'AMII Configuración Gráficos'},
          children: [
            {
              path: 'catalogo',
              name: 'GaleriaGraficos',
              component: () => import('./views/Galerias/GaleriaGraficos.vue'),
              meta: {title: 'AMII Catálogo Gráficos'},
            },
          ],
        },
        {
          path: 'lugares-de-trabajo',
          name: 'LugaresDeTrabajo',
          component: LugaresDeTrabajo,
          meta: {title: 'AMII Configuración Lugares'},
        },
        {
          path: 'metodos-de-pago',
          name: 'MetodosDePago',
          component: () => import('./views/Configuracion/MetodosDePago.vue'),
          meta: {title: 'AMII Configuración Métodos de pago'},
        },
        {
          path: 'paraclinicos',
          name: 'Paraclinicos',
          component: () => import('./views/Configuracion/Paraclinicos.vue'),
          meta: {title: 'AMII Configuración Paraclínicos'},
        },
        {
          path: 'tratamientos',
          name: 'Tratamientos',
          component: () => import('./views/Configuracion/Tratamientos.vue'),
          meta: {title: 'AMII Configuración Tratamientos'},
          children: [
            {
              path: 'catalogo',
              name: 'GaleriaTratamientos',
              component: () =>
                import('./views/Galerias/GaleriaTratamientos.vue'),
              meta: {title: 'AMII Catálogo Tratamientos'},
            },
          ],
        },
        {
          path: 'perfil',
          name: 'Perfil',
          component: () => import('./views/Configuracion/Perfil.vue'),
          meta: {title: 'AMII Configuración Perfil'},
          children: [
            {
              path: 'catalogo',
              name: 'GaleriaPerfil',
              component: () => import('./views/Galerias/GaleriaPerfil.vue'),
              meta: {title: 'AMII Catálogo Mis elementos'},
            },
          ],
        },
        {
          path: 'perfil-publico',
          name: 'PerfilPublico',
          component: () => import('./views/Configuracion/PerfilPublico.vue'),
          meta: {title: 'AMII Configuración Perfil Público'},
        },
        {
          path: 'plantillas',
          name: 'Plantillas',
          component: () => import('./views/Configuracion/Plantillas.vue'),
          meta: {title: 'AMII Configuración Plantillas'},
          children: [
            {
              path: 'impresion',
              name: 'Impresion',
              component:
                () => import('./components/Plantillas/OpcionesDeImpresion.vue'),
              meta: {title: 'AMII Configuración Opciones de impresión'},
            },
            {
              path: 'recipe',
              name: 'Recipe',
              component:
                () => import('./components/Plantillas/RecipePlantilla.vue'),
              meta: {title: 'AMII Configuración Recipe'},
            },
            {
              path: 'documentos/:docId?',
              name: 'Documentos',
              component:
                () => import('./components/Plantillas/DocumentosPlantilla.vue'),
              meta: {title: 'AMII Configuración Documentos'},
            },
            {
              path: 'catalogo',
              name: 'GaleriaDocumentos',
              component: () => import('./views/Galerias/GaleriaDocumentos.vue'),
              meta: {title: 'AMII Catálogo Documentos'},
              children: [
                {
                  path: ':documentId',
                  name: 'GaleriaDocumento',
                  component:
                    () => import('./views/Galerias/GaleriaDocumento.vue'),
                  meta: {title: 'AMII Catálogo Documentos'},
                },
              ],
            },
          ],
        },
        {
          path: 'pruebas-clinicas',
          name: 'PruebasClinicas',
          component: PruebasClinicas,
          beforeEnter: isAuthenticated,
          meta: {title: 'AMII Configuración Parámetros'},
          children: [
            {
              path: 'catalogo',
              name: 'GaleriaPruebasClinicas',
              component: GaleriaPruebasClinicas,
              meta: {title: 'AMII Catálogo Parámetros'},
              children: [
                {
                  path: ':serviceId',
                  name: 'GaleriaPruebaClinica',
                  component:
                    () => import('./views/Galerias/GaleriaPruebaClinica.vue'),
                  meta: {title: 'AMII Catálogo Parámetros'},
                },
              ],
            },
          ],
        },
        {
          path: 'plugin',
          name: 'Plugin',
          component: () => import('./views/Configuracion/Plugin.vue'),
          meta: {title: 'AMII Configuración Plugin'},
        },
        {
          path: 'vacunas',
          name: 'Vacunas',
          component: () => import('./views/Configuracion/Vacunas.vue'),
          meta: {title: 'AMII Configuración Vacunas'},
          children: [
            {
              path: 'catalogo',
              name: 'GaleriaVacunas',
              component: () => import('./views/Galerias/GaleriaVacunas.vue'),
              meta: {title: 'AMII Catálogo Vacunas'},
            },
          ],
        },
      ],
    },
    {
      path: '/salas-de-espera/',
      name: 'SalasDeEspera',
      component: SalasDeEspera,
      beforeEnter: isAuthenticated,
      meta: {title: 'AMII Salas de espera'},
    },
    {
      path: '/salas-de-espera/:lugar',
      name: 'SalaDeEspera',
      component: SalaDeEspera,
      beforeEnter: isAuthenticated,
      meta: {title: 'AMII Sala de espera'},
    },
    {
      path: '/pacientes',
      name: 'Pacientes',
      component: () => import('./views/Pacientes.vue'),
      beforeEnter: isAuthenticated,
      meta: {title: 'AMII Pacientes'},
    },
    {
      path: '/pacientes/nuevo',
      name: 'PacienteNuevo',
      component: () => import('./views/PacienteNuevo.vue'),
      beforeEnter: isAuthenticated,
    },
    {
      path: '/pacientes/:personId/:salaEsperaId?',
      name: 'Paciente',
      component: Paciente,
      beforeEnter: isAuthenticated,
      meta: {title: 'AMII Paciente'},
      children: [
        {
          path: 'diagnostico/:appointmentId/:aDT?/:iden?/:idenType?',
          name: 'PacienteDiagnostico',
          component: PacienteDiagnostico,
          meta: {title: 'AMII Encuentro'},
          children: [
            {
              path: 'diagnostico-detalles/:encounterId',
              name: 'PacienteDiagnosticoDetallesPrevia',
              component: () =>
                import('./components/PacienteDiagnosticoDetalles.vue'),
              meta: {title: 'AMII Detalles del encuentro'},
            },
          ],
        },
        {
          path: 'historial',
          name: 'PacienteHistorial',
          component: () => import('./components/PacienteHistorial.vue'),
          meta: {title: 'AMII Historial'},
        },
        {
          path: 'diagnostico-detalles/:encounterId',
          name: 'PacienteDiagnosticoDetalles',
          component: () =>
            import('./components/PacienteDiagnosticoDetalles.vue'),
          meta: {title: 'AMII Detalles del encuentro'},
        },
      ],
    },
    {
      path: '/citas-sin-confirmar',
      name: 'CitasSinConfirmar',
      component: () => import('./views/CitasSinConfirmar.vue'),
      beforeEnter: isAuthenticated,
      meta: {title: 'AMII Citas por confirmar'},
    },
    {
      path: '/ayuda/',
      name: 'Ayuda',
      component: () => import('./views/Ayuda.vue'),
      beforeEnter: isAuthenticated,
      meta: {title: 'AMII Ayuda'},
    },
    {
      path: '/mas',
      name: 'Mas',
      component: Mas,
      beforeEnter: isAuthenticated,
      meta: {title: 'AMII Más opciones'},
      children: [
        {
          path: 'analisis',
          name: 'Analisis',
          component: () => import('./views/Mas/Analisis.vue'),
          meta: {title: 'AMII Análisis'},
        },
        {
          path: 'finanzas',
          name: 'Finanzas',
          component: () => import('./views/Mas/Finanzas.vue'),
          beforeEnter: isAuthenticated,
          meta: {title: 'AMII Finanzas'},
        },
        {
          path: 'pagos',
          name: 'Pagos',
          component: () => import('./views/Mas/Pagos.vue'),
          meta: {title: 'AMII Pagos'},
        },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
        offset: {x: 0, y: 10},
      };
    } else {
      return {x: 0, y: 0};
    }
  },
});

const defaultTitle = 'AMII Médico';
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || defaultTitle;
  });
});

export default router;
