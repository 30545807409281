import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faTrash, faXmark, faTriangleExclamation, faChevronRight, faTachometerAlt,
  faCouch, faClipboardList, faUsers, faRightToBracket, faGear,
  faSearch, faCheck, faCirclePlus, faCircleMinus, faCopy, faPen,
  faRotate, faMoneyBillWave, faCircleInfo, faPlus, faCalendarDays,
  faArchive, faInbox, faMars, faVenus, faGenderless,
  faTransgender, faClipboardCheck, faPrescription, faChevronLeft,
  faCircleNotch, faPaperPlane, faAngleRight, faBars, faHandHoldingDollar,
  faCircleCheck, faDiagnoses, faClock, faDroplet, faUserPlus, faEye,
  faSlidersH, faCircleXmark, faExclamation, faCircleQuestion, faList, faRedo,
  faPhone, faPrint, faHospital, faFileLines, faIdBadge, faMoneyCheck, faGrip,
  faFilePowerpoint, faCircleUser, faUserDoctor, faStethoscope, faFolderOpen,
  faEyeSlash, faPills, faEnvelope, faThLarge, faGripHorizontal,
  faShareAlt, faBookmark, faStar, faBarsStaggered, faCalendarDay,
  faArrowUp, faFloppyDisk, faCaretRight, faShieldVirus, faHandshake,
  faRedoAlt, faBell, faBellSlash, faPenRuler, faVectorSquare, faArrowLeft,
  faCircleExclamation, faListCheck, faBroom, faLocationDot, faChartLine,
  faExpand, faChevronDown, faGripLines, faAddressBook, faChevronUp, faWrench,
  faClone, faSyringe, faQuestion, faPalette, faImage, faPuzzlePiece,
  faXRay, faChartSimple,
} from '@fortawesome/free-solid-svg-icons';
import {} from '@fortawesome/free-brands-svg-icons';
import {faThumbsUp, faSquare, faCircle,
} from '@fortawesome/free-regular-svg-icons';

library.add(
    // Solid
    faTrash, faXmark, faTriangleExclamation, faChevronRight, faTachometerAlt,
    faCouch, faClipboardList, faUsers, faRightToBracket, faGear,
    faSearch, faCheck, faCirclePlus, faCircleMinus, faCopy, faPen,
    faRotate, faMoneyBillWave, faCircleInfo, faPlus, faCalendarDays,
    faArchive, faInbox, faMars, faVenus, faGenderless,
    faTransgender, faClipboardCheck, faPrescription, faChevronLeft,
    faCircleNotch, faPaperPlane, faAngleRight, faBars, faHandHoldingDollar,
    faCircleCheck, faDiagnoses, faClock, faDroplet, faUserPlus, faEye,
    faSlidersH, faCircleXmark, faExclamation, faCircleQuestion, faList, faRedo,
    faPhone, faPrint, faHospital, faFileLines, faIdBadge, faMoneyCheck, faGrip,
    faFilePowerpoint, faCircleUser, faUserDoctor, faStethoscope, faFolderOpen,
    faEyeSlash, faPills, faEnvelope, faThLarge, faGripHorizontal,
    faShareAlt, faBookmark, faStar, faBarsStaggered, faCalendarDay,
    faArrowUp, faFloppyDisk, faCaretRight, faShieldVirus, faHandshake,
    faRedoAlt, faBell, faBellSlash, faPenRuler, faVectorSquare, faArrowLeft,
    faCircleExclamation, faListCheck, faBroom, faLocationDot, faChartLine,
    faExpand, faChevronDown, faGripLines, faAddressBook, faChevronUp, faWrench,
    faClone, faSyringe, faQuestion, faPalette, faImage, faPuzzlePiece,
    faXRay, faChartSimple,
    // Regular
    faThumbsUp, faSquare, faCircle,
);
