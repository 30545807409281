<template>
  <section class="mt-2" v-if="sponsors.length">
    <p class="text-center text-muted">
      Disfrutas de AMII gracias a tus aliados
    </p>
    <div class="row justify-content-center">
      <div
        v-for="(sponsor, indexSponsor) in sponsors"
        :key="indexSponsor"
        class="col-auto"
      >
        <figure>
          <img
            :src="imgPath+sponsor.logo"
            :alt="sponsor.link"
            loading="lazy"
            height="70px"
          >
        </figure>
      </div>
    </div>
  </section>
</template>
<script>
import {getSponsors} from '@/utils/authdecode.js';

const imgPath = process.env.NODE_ENV === 'production'
              ? process.env.VUE_APP_MULTIMEDIA_HTTPS
              : process.env.VUE_APP_MULTIMEDIA_HTTP;

export default {
  name: 'LogosPatrocinantes',
  data() {
    return {
      imgPath: imgPath+'dir/',
      sponsors: getSponsors(),
    };
  },
};
</script>
