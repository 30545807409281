<template>
  <div>
    <div>
      <select
        id="chartmixedselect"
        v-model="graficoSeleccionado"
        class="custom-select"
      >
        <option
          class="d-none"
          disabled
          :value="{}"
        >
          Seleccione
        </option>
        <option
          v-for="graf in graficos"
          :key="graf.id"
          :value="graf"
        >
          {{ graf.name }}
        </option>
      </select>
      <label
        for="chartmixedselect"
        :class="graficoSeleccionado.id ? 'label-active' : 'label-inactive'"
      >
        Gráfico
      </label>
    </div>
    <div
      v-if="parametros.length"
      class="md-form md-outline outline-select my-1"
    >
      <select
        id="chartXParam"
        v-model="dataToChart[0].x"
        class="custom-select"
      >
        <option
          class="d-none"
          disabled
          :value="0"
        >
          Seleccione
        </option>
        <option
          v-for="parametro in parametros"
          :key="parametro.id"
          :value="parametro.value"
        >
          {{ parametro.name }}: {{ parametro.value }} {{ parametro.symbol }}
        </option>
      </select>
      <label
        for="chartXParam"
        :class="true ? 'label-active' : 'label-inactive'"
      >
        {{ graficoSeleccionado.xLabel
          ? graficoSeleccionado.xLabel
          : 'Parámetro para X' }}
      </label>
    </div>
    <div
      v-if="parametros.length"
      class="md-form md-outline outline-select my-1"
    >
      <select
        id="chartYParam"
        v-model="dataToChart[0].y"
        class="custom-select"
      >
        <option
          class="d-none"
          disabled
          :value="0"
        >
          Seleccione
        </option>
        <option
          v-for="parametro in parametros"
          :key="parametro.id"
          :value="parametro.value"
        >
          {{ parametro.name }}: {{ parametro.value }} {{ parametro.symbol }}
        </option>
      </select>
      <label
        for="chartYParam"
        :class="true ? 'label-active' : 'label-inactive'"
      >
        {{
          graficoSeleccionado.yLabel
            ? graficoSeleccionado.yLabel
            : 'Parámetro para Y'
        }}
      </label>
    </div>
    <div class="row">
      <div class="col-6">
        <mdb-input
          v-model.number="dataToChart[0].x"
          type="number"
          label="Valor eje X"
          class="my-1 text-nowrap border-cyan"
          title="Valor en el eje X (horizontal)"
          outline
        />
        <!-- <input v-model="dataToChart[0].x"> -->
      </div>
      <div class="col-6">
        <!-- <input v-model="dataToChart[0].y"> -->
        <mdb-input
          v-model.number="dataToChart[0].y"
          type="number"
          label="Valor eje Y"
          class="my-1 text-nowrap border-cyan"
          title="Valor en el eje Y (vertical)"
          outline
        />
      </div>
    </div>
    <div>
      <mdb-btn
        class="col mx-0 mb-3 flat-bordered"
        dark-waves
        flat
        :icon="`fas ${$apolloData.queries.grafico.loading
          ? 'fa-circle-notch' : 'fa-chart-line'}`"
        :icon-class="`fa-lg
                      ${$apolloData.queries.grafico.loading ? 'fa-spin': ''}`"
        size="sm"
        :disabled="$apolloData.queries.grafico.loading"
        @click="mixDatasets"
      >
        Graficar
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import {mdbBtn, mdbInput} from 'mdbvue';
import {getPersonId} from '@/utils/authdecode.js';
import {mixDatasets} from '@/utils/charts.js';
import doctorServicesInWorkplaceGql from
  '@/graphql/doctorServicesInWorkplace.gql';
import chartTemplateGql from '@/graphql/chartTemplate.gql';

export default {
  name: 'ChartMixer',
  components: {
    mdbBtn,
    mdbInput,
  },
  props: {
    parametros: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      graficoSeleccionado: {},
      graficos: [],
      grafico: {},
      dataToChart: [{
        x: 0,
        y: 0,
      }],
    };
  },
  methods: {
    mixDatasets() {
      const options = mixDatasets(this.grafico, this.dataToChart);

      this.$emit('graficar', options);
    },
  },
  apollo: {
    graficos() {
      return {
        query: doctorServicesInWorkplaceGql,
        variables: {
          id: getPersonId(),
        },
        update: (data) => data.Doctor[0].chartsTemplate,
      };
    },
    grafico() {
      return {
        query: chartTemplateGql,
        variables() {
          return {
            id: this.graficoSeleccionado.id,
          };
        },
        update: (data) => data.Chart_Template[0],
        skip() {
          return this.graficoSeleccionado.id ? false : true;
        },
      };
    },
  },
};
</script>
