/**
 * Formatos de impresión.
 * Ordenados alfabéticamente.
 * @returns {Object} Nombre del documento y Formato | Vista de impresión
 */

/**
 * Devuelve un objeto con el nombre del documento y el formato de impresión
 * @param {String} fechaFactura Fecha en formato dd/mm/aaaa
 * @param {String} pagador Nombre en formato fname mname lname llname
 * @param {String} monto Monto expresado en symbol amount
 * @param {Number} impuesto Porcentaje del impuesto
 * @param {String} calcImpuesto Calculo del impuesto expresado en symbol taxVal
 * @param {String} montoTotal Monto total expresado en symbol total
 * @param {String} estadoFactura Status de la factura en español
 * @param {Array} detallesPago Detalle de pago(s), ordenado para impresión
 * @return {Object} Nombre del documento y Formato | Vista de impresión
 */
function detallesFacturaFormat(fechaFactura, pagador, monto, impuesto,
    calcImpuesto, montoTotal, estadoFactura, detallesPago) {
  const document = {
    name: `Detalles factura ${fechaFactura} - ${pagador}`,
    viewDetallesFactura: [
      {
        text: `Detalles de la factura ${fechaFactura}\n\n`,
        alignment: 'center',
        style: 'header5',
      },
      {
        columns: [
          {
            text: [
              {text: 'Nombre del pagador\n', bold: true},
              {text: pagador},
            ],
          },
          {
            width: '33%',
            text: [
              {text: 'Estado de la factura\n', bold: true},
              {text: estadoFactura},
            ],
          },
        ],
      },
      {text: '\n'},
      {
        columns: [
          {
            text: [
              {text: 'Monto \n', bold: true},
              {text: monto},
            ],
          },
          {
            text: [
              {text: `Impuesto ${impuesto}%\n`, bold: true},
              {text: calcImpuesto},
            ],
          },
          {
            text: [
              {text: 'Total\n', bold: true},
              {text: montoTotal},
            ],
          },
        ],
      },
      {
        text: '\nDetalles de pago\n\n',
        alignment: 'center',
        style: 'header6',
      },
      {
        columns: detallesPago,
      },
    ],
  };

  return document;
}

/**
 * Devuelve un objeto con el nombre del documento y el formato de impresión
 * @param {Object} paciente Datos del paciente {name,docName,age,identification}
 * @param {String} consultation Fecha en formato dd/mm/aaaa
 * @param {String} duration Duración expresados en min
 * @param {String} status Estatus del encuentro en español
 * @param {String} motivos Motivos del encuentro
 * @param {Array} usedServices Descripción de servicios, ordenado para impresión
 * @param {Array} orders Órdenes emitidas, ordenadas para impresión
 * @param {Array} diagnostico Síntomas y diagnosticos, ordenados para impresión
 * @param {String} enfermedadActual Descripción de la enfermedad actual
 * @param {String} examenFisico Examen físico
 * @param {String} previousOrdersDetails Notas paraclínicos anteriores
 * @param {String} notaEncuentro Notas del encuentro
 * @param {Array} facturacion Formato | Vista de impresión de la facturación
 * @param {String} tags Etiquetas asociadas al encuentro
 * @param {Array} recipeIndicaciones Formato | Vista de impresión del recipe
 * @return {Object} Nombre del documento y Formato | Vista de impresión
 */
function encuentroFormat(paciente, consultation,
    duration, status, motivos, usedServices, orders, diagnostico,
    enfermedadActual, examenFisico, previousOrdersDetails, notaEncuentro,
    facturacion, tags, recipeIndicaciones) {
  const document = {
    name: `${paciente.docName} ${consultation}`,
    viewEncuentro: [
      // Datos del paciente y la consulta
      {
        columns: [
          [
            {
              text: [
                {text: paciente.name, bold: true},
                {text: ` (${paciente.age})\n`},
                {text: `Identificación: ${paciente.identification}`},
              ],
            },
          ],
          [
            {
              alignment: 'right',
              text: [
                {text: `Fecha: ${consultation}\n`, bold: true},
                {text: `Duración: ${duration}.  `},
                {text: `Estatus: ${status}.\n`},
              ],
            },
          ],
        ],
      },
      // Motivos de la consulta
      {
        text: [
          {text: `\nMotivo(s): `, bold: true},
          {text: `${motivos}.\n`},
        ],
      },
      // Servicios usados
      {text: 'Parámetros\n\n', style: 'header5', alignment: 'center'},
      usedServices
      ? {columns: usedServices}
      : {
        text: 'No se registraron parámetros durante la consulta',
        alignment: 'center',
      },
      // Lista de exámenes externos
      {
        text: '\nExámenes externos\n\n',
        alignment: 'center',
        style: 'header5',
      },
      orders
      ? {columns: orders}
      : {
        text: 'No se registraron órdenes para exámenes externos'
              + ' durante la consulta',
        alignment: 'center',
      },
      previousOrdersDetails
      ? {
        text: [
          {
            text: '\nResultados exámenes paraclínicos previos\n',
            style: 'header5',
          },
          {text: previousOrdersDetails},
        ],
      }
      : {text: ''},
      // Notas tomadas durante la consulta
      {
        text: '\nConsulta\n',
        alignment: 'center',
        style: 'header5',
      },
      // Enfermedad actual
      {
        text: [
          {text: '\nEnfermedad actual\n', style: 'header5'},
          {text: enfermedadActual},
        ],
      },
      // Examen físico y Diagnóstico
      examenFisico
      ? {
        columns: [
          // Examen físico
          {
            text: [
              {text: '\nExamen físico\n', style: 'header5'},
              {text: examenFisico},
            ],
          },
          // Diagnostico
          diagnostico,
        ],
      }
      : diagnostico,
      // Notas para el historial
      notaEncuentro
      ? {
        text: [
          {text: '\nNotas adicionales\n', style: 'header5'},
          {text: notaEncuentro},
        ],
      }
      : {text: ''},
      {text: '\n'},
      // Facturación
      facturacion,
      {
        text: [
          {text: '\nEtiquetas de consulta: ', style: 'header5'},
          {text: `${tags}.\n\n`},
        ],
      },
      // Recipe e indicaciones
      recipeIndicaciones,
    ],
  };

  return document;
}

/**
 * Devuelve un objeto con el nombre del documento y el formato de impresión
 * @param {String} pacienteName Nombre en formato lname llname, fname mname
 * @param {Array} bodyTable Tabla de servicios, ordenada para impresión
 * @param {Array} metodoPago Descripción de pago(s), ordenado para impresión
 * @param {String} billStatus Estado de la factura
 * @param {String} fechaEncuentro Fecha en formato dd/mm/aaaa
 * @return {Object} Nombre del documento y Formato | Vista de impresión
 */
function facturacionFormat(pacienteName, bodyTable, metodoPago, billStatus,
    fechaEncuentro) {
  const document = {
    name: `${fechaEncuentro} Facturación: ${pacienteName}`,
    viewFacturacion: [
      {
        columns: [
          [
            {text: 'Facturación\n', style: 'header5'},
            {
              layout: 'lightHorizontalLines', // optional
              table: {
                // headers are automatically repeated if the table
                // spans over multiple pages
                // you can declare how many rows should be treated as headers
                headerRows: 1,
                widths: ['*', '*'],
                body: bodyTable,
              },
            },
          ],
          {
            width: '35%',
            text: [
              {
                text: metodoPago,
              },
              {
                text: [
                  {text: '\nEstado del pago: ', bold: true},
                  {text: `${billStatus}.`},
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  return document;
}

/**
 * Devuelve un objeto con el nombre del documento y el formato de impresión
 * @param {String} date Fecha en formato dd/mm/aaaa
 * @param {String} description Descripción de la orden
 * @param {String} index Índice de la orden
 * @param {Object} paciente Información del paciente paciente en formato
 *                          {name, age, identification, docName}
 * @return {Object} Nombre del documento y Formato | Vista de impresión
 */
function paraclinicoFormat(date, description, index, paciente) {
  const document = {
    name: `${date} Paraclínico ${index}: ${paciente.docName}`,
    viewParaclinico: [
      {
        text: [
          'Paciente: ',
          {text: paciente.name, bold: true},
        ],
      },
      {
        columns: [
          {
            width: '*',
            text: [
              'Edad: ',
              {text: paciente.age, bold: true},
            ],
          },
          {
            width: 'auto',
            text: [
              'Identificación: ',
              {text: paciente.identification, bold: true},
            ],
          },
        ],
      },
      {
        text: [
          'Fecha: ',
          {text: date, bold: true},
        ],
      },
      {text: '\n'},
      {text: description},
    ],
  };

  return document;
}

/**
 * Devuelve un objeto con el nombre del documento y el formato de impresión
 * @param {Object} fechas Fechas {desde, hasta} en formato dd/mm/aaaa
 * @param {Array} bodyTable Tabla de finanzas, ordenada para impresión
 * @return {Object} Nombre del documento y Formato | Vista de impresión
 */
function reporteFinanzasFormat(fechas, bodyTable) {
  const document = {
    name: `Finanzas del ${fechas.desde} - ${fechas.hasta}`,
    viewReporteFinanzas: [
      {
        columns: [
          {
            width: '*',
            text: 'Finanzas\n\n',
            alignment: 'center',
            style: 'header5',
          },
          {
            width: 'auto',
            text: [
              {
                text: 'Consulta de facturas: ',
                bold: true,
              },
              {
                text: `${fechas.desde} - ${fechas.hasta}.`,
              },
            ],
            alignment: 'right',
          },
        ],
      },
      {
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          widths: ['auto', '*', '*', '*', '*'],
          body: bodyTable,
        },
      },
    ],
  };

  return document;
}

export {
  detallesFacturaFormat,
  encuentroFormat,
  facturacionFormat,
  paraclinicoFormat,
  reporteFinanzasFormat,
};
