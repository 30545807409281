/**
 * getAuthPayload devuelve el payload del token en json
 * @return {Object} payload en JSON
 */
function getAuthPayload() {
  const token = localStorage.getItem('apollo-token');
  const payload = token ? token.split('.')[1] : null;
  return payload ? JSON.parse(atob(payload)) : {};
}

/**
 * getAuthPayload devuelve el payload del token en json
 * @return {String} el identificador de la persona loggueada
 */
function getPersonId() {
  return getAuthPayload().id;
}

/**
 * getAuthPayload devuelve el payload del token en json
 * @return {String} el nombre completo de la persona loggueada
 */
function getPersonName() {
  return getAuthPayload().name;
}

/**
 * getAuthPayload devuelve el payload del token en json
 * @return {String} el rol de la persona loggueada
 */
function getRole() {
  return getAuthPayload().role;
}

/**
 * getAuthPayload devuelve el payload del token en json
 * @return {String} el fecha de solvencia en segundos de la persona loggueada
 */
function getSolvency() {
  return getAuthPayload().solvent;
}
/**
 * getSponsors devuelve el payload del token en json
 * @return {String} patrocinadores de la persona loggueada
 */
function getSponsors() {
  return getAuthPayload().sponsors || [];
}

export {
  getPersonId,
  getPersonName,
  getRole,
  getSolvency,
  getSponsors,
};
