<template>
  <div class="mt-2">
    <div
      v-if="$apolloData.queries.paciente.loading"
      ref="cargando"
      class="text-center text-muted vertical-centered vc-75vh"
    >
      <font-awesome-icon
        :icon="['fas', 'circle-notch']"
        class="fa-spin fa-5x my-4"
      />
      <small class="d-block">
        CARGANDO
      </small>
    </div>
    <div
      v-else
      :class="['container pacient-view',
               {'d-none': hidePatientOverflowOnEncounterView}]"
    >
      <!-- Información básica -->
      <router-link :to="atrasRoute">
        <mdb-btn
          class="mx-0 my-2 py-3 px-2 text-btn"
          dark-waves
          flat
          icon="chevron-left"
          icon-class="text-btn"
        >
          Atrás
        </mdb-btn>
      </router-link>
      <div class="row justify-content-center align-items-center mb-3">
        <img
          v-if="emailToGravatar"
          class="col-auto mb-2 mb-sm-0"
          :src="`https://www.gravatar.com/avatar/${emailToGravatar}?s=100`"
          alt="Imagen de perfil"
        >
        <div class="col">
          <p class="h4-responsive mb-0 text-center">
            <span class="fs-inherit d-block d-lg-inline">
              {{ paciente.persona.fname }} {{ paciente.persona.mname }}
            </span>
            <span class="fs-inherit d-block d-lg-inline">
              {{ paciente.persona.lname }} {{ paciente.persona.llname }}
            </span>
          </p>
          <p class="mb-0 text-center">
            {{ identificationTypes[paciente.persona.identificationType] }}
            {{ paciente.persona.identification }}
          </p>
          <div class="text-center">
            <mdb-btn
              v-if="tels.length"
              flat
              dark-waves
              icon="whatsapp"
              fab
              tag="a"
              :title="`${tels[0].country?tels[0].country:''}${tels[0].number}`"
              :href="`https://wa.me/${tels[0].country?tels[0].country:''}${tels[0].number}?text=¡Hola%20AMII!,%20¿podemos%20conversar?`"
              rel="noopener noreferrer"
              target="_blank"
              class="btn-rounded px-2 py-2 m-0 mr-2 text-success"
            />
            <mdb-btn
              v-if="emails.length"
              flat
              dark-waves
              fab
              tag="a"
              :title="`${emails[0].email}`"
              :href="`mailto:${emails[0].email}`"
              rel="noopener noreferrer"
              target="_blank"
              class="btn-rounded px-3 py-2 m-0 ml-2 text-danger"
            >
              <font-awesome-icon
                icon="envelope"
              />
            </mdb-btn>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <p class="mb-1">
            <span v-if="tag">
              Te conoce por:
              <mdb-badge
                v-if="!editContactFrom"
                color="bg-primary"
                pill
                title="Primer medio de contacto del paciente"
                class="user-select-none"
              >
                {{ tag }}
              </mdb-badge>
            </span>
            <mdb-btn
              flat
              dark-waves
              :icon="editContactFrom ? 'sync' : tag  ? 'pen' : 'plus'"
              class="p-1"
              @click="updateRecord('fuente')"
            >
              {{ editContactFrom
                ? 'Actualizar' : tag
                  ? '' : 'registrar medio de contacto' }}
            </mdb-btn>
          </p>
          <div v-if="editContactFrom" class="row mb-2">
            <div class="col">
              <div class="md-form md-outline outline-select my-2">
                <select
                  id="newTag-select"
                  v-model="tag"
                  class="custom-select"
                >
                  <option
                    class="d-none"
                    disabled
                    value=""
                  >
                    Seleccione medio de contacto inicial
                  </option>
                  <option
                    v-for="(etiqueta, i) in tagsAvailables"
                    :key="i"
                    :value="etiqueta.name"
                  >
                    {{ etiqueta.name }}
                  </option>
                  <option value="otra">
                    Agregar nueva
                  </option>
                  <option
                    v-if="tag !== 'otra'"
                    class="d-none"
                    :value="tag"
                  >
                    {{ tag }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div
                v-if="tag == 'otra'"
                class="d-flex align-items-center mb-2"
              >
                <mdb-input
                  v-model="otherTag"
                  placeholder="Nueva Etiqueta"
                  class="col p-0 my-1"
                  outline
                />
                <mdb-btn
                  :class="['col-auto success-text ml-0 p-2',
                          {'animated pulse-bg infinite': otherTag.length,}]"
                  dark-waves
                  flat
                  icon="plus-circle"
                  icon-class="fs-15-mobileOnly"
                  size="sm"
                  title="Añadir etiqueta"
                  :disabled="(!tag || tag == 'otra') && !otherTag"
                  @click="addTag"
                />
              </div>
            </div>
          </div>
          <p class="mb-1">
            <font-awesome-icon
              :icon="['fas', genderIcons[paciente.persona.gender]]"
              size="lg"
              :class="['mr-1', genderIconColors[paciente.persona.gender]]"
            />
            {{ paciente.persona.age }}
            <font-awesome-icon
              :icon="['fas', 'calendar-days']"
              size="sm"
              class="ml-3 mr-1 primary-text"
            />
            {{ paciente.persona.born.formatted }}
            <span
              v-if="paciente.persona.bloodType"
              title="Tipo de sangre"
            >
              <font-awesome-icon
                :icon="['fas','droplet']"
                size="sm"
                class="ml-3 danger-text"
              />
              {{ bloodTypes[paciente.persona.bloodType] }}
            </span>
          </p>
          <p
            v-if="paciente.persona.address"
            class="mb-1"
          >
            <font-awesome-icon
              :icon="['fas', 'location-dot']"
              size="lg"
              class="mr-1 warning-text"
            />
            <span class="fs-inherit">
              {{ paciente.persona.address.line }}
            </span>
            <span class="fs-inherit">
              {{ paciente.persona.address.street }}
            </span>
            <span
              v-if="paciente.persona.address.city
                && paciente.persona.address.city.name"
              class="fs-inherit"
            >
              {{ paciente.persona.address.city.name }},
            </span>
            <span
              v-if="paciente.persona.address.city
                && paciente.persona.address.city.state
                && paciente.persona.address.city.state.name"
              class="fs-inherit"
            >
              {{ paciente.persona.address.city.state.name }},
            </span>
            <span
              v-if="paciente.persona.address.city
                && paciente.persona.address.city.state
                && paciente.persona.address.city.state.country
                && paciente.persona.address.city.state.country.name"
              class="fs-inherit"
            >
              {{ paciente.persona.address.city.state.country.name }}.
            </span>
            <span class="fs-inherit">
              {{ paciente.persona.address.zip }}
            </span>
          </p>
        </div>
      </div>
      <!-- Botón para editar la información personal -->
      <div class="d-flex flex-column flex-sm-row align-items-md-end">
        <mdb-btn
          flat
          dark-waves
          class="c0l-12 col-sm-6 m-0 p-2"
          icon="pen"
          title="Editar información personal"
          type="button"
          @click="personaTemporal"
        >
          Editar Información
        </mdb-btn>
        <mdb-btn
          flat
          dark-waves
          class="c0l-12 col-sm-6 m-0 p-2"
          title="Guarda una nota privada sobre el paciente"
          @click="updateRecord('nota')"
        >
          <span class="px-2 m-0 icon-sticky-note" />
          {{ showAddNote
            ? 'Actualizar' : patientNote.length
              ? 'Editar nota' : 'Agregar nota' }}
        </mdb-btn>
      </div>
      <!-- Notas referenciales del paciente -->
      <div
        v-if="patientNote.length || showAddNote"
        class="card bord-style px-3 py-2 my-3"
      >
        <div class="row align-items-center">
          <header class="col text-muted mb-1">
            Notas referenciales sobre el paciente
          </header>
        </div>
        <Transition>
          <div v-if="showAddNote">
            <mdb-input
              v-model="patientNote"
              label="Escribe una nota personalizada"
              outline
              type="textarea"
              :rows="3"
              class="my-1"
            />
          </div>
          <div v-else>
            {{ patientNote }}
            <small class="d-block font-italic text-muted text-truncate">
              Solo visible para ti, el paciente no lo verá.
            </small>
          </div>
        </Transition>
      </div>
      <!-- Botón registro de consulta -->
      <div
        v-if="encuentrosEnProgreso"
        class="row justify-content-around mb-3"
      >
        <router-link
          v-for="encuentro in encuentrosEnProgreso"
          :key="encuentro.encounter.id"
          tag="button"
          :to="($route.params.salaEsperaId
            ? $route.params.salaEsperaId : $route.params.personId) +
            '/diagnostico/' + encuentro.id"
          class="col-auto btn btn-primary-base"
        >
          <div class="text-center">
            Seguir con la consulta del
            {{ encuentro.date.formatted | fecha_es_ve }}
            <font-awesome-icon
              :icon="['fas', 'prescription']"
              size="lg"
              class=""
            />
          </div>
        </router-link>
      </div>
      <!-- Tarjeta de consulta -->
      <div
        v-if="appointmentNext && !appointmentNext.encounter"
        class="row justify-content-around mb-3"
      >
        <div class="list-card rectangle">
          <div class="col mb-2 pl-2 pr-0">
            <p class="pl-2 pr-0">
              Motivo(s) de la cita
            </p>
            <ul
              v-for="(motivo, iMot) in appointmentNext.reasons"
              :key="iMot"
              class="angle-list"
            >
              <li>{{ motivo.name }}</li>
            </ul>
            <p class="pl-2 pr-0">
              {{ appointmentNext.date.formatted }}
            </p>
          </div>
          <mdb-btn
            class="col-auto"
            color="primary-base"
            :disabled="btnDisabled"
            @click="encounterCreate"
          >
            <div class="text-center">
              <p class="mb-1">
                Iniciar
              </p>
              <p class="mb-1">
                Consulta
              </p>
              <font-awesome-icon
                :icon="['fas', 'prescription']"
                size="lg"
                class=""
              />
            </div>
          </mdb-btn>
        </div>
      </div>
      <div
        v-else
        class="card bord-style px-3 py-2 my-3"
      >
        <header class="h5-responsive mb-0">
          Consultorios disponibles
        </header>
        <small
          v-if="lugares && lugares.length"
          class="font-weight-bold font-italic text-muted mb-2"
        >
          Selecciona un consultorio para iniciar la consulta
        </small>
        <ul
          v-if="lugares && lugares.length"
          class="list-unstyled row mb-0"
        >
          <li
            v-for="(lugar, il) in lugares"
            :key="lugar.id"
            class="our-radio col-12 col-sm-6 col-lg-4 mx-0"
          >
            <input
              :id="`place-${il}-${lugar.name}`"
              v-model="lugarSeleccionado"
              type="radio"
              :value="lugar.id"
            >
            <label
              :for="`place-${il}-${lugar.name}`"
              class="our-radio-label d-flex"
            >
              <p class="ml-2 mb-0">
                <span class="d-block font-weight-bold">{{ lugar.name }}</span>
                {{ lugar.in.name }}
                <small class="d-block">
                  {{ lugar.in.location.name }}
                </small>
              </p>
            </label>
          </li>
        </ul>
        <p
          v-else
          class="text-center text-muted font-italic"
        >
          Sin lugares disponibles
        </p>
        <div class="text-center">
          <button
            v-if="lugares && lugares.length"
            :disabled="(lugarSeleccionado === '' || btnDisabled)"
            :class="['btn', encuentrosEnProgreso
                       ? 'btn-outline-primary z-depth-0' : 'btn-primary-base',
                     'ripple-parent']"
            @click="crearCitaYEncuentro"
          >
            <font-awesome-icon
              icon="check"
              class="mr-1"
              size="lg"
            />
            Comenzar nueva consulta
          </button>
        </div>
      </div>

      <!-- Información de Médica -->
      <p class="h5-responsive text-center mb-0 mt-2">
        Información médica
      </p>
      <!--
        Descripción de alergías, condiciones
        especiales y temporales
      -->
      <div
        v-if="(allergies && allergies.length)
          || (condicionesEspeciales && condicionesEspeciales.length)
          || (condicionesTemporales && condicionesTemporales.length)
          || (paciente.record
            && paciente.record.immunizations
            && paciente.record.immunizations.length)
          || (historias && Object.keys(historias).length)"
        class="card-columns cc-1 cc-md-2 cc-lg-3"
      >
        <!-- Alergias -->
        <div
          v-if="allergies.length"
          class="card-flat"
        >
          <header class="font-weight-bold">
            Alergias
          </header>
          <ul
            v-for="(alergia, iAlergia) in allergies"
            :key="iAlergia"
            class="angle-list ml-0"
          >
            <li>
              {{ alergia.allergenic.name }}
            </li>
          </ul>
        </div>
        <!-- Condiciones especiales -->
        <div
          v-if="condicionesEspeciales.length"
          class="card-flat"
        >
          <header class="font-weight-bold">
            Condiciones especiales
          </header>
          <ul
            v-for="(condicionEspecial, iCondEspecial) in condicionesEspeciales"
            :key="iCondEspecial"
            class="angle-list ml-0"
          >
            <li>
              {{ condicionEspecial.name }}
            </li>
          </ul>
        </div>
        <!-- Condiciones temporales -->
        <div
          v-if="condicionesTemporales.length"
          class="card-flat"
        >
          <header class="font-weight-bold">
            Condiciones temporales
          </header>
          <ul
            v-for="(condicionTemporal, iCondTemporal) in condicionesTemporales"
            :key="iCondTemporal"
            class="list-unstyled"
          >
            <li>
              <font-awesome-icon
                v-if="condicionTemporal.operation === 'com'"
                :icon="['fas', 'angle-right']"
              />
              <font-awesome-icon
                v-else
                :icon="rangeDateValid(condicionTemporal.start,
                                      condicionTemporal.end)
                  ? ['fas', 'check'] : ['fas', 'angle-right']"
              />
              {{ condicionTemporal.name }}
              <small class="ml-3 d-block text-muted">
                {{ condicionTemporal.start | format_date }} -
                <span
                  v-if="condicionTemporal.operation === 'com'"
                  class="fs-inherit"
                >
                  {{ condicionTemporal.end }}
                </span>
                <span
                  v-else
                  class="fs-inherit"
                >
                  {{ condicionTemporal.end | format_date }}
                </span>
              </small>
            </li>
          </ul>
        </div>
        <!-- Antecedentes -->
        <div
          v-if="Object.keys(historias).length"
          class="card-flat"
        >
          <header
            v-if="Object.keys(historias).length"
            class="font-weight-bold"
          >
            Antecedentes
          </header>
          <div
            v-for="(hists,is) in historias"
            :key="'h'+is"
            class="card-flat"
          >
            <header class="font-weight-bold">
              {{ histClasses[is] }}
            </header>
            <ul
              v-for="(hist, ihp) in hists"
              :key="ihp"
              class="list-unstyled"
            >
              <li>
                <font-awesome-icon
                  v-if="rangeDateValid(hist.start.formatted,
                                       hist.end.formatted)"
                  :icon="['fas', 'check']"
                  class="success-text"
                />
                <font-awesome-icon
                  v-else
                  :icon="['fas', 'angle-right']"
                />
                {{ hist.item.name }}:
                <span
                  v-if="hist.value
                    && Array.isArray(hist.value)"
                >
                  <span
                    v-for="o in hist.value"
                    :key="o"
                    class="auto-coma"
                  >
                    {{ o }}
                  </span>
                </span>
                <span
                  v-else-if="hist.value != undefined
                    && typeof hist.value === 'boolean'"
                >
                  <span v-if="hist.value">Verdadero</span>
                  <span v-else>Falso</span>
                </span>
                <span v-else>
                  {{ hist.value }}
                </span>
                <p
                  v-if="hist.start.formatted || hist.end.formatted"
                  class="mb-0 ml-3 text-muted"
                >
                  <small v-if="hist.start.formatted">
                    {{ hist.start.formatted | format_date }}
                  </small>
                  <small v-if="hist.start.formatted && hist.end.formatted">
                    -
                  </small>
                  <small v-if="hist.end.formatted">
                    {{ hist.end.formatted | format_date }}
                  </small>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <!-- Vacunas -->
        <div
          v-if="paciente.record
            && paciente.record.immunizations
            && paciente.record.immunizations.length"
          class="card-flat"
        >
          <header class="font-weight-bold">
            Vacunas
          </header>
          <ul
            v-for="immunn in paciente.record.immunizations"
            :key="immunn.id"
            class="list-unstyled"
          >
            <li>
              {{ immunn.vaccine.name }}
              <mdb-badge
                pill
                :color="immunn.status == 'done' ? 'bg-success-base' :
                  immunn.status == 'assigned' ? 'bg-primary' :
                  immunn.status == 'inProgress'
                    ? 'bg-warning' : 'bg-danger-base'"
              >
                {{ immunn.status == 'done' ? 'Terminado' :
                  immunn.status == 'assigned' ? 'Asignado' :
                  immunn.status == 'inProgress'
                    ? 'En curso' : 'Perdido' }}
              </mdb-badge>
              <!-- <mdb-btn
                flat
                dark-waves
                size="sm"
                class="m-0 px-2 float-right icon-vial-circle-xmark"
                @click="lostVaccine(immunn)"
              >
                Pasar a perdido
              </mdb-btn> -->
              <small class="d-block text-muted">
                Dosis aplicadas:
                {{ immunn.vaccinations.length }}
                de
                {{ immunn.vaccine.doses.length }}
              </small>
              <small
                v-if="immunn.vaccinations[immunn.vaccinations.length-1]
                  .appliedAt
                  && immunn.vaccinations[immunn.vaccinations.length-1]
                    .appliedAt.formatted"
                class="d-block text-muted"
              >
                Última dosis:
                {{ immunn.vaccinations[immunn.vaccinations.length-1]
                  .appliedAt.formatted.split('T')[0] | format_date }}
              </small>
              <small
                v-if="immunn.vaccinations[immunn.vaccinations.length-1]
                  .fromDate"
                class="d-block font-weight-bold"
              >
                Próxima dosis:
                {{ immunn.vaccinations[immunn.vaccinations.length-1]
                  .fromDate.formatted.split('T')[0] | format_date }}
              </small>
            </li>
          </ul>
        </div>
      </div>
      <p
        v-else
        class="h6-responsive text-center text-muted userSelect-none mt-2 mb-5"
      >
        Aún no tenemos información médica registrada sobre el paciente
      </p>
      <!-- Información de Contacto -->
      <p class="h5-responsive text-center mb-0 mt-2">
        Información de contacto
      </p>
      <div class="row justify-content-between">
        <div class="col-12 col-lg-6 mt-2">
          <mdb-btn
            flat
            dark-waves
            icon="plus-circle"
            icon-class="success-text mr-1"
            title="Agregar teléfono"
            class="fs-inherit tt-inherit flex-align-center m-0 mb-2 ml-n3 p-2"
            type="button"
            @click="telAdd"
          >
            Teléfonos
          </mdb-btn>
          <div
            v-for="(tel, i) in tels"
            :key="i"
            class="row justify-content-between align-items-center"
          >
            <div
              class="col-9 col-sm"
              title="Teléfono"
            >
              <div
                :class="['row align-items-center',
                         {'disabled-form-inputs': !tel.editContactInfo}]"
              >
                <div
                  class="col-auto our-radio2"
                  title="Marcar como número primario"
                >
                  <label>
                    <input
                      v-model="tel.primary"
                      type="radio"
                      name="primaryRadio"
                      value="primario"
                    >
                    <span />
                  </label>
                </div>
                <div
                  class="col"
                >
                  <CustomDatalist
                    :id="i+'tel'"
                    ref="customDatalist"
                    v-model="tel.country"
                    :identificador="i"
                    :list="telCodes"
                    :type="'number'"
                    class-name="my-2"
                    label="País"
                    :outline="true"
                    @save="tel.country=$event.code;"
                  />
                </div>
                <div class="col px-0">
                  <mdb-input
                    ref="telRef"
                    v-model="tel.number"
                    type="number"
                    label="Teléfono"
                    class="my-1"
                    outline
                  />
                </div>
              </div>
            </div>
            <div class="col-3 col-sm-auto">
              <mdb-btn
                v-if="!tel.editContactInfo"
                flat
                dark-waves
                fab
                icon="whatsapp"
                class="success-text m-1 px-2 py-3"
                tag="a"
                target="_blank"
                :href="`https://wa.me/${tel.country?tel.country:''}${tel.number}`"
              />
              <mdb-btn
                v-if="!tel.editContactInfo"
                :disabled="btnDisabled"
                class="m-1 px-2 py-3"
                flat
                dark-waves
                icon="pen"
                title="Editar teléfono"
                type="button"
                @click="editTelephone(tel); telephoneTemporal(tel)"
              />
              <mdb-btn
                v-if="!tel.editContactInfo"
                :disabled="btnDisabled"
                class="m-1 px-2 py-3"
                flat
                dark-waves
                icon="minus-circle"
                title="Eliminar teléfono"
                type="button"
                @click="showConfirmation();name=tel.number;objectToDelete(tel)"
              />
              <mdb-btn
                v-if="tel.editContactInfo"
                :disabled="btnDisabled"
                class="m-1 px-2 py-3"
                color="success-base"
                icon="sync"
                title="Actualizar teléfono"
                type="button"
                @click="telephoneUpdate(tel)"
              />
              <mdb-btn
                v-if="tel.editContactInfo"
                :disabled="btnDisabled"
                class="m-1 px-2 py-3"
                flat
                dark-waves
                icon="times"
                title="Cancelar edición"
                type="button"
                @click="editTelephone(tel); cancelTelephoneUpdate(tel)"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 mt-2">
          <mdb-btn
            flat
            dark-waves
            icon="plus-circle"
            icon-class="success-text mr-1"
            title="Agregar correo"
            class="fs-inherit tt-inherit flex-align-center m-0 mb-2 ml-n3 p-2"
            type="button"
            @click="emailAdd"
          >
            Correos electrónicos
          </mdb-btn>
          <div
            v-for="(email, i) in emails"
            :key="i"
            class="row justify-content-between align-items-center"
          >
            <div
              class="col-9 col-sm"
              title="Correo electrónico"
            >
              <div
                :class="['row align-items-center',
                         {'disabled-form-inputs': !email.editEmailInfo}]"
              >
                <div
                  class="col-auto our-radio2"
                  title="Marcar como correo electrónico primario"
                >
                  <label>
                    <input
                      v-model="email.primary"
                      type="radio"
                      name="primaryEmailRadio"
                      :value="true"
                    >
                    <span />
                  </label>
                </div>
                <div class="col px-0">
                  <mdb-input
                    ref="emailRef"
                    v-model="email.email"
                    type="email"
                    label="Correo"
                    class="my-1"
                    outline
                  />
                </div>
              </div>
            </div>
            <div class="col-3 col-sm-auto">
              <mdb-btn
                v-if="!email.editEmailInfo"
                :disabled="btnDisabled"
                class="m-1 px-2 py-3"
                flat
                dark-waves
                icon="pen"
                title="Editar correo"
                type="button"
                @click="editEmail(email); emailTemporal(email)"
              />
              <mdb-btn
                v-if="!email.editEmailInfo"
                :disabled="btnDisabled"
                class="m-1 px-2 py-3"
                flat
                dark-waves
                icon="minus-circle"
                title="Eliminar correo"
                type="button"
                @click="showConfirmation();name=email.email;
                        objectToDelete(email)"
              />
              <mdb-btn
                v-if="email.editEmailInfo"
                :disabled="btnDisabled"
                class="m-1 px-2 py-3"
                color="success-base"
                icon="sync"
                title="Actualizar correo"
                type="button"
                @click="emailUpdate(email)"
              />
              <mdb-btn
                v-if="email.editEmailInfo"
                :disabled="btnDisabled"
                class="m-1 px-2 py-3"
                flat
                dark-waves
                icon="times"
                title="Cancelar edición"
                type="button"
                @click="editEmail(email); cancelEmailUpdate(email)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Tarjeta del árbol familiar -->
      <div class="card bord-style px-3 py-2 my-3">
        <header class="h5-responsive mb-2">
          Árbol familiar
        </header>
        <p
          v-if="!represented.length && !represents.length"
          class="text-muted text-center"
        >
          Sin relación familiar registrada
        </p>
        <div
          v-if="represented.length"
          class="row"
        >
          <header class="col-12 my-1 font-weight-bold">
            Representado por:
          </header>
          <div
            v-for="(rep, i) in represented"
            :key="i+'c'+rep.fname"
            class="col-12 col-md-6 col-lg-4 my-2"
            :title="kinshipTypes[representedRel[i].kinship]"
          >
            <p class="mb-0 flex-align-center">
              <mdb-badge
                class="z-depth-0 fs-07 flex-align-center mr-2"
                color="secondary"
                title="Parentesco"
                pill
              >
                <font-awesome-icon
                  icon="users"
                  class="mr-1"
                />
                {{ kinshipTypes[representedRel[i].kinship] }}
              </mdb-badge>
              {{ rep.fname }} {{ rep.mname }}
              {{ rep.lname }} {{ rep.llname }}
            </p>
            <p class="my-1">
              <small class="d-block mb-n1 text-muted text-uppercase">
                <font-awesome-icon
                  icon="phone"
                  class="mr-1"
                />
                Teléfono(s)
              </small>
              <span
                v-for="(t, index) in rep.tels"
                :key="t.number"
                class="fs-09"
              >
                {{ index > 0 ? '/' : '' }}
                {{ t.number }}
              </span>
            </p>
            <p class="my-1">
              <small class="d-block mb-n1 text-muted text-uppercase">
                <font-awesome-icon
                  icon="envelope"
                  class="mr-1"
                />
                Correo(s)
              </small>
              <span
                v-for="e in rep.emails"
                :key="e.email"
                class="d-block fs-09"
              >
                {{ e.email }}
              </span>
            </p>
          </div>
        </div>

        <div
          v-if="represents.length"
          class="row"
        >
          <header class="col-12 my-1 font-weight-bold">
            Representante de:
          </header>
          <div
            v-for="(rep, i) in represents"
            :key="i+'c'+rep.fname"
            class="col-12 col-md-6 col-lg-4 my-2"
            :title="kinshipTypes[representsRel[i].kinship]"
          >
            <p class="mb-0 flex-align-center">
              <mdb-badge
                class="z-depth-0 fs-07 flex-align-center mr-2"
                color="default"
                title="Parentesco"
                pill
              >
                <font-awesome-icon
                  icon="users"
                  class="mr-1"
                />
                {{ kinshipTypes[representsRel[i].kinship] }}
              </mdb-badge>
              {{ rep.fname }} {{ rep.mname }}
              {{ rep.lname }} {{ rep.llname }}
            </p>
            <p class="my-1">
              <small class="d-block mb-n1 text-muted text-uppercase">
                <font-awesome-icon
                  icon="phone"
                  class="mr-1"
                />
                Teléfono(s)
              </small>
              <span
                v-for="(t, index) in rep.tels"
                :key="t.number"
                class="fs-09"
              >
                {{ index > 0 ? '/' : '' }}
                {{ t.number }}
              </span>
            </p>
            <p class="my-1">
              <small class="d-block mb-n1 text-muted text-uppercase">
                <font-awesome-icon
                  icon="envelope"
                  class="mr-1"
                />
                Correo(s)
              </small>
              <span
                v-for="e in rep.emails"
                :key="e.email"
                class="d-block fs-09"
              >
                {{ e.email }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <!-- Tarjeta de las últimas consultas Mismo médico -->
      <div class="card bord-style px-3 py-2 my-3">
        <header class="h5-responsive mb-2">
          Últimas consultas
        </header>
        <div
          v-if="paciente.citas && paciente.citas.length"
        >
          <ul class="list-unstyled">
            <li
              v-for="cita in paciente.citas"
              :key="cita.id"
              class="row justify-content-between align-items-center my-2"
            >
              <div class="col-12 col-md">
                <span class="w-sm-100px d-sm-block float-sm-left">
                  {{ cita.date.formatted | fecha_es_ve }}
                </span>
                <p class="mb-0">
                  <span
                    v-if="cita.encounter
                      && cita.encounter.symptoms
                      && cita.encounter.symptoms.length"
                  >
                    {{ listEncounterDetails(cita.encounter.symptoms) }}
                  </span>
                  <span v-else-if="cita.reasons.length">
                    {{ listEncounterDetails(cita.reasons) }}
                  </span>
                  <span
                    v-else
                    class="text-muted"
                  >
                    Sin motivos o síntomas
                  </span>
                  <small
                    v-if="cita.encounter
                      && cita.encounter.diagnosis
                      && cita.encounter.diagnosis.length"
                    class="d-block font-italic text-muted text-truncate"
                  >
                    Diagnóstico:
                    {{ listEncounterDetails(cita.encounter.diagnosis) }}.
                  </small>
                </p>
              </div>
              <router-link
                v-if="cita.encounter"
                :to="{name: 'PacienteDiagnosticoDetalles',
                      params: {encounterId: cita.encounter.id}}"
                tag="div"
                class="col-12 col-md-auto order-md-2"
              >
                <mdb-btn
                  block
                  color="primary-base"
                  class="mt-2 mb-3 my-md-0 px-md-4"
                  icon="plus"
                  icon-class="fa-lg"
                  icon-right
                  title="Ver consulta completa"
                  size="sm"
                >
                  Ver
                </mdb-btn>
              </router-link>
            </li>
          </ul>
          <mdb-btn
            class="col-auto mx-2 order-md-2 flat-bord-style"
            flat
            dark-waves
            icon="list"
            icon-class="fa-lg"
            title="Ver más parámetros"
            size="sm"
            @click="verTodasCitas"
          >
            Ver todas
          </mdb-btn>
        </div>
        <div
          v-else
          class="text-muted text-center"
        >
          No existen historias clínicas de este paciente
        </div>
      </div>
      <!-- Tarjeta de parámetros frecuentes -->
      <div class="card bord-style px-3 py-2 my-3">
        <header class="h5-responsive mb-2">
          Gráficos de parámetros frecuentes
        </header>
        <div
          v-if="paramsToChart && paramsToChart.length"
        >
          Selecciona un parámetro numérico para ver su historial en el tiempo
          <div class="md-form md-outline outline-select my-1">
            <select
              id="newTag-select"
              v-model="paramSelected"
              class="custom-select"
            >
              <option
                class="d-none"
                disabled
                :value="{}"
              >
                Seleccione
              </option>
              <option
                v-for="param in paramsToChart"
                :key="param.id"
                :value="param"
              >
                {{ param.name }}
              </option>
            </select>
            <label
              for="newTag-select"
              class="label-active"
            >
              Parámetros
            </label>
          </div>
          <Chart
            v-if="paramSelected.id"
            :options="chartOpt"
          />
        </div>
        <div
          v-else-if="paciente.citas && !paciente.citas.length"
          class="text-muted text-center"
        >
          Registra un historial de consulta primero
        </div>
        <div
          v-else
          class="text-muted text-center"
        >
          Este paciente no tiene parámetros numéricos asociados.
          Guarda el valor de los parámetros durante la consulta.
          Gestiona los parámetros disponibles en
          <span class="d-sm-block d-xl-inline">
            <router-link
              :to="{ name: 'PruebasClinicas' }"
              tag="span"
              class="fs-inherit cursor-pointer d-inline-flex
                justify-content-center align-items-center
                link-icon-hover text-nowrap"
            >
              Configuración /
              <font-awesome-icon
                :icon="['fas', 'stethoscope']"
                size="sm"
                class="mx-1"
              />
              Parámetros
              <font-awesome-icon
                :icon="['fas', 'angle-right']"
                class="my-2 fa-md icon"
              />
            </router-link>
          </span>
        </div>
      </div>
      <!-- Tarjeta de las últimas 3 consultas Otros médico -->
      <div class="card bord-style px-3 py-2 my-3  d-none">
        <header class="h5-responsive mb-2">
          Últimas 3 consultas con otros médicos
        </header>
        <ul class="list-unstyled d-none">
          <li class="row justify-content-between align-items-center my-2">
            <div class="col-12 mb-1 mb-md-n2">
              <mdb-badge
                class="z-depth-0"
                color="default"
                pill
              >
                Especialidad
              </mdb-badge>
            </div>
            <ul class="col-12 col-md list-unstyled mb-1 order-md-1">
              <li class="auto-coma">
                1 Motivo
              </li>
              <li class="auto-coma">
                2 Motivo
              </li>
            </ul>
            <p class="col-auto mb-1">
              10/02/2019
            </p>
            <mdb-btn
              color="primary-base"
              class="col-auto mr-3 order-md-2"
              dark-waves
              icon="plus"
              icon-class="fa-lg"
              icon-right
              title="Ver más parámetros"
              size="sm"
            >
              Ver
            </mdb-btn>
          </li>
          <li class="row justify-content-between align-items-center my-2">
            <div class="col-12 mb-1 mb-md-n2">
              <mdb-badge
                class="z-depth-0"
                color="default"
                pill
              >
                Especialidad
              </mdb-badge>
            </div>
            <ul class="col-12 col-md list-unstyled mb-1 order-md-1">
              <li class="auto-coma">
                1 Motivo
              </li>
              <li class="auto-coma">
                2 Motivo
              </li>
            </ul>
            <p class="col-auto mb-1">
              10/02/2019
            </p>
            <mdb-btn
              color="primary-base"
              class="col-auto mr-3 order-md-2"
              dark-waves
              icon="plus"
              icon-class="fa-lg"
              icon-right
              title="Ver más parámetros"
              size="sm"
            >
              Ver
            </mdb-btn>
          </li>
          <li class="row justify-content-between align-items-center my-2">
            <div class="col-12 mb-1 mb-md-n2">
              <mdb-badge
                class="z-depth-0"
                color="default"
                pill
              >
                Especialidad
              </mdb-badge>
            </div>
            <ul class="col-12 col-md list-unstyled mb-1 order-md-1">
              <li class="auto-coma">
                1 Motivo
              </li>
              <li class="auto-coma">
                2 Motivo
              </li>
            </ul>
            <p class="col-auto mb-1">
              10/02/2019
            </p>
            <mdb-btn
              color="primary-base"
              class="col-auto mr-3 order-md-2"
              dark-waves
              icon="plus"
              icon-class="fa-lg"
              icon-right
              title="Ver más parámetros"
              size="sm"
            >
              Ver
            </mdb-btn>
          </li>
        </ul>
        <mdb-btn
          class="col-auto mr-3 order-md-2 d-none"
          flat
          dark-waves
          icon="list"
          icon-class="fa-lg"
          title="Ver más parámetros"
          size="sm"
        >
          Ver todas
        </mdb-btn>
        <p class="h1-responsive text-center mb-0">
          Próximamente disponible
        </p>
      </div>
      <!-- Mensajes con el paciente -->
      <div class="card bord-style px-3 py-2 my-3">
        <header class="h5-responsive mb-2">
          Mensajes con el paciente
        </header>
        <!-- <ul class="list-unstyled d-none">
          <li class="row justify-content-start">
            <div class="col-auto msg-doctor">
              Hola Dr
              <small class="d-block">10/02/2019</small>
            </div>
          </li>
          <li class="row justify-content-end">
            <div class="col-auto msg-paciente">
              Hola paciente
              <small class="d-block text-right">10/02/2019</small>
            </div>
          </li>
        </ul> -->
        <p class="h1-responsive text-center mb-0">
          Próximamente disponible
        </p>
      </div>
    </div>
    <!-- Modal agregar nota personal -->
    <!-- <mdb-modal
      centered
      elegant
      scrollable
      :show="showAddNote"
    >
      <mdb-modal-header :close="false">
        <div class="row justify-content-between">
          <div class="col">
            <mdb-modal-title>
              ¿Cómo te sientes hoy?
            </mdb-modal-title>
          </div>
          <div class="col-auto px-0">
            <mdb-btn
              flat
              dark-waves
              class="px-2 m-0"
              icon-class="success-text"
              icon="plus-circle"
              title="Agregar motivo"
              size="sm"
              @click="addNewItem = true"
            />
          </div>
        </div>
        <a
          ref="mdbModalCerrarModal"
          class="close"
          @click="showAddNote = false"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <ul class="list-unstyled">
          <li class="row justify-content-between align-items-center">
            <div class="col">
              <mdb-input
                placeholder="Describe lo que sientes"
                class="my-1"
                outline
                value="Fiebre"
              />
            </div>
            <div class="col-auto">
              <mdb-btn
                class="px-2"
                dark-waves
                flat
                icon="trash"
                title="Eliminar"
                size="sm"
              />
            </div>
          </li>
          <li
            v-if="addNewItem"
            class="row justify-content-between align-items-center"
          >
            <div class="col">
              <mdb-input
                placeholder="Describe lo que sientes"
                class="my-1"
                outline
              />
            </div>
            <div class="col-auto">
              <mdb-btn
                class="px-2"
                dark-waves
                flat
                icon="trash"
                title="Eliminar"
                size="sm"
                @click="addNewItem = false"
              />
            </div>
          </li>
        </ul>
        <div class="modal-footer-btn">
          <mdb-btn
            flat
            dark-waves
            icon="xmark"
            @click="showAddNote = false"
          >
            Cerrar
          </mdb-btn>
          <mdb-btn
            type="submit"
            color="success-base"
          >
            <font-awesome-icon
              :icon="['fas', 'check']"
              size="lg"
            />
            Agregar
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal> -->
    <!-- Modal Editar información personal -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="showEditPersonalInfo"
    >
      <mdb-modal-header :close="false">
        <mdb-modal-title>
          Editar información personal
        </mdb-modal-title>
        <a
          ref="mdbModalCerrarModal"
          class="close"
          title="Cancelar edición"
          @click="cancelPersonUpdate"
        >×</a>
      </mdb-modal-header>
      <mdb-modal-body>
        <form class="row justify-content-center mt-sm-2">
          <div class="col-11 col-sm-6">
            <mdb-input
              v-model="pacienteTemp.fname"
              label="Primer nombre"
              class="my-2"
              outline
            />
          </div>
          <div class="col-11 col-sm-6">
            <mdb-input
              v-model="pacienteTemp.mname"
              label="Segundo nombre"
              class="my-2"
              outline
            />
          </div>
          <div class="col-11 col-sm-6">
            <mdb-input
              v-model="pacienteTemp.lname"
              label="Primer apellido"
              class="my-2"
              outline
            />
          </div>
          <div class="col-11 col-sm-6">
            <mdb-input
              v-model="pacienteTemp.llname"
              label="Segundo apellido"
              class="my-2"
              outline
            />
          </div>
          <div class="col-11 col-sm-6">
            <SelectIdentificationType
              v-model="pacienteTemp.identificationType"
              class-name="md-outline outline-select my-1"
              @save="identificationType=$event"
            />
          </div>
          <div class="col-11 col-sm-6">
            <mdb-input
              v-model="pacienteTemp.identification"
              label="Identificación"
              class="my-2"
              outline
            />
          </div>
          <div class="col-11 col-sm-12">
            <mdb-input
              v-model="pacienteTemp.born.formatted"
              type="date"
              class="my-2 outline-date"
              label="F de Nacimiento"
              outline
            />
          </div>
          <!-- v-if="pacienteTemp.childNumber" -->
          <div
            class="col-11 col-sm-12"
          >
            <mdb-input
              v-model.number="pacienteTemp.childNumber"
              type="number"
              class="my-2"
              label="Número de hijo"
              outline
            />
          </div>
          <div class="col-11 col-sm-6">
            <SelectGender
              v-model="pacienteTemp.gender"
              class-name="md-outline outline-select my-2"
              name-select="doctor"
            />
          </div>
          <div class="col-11 col-sm-6">
            <SelectBloodType
              v-model="pacienteTemp.bloodType"
              class-name="md-outline outline-select my-2"
              name-select="doctor"
            />
          </div>
          <header class="col-12 mt-2 font-weight-bold">
            Dirección
          </header>
          <div class="col-12 col-sm-6">
            <div class="md-form md-outline outline-select my-2">
              <select
                id="pais"
                ref="selectPais"
                v-model="countryId"
                class="custom-select"
                @change="cleanSelectsLugar"
              >
                <option
                  class="d-none"
                  disabled
                  value=""
                >
                  Seleccione
                </option>
                <option
                  v-for="country in countries"
                  :key="country.id"
                  :value="country.id"
                >
                  {{ country.name }}
                </option>
              </select>
              <label
                for="pais"
                :class="countryId.length ? 'label-active' : 'label-inactive'"
              >
                País
              </label>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="md-form md-outline outline-select my-2">
              <select
                id="estado"
                v-model="stateId"
                :class="['custom-select', {'no-background':
                  $apolloData.queries.states.loading}]"
                :disabled="$apolloData.queries.states.loading"
                @change="fillShowOtherState"
              >
                <option
                  class="d-none"
                  disabled
                  value=""
                >
                  Seleccione
                </option>
                <option
                  v-for="state in states"
                  :key="state.id"
                  :value="state.id"
                >
                  {{ state.name }}
                </option>
                <option
                  disabled="true"
                  value="otro en pais"
                >
                  Otro en el país
                </option>
              </select>
              <label
                for="estado"
                :class="stateId.length ? 'label-active' : 'label-inactive'"
              >
                Estado
              </label>
              <span
                v-if="$apolloData.queries.states.loading"
                class="form-loader"
              >
                <font-awesome-icon
                  :icon="['fas', 'circle-notch']"
                  class="fa-spin"
                />
              </span>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="md-form md-outline outline-select my-2">
              <select
                id="ciudad"
                v-model="address.cityId"
                :class="['custom-select', {'no-background':
                  $apolloData.queries.cities.loading}]"
                :disabled="$apolloData.queries.cities.loading"
                @change="fillShowOtherCity"
              >
                <option
                  class="d-none"
                  disabled
                  value=""
                >
                  Seleccione
                </option>
                <option
                  v-for="city in cities"
                  :key="city.id"
                  :value="city.id"
                >
                  {{ city.name }}
                </option>
                <option
                  disabled="true"
                  value="otro en estado"
                >
                  Otra en el estado
                </option>
              </select>
              <label
                for="ciudad"
                :class="address
                  && address.cityId
                  && address.cityId.length
                  ? 'label-active' : 'label-inactive'"
              >
                Ciudad
              </label>
              <span
                v-if="$apolloData.queries.cities.loading"
                class="form-loader"
              >
                <font-awesome-icon
                  :icon="['fas', 'circle-notch']"
                  class="fa-spin"
                />
              </span>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <mdb-input
              v-model="address.line"
              class="my-1"
              label="Edificio/Casa"
              outline
            />
          </div>
          <div class="col-12 col-sm-8">
            <mdb-input
              v-model="address.street"
              class="my-1"
              label="Calle/Avenida"
              outline
            />
          </div>
          <div class="col-12 col-sm-4">
            <mdb-input
              v-model="address.zip"
              class="my-1"
              label="Código postal"
              outline
            />
          </div>
        </form>
        <div class="modal-footer-btn">
          <mdb-btn
            flat
            dark-waves
            icon="times"
            class="flat-bord-style"
            @click="cancelPersonUpdate"
          >
            Cancelar
          </mdb-btn>
          <BtnLoader
            :btn-disabled="btnDisabled"
            btn-icon="sync"
            btn-title="Actualizar"
            @click="personUpdate"
          />
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view
        :paciente="paciente"
        @encounterEnds="encounterEnds"
        @encounterRemove="encounterRemove"
      />
    </transition>
    <ModalConfirmacionEliminar
      :show-confirmation="showConfirmacionEliminar"
      :name="name"
      :btn-disabled="btnDisabled"
      @close="showConfirmacionEliminar = $event"
      @confirm-delete="confirmDelete"
    />
    <AlertaMsj :alerta-msj="alertaMsj" />
  </div>
</template>
<script>
import {mdbBtn, mdbInput, mdbBadge, mdbModal, mdbModalHeader,
  mdbModalTitle, mdbModalBody} from 'mdbvue';
import gql from 'graphql-tag';
import md5 from 'md5';
import {v4 as uuidv4} from 'uuid';
import AlertaMsj from '@/components/AlertaMsj.vue';
import BtnLoader from '@/components/customButtons/BtnLoader.vue';
import CustomDatalist from '@/components/customFields/CustomDatalist.vue';
import ModalConfirmacionEliminar
  from '@/components/ModalConfirmacionEliminar.vue';
import {histClasses} from '@/components/Antecedentes.vue';
import Chart from '@/components/charts/ChartView.vue';
import {calculateAge, todayDate, emailSchema, validateIdentification,
  capitalizeAll, capitalize, listEncounterDetails, encounterLimitAlert}
  from '@/utils/validations.js';
import {getPersonId} from '@/utils/authdecode.js';
import {calcConditionTemporal}
  from '@/constantes/conditionTemplates.js';
import countriesGql from '@/graphql/countries.gql';
import citiesGql from '@/graphql/cities.gql';
import statesGql from '@/graphql/states.gql';
import patientGql from '@/graphql/patient.gql';
import patientUpdateGql from '@/graphql/patientUpdate.gql';
import doctorWorkPlacesSEGql from '@/graphql/doctorWorkPlacesSE.gql';
import recordUpdateGql from '@/graphql/recordUpdate.gql';
// Telephones
import telephoneCreateGql from '@/graphql/telephoneCreate.gql';
import telephoneUpdateGql from '@/graphql/telephoneUpdate.gql';
import telephoneDeleteGql from '@/graphql/telephoneDelete.gql';
// Emails
import emailCreateGql from '@/graphql/emailCreate.gql';
import emailUpdateGql from '@/graphql/emailUpdate.gql';
import emailDeleteGql from '@/graphql/emailDelete.gql';
// Appointment
import appointmentCreateGql from '@/graphql/appointmentCreate.gql';
// Encounter
import encounterCreateGql from '@/graphql/encounterCreate.gql';
// Para gráficos
import paramsWValuesGql from '@/graphql/paramsWValues.gql';
import paramObservationsGql from '@/graphql/paramObservations.gql';
import {SelectIdentificationType, identificationTypes}
  from '@/components/SelectIdentificationType.vue';
import SelectGender
  from '@/components/customFields/SelectGender.vue';
import {genderIcons, genderIconColors}
  from '@/components/customFields/SelectGender.vue';
import {SelectBloodType, bloodTypes}
  from '@/components/customFields/SelectBloodType.vue';
import {kinshipTypes}
  from '@/components/customFields/SelectKinship.vue';
import {telCodes} from '@/constantes/telephoneCodes.js';
import {tagsDefault} from '@/constantes/tagsDefault.js';

export default {
  name: 'Paciente',
  components: {
    AlertaMsj,
    BtnLoader,
    CustomDatalist,
    Chart,
    mdbBtn,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbBadge,
    ModalConfirmacionEliminar,
    SelectIdentificationType,
    SelectGender,
    SelectBloodType,
  },
  data() {
    return {
      tagsDefault,
      telCodes,
      calcConditionTemporal,
      atrasRoute: this.$route.params.salaEsperaId
        ? `/salas-de-espera/${this.$route.params.salaEsperaId}`
        : '/pacientes/',
      alertaMsj: {
        content: '',
      },
      showAddNote: false,
      editContactFrom: false,
      tagsAvailables: [],
      tag: '',
      newTag: false,
      otherTag: '',
      showEditPersonalInfo: false,
      showConfirmacionEliminar: false,
      encounterStart: '',
      encounterEnd: '',
      paciente: {
        persona: {
          allergies: [],
          born: {},
        },
        citas: [],
        address: {
          line: '',
          street: '',
          zip: null,
          cityId: '',
        },
      },
      patientNote: '',
      // Dirección
      address: {
        line: '',
        street: '',
        zip: null,
        cityId: '',
      },
      countryId: '',
      stateId: '',
      // Componente NuevasUbicaciones
      showOtherState: {},
      showOtherCity: {},
      pacienteTemp: {born: {formatted: ''}},
      emailTemp: {},
      telephoneTemp: {},
      objectDelete: {},
      name: '',
      identificationTypes, // Para mostrar el tipo de identificación
      genderIcons, genderIconColors, // Para mostrar icon y color de gender
      bloodTypes, // Para mostrar el tipo de sangre
      kinshipTypes, // Para mostrar la relación
      encounterId: '',
      tels: [],
      allergies: [],
      emails: [],
      represented: [],
      represents: [],
      representedRel: [],
      representsRel: [],
      condicionesEspeciales: [],
      condicionesTemporales: [],
      historias: {},
      btnDisabled: false,
      emailToGravatar: '',
      histClasses,
      listEncounterDetails,
      lugares: [],
      lugarSeleccionado: '',
      // Variables del gráfico
      chartOpt: {},
      paramSelected: {},
      paramObservations: [],
      paramsToChart: [],
    };
  },
  computed: {
    appointmentNext() {
      // Devuelve citas que no tengan encuentro con un máximo de 10 días
      // atrás (1296000000) y que su status sea diferente a Fulfilled
      // con esto evitamos mostrar las citas con encuentros borrados
      const proximasCitas = [];

      this.paciente.citas.forEach((c) => {
        let hoy = new Date((todayDate()+'T00:00'));
        hoy = ( hoy.getTime() + hoy.getTimezoneOffset()*60000 );
        let cita = new Date((((c.date.formatted.split('T')[0]))+'T00:00'));
        cita =( cita.getTime() + cita.getTimezoneOffset()*60000 );
        if (cita >= hoy - 1296000000 && !c.encounter
          && c.status !='Fulfilled') {
          proximasCitas.push(c);
        }
      });

      return proximasCitas.length
        ? proximasCitas[proximasCitas.length - 1]
        : null;
    },
    encuentrosEnProgreso() {
      const encuentrosEnProgreso = [];

      this.paciente.citas.forEach((c) => {
        if (c.encounter && c.encounter.status == 'InProgress') {
          encuentrosEnProgreso.push(c);
        }
      });

      return encuentrosEnProgreso.length
        ? encuentrosEnProgreso
        : null;
    },
    hidePatientOverflowOnEncounterView() {
      const ruta = this.$route.path.slice(1).split('/');
      return ruta[2] == 'diagnostico' || ruta[2] == 'diagnostico-detalles'
        || ruta[3] == 'diagnostico' ? true : false;
    },
  },
  watch: {
    paciente() {
      if (this.paciente.address && this.paciente.address.line) {
        if (!this.paciente.address.cityId) {
          this.paciente.address.cityId = '';
        }
        this.address = this.paciente.address;
      }
    },
    lugares() {
      if (this.lugares && this.lugares.length === 1) {
        this.lugarSeleccionado = this.lugares[0].id;
      }
    },

    // Datos para gráficas
    paramObservations(obs) {
      this.chartOpt = {
        type: 'line',
        data: {
          labels: this.paramObservations.map((o) => o.createdAt.formatted),
          datasets: [{
            label: `${this.paramSelected.name} ${this.paramSelected.symbol
              ? '['+this.paramSelected.symbol+']' : ''}`,
            data: this.paramObservations.map((o) => o.value),
            borderWidth: 3,
            borderColor: 'blue',
          }],
        },
        options: {
          scales: {
            y: {
              // beginAtZero: true,
            },
          },
        },
      };
    },
  },
  methods: {
    encounterCreate() {
      const patientId = this.paciente.persona.id;
      const appointmentId = this.appointmentNext.id;
      const variablesApollo = {
        encounter: {
          date: new Date().toISOString(),
          status: 'InProgress',
        },
        appointmentDT: this.appointmentNext.date.formatted,
        identification: this.paciente.persona.identification,
        identificationType: this.paciente.persona.identificationType,
      };
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${encounterCreateGql}`,
        variables: variablesApollo,
        context: {serializationKey: 'PERSONS'},
        optimisticResponse: {
          __typename: 'Mutation',
          encounterCreate: {
            __typename: 'Encounter',
            id: uuidv4(),
          },
        },
      }).then((res) => {
        this.encounterId = res.data.encounterCreate.id;
        this.appointmentNext.encounter = {
          id: this.encounterId,
          status: 'InProgress',
        };
        this.btnDisabled = false;
        if (res.data.encounterCreate.insolvencyStatus) {
          this.alertaMsj = {
            content:
              encounterLimitAlert(res.data.encounterCreate.insolvencyStatus),
            type: 'warning',
          };
        }
        if (this.encounterId && this.$route.name === 'Paciente'
          && this.$route.params.personId === patientId
        ) {
          this.$router.push(this.$route.params.salaEsperaId
            + '/diagnostico/' + appointmentId);
        }
      }).catch((error) => {
        this.btnDisabled = false;
        this.alertaMsj = {
          content: 'Error al crear el encuentro',
          type: 'danger',
        };
      });
    },
    encounterEnds(appointmentId) {
      this.paciente.citas.some((c, i) => {
        if (c && c.id == appointmentId) {
          if (!this.paciente.citas[i].encounter) {
            this.paciente.citas[i].encounter = {};
          }
          this.paciente.citas[i].encounter.status = 'Finished';
        }
        return (c.id == appointmentId);
      });
    },
    encounterRemove(encounterId) {
      this.paciente.citas.some((c, i) => {
        if (c.encounter && c.encounter.id == encounterId) {
          this.paciente.citas[i].encounter = null;
        }
        return (c.encounter && c.encounter.id == encounterId);
      });
    },
    rangeDateValid(condStart, condEnd) {
      const start = new Date(condStart);
      const end = new Date(condEnd);
      const today = new Date(todayDate());
      if (start <= today && end >= today) {
        return true;
      } else {
        return false;
      }
    },
    personaTemporal() {
      Object.assign(this.pacienteTemp, {...JSON.parse(
          JSON.stringify(this.paciente.persona))});
      Object.assign(this.address, {...JSON.parse(
          JSON.stringify(this.paciente.address))});
      delete this.address.__typename;
      delete this.address.city;
      this.showEditPersonalInfo = true;
    },
    cancelPersonUpdate() {
      this.showEditPersonalInfo = false;
      this.pacienteTemp = {born: {formatted: ''}};
      // Dirección
      this.countryId = '';
      this.stateId = '';
      this.cityId = '';
      this.address = {
        line: '',
        street: '',
        zip: '',
      };
      // Componente NuevasUbicaciones
      this.showOtherState = {};
      this.showOtherCity = {};
    },
    focus(refName, i) {
      const self = this;
      setTimeout(() => {
        self.$refs[refName][i].focus();
      }, 100);
    },
    verTodasCitas() {
      this.btnDisabled = true;
      this.$apollo.query({
        query: gql`${patientGql}`,
        variables: {
          id: this.$route.params.personId,
          first: 100,
        },
      }).then((res) => {
        this.paciente.citas = res.data.AppointmentWithMeBy;
        this.btnDisabled = false;
      }).catch((error) => {
        this.alertaMsj = {
          content: 'Hubo un error al buscar las citas',
          type: 'danger',
        };
        this.btnDisabled = false;
      });
    },
    // Methods Add New Input
    telAdd() {
      this.tels.push({
        number: '',
        country: '',
      });
      this.tels[this.tels.length - 1].editContactInfo = true;
      this.focus('telRef', this.tels.length - 1);
    },
    emailAdd() {
      this.emails.push({
        email: '',
      });
      this.emails[this.emails.length - 1].editEmailInfo = true;
      this.focus('emailRef', this.emails.length - 1);
    },
    // Methods Create
    telephoneCreate(tel) {
      if (!tel.number) {
        return this.alertaMsj = {
          content: 'Debes colocar un número de teléfono para agregarlo',
          type: 'warning',
        };
      }
      if (!tel.country) {
        return this.alertaMsj = {
          content: 'Debes colocar un código de país para agregarlo',
          type: 'warning',
        };
      }
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${telephoneCreateGql}`,
        variables: {
          newTel: {
            number: tel.number,
            country: tel.country,
          },
          telOf: 'Patient',
          ownerId: this.paciente.persona.id,
        },
      }).then((res) => {
        tel.__typename = 'Telephone';
        this.alertaMsj = {
          content: 'Agregado nuevo número de teléfono',
          type: 'success',
        };
        tel.editContactInfo='';
        this.btnDisabled = false;
      }).catch((error) => {
        this.alertaMsj = {
          content: 'Hubo un error al enviar el número de teléfono',
          type: 'danger',
        };
        this.btnDisabled = false;
      });
    },
    emailCreate(email) {
      if (!email.email) {
        return this.alertaMsj = {
          content: 'Debes colocar un correo para agregarlo',
          type: 'warning',
        };
      }
      delete email.editEmailInfo;
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${emailCreateGql}`,
        variables: {
          email: email,
          telOf: 'Patient',
          ownerId: this.paciente.persona.id,
        },
      }).then((res) => {
        email.__typename = 'Email';
        this.alertaMsj = {
          content: 'Agregado nuevo correo',
          type: 'success',
        };
        email.editEmailInfo='';
        this.btnDisabled = false;
      }).catch((error) => {
        this.alertaMsj = {
          content: 'Hubo un error al enviar el correo',
          type: 'danger',
        };
        this.btnDisabled = false;
      });
    },
    // Methods Delete
    telephoneDelete(tel) {
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${telephoneDeleteGql}`,
        variables: {
          tel: {
            number: tel.number,
            country: tel.country,
          },
          ownerId: this.paciente.persona.id,
        },
      }).then((res) => {
        this.showConfirmacionEliminar = false;
        const msg = res.data.telephoneDelete.msg;
        switch (msg) {
          case 'success':
            this.tels.splice(this.tels.indexOf(tel), 1);
            this.alertaMsj = {
              content: 'Número de teléfono eliminado',
              type: 'success',
            };
            break;
          default:
            this.alertaMsj = {
              content: 'Hubo un fallo al eliminar el número de teléfono',
              type: 'danger',
            };
            break;
        }
        this.btnDisabled = false;
      }).catch((error) => {
        this.showConfirmacionEliminar = false;
        this.alertaMsj = {
          content: 'Hubo un error al eliminar el número de teléfono',
          type: 'danger',
        };
        this.btnDisabled = false;
      });
    },
    emailDelete(email) {
      if (this.emails.length == '1') {
        return this.alertaMsj = {
          content: 'Debes tener como mínimo un correo',
          type: 'warning',
        };
      }
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${emailDeleteGql}`,
        variables: {
          email: email.email,
          ownerId: this.paciente.persona.id,
        },
      }).then((res) => {
        this.showConfirmacionEliminar = false;
        const msg = res.data.emailDelete.msg;
        switch (msg) {
          case 'success':
            this.emails.splice(this.emails.indexOf(email), 1);
            this.alertaMsj = {
              content: 'Correo eliminado',
              type: 'success',
            };
            break;
          default:
            this.alertaMsj = {
              content: 'Hubo un fallo al eliminar el correo',
              type: 'danger',
            };
            break;
        }
        this.btnDisabled = false;
      }).catch((error) => {
        this.showConfirmacionEliminar = false;
        this.alertaMsj = {
          content: 'Hubo un error al eliminar el correo',
          type: 'danger',
        };
        this.btnDisabled = false;
      });
    },
    // Methods Update
    personUpdate() {
      if (!this.pacienteTemp.fname || !this.pacienteTemp.lname
        || !this.pacienteTemp.identificationType
        || !this.pacienteTemp.identification
        || !this.pacienteTemp.born.formatted
        || !this.pacienteTemp.gender) {
        return this.alertaMsj = {
          content: 'Llena los campos requeridos '
          + 'para actualizar el perfil del paciente',
          type: 'warning',
        };
      }
      const identification = this.pacienteTemp.childNumber
        ? this.pacienteTemp.identification
            .replace(
                new RegExp('-'+this.pacienteTemp.childNumber+'$'), '',
            )
        : this.pacienteTemp.identification;
      if (!validateIdentification(this.identificationType,
          identification)
          || !this.identificationType.value) {
        return this.alertaMsj = {
          content: this.identificationType.mensaje,
          type: 'warning',
        };
      }
      if (this.pacienteTemp.childNumber) {
        this.pacienteTemp.identification = identification + '-'
          + this.pacienteTemp.childNumber;
      } else {
        this.pacienteTemp.childNumber = null;
      }

      // Estandarizando nombres ingresados
      this.pacienteTemp.fname = capitalizeAll(this.pacienteTemp.fname.trim());
      if (this.pacienteTemp.mname) {
        this.pacienteTemp.mname = capitalizeAll(this.pacienteTemp.mname.trim());
      }
      this.pacienteTemp.lname = capitalizeAll(this.pacienteTemp.lname.trim());
      if (this.pacienteTemp.llname) {
        this.pacienteTemp.llname =
          capitalizeAll(this.pacienteTemp.llname.trim());
      }

      const person = {};
      for (const prop in this.pacienteTemp) {
        if (
          Object.prototype.hasOwnProperty.call(this.pacienteTemp, prop)
          && prop != '__typename'
        ) {
          if (typeof this.pacienteTemp[prop] == 'string'
            || typeof this.pacienteTemp[prop] == 'number') {
            person[prop] = this.pacienteTemp[prop];
          }
        }
      }
      person.born = this.pacienteTemp.born.formatted;
      delete person.age;

      const address = this.address.line
        ? {
          line: this.address.line,
          street: this.address.street,
          zip: this.address.zip || null,
          cityId: this.address.cityId || null,
        }
        : null;
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${patientUpdateGql}`,
        variables: {
          person: person,
          address,
        },
      }).then(({data}) => {
        if (data.patientUpdate.msg === 'success') {
          person.age = calculateAge({formatted: person.born});
          person.born ={
            formatted: person.born,
          };
          this.paciente.persona = person;
          this.paciente.persona.address = this.address;
          this.alertaMsj = {
            content: 'Paciente actualizado',
            type: 'success',
          };
          this.showEditPersonalInfo = false;
        } else {
          this.alertaMsj = {
            content: 'Solo puedes modificar registros creados '
              + 'por ti sin cuenta propia',
            type: 'warning',
          };
        }
        this.btnDisabled = false;
      }).catch((error) => {
        this.alertaMsj = {
          content: 'Hubo un error al actualizar los datos',
          type: 'danger',
        };
        this.btnDisabled = false;
      });
    },
    telephoneUpdate(tel) {
      if (!this.telephoneTemp.number) {
        return this.telephoneCreate(tel);
      }
      if (!tel.number || !tel.country) {
        return this.alertaMsj = {
          content: 'Llena el campo para actualizar el número de teléfono',
          type: 'warning',
        };
      }
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${telephoneUpdateGql}`,
        variables: {
          newTel: {
            number: tel.number,
            country: tel.country,
          },
          oldTel: {
            number: this.telephoneTemp.number,
            country: this.telephoneTemp.country,
          },
          ownerId: this.paciente.persona.id,
        },
      }).then((res) => {
        tel.__typename = 'Telephone';
        this.alertaMsj = {
          content: 'Número de teléfono actualizado',
          type: 'success',
        };
        tel.editContactInfo='';
        this.telephoneTemp = {};
        this.btnDisabled = false;
      }).catch((error) => {
        this.alertaMsj = {
          content: 'Hubo un error al actualizar el número de teléfono',
          type: 'danger',
        };
        this.btnDisabled = false;
      });
    },
    emailUpdate(email) {
      if (!(emailSchema.isValidSync(email))) {
        return this.alertaMsj = {
          content: 'Correo no válido',
          type: 'warning',
        };
      }
      if (!this.emailTemp.email) {
        return this.emailCreate(email);
      }
      if (!email.email) {
        return this.alertaMsj = {
          content: 'Llena el campo para actualizar el correo',
          type: 'warning',
        };
      }
      delete email.__typename;
      delete email.editEmailInfo;
      this.btnDisabled = true;
      this.$apollo.mutate({
        mutation: gql`${emailUpdateGql}`,
        variables: {
          email: email,
          oldEmail: this.emailTemp.email,
          ownerId: this.paciente.persona.id,
        },
      }).then((res) => {
        email.__typename = 'Email';
        this.alertaMsj = {
          content: 'Correo actualizado',
          type: 'success',
        };
        email.editEmailInfo='';
        this.emailTemp = {};
        this.btnDisabled = false;
      }).catch((error) => {
        this.alertaMsj = {
          content: 'Hubo un error al actualizar el correo',
          type: 'danger',
        };
        this.btnDisabled = false;
      });
    },
    // Single edition for input
    editTelephone(tel) {
      tel.editContactInfo = !tel.editContactInfo;
      this.tels = [].concat(this.tels);
    },
    editEmail(email) {
      email.editEmailInfo = !email.editEmailInfo;
      this.emails = [].concat(this.emails);
    },
    // Variables Temporals
    telephoneTemporal(tel) {
      Object.assign(this.telephoneTemp, {...JSON.parse(JSON.stringify(tel))});
    },
    emailTemporal(email) {
      Object.assign(this.emailTemp, {...JSON.parse(JSON.stringify(email))});
    },
    cancelTelephoneUpdate(tel) {
      if (!this.telephoneTemp.number) {
        this.tels.pop();
      }
      if (tel.number !== this.telephoneTemp.number
        || tel.country !== this.telephoneTemporal.country) {
        tel.number = this.telephoneTemp.number;
        tel.country = this.telephoneTemp.country;
      }
      this.telephoneTemp = {};
    },
    cancelEmailUpdate(email) {
      if (!this.emailTemp.email) {
        this.emails.pop();
      }
      if (email.email !== this.emailTemp.email) {
        email.email = this.emailTemp.email;
      }
      this.emailTemp = {};
    },
    // General Confirm Delete
    showConfirmation() {
      this.showConfirmacionEliminar = true;
    },
    objectToDelete(obj) {
      this.objectDelete = {};
      this.objectDelete = obj;
    },
    confirmDelete() {
      if (this.objectDelete.__typename == 'Telephone') {
        this.telephoneDelete(this.objectDelete);
      }
      if (this.objectDelete.__typename == 'Email') {
        this.emailDelete(this.objectDelete);
      }
    },
    cleanSelectsLugar() {
      this.stateId = '';
      this.cityId = '';
    },
    fillShowOtherState() {
      this.showOtherState = {
        stateId: this.stateId,
        countryId: this.countryId,
      };
      this.cityId = '';
    },
    fillShowOtherCity() {
      this.showOtherCity = {
        cityId: this.cityId,
        stateId: this.stateId,
      };
    },
    crearCitaYEncuentro() {
      if (!this.lugarSeleccionado.length) {
        return this.alertaMsj = {
          content: 'Selecciona un consultorio',
          type: 'warning',
        };
      }
      const newAppoDateTime = (new Date()).toISOString();
      const d = new Date(newAppoDateTime);
      const z = d.getTimezoneOffset();
      const v = d.valueOf();
      const r = z * 1000*60 + v;
      const da = new Date(r);
      const k = da.getDay();
      const days = [
        'Domingo',
        'Lunes',
        'Martes',
        'Miercoles',
        'Jueves',
        'Viernes',
        'Sabado',
      ];
      this.btnDisabled = true;
      const variablesApollo = {
        appointment: {
          status: 'Booked',
          type: 'En sitio',
          date: newAppoDateTime,
        },
        patient: {
          id: this.paciente.persona.id,
          identification: this.paciente.persona.identification,
          identificationType: this.paciente.persona.identificationType,
          fname: this.paciente.persona.fname,
          lname: this.paciente.persona.lname,
          born: this.paciente.persona.born.formatted,
          gender: this.paciente.persona.gender,
        },
        schedule: {
          start: '',
          end: '',
          day: {
            name: days[k],
          },
        },
        workPlaceId: this.lugarSeleccionado,
      };

      this.$apollo.mutate({
        mutation: gql`${appointmentCreateGql}`,
        variables: variablesApollo,
        context: {serializationKey: 'PERSONS'},
      })
          .then(({data}) => {
            if (data.appointmentCreate.id) {
              variablesApollo.appointment.id = data.appointmentCreate.id;
              variablesApollo.appointment.date = {
                formatted: variablesApollo.appointment.date,
              };
              variablesApollo.appointment.reasons = [];
              this.paciente.citas.push(variablesApollo.appointment);

              this.$nextTick(() => {
                this.encounterCreate();
              });
            }
            if (data.appointmentCreate.insolvencyStatus) {
              this.alertaMsj = {
                content:
                  encounterLimitAlert(data.appointmentCreate.insolvencyStatus),
                type: 'warning',
              };
              this.btnDisabled = false;
            }
          })
          .catch((error) => {
            this.btnDisabled = false;
            return this.alertaMsj = {
              content: 'Hubo un error al crear cita del paciente'+error,
              type: 'danger',
            };
          });
    },
    updateRecord(type) {
      if (this.showAddNote || this.editContactFrom) {
        this.btnDisabled = true;
        this.$apollo.mutate({
          mutation: gql`${recordUpdateGql}`,
          variables: {
            patientId: this.paciente.persona.id,
            record: {
              notes: this.patientNote,
              firstContactFrom: this.tag,
            },
          },
        }).then((res) => {
          const msg = res.data.recordUpdate.msg;
          switch (msg) {
            case 'success':
              this.showAddNote = false;
              this.editContactFrom = false;
              this.alertaMsj = {
                content: 'Información actualizada',
                type: 'success',
              };
              break;
            default:
              this.alertaMsj = {
                content: 'Hubo un error al guardar',
                type: 'warning',
              };
              break;
          }
          this.btnDisabled = false;
        }).catch((error) => {
          this.alertaMsj = {
            content: 'Hubo un error al guardar',
            type: 'danger',
          };
          this.btnDisabled = false;
        });
        return;
      }
      switch (type) {
        case 'nota':
          this.showAddNote = !this.showAddNote;
          break;
        default:
          this.editContactFrom = !this.editContactFrom;
          break;
      }
    },
    addTag() {
      if (this.tag == 'otra' && !this.otherTag) return;
      this.tag = capitalize(this.otherTag.trim());
      this.otherTag = '';
    },
    lostVaccine(immunn) {
      immunn.status = 'lost';
    },
  },
  apollo: {
    paciente() {
      return {
        query: gql`${patientGql}`,
        variables: {
          id: this.$route.params.personId,
          first: 5,
        },
        update: (data) => {
          return {
            persona: data.Patient,
            citas: data.AppointmentWithMeBy,
            address: data.Patient.address,
            record: data.Record,
          };
        },
        result: ({data}) => {
          if (!data || (data && !data.Patient)) return;
          let conditions = data.Patient.conditions;
          this.allergies = data.Patient.allergies;
          this.tels = data.Patient.tels;
          this.emails = data.Patient.emails;
          this.represented = data.Patient.represented;
          this.representedRel = data.Patient.representedRel;
          this.represents = data.Patient.represents;
          this.representsRel = data.Patient.representsRel;
          this.condicionesEspeciales = [];
          this.condicionesTemporales = [];
          if (data.Record) {
            this.patientNote = data.Record.notes || '';
            this.tag = data.Record.firstContactFrom || '';
            if (data.Record.conditions
            && data.Record.conditions.length) {
              conditions = conditions.concat(data.Record.conditions);
            }
          }
          conditions.forEach((cond) => {
            if (cond.type == 'Temporal') {
              if (cond.operation === 'com') {
                cond.end = calcConditionTemporal(cond);
              }
              this.condicionesTemporales.push(cond);
            } else {
              this.condicionesEspeciales.push(cond);
            }
          });
          this.historias = {};
          const histories = data.patientHistory;
          histories.forEach((h) => {
            if (h.value == 'true') {
              h.value = true;
            } else if (h.value == 'false') {
              h.value = false;
            } else if (typeof h.value == 'string'
              && h.value.indexOf('[') == 0 ) {
              h.value = JSON.parse(h.value);
            }
            if (h.item.type == 'boolean' && h.value == null) {
              h.value = false;
            }
            if (!this.historias[h.item.class]) {
              this.historias[h.item.class]=[];
            }
            this.historias[h.item.class].push(h);
          });
          this.paciente.persona.age =
            calculateAge(data.Patient.born);
          const emailPrimary = data.Patient.emails.filter((e) =>
            e.primary);
          this.emailToGravatar = emailPrimary.length
            ? md5(emailPrimary[0].email)
            : ' ';
        },
        fetchPolicy: 'cache-and-network',
      };
    },
    countries() {
      return {
        query: gql`${countriesGql}`,
      };
    },
    states() {
      return {
        query: gql`${statesGql}`,
        variables() {
          return {
            id: this.countryId,
          };
        },
        skip() {
          return this.countryId ? false : true;
        },
      };
    },
    cities() {
      return {
        query: gql`${citiesGql}`,
        variables() {
          return {
            id: this.stateId,
          };
        },
        skip() {
          return this.stateId ? false : true;
        },
      };
    },
    lugares() {
      return {
        query: gql`${doctorWorkPlacesSEGql}`,
        variables: {
          id: getPersonId(),
          date: todayDate(),
        },
        update: (data) => {
          const contactFromTgs = data.Doctor[0].contactFromTgs;
          const tagsSelect = contactFromTgs.concat(tagsDefault);
          this.tagsAvailables = tagsSelect.filter((e) => e != null);
          return data.Doctor[0].works_at;
        },
        fetchPolicy: 'cache-and-network',
      };
    },
    paramsToChart() {
      return {
        query: gql`${paramsWValuesGql}`,
        variables: {
          patientId: this.$route.params.personId,
          date: todayDate(),
        },
        update: (data) => data.paramsWValues,
        fetchPolicy: 'cache-and-network',
      };
    },
    paramObservations() {
      return {
        query: gql`${paramObservationsGql}`,
        variables() {
          return {
            patientId: this.$route.params.personId,
            paramId: this.paramSelected.id,
          };
        },
        update: (data) => data.paramObservations,
        fetchPolicy: 'cache-and-network',
        skip() {
          return this.paramSelected.id ? false : true;
        },
      };
    },
  },
};
</script>
<style lang="scss">
  .list-card {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0 2.2rem 2.2rem 0;
    display: flex;
    flex-direction: row;
    margin: .25rem .5rem;
    overflow-x: hidden;
    padding: .5rem 0 1.5rem .5rem;
    position: relative;
    width: 90%;

    @media (min-width: 576px) {width: 80%;}
    @media (min-width: 768px) {width: calc(94% /2);}
    @media (min-width: 1200px) {width: calc(94% /3);}

    button.col-auto {
      box-shadow: none;
      margin-bottom: -1.5rem;
      margin-right: 0;
      margin-top: -.5rem;
      padding-left: .5rem;
      padding-right: .5rem;
      &:hover {
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
          0 4px 15px 0 rgba(0, 0, 0, 0.15);
      }
      .icon-size {
        height: 16px;
        width: 18px;
      }
    }
    small.text-muted {
      bottom: 2px;
      position: absolute;
      right: 40px;
    }

    &.rectangle {
      border-radius: 5px;
      padding-bottom: .5rem;

      button.col-auto {margin-bottom: -.5rem;}
    }
  }
  .msg-doctor {
    background-color: $green-lighten-5;
    border-radius: 0 20px 20px;
    margin-left: 1.5rem;
    padding-bottom: .5rem;
    padding-top: .5rem;
  }
  .msg-paciente {
    background-color: $blue-lighten-5;
    border-radius: 20px 20px 0;
    margin-right: 1.5rem;
    padding-bottom: .5rem;
    padding-top: .5rem;
  }
  .icon-sticky-note::before {
    @include agregar-icono('\f249')
  }
  @media print {
    .pacient-view {display: none;}
  }
</style>
