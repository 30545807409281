const tagsDefault = [
  {name: 'Directo'},
  {name: 'Facebook'},
  {name: 'Instagram'},
  {name: 'Radio'},
  {name: 'Referido'},
  {name: 'TikTok'},
  {name: 'Telegram'},
  {name: 'Web'},
  {name: 'WhatsApp'},
];
export {tagsDefault};
