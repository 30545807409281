<template>
  <div />
</template>

<script>
const antecedentes = {
  name: 'Antecedente',
  data() {
    return {
      histTypes: {
        boolean: 'Verdadero / Falso',
        date: 'Fecha',
        datetime: 'Fecha y hora',
        decimal: 'Numérico decimal',
        integer: 'Numérico entero',
        rangeDates: 'Rango de fechas',
        range: 'Rango numérico',
        simpleOption: 'Selección simple',
        multipleOption: 'Selección múltiple',
        text: 'Texto',
      },
      histClasses: {
        Personal: 'Personales',
        Family: 'Familiares',
        Habits: 'Hábitos psicobiológicos',
        Pathological: 'Patológicos',
        NoPathological: 'No Patológicos',
        Perinatal: 'Perinatales',
        GynecoObs: 'Gineco obstétricos',
        Other: 'Otros',
      },
    };
  },
};
const histTypes = antecedentes.data().histTypes;
const histClasses = antecedentes.data().histClasses;
export {
  histTypes,
  histClasses,
};
export default antecedentes;
</script>

<style>

</style>
